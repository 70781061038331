import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import useRequiredFields from "../../../../hooks/useRequiredFields";
import DatatableFunc from "../../../Common/DatatableFunc";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import ReactDataTable from "../../../Common/ReactDataTable";
import ReceipeDetail from "./ReceipeDetail";

const Create = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  const [product, setProduct] = useState([]);
  const [rmpm, setRmpm] = useState([]);
  const [factory, setFactory] = useState([]);
  const [list, setList] = useState({});
  const { checkRequiredFields } = useRequiredFields();
  const [receipeNotFound, setReceipeNotFound] = useState("Receipe Not Found");
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("");
  const [me, setMe] = useState({});
  const [showReactDataTable, setShowReactDataTable] = useState(false);
  const [planningOptions, setPlanningOptions] = useState([]);
  const [recipe, setRecipe] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [receiveData, setReceiveData] = useState([]);
  const [filteredFactory, setFilteredFactory] = useState([]); // Initialize state

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const resMe = await Http.get("/me");
        setMe(resMe.data);
        const resRmpm = await Http.get(`/product-list/all`);
        setRmpm(resRmpm.data); // Update list for raw materials data
        const resRole = await Http.get("/staff-type-check");
        setRole(resRole.data);

        if (resRole.data === "mould" || resRole.data === "sagger" || resRole.data === "dstore" || resRole.data === "pstore") {
          const defaultOption = { value: 0, label: "Other" };
          setInputs((values) => ({
            ...values,
            product_id: defaultOption.value,
          }));
          setShowReactDataTable(true);

          // Trigger selectHandleChange manually
          selectHandleChange(defaultOption, { name: "product_id" });
        }

        // Fetch planning options
        if (
          resRole.data === "decoration_local" ||
          resRole.data === "decoration_export" ||
          resRole.data === "packing_local" ||
          resRole.data === "packing_export"
        ) {
          const resPlanning = await Http.get("/planning");
          setPlanningOptions(
            resPlanning.data.map((plan) => ({
              value: plan.id,
              label: plan.receipt_no,
            }))
          );
        }

        const resProduct = await Http.get("/receipe-finished-good");
        const productData = resProduct.data;
        productData.push({ value: 0, label: "Other" });
        setProduct(productData);

        // Automatically set "Other" if role is "mould"
        if (resRole.data === "mould" || resRole.data === "sagger" || resRole.data === "dstore" || resRole.data === "pstore") {
          setInputs((values) => ({ ...values, product_id: 0 })); // Set "Other" as default
          setShowReactDataTable(true); // Show React Data Table
        }

        // if (inputs.product_id == "other") {
        //   const resRmpm = await Http.get("/product-list/raw_materials");
        //   setRmpm(resRmpm.data);
        // }

        const resFactorySelect = await Http.get("/factorySelect2");
        const modifiedFactoryData = resFactorySelect.data.map((factory) => ({
          ...factory,
          // Example: Change or add new properties here
          label: factory.label.toUpperCase(), // Convert label to uppercase
          value: `${factory.department_art_id}`,
        }));
        setFactory(modifiedFactoryData);

        // Automatically set filteredFactory and default factory_id based on role
        const defaultFactory = (() => {
          if (
            resRole.data === "body" ||
            resRole.data === "mould" ||
            resRole.data === "sagger" ||
            resRole.data === "dstore" ||
            resRole.data === "pstore"
          ) {
            return modifiedFactoryData.filter(
              (item) => Number(item.value) === 15
            );
          }
          if (resRole.data === "dstore") {
            return modifiedFactoryData.filter(
              (item) => Number(item.value) === 14
            );
          }
          if (resRole.data === "pstore") {
            return modifiedFactoryData.filter(
              (item) => Number(item.value) === 17
            );
          }
          return modifiedFactoryData.filter(
            (item) => Number(item.value) === 15
          );
        })();

        setFilteredFactory(defaultFactory);

        // Set the default factory_id if available
        if (defaultFactory.length > 0) {
          const defaultFactoryId = defaultFactory[0].value;
          setInputs((values) => ({
            ...values,
            factory_id: defaultFactoryId, // Set the first value as default
          }));
        }
        // Fetch raw materials data for the default factory

        const resBatchNo = await Http.get(
          "/last-code-increment-by-one/requisition_productions/req_no/RP"
        );
        setInputs((values) => ({ ...values, ["batch_no"]: resBatchNo.data }));

        getCurrentDateInput();

        const res = await Http.get("/requisition-art/" + id);
        setReceiveData(res.data);
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };

    DatatableFunc();
    fetchData();
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));

    // if (
    //   inputs.batch_size > 0 &&
    //   name == "batch_size"
    // ) {
    inputs.batch_size = value;
    inputs.receipe_type = "fifo";
    Http.post("/receipe-load", inputs).then((res) => {
      if (res.data.status == 1) {
        setList(res.data.purchase_order_items);
        setRecipe(res.data.info);
        setReceipeNotFound("");
      } else {
        setList(res.data.purchase_order_items);
        setReceipeNotFound("");
      }
    });
    // }
  };

  const handleCustomInputChange = (e, index) => {
    const { name, value } = e.target; // Chanages Experience Input Name and Value
    const inputInfo = [...list]; // Defined Experience State All Information into List Variable

    inputInfo[index][name] = value;
    setList(inputInfo);
  };

  const getCurrentDateInput = () => {
    const dateObj = new Date();

    // get the month in this format of 04, the same for months
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const year = dateObj.getFullYear();

    const shortDate = `${year}-${month}-${day}`;

    setInputs((values) => ({ ...values, ["date"]: shortDate }));
    setInputs((values) => ({
      ...values,
      ["expected_production_date"]: shortDate,
    }));

    // Get the current time in the format "HH:mm"
    const hours = ("0" + dateObj.getHours()).slice(-2);
    const minutes = ("0" + dateObj.getMinutes()).slice(-2);

    const defaultTime = `${hours}:${minutes}`;

    setInputs((values) => ({ ...values, ["time"]: defaultTime }));
  };

  // const formSubmit = () => {
  //   checkRequiredFields();
  //   inputs.items = list;
  //   Http.post("/requisition-production", inputs)
  //     .then((res) => {
  //       navigate("/requisition-production", {
  //         state: { message: res.data.message, type: "success" },
  //       });
  //     })
  //     .catch(function (e) {
  //       setError(e.response.data.errors);
  //     });
  // };

  const formSubmit = () => {
    checkRequiredFields();

    // Create the payload with requisition_art_id
    const payload = {
      ...inputs,
      requisition_art_id: id, // Include the id from useParams as requisition_art_id
      items: list,
    };

    Http.post("/requisition-production", payload)
      .then((res) => {
        navigate("/requisition-production", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch((e) => {
        setError(e.response.data.errors);
      });
  };

  const selectHandleChange = async (option, actionMeta) => {
    const name = actionMeta.name;
    const value = option.value;

    // Update selected option state
    setSelectedOption(option);

    setInputs((values) => ({ ...values, [name]: value }));
    setLoading(true);

    if (value === 0) {
      setShowReactDataTable(true);
      setList([]);
      setReceipeNotFound(null);

      try {
        // const resRmpm = await Http.get("/product-list/all");
        // setRmpm(resRmpm.data); // Update list for raw materials data
      } catch (error) {
        console.error("Error fetching raw materials:", error);
      } finally {
        setLoading(false); // Ensure loading is reset
      }
      return true;
    }

    setShowReactDataTable(false);

    // Update other inputs
    inputs.product_id = value;
    inputs.receipe_type = "fifo";

    Http.post("/receipe-load", inputs).then((res) => {
      setList(res.data.purchase_order_items);
      setInputs((values) => ({
        ...values,
        batch_size: res.data.info.batch_size,
        receipe_id: res.data.info.id,
      }));
      setReceipeNotFound("");
      setLoading(false);
    });
  };

  // const filteredFactory = (() => {
  //   if (role === "body" || role === "mould" || role === "sagger") {
  //     return factory.filter((item) => Number(item.value) === 15);
  //   }
  //   if (role === "dstore") {
  //     return factory.filter((item) => Number(item.value) === 14);
  //   }
  //   if (role === "pstore") {
  //     return factory.filter((item) => Number(item.value) === 17);
  //   }
  //   return factory.filter((item) => Number(item.value) === 15);
  // })();

  const handleSelect2Change = async (selectedOption) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      factory_id: selectedOption.value,
    }));

    if (selectedOption.value > 0) {
      setShowReactDataTable(true);
      setList([]);

      try {
        // const resRmpm = await Http.get(
        //   `/products/store/${selectedOption.value}`
        // );
        // setRmpm(resRmpm.data); // Update list for raw materials data
        setReceipeNotFound("");
        // setList([]);
      } catch (error) {
        console.error("Error fetching raw materials:", error);
      } finally {
        setLoading(false); // Ensure loading is reset
      }

      return true;
    }
  };

  const displayError = (fieldName) => {
    if (error && error[fieldName]) {
      return <div className="text-danger small">{error[fieldName]}</div>;
    }
    return null;
  };

  const addMoreList = async (id) => {
    try {
      const response = await Http.get(`/product/${id}`);
      if (!list.find((item) => item.id === response.data.id)) {
        setList((prev) => [...prev, response.data]);
      } else {
        Swal.fire(
          "Warning!",
          "This Product is already in the list!",
          "warning"
        );
      }
    } catch (error) {
      console.error("Error fetching product:", error);
      Swal.fire("Error!", "Failed to add product.", "error");
    }
  };
  // Remove List
  const removeList = (index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedList = list.filter((_, i) => i !== index);
        setList(updatedList);
        Swal.fire("Deleted!", "Your item has been deleted.", "success");
      }
    });
  };

  const cols = [
    {
      field: (i) => i.code + " || " + i.name,
      header: "Item",
    },
  ];

  const actionTemplate = (item, deleteData) => {
    return (
      <td className="text-right">
        <button
          className="btn btn-success btn-sm"
          onClick={() => addMoreList(item.id)}
        >
          <i className="fa fa-plus"></i>
        </button>
      </td>
    );
  };

  return (
    <div>
      {/* <LoaderComponent /> */}
      <IsLogin></IsLogin>
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Create Store Requisition</h5>
        </div>
        <div className="am-pagebody row d-flex justify-content-evenly">
          <div
            className={`card pd-20 pd-sm-40 ${
              showReactDataTable ||
              role === "decoration_local" ||
              role === "decoration_export" ||
              role === "packing_local" ||
              role === "packing_export" ||
              role === "pstore" ||
              role === "dstore"
                ? "col-lg-7"
                : "col-lg-11"
            }`}
          >
            <h6 className="card-body-title mg-sm-b-30 text-center">
              Create Store Requisition Form
            </h6>
            <hr></hr>
            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg">
                    {receiveData?.items && receiveData.items.length > 0 && (
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="card-solid">
                            <div className="card-header with-border text-center">
                              <h5 className="card-title mb-0">
                                Requisition List
                              </h5>
                            </div>
                            <div className="card-body mb-3 p-0 pt-3">
                              <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0">
                                <thead>
                                  <tr>
                                    {/* <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                  Code
                                </th> */}
                                    <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                      Product Name
                                    </th>
                                    <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                      Required Qty
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {receiveData.items.map((item, index) => (
                                    <tr key={index}>
                                      {/* <td>{item.product.code}</td> */}
                                      <td>
                                        ({item.product.code}){" "}
                                        {item.product.name}
                                      </td>
                                      <td>{item.required_qty}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg">
                    <label>
                      Date <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="date"
                      value={inputs.date || ""}
                      onChange={handleChange}
                      placeholder="Input Date"
                      data-required
                    />

                    {displayError("date")}
                  </div>
                  <div className="col-lg">
                    <label>
                      Expected Time <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      name="time"
                      value={inputs.time || ""}
                      onChange={handleChange}
                      placeholder="Input Time"
                      data-required
                    />
                    {displayError("time")}
                  </div>
                  <div className="col-lg">
                    <label>
                      Req. No. <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="batch_no"
                      className="form-control"
                      name="batch_no"
                      value={inputs.batch_no || ""}
                      onChange={handleChange}
                      placeholder="Input Batch No"
                      data-required
                    />
                    {displayError("batch_no")}
                  </div>
                  {(role === "decoration_local" ||
                    role === "decoration_export" ||
                    role === "packing_local" ||
                    role === "packing_export") && (
                    <div className="col-lg">
                      <label>
                        Planning <i className="fa fa-star required"></i>
                      </label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        name="planning_id"
                        options={planningOptions}
                        onChange={(selectedOption) =>
                          setInputs((values) => ({
                            ...values,
                            planning_id: selectedOption.value,
                          }))
                        }
                        data-required
                      />
                      {displayError("planning_id")}
                    </div>
                  )}
                </div>
                <div className="row mt-3">
                  {(role === "body" ||
                    role === "mould" ||
                    role === "sagger" ||
                    role === "dstore" ||
                    role === "pstore") && (
                    <>
                      {role === "mould" ||
                        (role === "sagger" && (
                          <div className="col-lg">
                            <label htmlFor="">
                              Finished Good{" "}
                              <i className="fa fa-star required"></i>
                            </label>

                            {/* <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={"Select"}
                          name="product_id"
                          options={product}
                          onChange={selectHandleChange}
                          isDisabled={role === "mould" || role === "sagger"} // Disable if role is "mould"
                          data-required
                        /> */}
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              value={selectedOption} // Use selected option state
                              name="product_id"
                              options={product}
                              onChange={selectHandleChange}
                              // isDisabled={role === "mould" || role === "sagger"}
                              data-required
                            />
                            {displayError("product_id")}
                          </div>
                        ))}

                      {!showReactDataTable && (
                        <div className="col-lg">
                          <label>
                            Batch Size <i className="fa fa-star required"></i>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            name="batch_size"
                            value={recipe.batch_size || inputs.batch_size}
                            onChange={handleChange}
                            placeholder="Input Batch Size"
                            data-required
                          />
                          {displayError("batch_size")}
                        </div>
                      )}
                    </>
                  )}
                  <div className="col-lg">
                    <label htmlFor="">Store</label>

                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      name="factory_id"
                      options={filteredFactory} // Use filteredFactory for conditional display
                      onChange={handleSelect2Change}
                      value={filteredFactory.find(
                        (item) => item.value === inputs.factory_id
                      )} // Match the default value
                      // isDisabled
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="card-solid">
                    <div className="card-header with-border text-center">
                      <h5 className="card-title mb-0">Items in the list</h5>
                    </div>
                    <div className="card-body mt-3 p-0 pt-3">
                      <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0">
                        <thead>
                          <tr>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Code
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Product
                            </th>
                            {!showReactDataTable && (
                              <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                Standard Qty
                              </th>
                            )}
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Request Qty
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.from(list).map((x, i) => (
                            <ReceipeDetail
                              key={i}
                              listInfo={x}
                              listSl={i}
                              rmpm={rmpm}
                              remove={() => removeList(i)}
                              listInputChange={(e) =>
                                handleCustomInputChange(e, i)
                              }
                              hideStandardQty={showReactDataTable} // Pass showReactDataTable as hideStandardQty
                            />
                          ))}
                          {receipeNotFound != "" ? (
                            <tr>
                              <td colSpan={5}>
                                <p
                                  style={{ color: "red" }}
                                  className="text-center mt-3"
                                >
                                  {receipeNotFound}
                                </p>
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-3">
                    <button
                      className="btn btn-primary btn-block mg-b-10"
                      onClick={formSubmit}
                    >
                      <i className="fa fa-send mg-r-10"></i> Create
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          {(role === "decoration_local" ||
            role === "decoration_export" ||
            role === "packing_local" ||
            role === "packing_export" ||
            role === "pstore" ||
            role === "dstore" ||
            showReactDataTable) && (
            <>
              <div
                className="card pd-20 pd-sm-40 col-4"
                style={{ backgroundColor: "#ffffffc7" }}
              >
                <h6 className="card-body-title mg-sm-b-30 text-center">
                  Raw &amp; Packaging Materials
                </h6>
                <hr></hr>

                <ReactDataTable
                  cols={cols}
                  receiveData={rmpm}
                  isLoading={loading}
                  actionTemplate={actionTemplate}
                  // deleteData={deleteData}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Create;
