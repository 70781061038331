import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Alert from "../../../Common/Alert";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";

import useAlertFromLocation from "../../../../hooks/useAlertFromLocation";
import useDeleteData from "../../../../hooks/useDeleteData";
import ReactDataTable from "../../../Common/ReactDataTable";

const RepairList = () => {
  const [planningData, setPlanningData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState(""); // State to hold the role
  const [userDepartment, setUserDepartment] = useState(""); // State to hold the role

  // Fetch data from /planning API
  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await Http.get("/defect-list");
      const transferData = res.data.data; // Root-level array

      setPlanningData(transferData);
      // setPlanningData(dummy);

      Http.get("/staff-type-check").then((res) => {
        setRole(res.data);
      });
      Http.get("/me").then((res) => {
        setUserDepartment(res.data.data.department_art_id);
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Use custom hook to delete data
  const deleteData = useDeleteData(fetchData);
  const alert = useAlertFromLocation();

  const receiveSubmitData = (id, showMsg) => {
    Swal.fire({
      title: "Are you sure You Want to Receive this Item?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, receive it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Http.post("/" + deleteApiUrl + "/" + id + "/cash-count").then((res) => {
        //   setPlanningData(res.data);
        //   Swal.fire("Received!", showMsg + " has been deleted.", "success");
        // });
        Http.post(`/transfer-item-received/${id}`)
          .then((res) => {
            Swal.fire("Received!", "Item has been received.", "success");
            fetchData();
          })
          .catch(function (e) {
            Swal.fire("Could not receive!", "Could not receive", "danger");
          });
      }
    });
  };
  /*const produceSubmitData = (id, showMsg) => {
    Swal.fire({
      title: "Are you sure You Want to Produce this Repair Item?",
      text: "You won't be able to revert this! <>",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, produce it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Http.post(`/repair-produced/${id}`)
          .then((res) => {
            Swal.fire("Received!", "Item has been received.", "success");
            fetchData();
          })
          .catch(function (e) {
            Swal.fire("Could not receive!", "Could not receive", "danger");
          });
      }
    });
  };*/

  /*const produceSubmitData = (id, showMsg) => {
    Swal.fire({
      title: "Are you sure You Want to Produce this Repair Item?",
      text: "You won't be able to revert this!",
      icon: "warning",
      input: "number", // Add an input field for quantity
      inputPlaceholder: "Enter quantity",
      inputAttributes: {
        min: 1,
      },
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, produce it!",
      inputValidator: (value) => {
        if (!value || isNaN(value) || parseInt(value) <= 0) {
          return "Please enter a valid quantity!";
        }
      },
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        const qty = result.value; // Get the quantity value from the input
        Http.post(`/repair-produced/${id}`, { qty }) // Pass the quantity in the POST request
          .then((res) => {
            Swal.fire("Received!", "Item has been received.", "success");
            fetchData();
          })
          .catch((e) => {
            Swal.fire("Could not receive!", "Could not receive", "danger");
          });
      }
    });
  };*/

  const produceSubmitData = (id, userType, qty, itemName) => {
    const allowedRolesForLossQty = [
      "selection",
      "packing_local",
      "packing_export",
    ];
    const showLossQty = allowedRolesForLossQty.includes(userType);

    const showDropdown = userType === "selection";

    Swal.fire({
      title: `You Want to Repair ${itemName}?`,
      icon: "warning",
      html: `
        <input type="number" id="qty" value="${qty}" class="swal2-input" placeholder="Enter quantity" min="1">
        ${
          showLossQty
            ? '<input type="number" id="loss_qty" class="swal2-input" placeholder="Enter loss quantity" min="0">'
            : ""
        }
        ${
          showDropdown
            ? `
          <select id="selectionDropdown" class="swal2-input mt-3">
            <option value="" disabled selected>Select Transfer Department</option>
            <option value="9">Decorate Local</option>
            <option value="10">Decorate Export</option>
          </select>
        `
            : ""
        }
      `,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      preConfirm: () => {
        const qty = document.getElementById("qty").value;
        const lossQty = showLossQty
          ? document.getElementById("loss_qty").value
          : null;
        const dropdownValue = showDropdown
          ? document.getElementById("selectionDropdown").value
          : null;

        if (!qty || isNaN(qty) || parseInt(qty) <= 0) {
          Swal.showValidationMessage("Please enter a valid quantity!");
          return false;
        }

        if (
          showLossQty &&
          (lossQty === null ||
            lossQty === "" ||
            isNaN(lossQty) ||
            parseInt(lossQty) < 0)
        ) {
          Swal.showValidationMessage("Please enter a valid loss quantity!");
          return false;
        }

        if (showDropdown && (!dropdownValue || dropdownValue === "")) {
          Swal.showValidationMessage(
            "Please select an option from the dropdown!"
          );
          return false;
        }

        return { qty, loss_qty: lossQty, to_department_id: dropdownValue };
      },
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        const { qty, loss_qty, to_department_id } = result.value;
        Http.post(`/repair-produced/${id}`, { qty, loss_qty, to_department_id }) // Pass all values in the POST request
          .then((res) => {
            Swal.fire("Repaired!", "Item has been repaired.", "success");
            fetchData();
          })
          .catch((e) => {
            Swal.fire("Could not repair!", "Could not repair", "danger");
          });
      }
    });
  };

  /*const produceSubmitData = (id, role) => {
    Swal.fire({
      title: "Are you sure You Want to Produce this Repair Item?",
      html: `
        <div className="mt-3">
          <label for="qtyInput">Enter Quantity:</label>
          <input type="number" id="qtyInput" class="swal2-input" placeholder="Enter quantity" min="1" required>
        </div>
        ${role === "selection" || role === "packing_local" || role === "packing_export"
          ? `
        <div className="mt-3">
          <label for="rejectedQtyInput">Enter Rejected Quantity:</label>
          <input type="number" id="rejectedQtyInput" class="swal2-input" placeholder="Enter rejected quantity" min="0">
        </div>`
          : ""}
      `,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, produce it!",
      preConfirm: () => {
        const qty = document.getElementById("qtyInput").value;
        const rejectedQty = role === "selection" || role === "packing_local" || role === "packing_export"
          ? document.getElementById("rejectedQtyInput").value
          : null;

        if (!qty || isNaN(qty) || parseInt(qty) <= 0) {
          return Swal.showValidationMessage("Please enter a valid quantity.");
        }

        if (rejectedQty !== null && (isNaN(rejectedQty) || parseInt(rejectedQty) < 0)) {
          return Swal.showValidationMessage("Please enter a valid rejected quantity.");
        }

        return { qty, rejectedQty };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const { qty, rejectedQty } = result.value;
        Http.post(`/repair-produced/${id}`, { qty, loss_qty: rejectedQty || 0 })
          .then(() => {
            Swal.fire("Received!", "Item has been produced successfully.", "success");
            fetchData();
          })
          .catch(() => {
            Swal.fire("Error!", "An error occurred while producing the item.", "error");
          });
      }
    });
  };*/

  const cols = [
    { field: "product.name", header: "Product Name" },
    {
      field: (rowData) => rowData.shift?.toUpperCase(),
      header: "Shift",
    },

    
    {
      field: "date",
      header: "Date",
    },
    {
      field: "loss_qty",
      header: "Qty",
    },
    {
      field: "defect_type",
      header: "Type",
    },

    {
          field: (item) => (
            <div className="text-xs text-nowrap">
              <Link
                to={`/edit-selection-decoration-defect/req-art/${item.id}`}
                className="btn btn-secondary btn-sm ms-4"
              >
                <i className="fa fa-edit"></i> Edit
              </Link>
            </div>
          ),
          header: "Actions",
        },

  ];

  // const cols = [
  //   { field: "receipt_no", header: "Receipt No" },
  //   { field: "product_name", header: "Product Name" },
  //   { field: "shape", header: "Shape" },
  //   { field: "decoration", header: "Decor" },
  //   { field: "qty", header: "Qty Exp" },
  //   // { field: "qty_export", header: "Qty Exp" },
  //   // { field: "qty_a_class", header: "Qty A" },
  //   // { field: "qty_b_class", header: "Qty B" },
  //   // { field: "qty_c_class", header: "Qty C" },

  //   { field: "transfer_date", header: "Tr Date" },
  //   { field: "receive_date", header: "Rec Date" },
  //   { field: "from_department_art.name", header: "From" },
  //   { field: "to_department_art.name", header: "To" },
  //   { field: "status", header: " Status" },
  //   {
  //     field: (item) => (
  //       <div className="text-xs text-nowrap">
  //         {item.status === "Pending" ? (
  //           <Link
  //             className="btn btn-primary btn-sm ms-1"
  //             to={`/create-receive/${item.id}/req-art`}
  //           >
  //             <i className="fa fa-send"></i> Receive
  //           </Link>
  //         ) : (
  //           <i>Received</i>
  //         )}
  //       </div>
  //     ),
  //     header: "Actions",
  //   },
  // ];

  return (
    <div>
      <IsLogin />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">All Defects</h5>
        </div>
        <div className="am-pagebody">
          <Alert alert={alert} />
          <div className="card pd-20 pd-sm-40">
            <ReactDataTable
              cols={cols}
              receiveData={planningData}
              isLoading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepairList;
