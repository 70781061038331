import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Alert from "../../../Common/Alert";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";

import useAlertFromLocation from "../../../../hooks/useAlertFromLocation";
import useDeleteData from "../../../../hooks/useDeleteData";
import ReactDataTable from "../../../Common/ReactDataTable";

const PlanningList = () => {
  const [planningData, setPlanningData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState(""); // State to hold the role
  const [userDepartment, setUserDepartment] = useState(""); // State to hold the role

  // Fetch data from /planning API
  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await Http.get("/transfer-list");
      const transferData = res.data; // Root-level array

      setPlanningData(transferData);
      // setPlanningData(dummy);

      Http.get("/staff-type-check").then((res) => {
        setRole(res.data);
      });
      Http.get("/me").then((res) => {
        setUserDepartment(res.data.data.department_art_id);
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Use custom hook to delete data
  const deleteData = useDeleteData(fetchData);
  const alert = useAlertFromLocation();

  const receiveSubmitData = (id, showMsg) => {
    Swal.fire({
      title: "Are you sure You Want to Receive this Item?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, receive it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Http.post("/" + deleteApiUrl + "/" + id + "/cash-count").then((res) => {
        //   setPlanningData(res.data);
        //   Swal.fire("Received!", showMsg + " has been deleted.", "success");
        // });
        Http.post(`/transfer-item-received/${id}`)
          .then((res) => {
            Swal.fire("Received!", "Item has been received.", "success");
            fetchData();
          })
          .catch(function (e) {
            Swal.fire("Could not receive!", "Could not receive", "danger");
          });
      }
    });
  };
  const produceSubmitData = (id, showMsg) => {
    Swal.fire({
      title: "Are you sure You Want to Produce this Repair Item?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, produce it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Http.post(`/repair-produced/${id}`)
          .then((res) => {
            Swal.fire("Received!", "Item has been received.", "success");
            fetchData();
          })
          .catch(function (e) {
            Swal.fire("Could not receive!", "Could not receive", "danger");
          });
      }
    });
  };

  const cols = [
    {
      field: "production_finished_good_transfer.receipt_no",
      header: "Receipt No",
    },
    { field: "product.name", header: "Product Name" },

    ...(role !== "body" || role === "admin"
      ? [{ field: "shape.name", header: "Shape" }]
      : []),
    // ...(role === "dfw" ||
    // role === "packing_export" ||
    // role === "packing_local" ||
    // role === "admin"
    //   ? [{ field: "decoration", header: "Decor" }]
    //   : []),
    ...(role === "admin" ||
    (role !== "decoration_local" &&
      role !== "decoration_export" &&
      role !== "dfw" &&
      role !== "packing_local" &&
      role !== "packing_export")
      ? [{ field: "delivery_qty", header: "Qty" }]
      : []),
    ...(role === "admin" ||
    role === "decoration_export" ||
    role === "dfw" ||
    role === "selection" ||
    role === "packing_export"
      ? [{ field: "delivery_qty_export", header: "Qty Exp" }]
      : []),
    ...(role === "admin" ||
    role === "decoration_local" ||
    role === "dfw" ||
    role === "selection" ||
    role === "packing_local"
      ? [
          { field: "delivery_qty_a_class", header: "Qty A" },
          { field: "delivery_qty_b_class", header: "Qty B" },
          { field: "delivery_qty_c_class", header: "Qty C" },
        ]
      : []),
    {
      field: "production_finished_good_transfer.transfer_date",
      header: "Tr Date",
    },
    {
      field: "receive_date",
      header: "Rec Date",
    },
    { field: "from_department_art.name", header: "From" },
    { field: "to_department_art.name", header: "To" },
    {
      field: (item) => {
        if (item.from_department_art_id === userDepartment) {
          // From department logic
          return (role !== "dfw" &&
            role !== "decoration_local" &&
            role !== "packing_local" &&
            role !== "decoration_export" &&
            role !== "packing_export" &&
            role !== "selection" &&
            item.receive_qty < 1) ||
            ((role === "dfw" || role === "selection") &&
              item.receive_qty_export < 1 &&
              item.receive_qty_a_class < 1 &&
              item.receive_qty_b_class < 1 &&
              item.receive_qty_c_class < 1) ||
            ((role === "decoration_local" || role === "packing_local") &&
              item.receive_qty_a_class < 1 &&
              item.receive_qty_b_class < 1 &&
              item.receive_qty_c_class < 1) ||
            ((role === "decoration_export" || role === "packing_export") &&
              item.receive_qty_export < 1) ? (
            <span className="badge bg-info">Delivered</span>
          ) : (
            <span className="badge bg-success">Received</span>
          );
        } else if (item.to_department_art_id === userDepartment) {
          // To department logic
          return (role !== "dfw" &&
            // role !== "packing_local" &&
            // role !== "decoration_export" &&
            // role !== "packing_export" &&
            item.receive_qty < 1) ||
            (role === "dfw" &&
              item.receive_qty_export < 1 &&
              item.receive_qty_a_class < 1 &&
              item.receive_qty_b_class < 1 &&
              item.receive_qty_c_class < 1) ||
            ((role === "decoration_local" || role === "packing_local") &&
              item.receive_qty < 1 &&
              item.receive_qty_a_class < 1 &&
              item.receive_qty_b_class < 1 &&
              item.receive_qty_c_class < 1) ||
            ((role === "decoration_export" || role === "packing_export") &&
            item.receive_qty < 1 && item.receive_qty_export < 1) ? (
            <span className="badge bg-warning">Pending</span>
          ) : (
            <span className="badge bg-success">Received</span>
          );
        } else {
          // Default fallback (if neither department matches)
          return <span className="badge bg-secondary">Unknown</span>;
        }
      },
      header: " Status ",
    },
    // {
    //   field: (item) => (
    //     <div className="text-xs text-nowrap">
    //       {item.receive_qty < 1 &&
    //       item.to_department_art_id == userDepartment ? (
    //         <button
    //           className="btn btn-primary btn-sm ms-1"
    //           onClick={() => receiveSubmitData(item.id)}
    //         >
    //           <i className="fa fa-send"></i> Receive
    //         </button>
    //       ) : item.receive_qty < 1 ? (
    //         <i>Pending</i>
    //       ) : (
    //         <i
    //           className="fa fa-check"
    //           style={{ color: "green", fontWeight: "bold" }}
    //         >
    //           {" "}
    //           Received
    //         </i>
    //       )}
    //     </div>
    //   ),
    //   header: "Actions",
    // },

    {
      field: (item) => (
        <div className="text-xs text-nowrap">
          {item.production == null &&
          item.to_department_art_id === userDepartment &&
          item.is_repair == 1 &&
          item.receive_qty > 1 ? (
            <button
              className="btn btn-warning btn-sm ms-1"
              onClick={() => produceSubmitData(item.id)}
            >
              <i className="fa fa-cogs"></i> Produce
            </button>
          ) : (role !== "dfw" &&
              // role !== "decoration_local" &&
              // role !== "packing_local" &&
              // role !== "decoration_export" &&
              // role !== "packing_export" &&
              item.receive_qty < 1 &&
              item.to_department_art_id === userDepartment) ||
            (role === "dfw" &&
              item.receive_qty_export < 1 &&
              item.receive_qty_a_class < 1 &&
              item.receive_qty_b_class < 1 &&
              item.receive_qty_c_class < 1 &&
              item.to_department_art_id === userDepartment) ||
            ((role === "decoration_local" || role === "packing_local") &&
              item.receive_qty < 1 &&
              item.receive_qty_a_class < 1 &&
              item.receive_qty_b_class < 1 &&
              item.receive_qty_c_class < 1 &&
              item.to_department_art_id === userDepartment) ||
            ((role === "decoration_export" || role === "packing_export") &&
              item.receive_qty < 1 &&
              item.receive_qty_export < 1 &&
              item.to_department_art_id === userDepartment) ? (
            <>
              <button
                className="btn btn-primary btn-sm ms-1"
                onClick={() => receiveSubmitData(item.id)}
              >
                <i className="fa fa-send"></i> Receive
              </button>
            </>
          ) : (role !== "dfw" &&
              role !== "decoration_local" &&
              role !== "packing_local" &&
              role !== "decoration_export" &&
              role !== "packing_export" &&
              role !== "selection" &&
              item.receive_qty < 1) ||
            ((role === "dfw" || role === "selection") &&
              item.receive_qty_export < 1 &&
              item.receive_qty_a_class < 1 &&
              item.receive_qty_b_class < 1 &&
              item.receive_qty_c_class < 1 &&
              item.from_department_art_id === userDepartment) ||
            ((role === "decoration_local" || role === "packing_local") &&
              item.receive_qty_a_class < 1 &&
              item.receive_qty_b_class < 1 &&
              item.receive_qty_c_class < 1 && item.from_department_art_id === userDepartment) ||
            ((role === "decoration_export" || role === "packing_export") &&
              item.receive_qty_export < 1 && item.from_department_art_id === userDepartment) ? (
            <>
            {(role === "packing_local" || role === "packing_export") ? (
                // {role === "dfw" && (
                <>
                <i>To Transferred</i> &nbsp;
                <Link
                  to={`/edit-production-jigcast-decoration-tr/req-art/${item.production_finished_good_transfer_id}`}
                  className="btn btn-secondary btn-sm ms-1"
                >
                  <i className="fa fa-edit"></i> Edit
                </Link>
                </>
              ):(
                <>
              <i>Pending</i> &nbsp;
              {/* {role === "body" ? (
                <Link
                  to={`/edit-department-transfer/${item.id}`}
                  className="btn btn-secondary btn-sm ms-1"
                >
                  <i className="fa fa-edit"></i> Edit
                </Link>
              ) : (
                <Link
                  to={`/edit-transfer-uu/${item.id}`}
                  className="btn btn-secondary btn-sm ms-1"
                >
                  <i className="fa fa-edit"></i> Edit
                </Link>
              )} */}
              {/* {role === "body" && (
                <Link
                  to={`/edit-department-transfer/${item.id}`}
                  className="btn btn-secondary btn-sm ms-1"
                >
                  <i className="fa fa-edit"></i> Edit
                </Link>
              )} */}
              {(role === "finishing" ||
                role === "bk" ||
                role === "glazing" ||
                role === "gk") && (
                <Link
                  to={`/edit-production-jigcast-till-selection/req-art/${item.production_finished_good_transfer_id}`}
                  className="btn btn-secondary btn-sm ms-1"
                >
                  <i className="fa fa-edit"></i> Edit
                </Link>
              )}
              {(role === "selection" || role === "dfw") && (
                <Link
                  to={`/edit-production-jigcast-selection-tr/req-art/${item.production_finished_good_transfer_id}`}
                  className="btn btn-secondary btn-sm ms-1"
                >
                  <i className="fa fa-edit"></i> Edit
                </Link>
              )}
              </>
              )}
              
              {/* {!(
                role === "body" ||
                role === "finishing" ||
                role === "bk" ||
                role === "glazing" ||
                role === "gk" ||
                role === "selection" ||
                role === "decoration_local" ||
                role === "decoration_export" ||
                role === "dfw" ||
                role === "packaging_local" ||
                role === "packaging_export"
              ) && (
                <Link
                  to={`/edit-transfer-uu/${item.id}`}
                  className="btn btn-secondary btn-sm ms-1"
                >
                  <i className="fa fa-edit"></i> Edit
                </Link>
              )} */}
            </>
          ) : (
            <i
              className="fa fa-check"
              style={{ color: "green", fontWeight: "bold" }}
            >
              {" "}
              Received
            </i>
          )}
        </div>
      ),
      header: "Actions",
    },

    /* old condition for action column (without exp,a,b,c qtys)
    {
      field: (item) => (
        <div className="text-xs text-nowrap">
          {item.production == null &&
          item.to_department_art_id === userDepartment &&
          item.is_repair == 1 &&
          item.receive_qty > 1 ? (
            <button
              className="btn btn-warning btn-sm ms-1"
              onClick={() => produceSubmitData(item.id)}
            >
              <i className="fa fa-cogs"></i> Produce
            </button>
          ) : item.receive_qty < 1 &&
            item.to_department_art_id === userDepartment ? (
            <button
              className="btn btn-primary btn-sm ms-1"
              onClick={() => receiveSubmitData(item.id)}
            >
              <i className="fa fa-send"></i> Receive
            </button>
          ) : item.receive_qty < 1 ? (
            <i>Pending</i>
          ) : (
            <i
              className="fa fa-check"
              style={{ color: "green", fontWeight: "bold" }}
            >
              {" "}
              Received
            </i>
          )}
        </div>
      ),
      header: "Actions",
    },
    */
  ];

  // const cols = [
  //   { field: "receipt_no", header: "Receipt No" },
  //   { field: "product_name", header: "Product Name" },
  //   { field: "shape", header: "Shape" },
  //   { field: "decoration", header: "Decor" },
  //   { field: "qty", header: "Qty Exp" },
  //   // { field: "qty_export", header: "Qty Exp" },
  //   // { field: "qty_a_class", header: "Qty A" },
  //   // { field: "qty_b_class", header: "Qty B" },
  //   // { field: "qty_c_class", header: "Qty C" },

  //   { field: "transfer_date", header: "Tr Date" },
  //   { field: "receive_date", header: "Rec Date" },
  //   { field: "from_department_art.name", header: "From" },
  //   { field: "to_department_art.name", header: "To" },
  //   { field: "status", header: " Status" },
  //   {
  //     field: (item) => (
  //       <div className="text-xs text-nowrap">
  //         {item.status === "Pending" ? (
  //           <Link
  //             className="btn btn-primary btn-sm ms-1"
  //             to={`/create-receive/${item.id}/req-art`}
  //           >
  //             <i className="fa fa-send"></i> Receive
  //           </Link>
  //         ) : (
  //           <i>Received</i>
  //         )}
  //       </div>
  //     ),
  //     header: "Actions",
  //   },
  // ];

  return (
    <div>
      <IsLogin />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">All Transfers</h5>
          {(role === "jigger" || role === "casting") && (
            <Link
              to="/create-delivery-jigcast/req-art"
              className="btn btn-primary"
            >
              <i className="fa fa-plus-square mg-r-6"></i> Production
            </Link>
          )}
          {(role === "finishing" ||
            role === "bk" ||
            role === "glazing" ||
            role === "gk") && (
            <Link
              to="/create-delivery-jigcast-till-selection/req-art"
              className="btn btn-primary"
            >
              <i className="fa fa-plus-square mg-r-6"></i> Production
            </Link>
          )}
          {(role === "selection" || role === "dfw") && (
            <div>
              <Link
                to="/create-delivery-jigcast-selection-tr/req-art"
                className="btn btn-success me-4"
              >
                {/* <i className="fa fa-plus-square mg-r-6"></i> Transfer */}
                <i className="fa fa-paper-plane"></i> Transfer
              </Link>
              {role === "selection" && (
                <Link
                  to="/create-delivery-jigcast-selection/req-art"
                  className="btn btn-primary"
                >
                  <i className="fa fa-plus-square mg-r-6"></i> Production
                </Link>
              )}
              {/* {role === "dfw" && (
                <Link
                to="/create-delivery-jigcast-decoration/req-art"
                className="btn btn-primary"
              >
                <i className="fa fa-plus-square mg-r-6"></i> Production
              </Link>
              )} */}
            </div>
          )}
          {(role === "decoration_local" ||
            role === "decoration_export" ||
            role === "packing_local" ||
            role === "packing_export") && (
            <Link
              to="/create-delivery-jigcast-decoration/req-art"
              className="btn btn-primary"
            >
              <i className="fa fa-plus-square mg-r-6"></i> Production
            </Link>
          )}
        </div>
        <div className="am-pagebody">
          <Alert alert={alert} />
          <div className="card pd-20 pd-sm-40">
            <ReactDataTable
              cols={cols}
              receiveData={planningData}
              isLoading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanningList;
