import $ from "jquery"; // Import jQuery
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import useRequiredFields from "../../../../../hooks/useRequiredFields";
import Http from "../../../../Common/Http";
import IsLogin from "../../../../Common/IsLogin";
import { LoaderComponent } from "../../../../Common/LoaderComponent";

const Create = () => {
  const { checkRequiredFields } = useRequiredFields();
  const navigate = useNavigate();
  const { id } = useParams();

  const [inputs, setInputs] = useState({
    receipt_no: "",
    production_work_order_id: "",
    date: "",
    receive_date: "",
    copy_type: "",
    purchase_type: "",
    from_department: "",
    to_department: "",
    status: "requested",
    remarks: "",
  });

  const [list, setList] = useState([]);
  const [error, setError] = useState({});
  const [product, setProduct] = useState([]);
  const [planningOptions, setPlanningOptions] = useState([]);
  const [role, setRole] = useState("");
  const [itemLoading, setItemLoading] = useState(false);
  const [receiveData, setReceiveData] = useState([]);

  // const [departments, setDepartments] = useState([
  //   { id: 1, name: "BODY", user_type: "body" },
  //   { id: 2, name: "JIGGER", user_type: "jigger" },
  //   { id: 3, name: "CASTING", user_type: "casting" },
  //   { id: 4, name: "FINISHING", user_type: "finishing" },
  //   { id: 5, name: "BK", user_type: "bk" },
  //   { id: 6, name: "GLAZING", user_type: "glazing" },
  //   { id: 7, name: "GK", user_type: "gk" },
  //   { id: 8, name: "SELECTION", user_type: "selection" },
  //   { id: 9, name: "DECORATION LOCAL", user_type: "decoration_local" },
  //   { id: 10, name: "DECORATION EXPORT", user_type: "decoration_export" },
  //   { id: 11, name: "DFW", user_type: "dfw" },
  //   { id: 12, name: "PACKING LOCAL", user_type: "packing_local" },
  //   { id: 13, name: "PACKING EXPORT", user_type: "packing_export" },
  //   { id: 14, name: "DSTORE", user_type: "dstore" },
  //   { id: 15, name: "GSTORE", user_type: "gstore" },
  //   { id: 16, name: "MOULD", user_type: "mould" },
  //   { id: 17, name: "PSTORE", user_type: "pstore" },
  //   { id: 18, name: "PLANNING", user_type: "planning" },
  //   { id: 19, name: "SAGGER", user_type: "sagger" },
  // ]);

  // const response = {
  //   data: [
  //     {
  //       id: 1,
  //       product_name: "Product A",
  //       rec_a: 100,
  //       tr_a: 20,
  //       rec_b: 80,
  //       tr_b: 10,
  //       rec_c: 60,
  //       tr_c: 5,
  //       rec_export: 50,
  //       tr_export: 10,
  //       decoration: "ABC",
  //     },
  //     {
  //       id: 2,
  //       product_name: "Product B",
  //       rec_a: 200,
  //       tr_a: 50,
  //       rec_b: 150,
  //       tr_b: 30,
  //       rec_c: 120,
  //       tr_c: 10,
  //       rec_export: 90,
  //       tr_export: 15,
  //     },
  //     {
  //       id: 3,
  //       product_name: "Product C",
  //       rec_a: 50,
  //       tr_a: 10,
  //       rec_b: 40,
  //       tr_b: 5,
  //       rec_c: 30,
  //       tr_c: 2,
  //       rec_export: 20,
  //       tr_export: 5,
  //     },
  //   ],
  // };

  const dummyDecorations = [
    "Gold Rim",
    "Platinum Rim",
    "Flower Design",
    "Leaf Pattern",
    "Textured Edge",
  ];

  // const defectTypes = ["BN", "IS", "PH", "GD", "UG"];
  // const repairTypes = ["PH", "SS", "AIR", "PC"];

  // Fetch the requisition data by ID and populate the form inputs
  // const fetchData = () => {
  //   Http.get("/product-list/all").then((res) => {
  //     setProduct(res.data);
  //   });

  //   Http.get("/staff-type-check").then((res) => {
  //     setRole(res.data);
  //   });

  //   Http.get("/planning")
  //     .then((res) => {
  //       setPlanningOptions(res.data); // Set planning options
  //     })
  //     .catch((e) => {
  //       console.error("Error fetching planning options:", e);
  //     });
  // };

  const fetchData = async () => {
    try {
      // const resProduct = await Http.get("/product-list/all");
      // setProduct(resProduct.data);

      const redData = await Http.get("/production-transfer/" + id + "/edit");
      setReceiveData(redData.data);
      setInputs((prevInputs) => ({
        ...prevInputs,
        planning_id: redData.data.data.planning_id, // Assuming the ID field for planning
        shift: redData.data.data.shift,
      }));
      handlePlanningChange(redData.data.data.planning_id);

      const resRole = await Http.get("/staff-type-check");
      setRole(resRole.data);

      const resPlanning = await Http.get("/planning");
      const planningData = resPlanning.data;
      setPlanningOptions(planningData);

      // if (planningData.length > 0) {
      //   // Automatically select the first planning and set in inputs
      //   const firstPlanning = planningData[0];
      //   setInputs((prevInputs) => ({
      //     ...prevInputs,
      //     planning_id: firstPlanning.id, // Assuming the ID field for planning
      //   }));

      //   // Load items for the first planning
      //   handlePlanningChange(firstPlanning.id);
      // }
      // setList(response.data);
      // Fetch the receipt number
      const resReceiptNo = await Http.get(
        "/last-code-increment-by-one/production_finished_goods/receipt_no/P"
      );
      const receiptNo = resReceiptNo.data; // Assuming the API returns the receipt number directly

      // Set the fetched receipt number to inputs
      setInputs((prevInputs) => ({
        ...prevInputs,
        receipt_no: receiptNo,
      }));
    } catch (e) {
      console.error("Error fetching data:", e);
    }
  };

  useEffect(() => {
    $("body").addClass("collapse-menu");
    // $("body").toggleClass("show-left");
    fetchData();

    // // Set `to_department` based on `role`
    // if (role === "jigger") {
    //   setInputs((prevInputs) => ({
    //     ...prevInputs,
    //     to_department:
    //       departments.find((dept) => dept.name === "FINISHING")?.id || "",
    //   }));
    // } else if (role === "casting") {
    //   setInputs((prevInputs) => ({
    //     ...prevInputs,
    //     to_department: departments.find((dept) => dept.name === "BK")?.id || "",
    //   }));
    // }
  }, []);

  const handleAddType = (index, type) => {
    const updatedList = [...list];
    const newEntry = { type: "", qty: 0 };

    if (type === "defect") {
      updatedList[index].defects = [
        ...(updatedList[index].defects || []),
        newEntry,
      ];
    } else if (type === "repair") {
      updatedList[index].repairs = [
        ...(updatedList[index].repairs || []),
        newEntry,
      ];
    }

    setList(updatedList);
  };

  const handleTypeChange = (index, subIndex, value, type) => {
    const updatedList = [...list];
    if (type === "defect") {
      updatedList[index].defects[subIndex].type = value;
    } else if (type === "repair") {
      updatedList[index].repairs[subIndex].type = value;
    }
    setList(updatedList);
  };

  const handleQtyChange = (index, subIndex, value, type) => {
    const updatedList = [...list];
    const quantity = parseInt(value) || 0;
    if (type === "defect") {
      updatedList[index].defects[subIndex].qty = quantity;
    } else if (type === "repair") {
      updatedList[index].repairs[subIndex].qty = quantity;
    }
    setList(updatedList);
  };

  const handleRemoveType = (index, subIndex, type) => {
    const updatedList = [...list];
    if (type === "defect") {
      updatedList[index].defects.splice(subIndex, 1);
    } else if (type === "repair") {
      updatedList[index].repairs.splice(subIndex, 1);
    }
    setList(updatedList);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleDecorationChange = (index, value) => {
    const updatedList = [...list];
    updatedList[index].decoration = value; // Update the decoration for the specific item
    setList(updatedList);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedList = [...list];
    let inputValue = parseFloat(value) || 0;

    // const limits = {
    //   qty_a_class: updatedList[index].rec_a - (updatedList[index].tr_a || 0),
    //   qty_b_class: updatedList[index].rec_b - (updatedList[index].tr_b || 0),
    //   qty_c_class: updatedList[index].rec_c - (updatedList[index].tr_c || 0),
    //   qty_export:
    //     updatedList[index].rec_export - (updatedList[index].tr_export || 0),
    // };

    const limits = {
      qty_a_class:
        updatedList[index].received_qty_class?.a_class -
        (updatedList[index].transfered_qty_class?.a_class || 0),
      qty_b_class:
        updatedList[index].received_qty_class?.b_class -
        (updatedList[index].transfered_qty_class?.b_class || 0),
      qty_c_class:
        updatedList[index].received_qty_class?.c_class -
        (updatedList[index].transfered_qty_class?.c_class || 0),
      qty_export:
        updatedList[index].received_qty_class?.export_class -
        (updatedList[index].transfered_qty_class?.export_class || 0),
    };

    if (inputValue > (limits[name] || Infinity)) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: `${name
          .replace("qty_", "")
          .toUpperCase()} Quantity exceeds the limit.`,
      });
      updatedList[index][name] = limits[name];
    } else {
      updatedList[index][name] = inputValue;
    }

    setList(updatedList);
  };

  const getRandomDecoration = () => {
    const randomIndex = Math.floor(Math.random() * dummyDecorations.length);
    return dummyDecorations[randomIndex];
  };

  const formSubmit = () => {
    checkRequiredFields();
    const formData = {
      ...inputs,
      items: list,
    };

    Http.put(`/production-transfer/${id}`, formData)
      .then((res) => {
        navigate("/transfer-art", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  const handlePlanningChange = async (planningId) => {
    if (!planningId) return;

    setInputs((prevInputs) => ({
      ...prevInputs,
      planning_id: planningId, // Update planning_id in inputs
    }));

    try {
      // Fetch data based on selected planning ID
      const response = await Http.get(`/planning/${planningId}`);

      // Update list with items from the response
      const planningItems = response.data.items.map((item) => ({
        id: item.id,
        product_id: item.product_id,
        product_name: item.product.name,
        shape: item.shape?.name,
        planning_qty: item.qty,
        production_work_order: item.production_work_order?.receipt_no,
        company: item.production_work_order?.company?.name,
        decoration: item.decoration,
        product_code: item.product.code,
        opening_balance: 0, // Adjust based on requirement or add additional fields as needed
        weight: item.weight, // Example if qty is used as weight
        // production_qty: item.qty,
        transfer_qty: 0,
        received_qty_class_exp:
          item.received_qty_class?.receive_qty_export_class,
        received_qty_class_a: item.received_qty_class?.a_class,
        received_qty_class_b: item.received_qty_class?.b_class,
        received_qty_class_c: item.received_qty_class?.c_class,
        transfered_qty_class_exp:
          item.transfered_qty_class?.delivery_qty_export_class,
        transfered_qty_class_a: item.transfered_qty_class?.a_class,
        transfered_qty_class_b: item.transfered_qty_class?.b_class,
        transfered_qty_class_c: item.transfered_qty_class?.c_class,
        closing_balance: item.qty, // Example calculation; adjust if necessary
        remarks_delivery: "", // Set to empty or adjust if needed
      }));

      setList(planningItems);

      const productionTransferResponse = await Http.get(
        `/production-transfer/${id}/edit`
      );
      const productionTransferItems =
        productionTransferResponse.data.data.items;

      setList((prevList) =>
        prevList.map((item) => {
          const transferItem = productionTransferItems.find(
            (tItem) => tItem.planning_item_id === item.id
          );
          return {
            ...item,
            qty_export: transferItem
              ? transferItem.delivery_qty_export
              : item.delivery_qty_export,
            qty_a_class: transferItem
              ? transferItem.delivery_qty_a_class
              : item.delivery_qty_a_class,
            qty_b_class: transferItem
              ? transferItem.delivery_qty_b_class
              : item.delivery_qty_b_class,
            qty_c_class: transferItem
              ? transferItem.delivery_qty_c_class
              : item.delivery_qty_c_class,
          };
        })
      );
    } catch (error) {
      console.error("Error fetching products for planning:", error);
      Swal.fire(
        "Error!",
        "Failed to fetch products for the selected planning.",
        "error"
      );
    }
  };

  const cols = [{ field: "name", header: "Item" }];

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Production and Deliver</h5>
        </div>
        <div className="am-pagebody  row d-flex justify-content-evenly">
          <div className="card pd-20 pd-sm-40 col-11">
            <h6 className="card-body-title mg-sm-b-30">
              Production and Delivery
            </h6>
            {/* <p>Transfer To Department {inputs.to_department}</p> */}
            <div className="row">
              <div className="col-lg">
                <label>Receipt No</label>
                <input
                  type="text"
                  className="form-control"
                  name="receipt_no"
                  value={inputs.receipt_no}
                  disabled
                />
              </div>

              {/* <div className="col-lg">
                <label>Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  value={inputs.date}
                  disabled
                />
              </div> */}
              <div className="col-lg">
                <label>Planning</label>
                <select
                  className="form-control"
                  value={inputs.planning_id || ""} // Bind to inputs
                  onChange={(e) => handlePlanningChange(e.target.value)}
                >
                  <option value="">Select Planning</option>
                  {planningOptions.map((planning) => (
                    <option key={planning.id} value={planning.id}>
                      {planning.receipt_no}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg">
                <label>
                  Shift <i className="fa fa-star required"></i>
                </label>
                <select
                  className="form-control"
                  name="shift"
                  value={inputs.shift}
                  onChange={handleChange}
                  data-required
                >
                  <option value="">Select Shift</option>
                  <option value="a">A</option>
                  <option value="b">B</option>
                  <option value="c">C</option>
                  <option value="general">General</option>
                </select>
              </div>

              {/* <div className="row">
                <label>Transfer To Department {inputs.to_department}</label>
                
              </div> */}

              <div className="col-lg">
                <label>Remarks</label>
                <textarea
                  className="form-control"
                  name="remarks"
                  value={inputs.remarks}
                />
              </div>
            </div>

            {/* Item List */}
            <div className="row mt-3">
              <div className="card-solid">
                <div className="card-header with-border text-center">
                  <h5 className="card-title mb-0">Items in the Production</h5>
                </div>
              </div>
              <div className="col-lg">
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      {/* <th>Planning Qty</th>
                      {(role === "dfw" ||
                        role === "decoration_export" ||
                        role === "packing_export") && <th>Rec Exp</th>}
                      {(role === "dfw" ||
                        role === "decoration_local" ||
                        role === "packing_local") && (
                        <>
                          <th>Rec A</th>
                          <th>Rec B</th>
                          <th>Rec C</th>
                        </>
                      )}
                      {(role === "dfw" ||
                        role === "decoration_export" ||
                        role === "packing_export") && <th>Tr Exp</th>}
                      {(role === "dfw" ||
                        role === "decoration_local" ||
                        role === "packing_local") && (
                        <>
                          <th>Tr A</th>
                          <th>Tr B</th>
                          <th>Tr C</th>
                        </>
                      )}
                      <th>Decoration</th>

                      <th>Opening Balance</th>

                      <th>Select Qty</th> */}
                      {(role === "dfw" ||
                        role === "decoration_export" ||
                        role === "packing_export") && <th>Export Class</th>}

                      {(role === "dfw" ||
                        role === "decoration_local" ||
                        role === "packing_local") && (
                        <>
                          <th>A Class</th>
                          <th>B Class</th>
                          <th>C Class</th>
                        </>
                      )}

                      {/* <th>Defects Qty</th>
                      <th>Repair Qty</th> */}
                      {/* <th>Total Produced</th> */}
                      {/* <th>Weight</th> */}
                      {/* <th>Production Qty</th> opening + rcved   */}
                      {/* <th>Transfered</th> */}
                      {/* <th>Transfer</th> */}
                      {/* {(role === "dfw" ||
                        role === "decoration_export" ||
                        role === "packing_export") && <th>Loss Export</th>} */}

                      {/* {(role === "dfw" ||
                        role === "decoration_local" ||
                        role === "packing_local") && (
                        <>
                          <th>Loss A</th>
                          <th>Loss B</th>
                          <th>Loss C</th>
                        </>
                      )} */}
                      {/* <th>Loss Export</th>
                      <th>Loss A</th>
                      <th>Loss B</th>
                      <th>Loss C</th> */}
                      {/* <th>Closing Balance</th> total p - total t */}
                    </tr>
                  </thead>
                  <tbody>
                    {list.length < 1 ? (
                      <tr>
                        <td colSpan={3} className="text-center">
                          Please add products
                        </td>
                      </tr>
                    ) : (
                      list.map((item, index) => (
                        <tr key={index}>
                          <td>
                            {item.product_name} ({item.shape}) || {item.company}{" "}
                            #{item.production_work_order} <br />
                            <strong>Planning:</strong> {item.planning_qty || 0}
                            {/* {(role === "dfw" ||
                              role === "decoration_export" ||
                              role === "packing_export") && (
                              <>
                                <strong>Received Exp</strong>{" "}
                                {item.received_qty_class_exp || 0} ||{" "}
                                <strong>Transferred Exp</strong>{" "}
                                {item.transfered_qty_class_exp || 0}
                              </>
                            )}
                            {(role === "dfw" ||
                              role === "decoration_local" ||
                              role === "packing_local") && (
                              <>
                                {" "}
                                <br />
                                <strong>Received A</strong>{" "}
                                {item.received_qty_class_a || 0} ||{" "}
                                <strong>Transferred A</strong>{" "}
                                {item.transfered_qty_class_a || 0} <br />
                                <strong>Received B</strong>{" "}
                                {item.received_qty_class_b || 0} ||{" "}
                                <strong>Transferred B</strong>{" "}
                                {item.transfered_qty_class_b || 0} <br />
                                <strong>Received C</strong>{" "}
                                {item.received_qty_class_c || 0} ||{" "}
                                <strong>Transferred C</strong>{" "}
                                {item.transfered_qty_class_c || 0}
                              </>
                            )} */}
                          </td>
                          {/* <td>{item.planning_qty || 0}</td>

                          {(role === "dfw" ||
                            role === "decoration_export" ||
                            role === "packing_export") && (
                            <td>{item.received_qty_class_exp || 0}</td>
                          )}
                          {(role === "dfw" ||
                            role === "decoration_local" ||
                            role === "packing_local") && (
                            <>
                              <td>{item.received_qty_class_a || 0}</td>
                              <td>{item.received_qty_class_b || 0}</td>
                              <td>{item.received_qty_class_c || 0}</td>
                            </>
                          )}
                          {(role === "dfw" ||
                            role === "decoration_export" ||
                            role === "packing_export") && (
                            <td>{item.transfered_qty_class_exp || 0}</td>
                          )}
                          {(role === "dfw" ||
                            role === "decoration_local" ||
                            role === "packing_local") && (
                            <>
                              <td>{item.transfered_qty_class_a || 0}</td>
                              <td>{item.transfered_qty_class_b || 0}</td>
                              <td>{item.transfered_qty_class_c || 0}</td>
                            </>
                          )} */}

                          {/* <td>1200</td> */}

                          {/* {(role === "decoration_local" ||
                            role === "decoration_export") && (
                            <td>
                              <select
                                className="form-control"
                                name="decoration"
                                value={inputs.decoration}
                                onChange={(e) =>
                                  handleDecorationChange(index, e.target.value)
                                }
                              >
                                <option value="">Select Decoration</option>
                                <option value="Gold Rim">Gold Rim</option>
                                <option value="Platinum Rim">
                                  Platinum Rim
                                </option>
                                <option value="Flower">Flower</option>
                                <option value="Leaf">Leaf</option>
                              </select>
                            </td>
                          )} */}
                          {/* {(role === "dfw" ||
                            role === "packing_local" ||
                            role === "packing_export") && (
                            <td>{item.decoration}</td>
                          )} */}
                          {(role === "dfw" ||
                            role === "decoration_export" ||
                            role === "packing_export") && (
                            // <td>
                            //   <div className="d-flex flex-column">
                            //     <input
                            //       type="number"
                            //       className="form-control mb-2"
                            //       name="qty_export"
                            //       value={item.qty_export}
                            //       onChange={(e) => handleInputChange(e, index)}
                            //       placeholder="Produce Exp"
                            //     />
                            //     <input
                            //       type="number"
                            //       className="form-control"
                            //       name="loss_qty_export"
                            //       value={item.loss_qty_export}
                            //       onChange={(e) => handleInputChange(e, index)}
                            //       placeholder="Loss Exp"
                            //     />
                            //   </div>
                            // </td>
                            <td>
                              <div className="d-flex justify-content-between align-items-center mb-2">
                                <strong>
                                  Received Qty:{" "}
                                  {item.received_qty_class_exp || 0}
                                </strong>
                                <input
                                  type="number"
                                  className="form-control ms-2"
                                  name="qty_export"
                                  style={{ width: "50%" }}
                                  value={item.qty_export}
                                  onChange={(e) => handleInputChange(e, index)}
                                  placeholder="Produce Exp"
                                />
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                <strong>
                                  Transferred Qty:{" "}
                                  {item.transfered_qty_class_exp || 0}
                                </strong>
                                <input
                                  type="number"
                                  className="form-control ms-2"
                                  name="loss_qty_export"
                                  style={{ width: "50%" }}
                                  value={item.loss_qty_export}
                                  onChange={(e) => handleInputChange(e, index)}
                                  placeholder="Loss Exp"
                                />
                              </div>
                            </td>
                          )}

                          {(role === "dfw" ||
                            role === "decoration_local" ||
                            role === "packing_local") && (
                            <>
                              {/* <td>
                                <div className="d-flex justify-content-between">
                                  <strong>
                                    Received Qty: {item.received_qty_class_a || 0}
                                  </strong>
                                  <strong>
                                    Transferred Qty: {item.transfered_qty_class_a || 0}
                                  </strong>
                                </div>
                                <div className="d-flex flex-column mt-2">
                                  <input
                                    type="number"
                                    className="form-control mb-2"
                                    name="qty_a_class"
                                    value={item.qty_a_class}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    placeholder="Produce A"
                                  />
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="loss_qty_a_class"
                                    value={item.loss_qty_a_class}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    placeholder="Loss A"
                                  />
                                </div>
                              </td> */}
                              <td>
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                  <strong>
                                    Received Qty:{" "}
                                    {item.received_qty_class_a || 0}
                                  </strong>
                                  <input
                                    type="number"
                                    className="form-control ms-2"
                                    name="qty_a_class"
                                    style={{ width: "50%" }}
                                    value={item.qty_a_class}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    placeholder="Produce A"
                                  />
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                  <strong>
                                    Transferred Qty:{" "}
                                    {item.transfered_qty_class_a || 0}
                                  </strong>
                                  <input
                                    type="number"
                                    className="form-control ms-2"
                                    name="loss_qty_a_class"
                                    style={{ width: "50%" }}
                                    value={item.loss_qty_a_class}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    placeholder="Loss A"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                  <strong>
                                    Received Qty:{" "}
                                    {item.received_qty_class_b || 0}
                                  </strong>
                                  <input
                                    type="number"
                                    className="form-control ms-2"
                                    name="qty_b_class"
                                    style={{ width: "50%" }}
                                    value={item.qty_b_class}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    placeholder="Produce B"
                                  />
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                  <strong>
                                    Transferred Qty:{" "}
                                    {item.transfered_qty_class_b || 0}
                                  </strong>
                                  <input
                                    type="number"
                                    className="form-control ms-2"
                                    name="loss_qty_b_class"
                                    style={{ width: "50%" }}
                                    value={item.loss_qty_b_class}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    placeholder="Loss B"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                  <strong>
                                    Received Qty:{" "}
                                    {item.received_qty_class_c || 0}
                                  </strong>
                                  <input
                                    type="number"
                                    className="form-control ms-2"
                                    name="qty_c_class"
                                    style={{ width: "50%" }}
                                    value={item.qty_c_class}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    placeholder="Produce C"
                                  />
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                  <strong>
                                    Transferred Qty:{" "}
                                    {item.transfered_qty_class_c || 0}
                                  </strong>
                                  <input
                                    type="number"
                                    className="form-control ms-2"
                                    name="loss_qty_c_class"
                                    style={{ width: "50%" }}
                                    value={item.loss_qty_c_class}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    placeholder="Loss C"
                                  />
                                </div>
                              </td>

                              {/* <td>
                                <div className="d-flex justify-content-between">
                                  <strong>
                                    Received Qty:{" "}
                                    {item.received_qty_class_b || 0}
                                  </strong>
                                  <strong>
                                    Transferred Qty:{" "}
                                    {item.transfered_qty_class_b || 0}
                                  </strong>
                                </div>
                                <div className="d-flex flex-column">
                                  <input
                                    type="number"
                                    className="form-control mb-2"
                                    name="qty_b_class"
                                    value={item.qty_b_class}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    placeholder="Produce B"
                                  />
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="loss_qty_b_class"
                                    value={item.loss_qty_b_class}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    placeholder="Loss B"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="d-flex flex-column">
                                  <input
                                    type="number"
                                    className="form-control mb-2"
                                    name="qty_c_class"
                                    value={item.qty_c_class}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    placeholder="Produce C"
                                  />
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="loss_qty_c_class"
                                    value={item.loss_qty_c_class}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    placeholder="Loss C"
                                  />
                                </div>
                              </td> */}
                            </>
                          )}
                          {/* {(role === "dfw" ||
                            role === "decoration_export" ||
                            role === "packing_export") && (
                            <td>
                              <input
                                type="number"
                                className="form-control"
                                name="loss_qty_export"
                                value={item.loss_qty_export}
                                onChange={(e) => handleInputChange(e, index)}
                                placeholder="Loss EXPORT"
                              />
                            </td>
                          )}

                          {(role === "dfw" ||
                            role === "decoration_local" ||
                            role === "packing_local") && (
                            <>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="loss_qty_a_class"
                                  value={item.loss_qty_a_class}
                                  onChange={(e) => handleInputChange(e, index)}
                                  placeholder="Loss A"
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="loss_qty_b_class"
                                  value={item.loss_qty_b_class}
                                  onChange={(e) => handleInputChange(e, index)}
                                  placeholder="Loss B"
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="loss_qty_c_class"
                                  value={item.loss_qty_c_class}
                                  onChange={(e) => handleInputChange(e, index)}
                                  placeholder="Loss C"
                                />
                              </td>
                            </>
                          )} */}
                          {/* <td>
                            {(item.defects || []).map((defect, subIndex) => (
                              <div
                                key={subIndex}
                                className="d-flex align-items-center mb-2"
                              >
                                <select
                                  className="form-control mr-2"
                                  value={defect.type}
                                  onChange={(e) =>
                                    handleTypeChange(
                                      index,
                                      subIndex,
                                      e.target.value,
                                      "defect"
                                    )
                                  }
                                >
                                  <option value="">Select Defect Type</option>
                                  {defectTypes.map((type) => (
                                    <option key={type} value={type}>
                                      {type}
                                    </option>
                                  ))}
                                </select>
                                <input
                                  type="number"
                                  className="form-control mr-2"
                                  value={defect.qty}
                                  onChange={(e) =>
                                    handleQtyChange(
                                      index,
                                      subIndex,
                                      e.target.value,
                                      "defect"
                                    )
                                  }
                                  placeholder="Qty"
                                />
                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm"
                                  onClick={() =>
                                    handleRemoveType(index, subIndex, "defect")
                                  }
                                >
                                  Remove
                                </button>
                              </div>
                            ))}
                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                              onClick={() => handleAddType(index, "defect")}
                            >
                              Add Defect
                            </button>
                          </td> */}

                          {/* Repairs column */}
                          {/* <td>
                            {(item.repairs || []).map((repair, subIndex) => (
                              <div
                                key={subIndex}
                                className="d-flex align-items-center mb-2"
                              >
                                <select
                                  className="form-control mr-2"
                                  value={repair.type}
                                  onChange={(e) =>
                                    handleTypeChange(
                                      index,
                                      subIndex,
                                      e.target.value,
                                      "repair"
                                    )
                                  }
                                >
                                  <option value="">Select Repair Type</option>
                                  {repairTypes.map((type) => (
                                    <option key={type} value={type}>
                                      {type}
                                    </option>
                                  ))}
                                </select>
                                <input
                                  type="number"
                                  className="form-control mr-2"
                                  value={repair.qty}
                                  onChange={(e) =>
                                    handleQtyChange(
                                      index,
                                      subIndex,
                                      e.target.value,
                                      "repair"
                                    )
                                  }
                                  placeholder="Qty"
                                />
                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm"
                                  onClick={() =>
                                    handleRemoveType(index, subIndex, "repair")
                                  }
                                >
                                  Remove
                                </button>
                              </div>
                            ))}
                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                              onClick={() => handleAddType(index, "repair")}
                            >
                              Add Repair
                            </button>
                          </td> */}
                          {/* <td>578</td> */}
                          {/* <td>
                            <input
                              type="text"
                              className="form-control"
                              name="weight"
                              value={item.weight}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              name="production_qty"
                              value={inputs.production_qty}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </td> */}
                          {/* <td>350</td> */}
                          {/* <td>
                            <input
                              type="number"
                              className="form-control"
                              name="transfer_qty"
                              value={item.transfer_qty}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              name="return_qty"
                              value={item.return_qty}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </td> */}
                          {/* <td>{item.closing_balance}</td> */}
                          {/* <td>
                            <input
                              type="number"
                              className="form-control"
                              name="return_qty"
                              value={item.return_qty}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              name="return_qty"
                              value={item.return_qty}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              name="return_qty"
                              value={item.return_qty}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              name="return_qty"
                              value={item.return_qty}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </td> */}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            {list.length > 0 && (
              <div className="row mt-3">
                <div className="col-3">
                  <button className="btn btn-primary" onClick={formSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create;
