import React from "react";
import { Link } from "react-router-dom";

const Defect = ({ role, onMenuClick, active }) => {
  return (
    <>
      <li className="nav-item">
        <Link
          to
          className={`nav-link with-sub ${
            active === "/receipe" ||
            active === "/defect-art"
              ? "active show-sub"
              : ""
          }`}
        >
          <i className="fa fa-briefcase" aria-hidden="true"></i>
          <span>Defect</span>
        </Link>
        <ul className="nav-sub">
        {(role === "admin" ||
                role === "glazing" ||
                role === "gk" ||
                role === "selection" ||
                role === "decoration_local" ||
                role === "decoration_export" ||
                role === "dfw" ||
                role === "packing_local" ||
                role === "packing_export") && (
                <li className="nav-item">
                  <Link
                    to="/defect-art"
                    className={`nav-link ${
                      active === "/defect-art" ? "active" : ""
                    }`}
                    onClick={() => onMenuClick("/defect-art")}
                  >
                    <i className="fa fa-clone" aria-hidden="true"></i>
                    &nbsp;&nbsp; Defect
                  </Link>
                </li>
              )}
        </ul>
      </li>
    </>
  );
};

export default Defect;
