import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import Alert from "../../../Common/Alert";
import DatatableFunc from "../../../Common/DatatableFunc";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
// import { LoaderComponent } from "../../../Common/LoaderComponent";
import ReactDataTable from "../../../Common/ReactDataTable";

const List = () => {
  const location = useLocation();
  const [receiveData, setReceiveData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);

  // const fetchData = () => {
  //   Http.get("/issue").then((res) => {
  //     setReceiveData(res.data);
  //   });
  //   Http.get("/staff-type-check").then((res) => {
  //     setRole(res.data);
  //   });
  // };

  const fetchData = async () => {
    setLoading(true); // Start loading

    try {
      const res = await Http.get(`/issue`);
      const resRole = await Http.get(`/staff-type-check`);
      setReceiveData(res.data);
      setRole(resRole.data);
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false); // Stop loading regardless of the outcome
    }
  };

  useEffect(() => {
    DatatableFunc();
    fetchData();
  }, []);

  const showAlert = (message, type) => {
    setAlert({
      message: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 4000);
  };

  useEffect(() => {
    if (!location.state) {
      return;
    }
    showAlert(location.state.message, location.state.type);
  }, [location]);

  const deleteData = (id, deleteApiUrl, showMsg) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Http.delete("/" + deleteApiUrl + "/" + id).then((res) => {
          setReceiveData(res.data);
          Swal.fire("Deleted!", showMsg + " has been deleted.", "success");
        });
      }
    });
  };

  const cols = [
    { field: "date", header: "Date" },
    { field: "time", header: "Time" },
    { field: "reference_no", header: "Referrence No" },
    { field: "product.name", header: "Finished Goods" },
    // { field: "batch_no", header: "Batch No" },
    { field: "batch_size", header: "Batch Size" },
    { field: "production_qty", header: "Total Weight Produced" },
    // { field: "production_qty", header: "Production Qty" },
    // { field: "transfer_qty", header: "Transfer Qty" },
    {
      field: (item) =>
        item.rmpm_receive_status != 1 ? (
           <i className="pending">Not Received Yet</i>
        ) : (
          <i className="pending">Received</i>
        ),
      header: " Status ",
    },
  ];

  // Conditionally add the Produce column if the role is not "gstore"
  // if (role !== "gstore" && role !== "depot") {
  //   cols.push({
  //     field: (item) =>
  //       item.rmpm_receive_status === 1 ? (
  //         item.production_status !== 1 ? (
  //           <Link
  //             to={
  //               role === "dstore"
  //                 ? `/production-generate-requisition-department-wise/${item.id}`
  //                 : `/production-generate/${item.id}`
  //             }
  //             className="btn btn-info btn-sm"
  //           >
  //             {role === "dstore" ? `Produce & Transfer` : `Produce`}
  //           </Link>
  //         ) : (
  //           <i className="pending">Produced</i>
  //         )
  //       ) : (
  //         ""
  //       ),
  //     header: "Produce",
  //   });
  // }

  const actionTemplate = (item, deleteData) => {
    // const role = "admin"; // Ensure 'role' is defined or passed in correctly

    return (
      <>
        <td className="text-xs">
          {/* {role === "admin" && ( */}
          <Link
            to={`/issue-view/${item.id}`}
            className="btn btn-primary btn-sm"
          >
            <i className="fa fa-list" aria-hidden="true"></i>
          </Link>
          {/* {role != "gstore" && item.qty > item.transfer_qty && ( */}
          
          {/* )} */}

          {item.rmpm_receive_status < 1 && (
            <Link
              to={`/edit-issue/${item.id}`}
              className="btn btn-secondary btn-sm ms-1"
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </Link>
          )}
          {/* {role === "gstore" && (
            <button
              className="btn btn-danger btn-sm ms-1"
              onClick={() => deleteData(item.id, "unit", "unit")}
            >
              <i className="fa fa-trash"></i>
            </button>
          )} */}
        </td>
      </>
    );
  };

  return (
    <div>
      <IsLogin></IsLogin>
      {/* <LoaderComponent /> */}
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">All Issues</h5>
          </div>

          <div className="am-pagebody">
            <Alert alert={alert} />

            <div className="card pd-20 pd-sm-40">
              <ReactDataTable
                cols={cols}
                receiveData={receiveData}
                isLoading={loading}
                actionTemplate={actionTemplate}
                deleteData={deleteData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
