import React, { useEffect, useState } from "react";
import useAlertFromLocation from "../../../../hooks/useAlertFromLocation";
import useDeleteData from "../../../../hooks/useDeleteData";
import Alert from "../../../Common/Alert";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import ReactDataTable from "../../../Common/ReactDataTable";

const List = () => {
  const [requisitionData, setRequisitionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState(""); // For storing the staff check result
  // const [departments, setDepartments] = useState([
  //   { id: 1, name: "BODY", user_type: "body" },
  //   { id: 2, name: "JIGGER", user_type: "jigger" },
  //   { id: 3, name: "CASTING", user_type: "casting" },
  //   { id: 4, name: "FINISHING", user_type: "finishing" },
  //   { id: 5, name: "BK", user_type: "bk" },
  //   { id: 6, name: "GLAZING", user_type: "glazing" },
  //   { id: 7, name: "GK", user_type: "gk" },
  //   { id: 8, name: "SELECTION", user_type: "selection" },
  //   { id: 9, name: "DECORATION LOCAL", user_type: "decoration_local" },
  //   { id: 10, name: "DECORATION EXPORT", user_type: "decoration_export" },
  //   { id: 11, name: "DFW", user_type: "dfw" },
  //   { id: 12, name: "PACKING LOCAL", user_type: "packing_local" },
  //   { id: 13, name: "PACKING EXPORT", user_type: "packing_export" },
  //   { id: 14, name: "DSTORE", user_type: "dstore" },
  //   { id: 15, name: "GSTORE", user_type: "gstore" },
  //   { id: 16, name: "MOULD", user_type: "mould" },
  //   { id: 17, name: "PSTORE", user_type: "pstore" },
  //   { id: 18, name: "PLANNING", user_type: "planning" },
  //   { id: 19, name: "SAGGER", user_type: "sagger" },
  // ]);

  // Helper function to get user type from department ID
  // const getUserType = (departmentId) => {
  //   const department = departments.find((dept) => dept.id === departmentId);
  //   return department ? department.user_type : null;
  // };

  // Helper function to get department name from department ID
  // const getDepartmentNameById = (departmentId) => {
  //   const department = departments.find((dept) => dept.id === departmentId);
  //   return department ? department.name : "N/A";
  // };

  // Fetch planning data and staff check data
  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await Http.get("/production-transfer");

      const roleResponse = await Http.get("/staff-type-check");
      const currentRole = roleResponse.data; // Assuming roleResponse.data is the string returned by the API
      setRequisitionData(res.data);
      setRole(currentRole);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Use custom hook to delete data
  const deleteData = useDeleteData(fetchData);
  const alert = useAlertFromLocation();
  const cols = [
    {
      field: (item) => (
        <>
          {item.production_work_order.company.name}{" "}
          <span style={{ fontWeight: "bold" }}>
            #{item.production_work_order.receipt_no}
          </span>
        </>
      ),
      header: "Work Order",
    },
    {
      field: "production_finished_good.planning.receipt_no",
      header: "Planning",
    },
    {
      field: (item) =>
        item.production_finished_good.receipt_no +
        " (" +
        item.production_finished_good.production_date +
        ")",
      header: "Production No",
    },
    // { field: "production_finished_good.production_date", header: "Production Date" },
    // { field: "product.code", header: "Code" },
    {
      field: (item) => "(" + item.product.code + ") " + item.product.name,
      header: "Name",
    },
    { field: "shape.name", header: "Shape" },
    { field: "weight", header: "Produced Weight" },
    { field: "qty", header: "Produced Qty" },
    {
      field: (item) => item.production_finished_good.shift.toUpperCase(),
      header: "Shift",
    },

    // {
    //   field: (item) => getDepartmentNameById(item.from_department), // Use department name instead of ID
    //   header: "From",
    // },
    // {
    //   field: (item) => getDepartmentNameById(item.to_department), // Use department name instead of ID
    //   header: "To",
    // },
    // {
    //   header: "Receive",
    //   field: (item) => {
    //     const toDeptUserType = getUserType(item.to_department);
    //     if (
    //       (role === "admin" || role === toDeptUserType)
    //       // && item.status === "requested"
    //     ) {
    //       return (
    //         <>
    //           {(role === "jigger" ||
    //             role === "casting" ||
    //             role === "finishing" ||
    //             role === "bk" ||
    //             role === "glazing" ||
    //             role === "gk" ||
    //             role === "admin") && (
    //             <Link
    //               className="btn btn-primary btn-sm ms-1"
    //               to={`/create-receive/${item.id}/req-art`}
    //             >
    //               <i className="fa fa-send"></i> Receive
    //             </Link>
    //           )}
    //           {(role === "decoration_local" ||
    //             role === "decoration_export" ||
    //             role === "dfw" ||
    //             role === "packing_local" ||
    //             role === "packing_export" ||
    //             role === "admin") && (
    //             <Link
    //               className="btn btn-primary btn-sm ms-1"
    //               to={`/create-receive-a-s/${item.id}/req-art`}
    //             >
    //               <i className="fa fa-send"></i> Receive
    //             </Link>
    //           )}
    //         </>
    //       );
    //     }
    //     return <i className="text-secondary ms-1">Received</i>;
    //   },
    // },
    // ...(role !== "dfw" && role !== "jigger" && role !== "casting"
    //   ? [
    //       {
    //         header: "Produce",
    //         field: (item) => {
    //           const toDeptUserType = getUserType(item.to_department);
    //           // if (item.status === "delivered") {
    //           //   return null; // Hide column if status is delivered
    //           // }
    //           if (role === "admin" || role === toDeptUserType) {
    //             return (
    //               <>
    //                 {(role === "finishing" ||
    //                   role === "bk" ||
    //                   role === "glazing" ||
    //                   role === "gk" ||
    //                   role === "admin") && (
    //                   <Link
    //                     className="btn btn-primary btn-sm ms-1"
    //                     to={`/create-delivery/${item.id}/req-art`}
    //                   >
    //                     <i className="fa fa-send"></i> Produce
    //                   </Link>
    //                 )}
    //                 {(role === "selection" || role === "admin") && (
    //                   <Link
    //                     className="btn btn-primary btn-sm ms-1"
    //                     to={`/create-delivery-selection/${item.id}/req-art`}
    //                   >
    //                     <i className="fa fa-send"></i> Produce
    //                   </Link>
    //                 )}
    //                 {(role === "decoration_local" ||
    //                   role === "decoration_export" ||
    //                   role === "packing_local" ||
    //                   role === "packing_export" ||
    //                   role === "admin") && (
    //                   <Link
    //                     className="btn btn-primary btn-sm ms-1"
    //                     to={`/create-delivery-decoration/${item.id}/req-art`}
    //                   >
    //                     <i className="fa fa-send"></i> Produce
    //                   </Link>
    //                 )}
    //               </>
    //             );
    //           }
    //           return <i className="text-secondary ms-1">Produced</i>;
    //         },
    //       },
    //     ]
    //   : []), // Conditionally include the "Produce" column based on the role
    // {
    //   field: (item) => (
    //     <div className="text-xs text-nowrap">
    //       <Link
    //         to={`/view-requisition-art/${item.id}`}
    //         className="btn btn-info btn-sm ms-1"
    //       >
    //         <i className="fa fa-list" aria-hidden="true"></i>
    //       </Link>
    //     </div>
    //   ),
    //   header: "Actions",
    // },
  ];

  return (
    <div>
      <IsLogin />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">All Production</h5>
          {/* <Link
            to="/create-delivery-jigcast/req-art"
            className="btn btn-primary"
          >
            <i className="fa fa-plus-square mg-r-6"></i> Add
          </Link> */}
        </div>
        <div className="am-pagebody">
          <Alert alert={alert} />
          <div className="card pd-20 pd-sm-40">
            <ReactDataTable
              cols={cols}
              receiveData={requisitionData}
              isLoading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
