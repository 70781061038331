import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Http from "../../../../Common/Http";
import IsLogin from "../../../../Common/IsLogin";
import { LoaderComponent } from "../../../../Common/LoaderComponent";
import Swal from "sweetalert2";

const Production = () => {
  const navigate = useNavigate();

  // State Management
  const [inputs, setInputs] = useState({ });
  const [product, setProduct] = useState([]);
  const [receiveData, setReceiveData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [list, setList] = useState([]);
  const [role, setRole] = useState("");
  const [receipeNotFound, setReceipeNotFound] = useState("Receipe Not Found");

  // Fetch Initial Data
  useEffect(() => {
    fetchInitialData();
    setInputs((prevInputs) => ({
      ...prevInputs,
      date: getTodayDate(),
      status: 0,
    }));
  }, []);

  const fetchInitialData = async () => {
    try {
      const resRole = await Http.get("/staff-type-check");
      setRole(resRole.data);

      const resProduct = await Http.get("/receipe-finished-good");
      setProduct(resProduct.data);
    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  };

  // Handle Change for Inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => ({ ...prev, [name]: value }));

    if (name === "batch_size" && inputs.product_id > 0) {
      const updatedInputs = { ...inputs, batch_size: value };
      fetchReceipeData(updatedInputs);
    }
  };

  const fetchReceipeData = async (updatedInputs) => {
    try {
      const res = await Http.post("/receipe-load-stock-wise", updatedInputs);
      setReceiveData(res.data);
      if (res.data.status !== 1) {
        Swal.fire({
          icon: "error",
          title: "Out of Stock",
          text: "The selected product is out of stock.",
          confirmButtonText: "OK",
        });
        // setList([]); // Clear list when out of stock
        // return;
      }
      if (res.data.list.length > 0) {
        setList(res.data.list);
        setInputs((prev) => ({
          ...prev,
          batch_size: prev.batch_size ? prev.batch_size : res.data.batch_size,
          receipe_id: res.data.info?.id || null,
        }));
        setReceipeNotFound("");
      } else {
        setList([]);
        setReceipeNotFound("No data found");
      }
    } catch (error) {
      console.error("Error loading receipe data:", error);
    }
  };

  // Handle Select Change
  const handleSelectChange = async (option) => {
    const value = option.value;
    setSelectedOption(option);
    setInputs((prev) => ({ ...prev, product_id: value }));

    if (value === 0) {
      setList([]);
      setReceipeNotFound("Receipe Not Found");
      return;
    }

    fetchReceipeData({ ...inputs, product_id: value });
  };

  // Form Submission
  const formSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await Http.post("/ball-charge-generate", {
        ...inputs,
        items: list,
      });
      navigate("/issue", {
        state: { message: res.data.message, type: "success" },
      });
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  // Helper Function to Get Today's Date
  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  return (
    <div>
      <IsLogin />
      <LoaderComponent />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Ball Mill Charge</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Ball Mill Charge</h6>
            <form onSubmit={formSubmit}>
              <div className="row">
                <div className="col-lg">
                  <label>
                    Date <i className="fa fa-star required"></i>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    name="date"
                    value={inputs.date}
                    onChange={handleChange}
                    data-required
                  />
                </div>
                <div className="col-lg">
                  <label>
                    Finished Good <i className="fa fa-star required"></i>
                  </label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={selectedOption}
                    name="product_id"
                    options={product}
                    onChange={handleSelectChange}
                    isDisabled={role === "mould" || role === "sagger"}
                  />
                </div>
                <div className="col-lg">
                  <label>
                    Produced Weight Liquid{" "}
                    <i className="fa fa-star required"></i>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    name="batch_size"
                    value={inputs.batch_size || ""}
                    onChange={handleChange}
                    placeholder="Input Batch Size"
                  />
                </div>

                <div className="row mt-3">
                  <div className="col-lg">
                    <label>
                      Shift <i className="fa fa-star required"></i>
                    </label>
                    <select
                      className="form-control"
                      name="shift"
                      value={inputs.shift || ""}
                      onChange={handleChange}
                    >
                      <option value="">Select Shift</option>
                      <option value="a">A</option>
                      <option value="b">B</option>
                      <option value="c">C</option>
                      <option value="general">General</option>
                    </select>
                  </div>
                  {/* <div className="col-lg">
                    <label>Transfer Weight</label>
                    <input
                      type="number"
                      className="form-control"
                      name="transfer_qty"
                      value={inputs.transfer_qty || 0}
                      onChange={handleChange}
                      placeholder="Input Deliver Qty"
                    />
                  </div>
                  {role === "body" && inputs.transfer_qty > 0 && (
                    <div className="col-lg">
                      <label>Transfer To Department</label>
                      <select
                        className="form-control"
                        name="to_department_id"
                        onChange={handleChange}
                      >
                        <option value={""}>Select Department</option>
                        <option value={2}>Jigger</option>
                        <option value={3}>Casting</option>
                        <option value={6}>Glaze</option>
                      </select>
                    </div>
                  )} */}

                  <div className="col-lg">
                    <label>Remarks</label>
                    <textarea
                      className="form-control"
                      name="remarks"
                      value={inputs.remarks || ""}
                      onChange={handleChange}
                      placeholder="Remarks"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="card-solid">
                  <div className="card-header with-border text-center">
                    <h5 className="card-title mb-0">Items in the List</h5>
                  </div>
                  <div className="card-body mt-3 p-0 pt-3">
                    <table className="table table-sm table-bordered table-striped mb-0">
                      <thead>
                        <tr>
                          <th>Code</th>
                          <th>Product</th>
                          <th>Standard Qty</th>
                          <th>Stock Qty</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.length > 0 ? (
                          list.map((item, index) => (
                            <tr key={index}>
                              <td>{item.code}</td>
                              <td>{item.name}</td>
                              <td>{item.stndrd_qty}</td>
                              <td>{item.stockQty}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={4} className="text-center">
                              {receipeNotFound}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {
                receiveData.status === 1 && (
                  <div className="row mt-3">
                  <div className="col-3">
                    <button className="btn btn-primary btn-block" type="submit">
                      <i className="fa fa-send mg-r-10"></i> Submit
                    </button>
                  </div>
                </div>
                )
              }
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Production;
