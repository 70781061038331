import { ProgressSpinner } from "primereact/progressspinner";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../GlobalContext";
import IsLogin from "../Common/IsLogin";
import AdminDashboard from "./Dashboard/Admin";
import StaffDashboard from "./Dashboard/Staff";

const Dashboard = () => {
  const [receiveData, setReceiveData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { globalData, fetchGlobalData } = useContext(GlobalContext);

  // useEffect(() => {
  //   setLoading(true);
  //   const fetchData = async () => {
  //     try {
  //       const res = await Http.get("/staff-type-check");
  //       setReceiveData(res.data);
  //     } catch (error) {
  //       console.error("Fetch error:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await fetchGlobalData();
        setReceiveData(globalData);
      } catch (error) {
        console.error("Error in Dashboard fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [fetchGlobalData, globalData]);

  return (
    <div>
      <IsLogin></IsLogin>
      {/* <LoaderComponent /> */}
      {loading ? (
        <ProgressSpinner className="dashLoad" />
      ) : (
        <>
          {(receiveData === "admin" ||
            receiveData === "depot" ||
            receiveData === "supply_chain" ||
            receiveData === "gstore" ||
            receiveData === "body" ||
            receiveData === "jigger" ||
            receiveData === "casting" ||
            receiveData === "finishing" ||
            receiveData === "bk" ||
            receiveData === "glazing" ||
            receiveData === "gk" ||
            receiveData === "selection" ||
            receiveData === "decoration_local" ||
            receiveData === "decoration_export" ||
            receiveData === "dfw" ||
            receiveData === "packing_local" ||
            receiveData === "packing_export" ||
            receiveData === "dstore" ||
            receiveData === "mould" ||
            receiveData === "pstore" ||
            receiveData === "planning" ||
            receiveData === "sagger") && <AdminDashboard />}
          {(receiveData === "staff" ||
            receiveData === "hr" ||
            receiveData === "so") && <StaffDashboard />}
        </>
      )}
    </div>
  );
};

export default Dashboard;
