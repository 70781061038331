import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormValidation from "../../../Common/FormValidation";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";

const Create = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  // const [list, setList] = useState({});
  const [list, setList] = useState([]);
  const [product, setProduct] = useState({});
  const [unit, setUnit] = useState({});
  const [location, setLocation] = useState({});
  const [factory, setFactory] = useState({});
  const [lastGrnNo, setLastGrnNo] = useState("000001");
  const [loading, setLoading] = useState(false);
  const dummy = {
    status: "received",
    date: "2025-01-12",
    challan_date: "2025-01-12",
    po_no: 19,
    spr_no: "SPR008",
    spr_date: "2024-12-19",
    store_dept_id: null,
    challan_no: "CH005",
    items: [
      {
        item_id: 29,
        product_id: 24,
        unit_qty: "1.00",
        unit_price: "11.00",
        qty: "11.00",
        accepted_qty: 9,
        actual_qty: "11.00",
        desc: "",
        prev_rcv: "0",
        received_qty: null,
        total_amount: 99,
        required_qty: 16,
        moisture: "4",
        rejected_qty: 1,
        spr_qty: 16,
        po_qty: "11.00",
      },
    ],
    work_order_id: "19",
    grn_no: 5,
    type: "dp",
    id: "19",
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target; // Chanages Experience Input Name and Value
    const inputInfo = [...list]; // Defined Experience State All Information into List Variable

    inputInfo[index][name] = value;
    setList(inputInfo);
  };
  const handleReceiveQty = (e, index) => {
    const { name, value } = e.target; // Chanages Experience Input Name and Value
    const inputInfo = [...list]; // Defined Experience State All Information into List Variable

    // if (
    //   parseFloat(e.target.getAttribute("due_qty")) >= parseFloat(e.target.value)
    // ) {
    //   inputInfo[index][name] = value;
    // } else {
    //   alert("Qty Not Available on Purchase Order");
    //   inputInfo[index][name] = 0;
    // }
    inputInfo[index][name] = value;

    setList(inputInfo);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  // const fetchData = () => {
  //   const listInfo = [];
  //   Http.get("/grn/" + id).then((res) => {
  //     const listInfo = [];
  //     Object.keys(res.data.items).forEach((key) => {
  //       listInfo.push({
  //         item_id: res.data.items[key].work_order_item_id,
  //         product_id: res.data.items[key].product_id,
  //         unit_id: res.data.items[key].unit_id,
  //         unit_qty: res.data.items[key].unit.qty,
  //         unit_price: res.data.items[key].unit_price,
  //         qty: res.data.items[key].qty,
  //         receive_qty: res.data.items[key].qty,
  //         actual_qty: res.data.items[key].work_order_items.actual_qty,
  //         desc: res.data.items[key].desc,
  //         prev_rcv: parseFloat(res.data.items[key].received_qty) > 0 ? res.data.items[key].received_qty : "0",
  //       });
  //       setInputs({
  //         id: res.data.id,
  //         work_order_id: res.data.work_order_id,
  //         date: res.data.date,
  //         grn_no: res.data.receipt_no,
  //         company_location_id: res.data.location_id,
  //         factory_id: res.data.factory_id,
  //         status: res.data.status,
  //       });
  //     });
  //     setList(listInfo);
  //   });

  //   Http.get("/product").then((res) => {
  //     setProduct(res.data);
  //   });
  //   Http.get("/last-grn-no").then((res) => {
  //     setLastGrnNo(res.data);
  //   });
  //   Http.get("/location").then((res) => {
  //     setLocation(res.data);
  //   });
  //   Http.get("/factory").then((res) => {
  //     setFactory(res.data);
  //   });
  //   Http.get("/unit").then((res) => {
  //     setUnit(res.data);
  //   });
  // };
  // useEffect(() => {
  //   fetchData();
  // }, []);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const [
          grnData,
          productRes,
          lastGrnNoRes,
          locationRes,
          factoryRes,
          unitRes,
        ] = await Promise.all([
          Http.get(`/grn/${id}`),
          // dummy,
          Http.get("/product"),
          Http.get("/last-grn-no"),
          Http.get("/location"),
          Http.get("/factory"),
          Http.get("/unit"),
        ]);
        //  console.log(grnRes);
        // console.log(grnRes.items);

        const grnRes = grnData.data;

        const listInfo = grnRes.items.map((item) => ({
          item_id: item.item_id,
          // product_name: item.product.name,
          unit_price: item.unit_price,
          spr_qty: item.qty,
          // po_qty: item.direct_purchase_items.qty,
          // prev_qty: item.qty,
          // moisture: item.moisture,
          // rejected_qty: item.rejected_qty,
          // accepted_qty: item.accepted_qty,
          // amount: item.actual_amount,
          // item_id: item.item_id,
          product_id: item.product_id,
          unit_qty: item.unit_qty,
          // unit_price: item.unit_price,
          qty: item.qty,
          accepted_qty: item.accepted_qty,
          actual_qty: item.actual_qty,
          desc: item.desc,
          prev_rcv: item.prev_rcv,
          received_qty: item.received_qty,
          total_amount: item.actual_amount || item.unit_price * item.accepted_qty,
          required_qty: item.required_qty,
          moisture: item.moisture,
          rejected_qty: item.rejected_qty,
          // spr_qty: item.spr_qty,
          po_qty: item.po_qty,
        }));
        console.log(listInfo);

        setInputs({
          id: grnRes.id,
          work_order_id: grnRes.work_order_id,
          date: grnRes.date,
          grn_no: grnRes.receipt_no,
          company_location_id: grnRes.location_id,
          factory_id: grnRes.factory_id,
          status: grnRes.status,
          // status: grnRes.status,
          // date: grnRes.date,
          challan_date: grnRes.challan_date,
          po_no: grnRes.po_no,
          spr_no: grnRes.direct_purchase.purchase_requisition_art.receipt_no,
          spr_date: grnRes.direct_purchase.purchase_requisition_art.date,
          // spr_date: grnRes.spr_date,
          challan_no: grnRes.challan_no,
          // grn_no: grnRes.grn_no,
          // work_order_id: grnRes.work_order_id,
          type: grnRes.type,
          // id: grnRes.id,
        });

        setList(listInfo);
        setProduct(productRes.data);
        setLastGrnNo(lastGrnNoRes.data);
        setLocation(locationRes.data);
        setFactory(factoryRes.data);
        setUnit(unitRes.data);
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false); // Optional: End loading state
      }
    };

    fetchData();
  }, []);

  const formSubmit = () => {
    const updatedInputs = {
    ...inputs,
    items: list, // Use the already updated `list`
    grn_id: id, // Include GRN ID
  };

  console.log("Payload before submit:", updatedInputs); // Debugging

    Http.put("/grn/" + id, updatedInputs)
      .then((res) => {
        navigate("/grn", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  // console.log('====================================');
  // console.log(list);
  // console.log('====================================');

  return (
    <div>
      <IsLogin></IsLogin>
      {/* <LoaderComponent /> */}
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">MRR with QC</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">MRR with QC</h6>
            <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div>
            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  {/* <input
                    type="date"
                    className="form-control"
                    name="date"
                    value={inputs.date || ""}
                    onChange={handleChange}
                  /> */}
                  {/* <input
                    type="date"
                    className="form-control"
                    name="challan_date"
                    value={inputs.challan_date || ""}
                    onChange={handleChange}
                  /> */}
                  
                  {/* <input
                    type="date"
                    className="form-control"
                    name="spr_date"
                    value={inputs.spr_date || ""}
                    readOnly
                  />
                  <input
                    type="text"
                    className="form-control"
                    name="challan_no"
                    value={inputs.challan_no || ""}
                    onChange={handleChange}
                  /> */}

                  <div className="col-lg">
                    <label>Date</label>
                    <input
                      type="date"
                      className="form-control"
                      name="date"
                      value={inputs.date || ""}
                      onChange={handleChange}
                      placeholder="Input Date"
                    />
                  </div>
                  <div className="col-lg">
                    <label>Challan Date</label>
                    <input
                      type="date"
                      className="form-control"
                      name="challan_date"
                      value={inputs.challan_date || ""}
                      onChange={handleChange}
                      placeholder="Input Challan Date"
                    />
                  </div>
                  <div className="col-lg mg-t-10 mg-lg-t-0">
                    <label>GRN No</label>
                    <input
                      type="text"
                      className="form-control"
                      name="grn_no"
                      value={lastGrnNo || "00001"}
                      onChange={handleChange}
                      placeholder="Input GRN No"
                    />
                  </div>
                  <div className="col-lg mg-t-10 mg-lg-t-0">
                    <label>SPR No</label>
                    <input
                    type="text"
                    className="form-control"
                    name="spr_no"
                    value={inputs.spr_no || ""}
                    readOnly
                  />
                  </div>
                  <div className="col-lg mg-t-10 mg-lg-t-0">
                    <label>SPR Date</label>
                    <input
                      type="date"
                      className="form-control"
                      name="spr_date"
                      value={inputs.spr_date || ""}
                      disabled
                    />
                  </div>

                  <div className="col-lg mg-t-10 mg-lg-t-0">
                    <label>Challan No</label>
                    <input
                      type="text"
                      className="form-control"
                      name="challan_no"
                      value={inputs.challan_no || "CH00001"}
                      onChange={handleChange}
                      placeholder="Input Challan No"
                    />
                  </div>
                  {/* <div className="col-lg">
                <label htmlFor="">Location</label>
                <select
                  className="form-control"
                  name="company_location_id"
                  onChange={handleChange}
                >
                  <option value="">Select Location</option>
                  {Array.from(location).map((item, index) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg">
                <label htmlFor="">Factory</label>
                <select
                  className="form-control"
                  name="factory_id"
                  onChange={handleChange}
                >
                  <option value="">Select Factory</option>
                  {Array.from(factory).map((item, index) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div> */}
                  <div className="col-lg mg-t-10 mg-lg-t-0">
                    <label>Status</label>
                    <select
                      className="form-control"
                      name="status"
                      onChange={handleChange}
                    >
                      <option
                        value="received"
                        selected={inputs.status === "received" ? true : false}
                      >
                        Received
                      </option>
                      {/* <option
                    value="active"
                    selected={inputs.status === "active" ? true : false}
                  >
                    Active
                  </option>
                  <option
                    value="inactive"
                    selected={inputs.status === "inactive" ? true : false}
                  >
                    inactive
                  </option> */}
                      <option
                        value="completed"
                        selected={inputs.status === "completed" ? true : false}
                      >
                        Completed
                      </option>
                    </select>
                  </div>
                </div>

                <div className="row mt-3">
                  <table className="table table-sm align-items-center mb-0">
                    <thead>
                      <tr>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Product
                        </th>
                        {/* <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                      Unit
                    </th> */}
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Unit Price
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          SPR Qty
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          PO Qty
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Prev Receive
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Moisture
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Rejected Receive
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Accepted Qty
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.from(list).map((x, i) => (
                        <tr key={i}>
                          <td>
                            <select
                              className="form-control"
                              name="product_id"
                              disabled
                              onChange={(e) => handleInputChange(e, i)}
                            >
                              <option value="">Select Product</option>
                              {Array.from(product).map((item, index) => (
                                <option
                                  value={item.id}
                                  key={item.id}
                                  selected={
                                    x.product_id === item.id ? true : false
                                  }
                                >
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </td>
                          {/* <td>
                        <select
                          className="form-control"
                          name="unit_id"
                          disabled
                          onChange={(e) => handleInputChange(e, i)}
                        >
                          <option value="">Select Unit</option>
                          {Array.from(unit).map((item, index) => (
                            <option
                              value={item.id}
                              key={item.id}
                              selected={x.unit_id === item.id ? true : false}
                            >
                              {item.name} - ({item.qty})
                            </option>
                          ))}
                        </select>
                      </td> */}
                          {/* <td>
                            <input
                              type="text"
                              className="form-control"
                              name="unit_price"
                              disabled
                              value={x.unit_price || ""}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                          </td> */}
                          {/* <td>
                            <input
                              type="text"
                              className="form-control"
                              name="qty"
                              disabled
                              value={x.spr_qty || 0}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                          </td> */}
                          {/* <td>{x.product_id}</td> */}
                          <td>{x.unit_price}</td>
                          {/* <td>{x.unit_qty}</td> */}
                          <td>{x.spr_qty}</td>
                          <td>{x.po_qty}</td>
                          <td>{x.prev_rcv}</td>
                          <td>
                            <div className="input-group">
                              <input
                                type="number"
                                className="form-control"
                                name="moisture"
                                value={x.moisture || ""}
                                onChange={(e) => handleInputChange(e, i)}
                              />
                              <span className="input-group-text">%</span>
                            </div>
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              name="rejected_qty"
                              value={x.rejected_qty || ""}
                              data-due_qty={
                                parseFloat(x.qty) - parseFloat(x.prev_rcv)
                              }
                              onChange={(e) => handleReceiveQty(e, i)}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              name="accepted_qty"
                              value={x.accepted_qty || ""}
                              data-due_qty
                              onChange={(e) => handleReceiveQty(e, i)}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              name="total_amount"
                              // value={x.total_amount || ""}
                              value={
                                x.accepted_qty && x.unit_price
                                  ? parseFloat(x.accepted_qty) * parseFloat(x.unit_price)
                                  : 0
                              }
                              data-due_qty
                              onChange={(e) => handleReceiveQty(e, i)}
                            />
                          </td>
                          {/* <td>
                        <input
                          type="text"
                          className="form-control"
                          name="prev_rcv"
                          disabled
                          value={x.prev_rcv || ""}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </td> */}
                          {/* <td>
                            <input
                              type="number"
                              className="form-control"
                              name="receive_qty"
                              value={x.receive_qty || 0}
                              due_qty={
                                parseFloat(x.receive_qty) +
                                parseFloat(x.qty) -
                                parseFloat(x.prev_rcv / x.unit_qty)
                              }
                              onChange={(e) => handleReceiveQty(e, i)}
                            />
                          </td>
                          <td>
                            <textarea
                              className="form-control"
                              name="desc"
                              value={x.desc || ""}
                              onChange={(e) => handleInputChange(e, i)}
                            ></textarea>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="row mt-3">
                  <div className="col-3">
                    <button
                      className="btn btn-primary btn-block mg-b-10"
                      onClick={formSubmit}
                    >
                      <i className="fa fa-send mg-r-10"></i> Update
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default Create;
