import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Http from "../../../Common/Http";
import ReactDataTable from "../../../Common/ReactDataTable";

const List = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [departments, setDepartments] = useState([
    { id: 1, name: "BODY", user_type: "body" },
    { id: 2, name: "JIGGER", user_type: "jigger" },
    { id: 3, name: "CASTING", user_type: "casting" },
    { id: 4, name: "FINISHING", user_type: "finishing" },
    { id: 5, name: "BK", user_type: "bk" },
    { id: 6, name: "GLAZING", user_type: "glazing" },
    { id: 7, name: "GK", user_type: "gk" },
    { id: 8, name: "SELECTION", user_type: "selection" },
    { id: 9, name: "DECORATION LOCAL", user_type: "decoration_local" },
    { id: 10, name: "DECORATION EXPORT", user_type: "decoration_export" },
    { id: 11, name: "DFW", user_type: "dfw" },
    { id: 12, name: "PACKING LOCAL", user_type: "packing_local" },
    { id: 13, name: "PACKING EXPORT", user_type: "packing_export" },
    { id: 14, name: "DSTORE", user_type: "dstore" },
    { id: 15, name: "GSTORE", user_type: "gstore" },
    { id: 16, name: "MOULD", user_type: "mould" },
    { id: 17, name: "PSTORE", user_type: "pstore" },
    { id: 18, name: "PLANNING", user_type: "planning" },
    { id: 19, name: "SAGGER", user_type: "sagger" },
  ]);

  // const fetchData = async () => {
  //   setLoading(true); // Start loading

  //   try {
  //     const res = await Http.get(`/production-transfer`);
  //     const { finished_goods, transfers } = res.data;

  //     // Merge finished_goods and transfers
  //     const mergedData = [
  //       ...finished_goods.map((item) => ({
  //         ...item,
  //         type: "Finished Goods",
  //       })),
  //       ...transfers.map((item) => ({
  //         ...item,
  //         type: "Transfers",
  //       })),
  //     ];

  //     setData(finished_goods);
  //   } catch (error) {
  //     console.error("Fetch error:", error);
  //   } finally {
  //     setLoading(false); // Stop loading regardless of the outcome
  //   }
  // };

  const fetchData = async () => {
    setLoading(true);

    try {
      const res = await Http.get(`/production-transfer`);
      const staffTypeResponse = await Http.get("/staff-type-check");
      const staffType = staffTypeResponse.data;

      const { finished_goods, transfers } = res.data;

      // Merge finished_goods and transfers
      const mergedData = [
        ...finished_goods.map((item) => ({
          ...item,
          type: "Finished Goods",
        })),
        ...transfers.map((item) => ({
          ...item,
          type: "Transfers",
        })),
      ];

      // Filter mergedData based on the staff type and from_department
      const filteredData = mergedData.filter((transfer) =>
        transfer.items.some((item) => {
          const department = departments.find(
            (dept) => dept.id === item.from_department
          );
          return department && department.user_type === staffType;
        })
      );

      setData(mergedData);
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  const dummyData = [
    {
      id: 1,
      receipt_no: "R001",
      production_date: "2023-10-31",
      type: "Finished Goods",
      items: [{ from_department: 1 }],
    },
    {
      id: 2,
      receipt_no: "R002",
      production_date: "2023-10-31",
      type: "Transfers",
      items: [{ from_department: 2 }],
    },
    // Add more dummy data as needed
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const deleteData = (id, showMsg) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Http.delete(`/production-transfer/${id}`).then(() => {
          Swal.fire("Deleted!", showMsg + " has been deleted.", "success");
          fetchData(); // Refresh data after deletion
        });
      }
    });
  };

  const cols = [
    { field: "receipt_no", header: "Receipt No" },
    { field: "production_date", header: "Production Date" },
    {
      // field: "receive",
      header: "Receive",
      field: (item) => (
        <Link
          to={`/receive-production-transfer-art/${item.id}`}
          className="btn btn-success btn-sm"
        >
          Receive
        </Link>
      ),
    },
    {
      // field: "produce",
      header: "Production",
      field: (item) => (
        <Link
          to={`/create-production-transfer-art/${item.id}`}
          className="btn btn-warning btn-sm"
        >
          Produce
        </Link>
      ),
    },
  ];

  const actionTemplate = (item) => (
    <td className="text-xs">
      <Link
        to={{ pathname: "/edit-production-transfer-art/" + item.id }}
        className="btn btn-primary btn-sm"
      >
        <i className="fa fa-pencil" aria-hidden="true"></i>
      </Link>
      <Link
        to={`/view-production-transfer-art/${item.id}`}
        className="btn btn-info btn-sm ms-1"
      >
        <i className="fa fa-list" aria-hidden="true"></i>
      </Link>
      <button
        className="btn btn-danger btn-sm ms-1"
        onClick={() => deleteData(item.id, "item")}
      >
        <i className="fa fa-trash"></i>
      </button>
    </td>
  );

  return (
    <div>
      <div className="am-mainpanel">
        {/* <div className="am-pagetitle">
          <h5 className="am-title">All Items</h5>
          <Link to="/create-production-transfer-art" className="btn btn-primary">
            <i className="fa fa-plus-square mg-r-6"></i> Add New Item
          </Link>
        </div> */}
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <ReactDataTable
              cols={cols}
              receiveData={data}
              isLoading={loading}
              actionTemplate={actionTemplate}
              deleteData={deleteData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
