import $ from "jquery"; // Import jQuery
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Http from "../../../../Common/Http";
import IsLogin from "../../../../Common/IsLogin";
import { LoaderComponent } from "../../../../Common/LoaderComponent";

const Create = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [list, setList] = useState([]);
  const [inputs, setInputs] = useState([]);
  const [error, setError] = useState({});
  const [role, setRole] = useState("");
  const [receiveData, setReceiveData] = useState([]);

  const fetchData = async () => {
    try {
      const resRole = await Http.get("/staff-type-check");
      setRole(resRole.data);

      Http.get("/issue/" + id).then((res) => {
        setReceiveData(res.data.info);
        setList(res.data.info.requisition_art.items);
      });

      // Fetch the receipt number
      const resReceiptNo = await Http.get(
        "/last-code-increment-by-one/production_finished_goods/receipt_no/P"
      );
      // Set the fetched receipt number to inputs
      setInputs((prevInputs) => ({
        ...prevInputs,
        receipt_no: resReceiptNo.data,
      }));
    } catch (e) {
      console.error("Error fetching data:", e);
    }
  };

  useEffect(() => {
    $("body").addClass("collapse-menu");
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedList = [...list];
    let inputValue = parseFloat(value) || 0;

    // if (name === "transfer_qty") {
    //   const totalProductionQty = updatedList[index].total_production_qty || 0;
    //   const productionQty = parseFloat(updatedList[index].production_qty) || 0;
    //   const totalTransferQty = updatedList[index].total_transfer_qty || 0;

    //   const maxTransferQty =
    //     totalProductionQty + productionQty - totalTransferQty;

    //   if (inputValue > maxTransferQty) {
    //     Swal.fire({
    //       icon: "error",
    //       title: "Validation Error",
    //       text: `Transfer quantity cannot exceed ${maxTransferQty}.`,
    //     });
    //     inputValue = maxTransferQty; // Reset to the maximum allowed value
    //   }
    // }

    updatedList[index][name] = inputValue;
    setList(updatedList);
  };

  const formSubmit = () => {
    const formData = {
      ...inputs,
      items: list,
      production_against_requisition_art_id: receiveData.requisition_art_id,
      production_against_requisition_issue_id: id,
      to_department_id: receiveData.requisition_art.from_department_art_id,
      planning_id: receiveData.requisition_art.planning_id,
    };

    Http.post(`/production-transfer`, formData)
      .then((res) => {
        navigate("/transfer-art", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Production and Deliver</h5>
        </div>
        <div className="am-pagebody  row d-flex justify-content-evenly">
          <div className="card pd-20 pd-sm-40 col-11">
            <h6 className="card-body-title mg-sm-b-30">
              Production and Delivery
            </h6>
            {/* <p>Transfer To Department {inputs.to_department}</p> */}
            <div className="row">
              <div className="col-lg">
                <label>Receipt No</label>
                <input
                  type="text"
                  className="form-control"
                  name="receipt_no"
                  value={inputs.receipt_no}
                  disabled
                />
              </div>
              <div className="col-lg">
                <label>Shift</label>
                <select
                  className="form-control"
                  name="shift"
                  value={inputs.shift}
                  onChange={handleChange}
                >
                  <option value="">Select Shift</option>
                  <option value="a">A</option>
                  <option value="b">B</option>
                  <option value="c">C</option>
                  <option value="general">General</option>
                </select>
              </div>

              <div className="col-lg">
                <label>Remarks</label>
                <textarea
                  className="form-control"
                  name="remarks"
                  value={inputs.remarks}
                />
              </div>
            </div>

            {/* Item List */}
            <div className="row mt-3">
              <div className="card-solid">
                <div className="card-header with-border text-center">
                  <h5 className="card-title mb-0">Items in the Production</h5>
                </div>
              </div>
              <div className="col-lg">
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      <th>Qty</th>
                      <th>Production Qty</th>
                      <th>Transfer Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {item.product?.name} ({item.product?.pack_size}) ||{" "}
                          {item.product?.code} #{item.id}
                        </td>

                        <td>{item.required_qty}</td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="production_qty"
                            value={list[index]?.production_qty || ""}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="transfer_qty"
                            value={list[index]?.transfer_qty || ""}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-3">
                <button className="btn btn-primary" onClick={formSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create;
