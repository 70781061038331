import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormValidation from "../../../Common/FormValidation";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import { LoaderComponent } from "../../../Common/LoaderComponent";
import useRequiredFields from "../../../../hooks/useRequiredFields";

const Transfer = () => {
  const { checkRequiredFields } = useRequiredFields();
  const navigate = useNavigate();
  const { id } = useParams();
  const [error, setError] = useState({});
  const [receiveData, setReceiveData] = useState([]);
  const [inputs, setInputs] = useState({ status: "1" });
  const [remainingQty, setRemainingQty] = useState(0);
  // const [workOrders, setWorkOrders] = useState([]); // State for work orders
  const [role, setRole] = useState(""); // State for role

  // const fetchData = async () => {
  //   Http.get("/transfer/edit/" + id).then((res) => {
  //     setReceiveData(res.data);
  //     console.log(parseFloat(res.data.info.production_qty),parseFloat(res.data.info.transfer_qty));
  //     // setRemainingQty(parseFloat(res.data.info.production_qty) - parseFloat(res.data.info.transfer_qty));
  //     setRemainingQty(
  //       res.data.info.transfer_qty 
  //         ? parseFloat(res.data.info.production_qty) - parseFloat(res.data.info.transfer_qty)
  //         : parseFloat(res.data.info.production_qty)
  //     );
      
  //   });
  //   const resRole = await Http.get("/staff-type-check"); // Fetch role
  //   setRole(resRole.data);
  // };

  const fetchData = async () => {
    try {
      const res = await Http.get("/transfer/edit/" + id);
      const apiData = res.data.data; // Access the nested data object
      setReceiveData(apiData.transfer); // Set the transfer data directly
  
      // Calculate the remaining quantity
      setRemainingQty(
        apiData.transfer.issue.transfer_qty
          ? parseFloat(apiData.transfer.issue.production_qty) -
            parseFloat(apiData.transfer.issue.transfer_qty)
          : parseFloat(apiData.transfer.issue.production_qty)
      );
  
      const resRole = await Http.get("/staff-type-check"); // Fetch role
      setRole(resRole.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  useEffect(() => {
    fetchData();
    setInputs((prevInputs) => ({
      ...prevInputs,
      date: getTodayDate(), // Set today's date as default
    }));
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));

    // if(name == 'transfer_qty'){
    //   if(parseInt(value) > remainingQty){
    //     alert('Transfer quantity cannot be greater than Produced quantity Remaining Qty is '+ remainingQty);
    //     setInputs((values) => ({ ...values, [name]: 0 }));
    //   }
    // }
  };

  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };
  

  const formSubmit = () => {
    checkRequiredFields();
    const payload = {
      ...inputs,
      issue_id: receiveData.issue_id,
      to_department_art_id: inputs.to_department_id || receiveData?.to_department_art_id,
      transfer_qty: inputs.transfer_qty || receiveData?.items?.[0]?.delivery_qty || "",
    };
    Http.put(`/transfer/update/${id}`, payload)
      .then((res) => {
        navigate("/transfer-art", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Edit Transfer</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">
              Edit Transfer From 
              {/* Issue Reference No - <i>{receiveData?.issue?.reference_no}</i> */}
            </h6>
            {/* <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg">
                <label>Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  value={inputs.date || ""}
                  onChange={handleChange}
                  placeholder="Input Date"
                />
              </div>
              {/* <div className="col-lg">
                <label>Total Produced</label>
                <input
                  type="number"
                  className="form-control"
                  name="prev_qty"
                  value={receiveData?.issue?.production_qty}
                  onChange={handleChange}
                  // placeholder="Input Produced Qty"
                  readOnly
                />
              </div> */}
              {/* <div className="col-lg">
                <label>Remaining Qty</label>
                <input
                  type="number"
                  className="form-control"
                  name="prev_qty"
                  value={remainingQty || 0}
                  onChange={handleChange}
                  // placeholder="Input Produced Qty"
                  readOnly
                />
              </div> */}
              {role === "mould" && (
                <div className="col-lg">
                <label>Plaster of Paris</label>
                <input
                  type="number"
                  className="form-control"
                  name="plaster_paris"
                  value={inputs.plaster_paris || 0}
                  onChange={handleChange}
                  placeholder="Input Plaster of Paris"
                />
              </div>
              )}
            </div>
            <div className="row mt-3">
              <div className="col-lg">
                <label>Transfer Qty <i className="fa fa-star required"></i>

                </label>
                <input
                  type="number"
                  className="form-control"
                  name="transfer_qty"
                  value={inputs.transfer_qty || receiveData?.delivery_qty || ""}
                  onChange={handleChange}
                  placeholder="Input Deliver Qty"
                  data-required
                />
              </div>
              {/* {(role === "body" && inputs.transfer_qty > 0 )  && ( */}
                <div className="col-lg">
                <label>To Department <i className="fa fa-star required"></i>

                </label>
                <select
                  className="form-control"
                  name="to_department_id"
                  onChange={handleChange}
                  value={inputs.to_department_id || receiveData.to_department_art_id} // Preselect "to_department_art_id: 2"
                  data-required
                >
                  <option value={""}>Select Department</option>
                  <option value={2}>Jigger</option>
                  <option value={3}>Casting</option>
                  <option value={6}>Glaze</option>
                </select>
              </div>
              <div className="col-lg">
                <label>Remarks</label>
                <textarea
                  className="form-control"
                  name="remarks"
                  onChange={handleChange}
                  placeholder="Remarks"
                >
                  {inputs.remarks || ""}
                </textarea>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-3">
                <button
                  className="btn btn-primary btn-block mg-b-10"
                  onClick={formSubmit}
                >
                  <i className="fa fa-send mg-r-10"></i> Update
                </button>
              </div>
            </div>
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default Transfer;
