import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import Alert from "../../../Common/Alert";
import DatatableFunc from "../../../Common/DatatableFunc";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import ReactDataTable from "../../../Common/ReactDataTable";

const List = () => {
  const location = useLocation();
  const [receiveData, setReceiveData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true); // Start loading

    try {
      const res = await Http.get(`/all-product-stock`);
      const resRole = await Http.get(`/staff-type-check`);
      setReceiveData(res.data.info);
      setRole(resRole.data);
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false); // Stop loading regardless of the outcome
    }
  };

  useEffect(() => {
    DatatableFunc();
    fetchData();
  }, []);

  const showAlert = (message, type) => {
    setAlert({
      message: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 4000);
  };

  // useEffect(() => {
  //   if (!location.state) {
  //     return;
  //   }
  //   showAlert(location.state.message, location.state.type);
  // }, [location]);

  const deleteData = (id, deleteApiUrl, showMsg) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Http.delete("/" + deleteApiUrl + "/" + id).then((res) => {
          setReceiveData(res.data);
          Swal.fire("Deleted!", showMsg + " has been deleted.", "success");
        });
      }
    });
  };

  function formatType(value) {
    if (!value) return ""; // Handle empty or undefined values
    return value
      .split("_") // Split the string by underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
      .join(" "); // Join the words back with spaces
  }

  const cols = [
    { field: "code", header: "Code" },
    { field: "name", header: "Name" },
    // { field: "type", header: "type" },
    {
      // field: "type",
      header: "Type",
      field: (rowData) => formatType(rowData.type), // Use formatType function
    },
    { field: "stockQty", header: "Stock" },
  ];

  // Add role-specific columns
  if (role === "dfw" || role === "selection") {
    cols.push(
      { field: "export", header: "Qty Export" },
      { field: "a", header: "Qty A Class" },
      { field: "b", header: "Qty B Class" },
      { field: "c", header: "Qty C Class" }
    );
  } else if (role === "decoration_local" || role === "packing_local") {
    cols.push(
      { field: "a", header: "Qty A Class" },
      { field: "b", header: "Qty B Class" },
      { field: "c", header: "Qty C Class" }
    );
  } else if (role === "decoration_export" || role === "packing_export") {
    cols.push(
      { field: "export", header: "Qty Export" },
    );
  }

  return (
    <div>
      <IsLogin></IsLogin>
      {/* <LoaderComponent /> */}
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">Stock Without Breakdown</h5>
            {/* <Link to="/create-damage-return" className="btn btn-primary">
              <i className="fa fa-plus-square mg-r-6"></i>New Damage Return
            </Link> */}
          </div>

          <div className="am-pagebody">
            <Alert alert={alert} />

            <div className="card pd-20 pd-sm-40">
              <ReactDataTable
                cols={cols}
                receiveData={receiveData}
                isLoading={loading}
                deleteData={deleteData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
