import $ from "jquery"; // Import jQuery
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import useRequiredFields from "../../../../../hooks/useRequiredFields";
import Http from "../../../../Common/Http";
import IsLogin from "../../../../Common/IsLogin";
import { LoaderComponent } from "../../../../Common/LoaderComponent";

const Create = () => {
  const { checkRequiredFields } = useRequiredFields();
  const navigate = useNavigate();
  // const { id } = useParams();

  const [inputs, setInputs] = useState({
    receipt_no: "",
    production_work_order_id: "",
    date: "",
    receive_date: "",
    copy_type: "",
    purchase_type: "",
    from_department: "",
    to_department: "",
    status: "requested",
    // remarks: "",
  });

  const [list, setList] = useState([]);
  const [error, setError] = useState({});
  const [product, setProduct] = useState([]);
  const [planningOptions, setPlanningOptions] = useState([]);
  const [role, setRole] = useState("");
  const [itemLoading, setItemLoading] = useState(false);
  const [departments, setDepartments] = useState([
    { id: 1, name: "BODY", user_type: "body" },
    { id: 2, name: "JIGGER", user_type: "jigger" },
    { id: 3, name: "CASTING", user_type: "casting" },
    { id: 4, name: "FINISHING", user_type: "finishing" },
    { id: 5, name: "BK", user_type: "bk" },
    { id: 6, name: "GLAZING", user_type: "glazing" },
    { id: 7, name: "GK", user_type: "gk" },
    { id: 8, name: "SELECTION", user_type: "selection" },
    { id: 9, name: "DECORATION LOCAL", user_type: "decoration_local" },
    { id: 10, name: "DECORATION EXPORT", user_type: "decoration_export" },
    { id: 11, name: "DFW", user_type: "dfw" },
    { id: 12, name: "PACKING LOCAL", user_type: "packing_local" },
    { id: 13, name: "PACKING EXPORT", user_type: "packing_export" },
    { id: 14, name: "DSTORE", user_type: "dstore" },
    { id: 15, name: "GSTORE", user_type: "gstore" },
    { id: 16, name: "MOULD", user_type: "mould" },
    { id: 17, name: "PSTORE", user_type: "pstore" },
    { id: 18, name: "PLANNING", user_type: "planning" },
    { id: 19, name: "SAGGER", user_type: "sagger" },
  ]);

  const defectTypes = ["BN", "IS", "PH", "GD", "UG"];
  const repairTypes = ["PH", "SS", "AIR", "PC"];

  // Fetch the requisition data by ID and populate the form inputs
  // const fetchData = () => {
  //   Http.get("/product-list/all").then((res) => {
  //     setProduct(res.data);
  //   });

  //   Http.get("/staff-type-check").then((res) => {
  //     setRole(res.data);
  //   });

  //   Http.get("/planning")
  //     .then((res) => {
  //       setPlanningOptions(res.data); // Set planning options
  //     })
  //     .catch((e) => {
  //       console.error("Error fetching planning options:", e);
  //     });
  // };

  const fetchData = async () => {
    try {
      const resProduct = await Http.get("/product-list/all");
      setProduct(resProduct.data);

      const resRole = await Http.get("/staff-type-check");
      setRole(resRole.data);

      const resPlanning = await Http.get("/planning");
      const planningData = resPlanning.data;
      setPlanningOptions(planningData);

      if (planningData.length > 0) {
        // Automatically select the first planning and set in inputs
        const firstPlanning = planningData[0];
        setInputs((prevInputs) => ({
          ...prevInputs,
          planning_id: firstPlanning.id, // Assuming the ID field for planning
        }));

        // Load items for the first planning
        handlePlanningChange(firstPlanning.id);
      }

      // Fetch the receipt number
      const resReceiptNo = await Http.get(
        "/last-code-increment-by-one/production_finished_goods/receipt_no/FG"
      );
      const receiptNo = resReceiptNo.data; // Assuming the API returns the receipt number directly

      // Set the fetched receipt number to inputs
      setInputs((prevInputs) => ({
        ...prevInputs,
        receipt_no: receiptNo,
      }));
    } catch (e) {
      console.error("Error fetching data:", e);
    }
  };

  useEffect(() => {
    $("body").addClass("collapse-menu");
    // $("body").toggleClass("show-left");
    fetchData();

    // // Set `to_department` based on `role`
    // if (role === "jigger") {
    //   setInputs((prevInputs) => ({
    //     ...prevInputs,
    //     to_department:
    //       departments.find((dept) => dept.name === "FINISHING")?.id || "",
    //   }));
    // } else if (role === "casting") {
    //   setInputs((prevInputs) => ({
    //     ...prevInputs,
    //     to_department: departments.find((dept) => dept.name === "BK")?.id || "",
    //   }));
    // }
  }, []);

  const handleAddType = (index, type) => {
    const updatedList = [...list];
    const newEntry = { type: "", qty: 0 };

    if (type === "defect") {
      updatedList[index].defect_items = [
        ...(updatedList[index].defect_items || []),
        newEntry,
      ];
    } else if (type === "repair") {
      updatedList[index].repair_items = [
        ...(updatedList[index].repair_items || []),
        newEntry,
      ];
    }

    setList(updatedList);
  };

  const handleTypeChange = (index, subIndex, value, type) => {
    const updatedList = [...list];
    if (type === "defect") {
      updatedList[index].defect_items[subIndex].type = value;
    } else if (type === "repair") {
      updatedList[index].repair_items[subIndex].type = value;
    }
    setList(updatedList);
  };

  const handleQtyChange = (index, subIndex, value, type) => {
    const updatedList = [...list];
    const quantity = parseInt(value) || 0;

    const maxSelectQty =
      parseFloat(updatedList[index].total_received_qty || 0) -
      parseFloat(updatedList[index].total_selcted_qty || 0);

    if (quantity > maxSelectQty) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: `${
          type === "defect" ? "Defect" : "Repair"
        } quantity cannot exceed ${maxSelectQty}.`,
      });
      return;
    }
    if (type === "defect") {
      updatedList[index].defect_items[subIndex].qty = quantity;
    } else if (type === "repair") {
      updatedList[index].repair_items[subIndex].qty = quantity;
    }
    setList(updatedList);
  };

  const handleRemoveType = (index, subIndex, type) => {
    const updatedList = [...list];
    if (type === "defect") {
      updatedList[index].defect_items.splice(subIndex, 1);
    } else if (type === "repair") {
      updatedList[index].repair_items.splice(subIndex, 1);
    }
    setList(updatedList);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const type = e.target.type;
    const updatedList = [...list];
    // Check the type of input and handle accordingly
    if (type === "number") {
      updatedList[index][name] = parseFloat(value) || 0;
    } else {
      updatedList[index][name] = value;
    }

    // Validation: transfer_qty can't be more than production_qty
    const production_qty = parseFloat(updatedList[index].production_qty) || 0;
    const transfer_qty = parseFloat(updatedList[index].transfer_qty) || 0;

    if (name === "transfer_qty" && transfer_qty > production_qty) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Transfer quantity cannot exceed production quantity.",
      });
      updatedList[index].transfer_qty = production_qty;
    }
    if (
      name === "qty_export" ||
      name === "qty_a_class" ||
      name === "qty_b_class" ||
      name === "qty_c_class"
    ) {
      updatedList[index].select_qty =
        updatedList[index].qty_export +
        updatedList[index].qty_a_class +
        updatedList[index].qty_b_class +
        updatedList[index].qty_c_class;

      const total_received_qty =
        parseFloat(updatedList[index].total_received_qty) || 0;
      const total_selcted_qty =
        parseFloat(updatedList[index].total_selcted_qty) || 0;

      const maxSelectQty = total_received_qty - total_selcted_qty;

      if (updatedList[index].select_qty > maxSelectQty) {
        Swal.fire({
          icon: "error",
          title: "Validation Error",
          text: `Select quantity cannot exceed ${
            total_received_qty - total_selcted_qty
          }.`,
        });
        // updatedList[index][name] = 0;
        // Calculate the remaining allowed value for the current field
        const remainingAllowedValue =
          maxSelectQty -
          (updatedList[index].select_qty - updatedList[index][name]);
        // Update the current field with the remaining allowed value
        updatedList[index][name] = Math.max(remainingAllowedValue, 0);

        // Recalculate select_qty after resetting the current field
        updatedList[index].select_qty =
          updatedList[index].qty_export +
          updatedList[index].qty_a_class +
          updatedList[index].qty_b_class +
          updatedList[index].qty_c_class;
      }
    }

    setList(updatedList);
  };

  const formSubmit = () => {
    checkRequiredFields();
    const formData = {
      ...inputs,
      items: list,
    };

    Http.post(`/production-transfer`, formData)
      .then((res) => {
        navigate("/transfer-art", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  const handlePlanningChange = async (planningId) => {
    if (!planningId) return;

    setInputs((prevInputs) => ({
      ...prevInputs,
      planning_id: planningId, // Update planning_id in inputs
    }));

    try {
      // Fetch data based on selected planning ID
      const response = await Http.get(`/planning/${planningId}`);

      // Update list with items from the response
      const planningItems = response.data.items.map((item) => ({
        id: item.id,
        product_id: item.product_id,
        product_name: item.product.name,
        shape: item.shape?.name,
        production_work_order: item.production_work_order?.receipt_no,
        company: item.production_work_order?.company?.name,
        product_code: item.product.code,
        opening_balance: 0, // Adjust based on requirement or add additional fields as needed
        weight: item.weight, // Example if qty is used as weight
        production_qty: 0,
        qty_export: 0,
        qty_a_class: 0,
        qty_b_class: 0,
        qty_c_class: 0,
        transfer_qty: 0,
        closing_balance: item.qty, // Example calculation; adjust if necessary
        remarks_delivery: "", // Set to empty or adjust if needed
        qty: item.qty,
        total_received_qty: item.total_received_qty,
        total_transfer_qty: item.total_transfer_qty,
        total_selcted_qty: item.total_selcted_qty,
        total_repair_qty: item.total_repair_qty,
        total_defect_qty: item.total_defect_qty,
      }));

      setList(planningItems);
    } catch (error) {
      console.error("Error fetching products for planning:", error);
      Swal.fire(
        "Error!",
        "Failed to fetch products for the selected planning.",
        "error"
      );
    }
  };

  const cols = [{ field: "name", header: "Item" }];

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Production</h5>
        </div>
        <div className="am-pagebody  row d-flex justify-content-evenly">
          <div className="card pd-20 pd-sm-40 col-11">
            <h6 className="card-body-title mg-sm-b-30">Production</h6>
            {/* <p>Transfer To Department {inputs.to_department}</p> */}
            <div className="row">
              <div className="col-lg">
                <label>Receipt No</label>
                <input
                  type="text"
                  className="form-control"
                  name="receipt_no"
                  value={inputs.receipt_no}
                  disabled
                />
              </div>

              {/* <div className="col-lg">
                <label>Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  value={inputs.date}
                  disabled
                />
              </div> */}
              <div className="col-lg">
                <label>Planning</label>
                <select
                  className="form-control"
                  value={inputs.planning_id || ""} // Bind to inputs
                  onChange={(e) => handlePlanningChange(e.target.value)}
                >
                  <option value="">Select Planning</option>
                  {planningOptions.map((planning) => (
                    <option key={planning.id} value={planning.id}>
                      {planning.receipt_no}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg">
                <label>
                  Shift <i className="fa fa-star required"></i>
                </label>
                <select
                  className="form-control"
                  name="shift"
                  value={inputs.shift}
                  onChange={handleChange}
                  data-required
                >
                  <option value="">Select Shift</option>
                  <option value="a">A</option>
                  <option value="b">B</option>
                  <option value="c">C</option>
                  <option value="general">General</option>
                </select>
              </div>

              {/* <div className="row">
                <label>Transfer To Department {inputs.to_department}</label>
                
              </div> */}

              <div className="col-lg">
                <label>Remarks</label>
                <textarea
                  className="form-control"
                  name="remarks"
                  value={inputs.remarks}
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* Item List */}
            <div className="row mt-3">
              <div className="card-solid">
                <div className="card-header with-border text-center">
                  <h5 className="card-title mb-0">Items in the Production</h5>
                </div>
              </div>
              <div className="col-lg">
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      {/* <th>Planning Qty</th> */}
                      {/* <th>Opening Balance</th> */}
                      {/* <th>Received</th>
                      <th>Total Selected</th> */}
                      {/* <th>Repair Qty</th>
                      <th>Transfered Qty</th> */}
                      <th>Export Class Qty</th>
                      <th>A Class Qty</th>
                      <th>B Class Qty</th>
                      <th>C Class Qty</th>
                      <th>Select Qty</th>
                      <th>Defects Qty</th>
                      <th>Repair Qty</th>
                      {/* <th>Total Produced</th> */}
                      {/* <th>Weight</th> */}
                      {/* <th>Production Qty</th> opening + rcved   */}
                      {/* <th>Transfered</th> */}
                      {/* <th>Transfer</th> */}
                      {/* <th>Loss Qty</th> */}
                      {/* <th>Closing Balance</th> total p - total t */}
                    </tr>
                  </thead>
                  <tbody>
                    {list.length < 1 ? (
                      <tr>
                        <td colSpan={16} className="text-center">
                          Please add products
                        </td>
                      </tr>
                    ) : (
                      list.map((item, index) => (
                        <tr key={index}>
                          <td>
                            {item.product_name} ({item.shape}) || {item.company}{" "}
                            #{item.production_work_order}
                            <br />
                            <b>Planning Qty:</b> {item.qty || 0} <br />{" "}
                            <b>Received Qty:</b> {item.total_received_qty || 0}{" "}
                            || <b>Total Selected Qty:</b>{" "}
                            {item.total_selcted_qty || 0}
                            <br />
                            <b>Repair Qty: </b> {item.total_repair_qty || 0} ||{" "}
                            <b>Transfered Qty: </b>{" "}
                            {item.total_transfer_qty || 0}
                          </td>
                          {/* <td>{item.qty}</td> */}

                          {/* <td>{item.opening_balance}</td> */}
                          {/* <td>{item.total_received_qty || 0}</td>
                          <td>{item.total_selcted_qty || 0}</td> */}
                          {/* <td>{item.total_repair_qty || 0}</td>
                          <td>{item.total_transfer_qty || 0}</td> */}

                          <td>
                            <input
                              type="number"
                              className="form-control mb-2"
                              name="qty_export"
                              value={item.qty_export}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                            <textarea
                              name="remarks_export"
                              value={item.remarks_export || ""}
                              onChange={(e) => handleInputChange(e, index)}
                            ></textarea>
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control mb-2"
                              name="qty_a_class"
                              value={item.qty_a_class}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                            <textarea
                              name="remarks_a"
                              value={item.remarks_a || ""}
                              onChange={(e) => handleInputChange(e, index)}
                            ></textarea>
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control mb-2"
                              name="qty_b_class"
                              value={item.qty_b_class}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                            <textarea
                              name="remarks_b"
                              value={item.remarks_b || ""}
                              onChange={(e) => handleInputChange(e, index)}
                            ></textarea>
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control mb-2"
                              name="qty_c_class"
                              value={item.qty_c_class}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                            <textarea
                              name="remarks_c"
                              value={item.remarks_c || ""}
                              onChange={(e) => handleInputChange(e, index)}
                            ></textarea>
                          </td>
                          <td>{item.select_qty || 0}</td>

                          
                          <td>
                            <div className="text-center mb-2">
                              <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                onClick={() => handleAddType(index, "defect")}
                              >
                                <i className="fa fa-plus"></i>
                              </button>
                            </div>
                            {(item.defect_items || []).map((defect, subIndex) => (
                              <div
                                key={subIndex}
                                className="d-flex align-items-center mb-2"
                              >
                                <select
                                  className="form-control"
                                  value={defect.type}
                                  onChange={(e) =>
                                    handleTypeChange(
                                      index,
                                      subIndex,
                                      e.target.value,
                                      "defect"
                                    )
                                  }
                                >
                                  <option value="">Select Defect Type</option>
                                  {defectTypes.map((type) => (
                                    <option key={type} value={type}>
                                      {type}
                                    </option>
                                  ))}
                                </select>
                                <input
                                  type="number"
                                  className="form-control mx-2"
                                  name="defect_qty"
                                  value={defect.qty}
                                  onChange={(e) =>
                                    handleQtyChange(
                                      index,
                                      subIndex,
                                      e.target.value,
                                      "defect"
                                    )
                                  }
                                  placeholder="Qty"
                                />
                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm"
                                  onClick={() =>
                                    handleRemoveType(index, subIndex, "defect")
                                  }
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </div>
                            ))}
                          </td>

                          {/* Repairs column */}
                          <td>
                            <div className="text-center mb-2">
                              <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                onClick={() => handleAddType(index, "repair")}
                              >
                                <i className="fa fa-plus"></i>
                              </button>
                            </div>
                            {(item.repair_items || []).map(
                              (repair, subIndex) => (
                                <div
                                  key={subIndex}
                                  className="d-flex align-items-center mb-2"
                                >
                                  <select
                                    className="form-control"
                                    value={repair.type}
                                    onChange={(e) =>
                                      handleTypeChange(
                                        index,
                                        subIndex,
                                        e.target.value,
                                        "repair"
                                      )
                                    }
                                  >
                                    <option value="">Select Repair Type</option>
                                    {repairTypes.map((type) => (
                                      <option key={type} value={type}>
                                        {type}
                                      </option>
                                    ))}
                                  </select>
                                  <input
                                    type="number"
                                    className="form-control mx-2"
                                    name="repair_qty"
                                    value={repair.qty}
                                    onChange={(e) =>
                                      handleQtyChange(
                                        index,
                                        subIndex,
                                        e.target.value,
                                        "repair"
                                      )
                                    }
                                    placeholder="Qty"
                                  />
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm"
                                    onClick={() =>
                                      handleRemoveType(
                                        index,
                                        subIndex,
                                        "repair"
                                      )
                                    }
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </div>
                              )
                            )}
                          </td>
                          {/* <td>578</td> */}
                          {/* <td>
                            <input
                              type="number"
                              className="form-control"
                              name="weight"
                              value={item.weight}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </td> */}
                          {/* <td>
                            <input
                              type="number"
                              className="form-control"
                              name="production_qty"
                              value={inputs.production_qty || 0}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </td> */}
                          {/* <td>350</td> */}
                          {/* <td>
                            <input
                              type="number"
                              className="form-control"
                              name="transfer_qty"
                              value={item.transfer_qty}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              name="return_qty"
                              value={item.return_qty}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </td> */}
                          {/* <td>{item.closing_balance}</td> */}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            {list.length > 0 && (
              <div className="row mt-3">
                <div className="col-3">
                  <button className="btn btn-primary" onClick={formSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create;
