import $ from "jquery"; // Import jQuery
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import useRequiredFields from "../../../../hooks/useRequiredFields";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import { LoaderComponent } from "../../../Common/LoaderComponent";

const Create = () => {
  const { checkRequiredFields } = useRequiredFields();
  const navigate = useNavigate();
  const { id } = useParams();

  const [inputs, setInputs] = useState({
    receipt_no: "",
    production_work_order_id: "",
    date: "",
    receive_date: "",
    copy_type: "",
    purchase_type: "",
    from_department: "",
    to_department: "",
    status: "requested",
    remarks: "",
  });

  const [list, setList] = useState([]);
  const [error, setError] = useState({});
  const [planningOptions, setPlanningOptions] = useState([]);
  const [role, setRole] = useState("");
  const [receiveData, setReceiveData] = useState([]);
  const [itemLoading, setItemLoading] = useState(false);

  const fetchData = async () => {
    try {
      const resRole = await Http.get("/staff-type-check");
      setRole(resRole.data);
      const redData = await Http.get("/production-transfer/" + id);
      setReceiveData(redData.data);
      setInputs((prevInputs) => ({
        ...prevInputs,
        planning_id: redData.data.data.planning_id, // Assuming the ID field for planning
        shift: redData.data.data.shift,
      }));
      handlePlanningChange(redData.data.data.planning_id);

      const resPlanning = await Http.get("/planning?production=1");
      const planningData = resPlanning.data;
      setPlanningOptions(planningData);

      // if (planningData.length > 0) {
      //   // Automatically select the first planning and set in inputs
      //   const firstPlanning = planningData[0];
      //   setInputs((prevInputs) => ({
      //     ...prevInputs,
      //     planning_id: firstPlanning.id, // Assuming the ID field for planning
      //   }));

      //   // Load items for the first planning
      //   handlePlanningChange(firstPlanning.id);
      // }

      // Fetch the receipt number
      const resReceiptNo = await Http.get(
        "/last-code-increment-by-one/production_finished_goods/receipt_no/P"
      );
      const receiptNo = resReceiptNo.data; // Assuming the API returns the receipt number directly

      // Set the fetched receipt number to inputs
      setInputs((prevInputs) => ({
        ...prevInputs,
        receipt_no: receiptNo,
      }));

      const productionTransferItems = redData.data.data.items;

      // Map productionTransferItems to include slice_weight in the planning items
      // setList((prevList) =>
      //   prevList.map((item) => {
      //     const transferItem = productionTransferItems.find(
      //       (tItem) => tItem.product_id === item.product_id
      //     );
      //     return {
      //       ...item,
      //       slice_weight: transferItem
      //         ? transferItem.slice_weight
      //         : item.slice_weight,
      //     };
      //   })
      // );
    } catch (e) {
      console.error("Error fetching data:", e);
    }
  };

  useEffect(() => {
    $("body").addClass("collapse-menu");
    // $("body").toggleClass("show-left");
    fetchData();

    // // Set `to_department` based on `role`
    // if (role === "jigger") {
    //   setInputs((prevInputs) => ({
    //     ...prevInputs,
    //     to_department:
    //       departments.find((dept) => dept.name === "FINISHING")?.id || "",
    //   }));
    // } else if (role === "casting") {
    //   setInputs((prevInputs) => ({
    //     ...prevInputs,
    //     to_department: departments.find((dept) => dept.name === "BK")?.id || "",
    //   }));
    // }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // const handleInputChange = (e, index) => {
  //   const { name, value } = e.target;
  //   const updatedList = [...list];
  //   let inputValue = parseFloat(value) || 0;

  //   // Update the specific field with the input value
  //   updatedList[index][name] = inputValue;

  //   // Validation: transfer_qty can't be more than production_qty
  //   /*const production_qty = parseFloat(updatedList[index].production_qty) || 0;
  //   const transfer_qty = parseFloat(updatedList[index].transfer_qty) || 0;

  //   if (name === "transfer_qty" && transfer_qty > production_qty) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Validation Error",
  //       text: "Transfer quantity cannot exceed production quantity.",
  //     });
  //     updatedList[index].transfer_qty = production_qty;
  //   }*/

  //   setList(updatedList);
  // };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedList = [...list];
    let inputValue = parseFloat(value);

    if (name === "transfer_qty") {
      const totalProductionQty = list[index].total_production_qty || 0;
      const productionQty = parseFloat(list[index].production_qty) || 0;
      const totalTransferQty = list[index].total_transfer_qty || 0;

      const maxTransferQty =
        totalProductionQty + productionQty - totalTransferQty;
      console.log("====================================");
      console.log(
        maxTransferQty,
        productionQty,
        totalTransferQty,
        totalProductionQty
      );
      console.log("====================================");
      if (inputValue > maxTransferQty) {
        Swal.fire({
          icon: "error",
          title: "Validation Error",
          text: `Transfer quantity cannot exceed ${maxTransferQty}.`,
        });
        inputValue = 0; // Reset to the maximum allowed value
      }
    }

    updatedList[index][name] = inputValue;
    setList(updatedList);
  };

  const formSubmit = () => {
    checkRequiredFields();
    const formData = {
      ...inputs,
      items: list,
    };

    Http.put(`/production-transfer/` + id, formData)
      .then((res) => {
        navigate("/production", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  const handlePlanningChange = async (planningId) => {
    if (!planningId) return;

    setInputs((prevInputs) => ({
      ...prevInputs,
      planning_id: planningId, // Update planning_id in inputs
    }));

    try {
      // Fetch data based on selected planning ID
      const response = await Http.get(`/planning/${planningId}?production=1`);

      // Extract production_transfer_info for mapping quantities
      const productionTransferInfo = response.data.production_transfer_info;

      const planningItems = response.data.items.map((item) => {
        const productId = item.product_id;

        return {
          id: item.id,
          planning_id: item.planning_id,
          product_id: productId,
          product_name: item.product.name,
          shape: item.shape?.name,
          production_work_order: item.production_work_order?.receipt_no,
          company: item.production_work_order?.company?.name,
          product_code: item.product.code,
          planning_qty: item.qty,
          production_qty: "",
          return_qty: 0,
          transfer_qty: "",
          total_production_qty:
            productionTransferInfo[productId]?.production_qty || 0, // Map production_qty
          total_transfer_qty: item.total_transfer_qty || 0, // Map transfer_qty
          slice_weight: item.slice_weight, // Example if qty is used as slice_weight
          closing_balance: item.qty, // Example calculation; adjust if necessary
          remarks_delivery: "", // Set to empty or adjust if needed
        };
      });

      setList(planningItems);

      // Fetch slice_weight for each item from /production-transfer/{id}
      const productionTransferResponse = await Http.get(
        `/production-transfer/${id}`
      );
      const productionTransferItems =
        productionTransferResponse.data.data.items;

      setList((prevList) =>
        prevList.map((item) => {
          const transferItem = productionTransferItems.find(
            (tItem) => tItem.planning_item_id === item.id
          );
          return {
            ...item,
            slice_weight: transferItem
              ? transferItem.slice_weight
              : item.slice_weight,
            green_weight: transferItem
              ? transferItem.green_weight
              : item.green_weight,
            production_qty: transferItem
              ? transferItem.qty
              : item.production_qty,
            transfer_qty: transferItem
              ? transferItem.transfer_qty
              : item.transfer_qty,
          };
        })
      );
    } catch (error) {
      console.error("Error fetching products for planning:", error);
      Swal.fire(
        "Error!",
        "Failed to fetch products for the selected planning.",
        "error"
      );
    }
  };

  const cols = [{ field: "name", header: "Item" }];

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Edit Production and Deliver</h5>
        </div>
        <div className="am-pagebody  row d-flex justify-content-evenly">
          <div className="card pd-20 pd-sm-40 col-11">
            <h6 className="card-body-title mg-sm-b-30">
              Edit Production and Delivery
            </h6>
            {/* <p>Transfer To Department {inputs.to_department}</p> */}
            <div className="row">
              <div className="col-lg">
                <label>Receipt No</label>
                <input
                  type="text"
                  className="form-control"
                  name="receipt_no"
                  value={inputs.receipt_no}
                  disabled
                />
              </div>

              {/* <div className="col-lg">
                <label>Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  value={inputs.date}
                  disabled
                />
              </div> */}
              <div className="col-lg">
                <label>Planning</label>
                <select
                  className="form-control"
                  name="planning_id"
                  value={inputs.planning_id || ""} // Bind to inputs
                  onChange={(e) => handlePlanningChange(e.target.value)}
                  // disabled
                >
                  <option value="">Select Planning</option>
                  {planningOptions.map((planning) => (
                    <option key={planning.id} value={planning.id}>
                      {planning.receipt_no}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg">
                <label>
                  Shift <i className="fa fa-star required"></i>
                </label>
                <select
                  className="form-control"
                  name="shift"
                  value={inputs.shift} // Bind only to inputs.shift
                  onChange={handleChange}
                  // disabled
                  data-required
                >
                  <option value="">Select Shift</option>
                  <option value="a">A</option>
                  <option value="b">B</option>
                  <option value="c">C</option>
                  <option value="general">General</option>
                </select>
              </div>

              {/* <div className="row">
                <label>Transfer To Department {inputs.to_department}</label>
                
              </div> */}

              <div className="col-lg">
                <label>Remarks</label>
                <textarea
                  className="form-control"
                  name="remarks"
                  value={inputs.remarks}
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* Item List */}
            <div className="row mt-3">
              <div className="card-solid">
                <div className="card-header with-border text-center">
                  <h5 className="card-title mb-0">Items in the Production</h5>
                </div>
              </div>
              <div className="col-lg">
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      {/* <th>Planning Qty</th> */}
                      {/* <th>Opening Balance</th> */}
                      {/* <th>Total Produced</th> */}
                      {(role === "jigger" || role === "casting") && (
                        <>
                          <th>Slice Weight</th>
                          <th>Green Weight</th>
                        </>
                      )}
                      <th>Production Qty</th> {/* opening + rcved   */}
                      <th>Total Transfered</th>
                      <th>Transfer Qty</th>
                      {/* {(role === "jigger" ||
                        role === "casting" ||
                        role === "finishing") && <th>Return Qty</th>} */}
                      {/* <th>Return Qty</th> */}
                      {/* <th>Closing Balance</th> total p - total t */}
                    </tr>
                  </thead>
                  <tbody>
                    {list.length < 1 ? (
                      <tr>
                        <td colSpan={13} className="text-center">
                          Please add products
                        </td>
                      </tr>
                    ) : (
                      list.map((item, index) => (
                        <tr key={index}>
                          <td>
                            {item.product_name} ({item.shape}) || {item.company}{" "}
                            #{item.production_work_order} <br />
                            <b>Planning Qty:</b> {item.planning_qty} ||{" "}
                            <b>Total Produced:</b> {item.total_production_qty}
                          </td>
                          {/* <td>{item.planning_qty}</td>

                          <td>{item.total_production_qty}</td> */}
                          {(role === "jigger" || role === "casting") && (
                            <>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="slice_weight"
                                  value={list[index].slice_weight || ""}
                                  onChange={(e) => handleInputChange(e, index)}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="green_weight"
                                  value={list[index].green_weight || ""}
                                  onChange={(e) => handleInputChange(e, index)}
                                />
                              </td>
                            </>
                          )}

                          <td>
                            <input
                              type="number"
                              className="form-control"
                              name="production_qty"
                              value={list[index].production_qty}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </td>
                          <td>{item.total_transfer_qty}</td>
                          {/* <td>
                            <input
                              type="number"
                              className="form-control"
                              name="transfer_qty"
                              value={inputs.transfer_qty}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </td> */}
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              name="transfer_qty"
                              value={list[index].transfer_qty} // Ensure it binds to the state
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </td>
                          {/* {(role === "jigger" ||
                            role === "casting" ||
                            role === "finishing") && (
                            <td>
                              <input
                                type="number"
                                className="form-control"
                                name="return_qty"
                                value={item.return_qty}
                                onChange={(e) => handleInputChange(e, index)}
                              />
                            </td>
                          )} */}
                          {/* <td>{item.closing_balance}</td> */}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            {list.length > 0 && (
              <div className="row mt-3">
                <div className="col-3">
                  <button className="btn btn-primary" onClick={formSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create;
