import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import DatatableFunc from "../../../Common/DatatableFunc";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import { LoaderComponent } from "../../../Common/LoaderComponent";
import IssueDetail from "./IssueDetail";

const Create = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const { id } = useParams();
  const [error, setError] = useState({});
  // const [product, setProduct] = useState({});
  // const [rmpm, setRmpm] = useState({});
  // const [factory, setFactory] = useState({});
  // const [lastReceiptNo, setLastReceiptNo] = useState("000001");
  const [list, setList] = useState({});
  const [listDetail, setListDetail] = useState({});
  const [listDetailId, setListDetailId] = useState({});
  const [custom, setCustom] = useState({});

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleCustomInputChange = (e, index) => {
    const { name, value } = e.target; // Chanages Experience Input Name and Value
    const inputInfo = [...list]; // Defined Experience State All Information into List Variable

    if (inputs.receipe_type == "custom" && name == "custom_purchase_order") {
      const selectedOptions = Array.from(e.target.selectedOptions).map((option) => option.value);
      inputInfo[index]["custom_purchase_order"] = selectedOptions;
    } else {
      inputInfo[index][name] = value;
    }
    setList(inputInfo);
    const listArr = [];
    listArr.push(index);
    listArr.push(list[index]);
    listArr.push(inputs);

    Http.post("/receipe-item-load", listArr).then((res) => {
      if (res.type == "custom") {
        const inputInfo = [...list];
        inputInfo[res.data.index] = res.data.product_purchase_list[res.data.product_id];
        setList(inputInfo);
        setListDetail([]);
        setListDetailId([]);
      } else {
        if (listDetail.length > 0) {
          const inputInfo = [...listDetail];
          inputInfo[res.data.index] = res.data.product_purchase_detail[res.data.product_id];
          setListDetail(inputInfo);

          const inputInfo2 = [...listDetailId];
          inputInfo2[res.data.index] = res.data.purchase_order_items[res.data.product_id];
          setListDetailId(inputInfo2);
        }
      }
    });
  };

  const fetchData = async () => {
    try {
      // Load recipe type-wise data
      const inputsToSend = {
        ...inputs,
        receipe_type: 'fifo',
        requisition_id: id,
      };
  
      const { data: recipeData } = await Http.post("/requisition-production-load-receipe-type-wise", inputsToSend);
      setList(recipeData.purchase_order_items);
      setListDetail(recipeData.product_purchase_detail);
      setListDetailId(recipeData.purchase_order_items);

      const {
        date,
        time,
        batch_size,
        product_id,
        from_department: { name: from_department },
        items,
      } = recipeData.data;
  
      // Set inputs for requisition data
      setInputs({
        date,
        time,
        batch_size,
        product_id,
        from_department,
        receipe_type:'fifo'
      });


      // Fetch reference number
      const { data: referenceNo } = await Http.get("/last-code-increment-by-one/issues/reference_no/IR");
      // Set input for reference number
      setInputs((prevValues) => ({
        ...prevValues,
        reference_no: referenceNo,
      }));
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
    }
  };
  
  

  useEffect(() => {
    DatatableFunc();
    fetchData();
  }, []);
  // End Default Load

  const formSubmit = () => {
    inputs.list = list;
    inputs.listDetail = listDetail;
    inputs.list_detail_id = listDetailId;
    inputs.requisition_production_id = id;

    Http.post("/issue", inputs)
      .then((res) => {
        navigate("/issue", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };
  // Remove List
  const removeList = (index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const inputInfo = [...list]; // Load All Existing experience State Data
        inputInfo.splice(index, 1); // Remove Experience by Index/Click Event Wise
        setList(inputInfo); // Replace Existing experience into State
      }
    });
  };
  const displayError = (fieldName) => {
    if (error && error[fieldName]) {
      return <div className="text-danger small">{error[fieldName]}</div>;
    }
    return null;
  };

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Create Issue</h5>
        </div>
        <div className="am-pagebody row">
          <div className="card pd-20 pd-sm-40 col-12">
            <h6 className="card-body-title mg-sm-b-30 text-center">Generate Issue Against Store Requisition</h6>
            <hr></hr>
            <div className="row">
              <div className="col-lg">
                <label>
                  Date <i className="fa fa-star required"></i>
                </label>
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  value={inputs.date || ""}
                  onChange={handleChange}
                  placeholder="Input Date"
                  data-required
                  disabled
                />
                {displayError("date")}
              </div>
              {/* col */}
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label>
                  Reference No <i className="fa fa-star required"></i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="reference_no"
                  value={inputs.reference_no || ""}
                  onChange={handleChange}
                  placeholder="Input Reference No"
                  data-required
                  disabled
                />

                {displayError("reference_no")}
              </div>
              <div className="col-lg">
                <label>
                  Batch Size <i className="fa fa-star required"></i>
                </label>
                <input
                  type="batch_size"
                  className="form-control"
                  disabled
                  name="batch_size"
                  value={inputs.batch_size || ""}
                  onChange={handleChange}
                  placeholder="Input Batch Size"
                  data-required
                />
                {displayError("batch_size")}
              </div>
              <div className="col-lg">
                <label>
                From Department <i className="fa fa-star required"></i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  name="from_department"
                  value={inputs.from_department || ""}
                  onChange={handleChange}
                  placeholder="Input Batch Size"
                  data-required
                />
                {displayError("from_department")}
              </div>
            </div>

            <div className="row mt-3">
              <div className="card-solid">
                <div className="card-header with-border text-center">
                  <h5 className="card-title mb-0">Items in the Requisition</h5>
                </div>
                <div className="card-body mt-3 p-0 pt-3">
                  <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0">
                    <thead>
                      <tr>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Code</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Product</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Standard Qty</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Issue Qty</th>
                        {/* <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Details</th> */}
                        
                        {/* <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {Array.from(list).map((x, i) => (
                        <IssueDetail
                          key={i}
                          receipeType={inputs.receipe_type}
                          listInfo={x}
                          listSl={i}
                          listDetail={listDetail}
                          remove={() => removeList(i)}
                          listInputChange={(e) => handleCustomInputChange(e, i)}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-3">
                <button className="btn btn-primary btn-block mg-b-10" onClick={formSubmit}>
                  <i className="fa fa-send mg-r-10"></i> Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create;
