import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import FormValidation from "../../../Common/FormValidation";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import useRequiredFields from "../../../../hooks/useRequiredFields";

const CreateRegion = () => {
  const { checkRequiredFields } = useRequiredFields();
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [product, setProduct] = useState({});
  const [department, setDepartment] = useState({});
  const [rmpm, setRmpm] = useState({});
  const [error, setError] = useState({});
  const [lastReceiptNo, setLastReceiptNo] = useState("000001");
  const [list, setList] = useState([{ rmpm_id: "", qty: "", desc: "" }]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const resRmpm = await Http.get("/product-list/raw_materials");

        // Filter raw_materials where department_finished_good is null for rmpm_id
        const rmpmOptions = resRmpm.data.filter(
          (item) => item.department_finished_good === null
        );

        setRmpm(rmpmOptions);

        // Filter raw_materials where department_finished_good is 1 for product_id
        const productOptions = resRmpm.data
          .filter((item) => item.department_finished_good === 1)
          .map((product) => ({
            value: product.id,
            label: `${product.name} - ${product.unit.name}`,
          }));
        setProduct(productOptions);

        // const resLastReceiptNo = await Http.get("/last-receipe-no");
        // setLastReceiptNo(resLastReceiptNo.data);

        const resLastCodeIncrement = await Http.get(
          "/last-code-increment-by-one/receipes/receipt_no/R"
        );
        setLastReceiptNo(resLastCodeIncrement.data);

        const resDepartment = await Http.get("/departmentArtSelect2");
        console.log(resDepartment);
        setDepartment(resDepartment.data);

        getCurrentDateInput();
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // Set Experience State
  const handleInputChange = (e, index) => {
    const { name, value } = e.target; // Chanages Experience Input Name and Value
    const inputInfo = [...list]; // Defined Experience State All Information into List Variable

    inputInfo[index][name] = value;
    setList(inputInfo);
  };

  const selectHandleChange = (selectedOption, actionMeta) => {
    const name = actionMeta.name;
    const value = selectedOption.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const getCurrentDateInput = () => {
    const dateObj = new Date();

    // get the month in this format of 04, the same for months
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const year = dateObj.getFullYear();

    const shortDate = `${year}-${month}-${day}`;

    setInputs((values) => ({ ...values, ["date"]: shortDate }));
  };
  // Default Load
  // const fetchData = () => {
  //   Http.get("/finished-good").then((res) => {
  //     setProduct(res.data);
  //   });
  //   Http.get("/raw-packing-materials").then((res) => {
  //     setRmpm(res.data);
  //   });
  //   // Http.get("/last-receipe-no").then((res) => {
  //   //   setLastReceiptNo(res.data);
  //   // });
  //   Http.get("/last-code-increment-by-one/receipes/receipt_no/R").then((res) => {
  //     setLastReceiptNo(res.data);
  //   });
  //   getCurrentDateInput();
  // };
  // useEffect(() => {
  //   fetchData();
  // }, []);
  // End Default Load

  // Add More List
  const addMoreList = () => {
    setList([...list, { rmpm_id: "", qty: "", desc: "" }]); // Add One More List
  };
  // Remove List
  const removeList = (index) => {
    const inputInfo = [...list]; // Load All Existing experience State Data
    inputInfo.splice(index, 1); // Remove Experience by Index/Click Event Wise
    setList(inputInfo); // Replace Existing experience into State
  };

  const formSubmit = (e) => {
    e.preventDefault();
    const isValid = checkRequiredFields();
    const listArr = [];
    Object.keys(list).forEach((key) => {
      listArr.push(list[key]);
    });

    inputs.items = listArr;
    inputs.receipt_no = lastReceiptNo;
    Http.post("/receipe", inputs)
      .then((res) => {
        navigate("/receipe", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };
  const reactDropdownHandler = (field, prop) => (e) => {
    setInputs((values) => ({
      ...values,
      [field]: e.value[prop],
    }));
  };

  return (
    <div>
      <IsLogin></IsLogin>
      {/* <LoaderComponent /> */}
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Create Receipe</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Create Receipe Form</h6>
            {/* <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div> */}
            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg">
                    <label>
                      Date <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="date"
                      value={inputs.date || ""}
                      onChange={handleChange}
                      data-required
                    />
                  </div>
                  <div className="col-lg">
                    <label>Receipt no <i className="fa fa-star required"></i></label>
                    <input
                      type="text"
                      className="form-control"
                      name="receipt_no"
                      value={lastReceiptNo || ""}
                      onChange={handleChange}
                      data-required
                    />
                  </div>
                  <div className="col-lg">
                    <label>
                      Batch Size <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="batch_size"
                      value={inputs.batch_size || ""}
                      onChange={handleChange}
                      data-required
                    />
                  </div>
                  {/* <div className="col-lg">
                    <label>Yield %</label>
                    <input
                      type="text"
                      className="form-control"
                      name="yield"
                      value={inputs.yield || ""}
                      onChange={handleChange}
                    />
                  </div> */}
                </div>
                <div className="row mt-3">
                  <div className="col-lg">
                    <label>
                      Department <i className="fa fa-star required"></i>
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue="Select a department"
                      name="department_id"
                      options={department}
                      onChange={selectHandleChange}
                    />
                  </div>
                  {/* <div className="col-lg">
                    <label htmlFor="">
                      Department <i className="fa fa-star required"></i>
                    </label>
                    <select
                      className="form-control"
                      name="department_id"
                      onChange={handleChange}
                    >
                      <option value="">Select Department</option>
                      {Array.from(department).map((item, index) => (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    {/* <ReactDropdown
                      options={department}
                      optitle={"name"}
                      onChange={reactDropdownHandler("department_id", "id")}
                    /> 
                  </div> */}
                  <div className="col-lg">
                    <label htmlFor="">
                      Finished Good <i className="fa fa-star required"></i>
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue="Select a product"
                      name="product_id"
                      options={product}
                      onChange={selectHandleChange}
                    />
                    {/* <ReactDropdown
                      options={product}
                      optitle={"name"}
                      onChange={reactDropdownHandler("product_id", "id")}
                    /> */}
                  </div>
                  {/* <div className="col-lg">
                    <label>Overhead %</label>
                    <input
                      type="text"
                      className="form-control"
                      name="overhead_percent"
                      value={inputs.overhead_percent || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg">
                    <label>Overhead Amount</label>
                    <input
                      type="text"
                      className="form-control"
                      name="overhead_amount"
                      value={inputs.overhead_amount || ""}
                      onChange={handleChange}
                    />
                  </div> */}
                </div>

                <div className="row mt-3">
                  <div className="col-10"></div>
                  <div className="col-2">
                    <button
                      className="btn btn-info btn-block mg-b-10"
                      onClick={addMoreList}
                    >
                      <i className="fa fa-plus mg-r-10"></i> ADD More
                    </button>
                  </div>
                  <div className="col-12">
                    <table className="table table-sm align-items-center mb-0">
                      <thead>
                        <tr>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            Product <i className="fa fa-star required"></i>
                          </th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            Qty <i className="fa fa-star required"></i>
                          </th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            Description
                          </th>
                          {list.length > 1 && (
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Action
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {Array.from(list).map((x, i) => (
                          <tr key={i}>
                            <td>
                              {/* <Select
                                className="basic-single"
                                classNamePrefix="select"
                                name="rmpm_id"
                                options={rmpm} // Updated raw materials options for react-select
                                onChange={selectHandleChange}
                              /> */}

                              <select
                                className="form-control"
                                name="rmpm_id"
                                onChange={(e) => handleInputChange(e, i)}
                                data-required
                              >
                                <option value="">Select Product</option>
                                {Array.from(rmpm).map((item, index) => (
                                  <option value={item.id} key={item.id}>
                                    {item.name} - {item.unit.name}
                                  </option>
                                ))}
                              </select>
                              {/* <ReactDropdown
                              options={rmpm}
                              optitle={"name"}
                              onChange={reactDropdownHandler(
                                "product_id",
                                "id"
                              )}
                            /> */}
                            </td>
                            <td>
                              <input
                                type="number"
                                className="form-control"
                                name="qty"
                                value={x.qty || ""}
                                onChange={(e) => handleInputChange(e, i)}
                                data-required
                              />
                            </td>
                            <td>
                              <textarea
                                className="form-control"
                                name="desc"
                                value={x.desc || ""}
                                onChange={(e) => handleInputChange(e, i)}
                              ></textarea>
                            </td>
                            {list.length > 1 && (
                              <td>
                                <button
                                  className="btn btn-danger btn-block"
                                  onClick={() => removeList(i)}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-6"></div>
                  <div className="col-3"></div>
                  <div className="col-3">
                    <button
                      className="btn btn-primary btn-block mg-b-10"
                      onClick={formSubmit}
                    >
                      <i className="fa fa-send mg-r-10"></i> Create
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default CreateRegion;
