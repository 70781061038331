import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import useRequiredFields from "../../../../hooks/useRequiredFields";
import FormValidation from "../../../Common/FormValidation";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";

const Create = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  const [base, setBase] = useState({});
  const [factory, setFactory] = useState({});
  const [selectedBaseOption, setSelectedBaseOption] = useState(null);
  const [selectedSrBase, setSelectedSrBase] = useState([]);
  const { checkRequiredFields } = useRequiredFields();
  const [isRequired, setIsRequired] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const res4 = await Http.get("/last-code-increment-by-one/companies/code/COM");
        setInputs({ code: res4.data });
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const companyTypeOptions = [
    { value: "foreign", label: "Foreign" },
    { value: "local", label: "Local" },
    { value: "corporate", label: "Corporate" },
  ];

  const formSubmit = (e) => {
    e.preventDefault();
    const isValid = checkRequiredFields();
    Http.post("/companies", inputs)
      .then((res) => {
        navigate("/company", { state: { message: res.data.message, type: "success" } });
      })
      .catch((e) => setError(e.response.data.errors));
  };

  return (
    <div>
      <IsLogin />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Create Company</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Create Company Form</h6>
            <div className="row">
              {/* <div className="col-lg">
                <FormValidation error={error} />
              </div> */}
            </div>
            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg">
                    <label>
                      Code <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="code"
                      value={inputs.code || ""}
                      onChange={handleChange}
                      placeholder="Input code"
                      data-required
                    />
                  </div>
                  <div className="col-lg">
                    <label>
                      Company Name <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={inputs.name || ""}
                      onChange={handleChange}
                      placeholder="Input Name"
                      data-required
                    />
                  </div>
                  <div className="col-lg">
                    <label>
                      Contact Person <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="contact_person"
                      value={inputs.contact_person || ""}
                      onChange={handleChange}
                      placeholder="Input Contact Person"
                      data-required
                    />
                  </div>
                  <div className="col-lg">
                    <label>Contact Phone <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="contact_phone"
                      value={inputs.contact_phone || ""}
                      onChange={handleChange}
                      placeholder="Input Contact Phone"
                      data-required
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-lg">
                    <label>Secondary Phone</label>
                    <input
                      type="number"
                      className="form-control"
                      name="secondary_phone"
                      value={inputs.secondary_phone || ""}
                      onChange={handleChange}
                      placeholder="Input Secondary Phone"
                    />
                  </div>
                  <div className="col-lg">
                    <label>Email
                      <i className="fa fa-star required"></i>

                    </label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={inputs.email || ""}
                      onChange={handleChange}
                      placeholder="Input email"
                      data-required
                    />
                  </div>
                  <div className="col-lg">
                    <label>Opening Date</label>
                    <input
                      type="date"
                      className="form-control"
                      name="opening_date"
                      value={inputs.opening_date || ""}
                      onChange={handleChange}
                      placeholder="Input opening_date"
                    />
                  </div>
                  <div className="col-lg">
                    <label>Previous Due</label>
                    <input
                      type="number"
                      className="form-control"
                      name="previous_due"
                      value={inputs.previous_due || ""}
                      onChange={handleChange}
                      placeholder="Input Previous Due"
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-lg">
                    <label>Present Address</label>
                    <textarea
                      className="form-control"
                      name="present_address"
                      onChange={handleChange}
                    >
                      {inputs.present_address || ""}
                    </textarea>
                  </div>
                  <div className="col-lg">
                    <label>Permanent Address</label>
                    <textarea
                      className="form-control"
                      name="permanent_address"
                      onChange={handleChange}
                    >
                      {inputs.permanent_address || ""}
                    </textarea>
                  </div>
                  <div className="col-lg">
                    <label>
                      Company Type <i className="fa fa-star required"></i>
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      name="company_type"
                      options={companyTypeOptions}
                      value={
                        companyTypeOptions.find((option) => option.value === inputs.company_type) ||
                        null
                      }
                      onChange={(selectedOption) =>
                        setInputs((values) => ({
                          ...values,
                          company_type: selectedOption.value,
                        }))
                      }
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-lg">
                    <label>Bin No</label>
                    <input
                      type="text"
                      className="form-control"
                      name="bin_no"
                      value={inputs.bin_no || ""}
                      onChange={handleChange}
                      placeholder="Input Bin no"
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-lg">
                    <label>NID No</label>
                    <input
                      type="text"
                      className="form-control"
                      name="nid"
                      value={inputs.nid || ""}
                      onChange={handleChange}
                      placeholder="Input NID no"
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-3">
                    <button
                      className="btn btn-primary btn-block mg-b-10"
                      onClick={formSubmit}
                    >
                      <i className="fa fa-send mg-r-10"></i> Create
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create;
