import $ from "jquery"; // Import jQuery
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import useRequiredFields from "../../../../hooks/useRequiredFields";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import ReactDataTable from "../../../Common/ReactDataTable";

const Create = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  const [rmpm, setRmpm] = useState([]);
  const [list, setList] = useState([]);
  const { checkRequiredFields } = useRequiredFields();
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("");
  const [planningOptions, setPlanningOptions] = useState([]);
  const {id}=useParams();

  useEffect(() => {
    setLoading(true);
    $("body").addClass("collapse-menu");

    const fetchData = async () => {
      try {
        const resPlanning = await Http.get("/planning?production=1");
        const planningData = resPlanning.data;
        setPlanningOptions(planningData);
        if (planningData.length > 0) {
          // Automatically select the first planning and set in inputs
          const firstPlanning = planningData[0];
          setInputs((prevInputs) => ({
            ...prevInputs,
            planning_id: firstPlanning.id, // Assuming the ID field for planning
          }));

          // Load items for the first planning
          handlePlanningChange(firstPlanning.id);
        }

        const resRole = await Http.get("/staff-type-check");
        setRole(resRole.data);
        // const resRmpm = await Http.get("/product-list/all");
        // setRmpm(resRmpm.data);

        const resBatchNo = await Http.get(
          "/last-code-increment-by-one/requisition_arts/receipt_no/RD"
        );
        setInputs((values) => ({ ...values, ["receipt_no"]: resBatchNo.data }));

        // getCurrentDateInput();

        const res = await Http.get(`/requisition-art/${id}`); // Fetch the existing data
        const fetchedData = res.data;

        // Pre-fill form fields with fetched data
        setInputs({
          ...fetchedData,
          date: fetchedData.date || "",
          expected_time: fetchedData.expected_time || "",
          planning_id: fetchedData.planning_id || "",
        });

        setList(fetchedData.items || []); // Pre-fill items list
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // const getCurrentDateInput = () => {
  //   const dateObj = new Date();

  //   // get the month in this format of 04, the same for months
  //   const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
  //   const day = ("0" + dateObj.getDate()).slice(-2);
  //   const year = dateObj.getFullYear();

  //   const shortDate = `${year}-${month}-${day}`;

  //   setInputs((values) => ({ ...values, ["date"]: shortDate }));
  //   setInputs((values) => ({
  //     ...values,
  //     ["expected_production_date"]: shortDate,
  //   }));

  //   // Get the current time in the format "HH:mm"
  //   const hours = ("0" + dateObj.getHours()).slice(-2);
  //   const minutes = ("0" + dateObj.getMinutes()).slice(-2);

  //   const defaultTime = `${hours}:${minutes}`;

  //   setInputs((values) => ({ ...values, ["expected_time"]: defaultTime }));
  // };

  const addMoreList = async (id) => {
    try {
      const response = await Http.get(`/product-search-planning-wise/${id}`);
      if (!list.find((item) => item.planning_item_id === response.data.id)) {
        // setList((prev) => [...prev, { ...response.data, receive_qty: 0 }]);
        setList((prev) => [
          ...prev,
          {
            ...response.data,
            receive_qty: null,
            delivery_qty: null,
            required_qty: null,
            in_hand_qty: null,
            in_pipeline_qty: null,
            on_order_qty: null,
            mould_qty_per_set: null,
            required_time: null,
            estimated_unit_price: null,
            estimated_total_price: null,
            avg_monthly_consumption: null,
            decoration: '',
            remarks: '',
            remarks_delivery: '',
            remarks_receive: '',
          },
        ]);
      } else {
        Swal.fire(
          "Warning!",
          "This Product is already in the list!",
          "warning"
        );
      }
    } catch (error) {
      console.log("Error fetching product:", error);
      Swal.fire("Error!", "Failed to add product.", "error");
    }
  };
  // Remove List
  const removeList = (index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedList = list.filter((_, i) => i !== index);
        setList(updatedList);
        Swal.fire("Deleted!", "Your item has been deleted.", "success");
      }
    });
  };

  // Add handleInputChange function to update individual items in the list
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedList = [...list]; // Copy the list array
    updatedList[index][name] = value; // Update the specific item in the list
    setList(updatedList); // Update the state with the modified list
  };
  const handlePlanningChange = async (planningId) => {
    if (!planningId) return;

    setList([]);
    setInputs((prevInputs) => ({
      ...prevInputs,
      planning_id: planningId, // Update planning_id in inputs
    }));

    try {
      // Fetch data based on selected planning ID
      const response = await Http.get(`/planning/${planningId}?production=1`);
      setRmpm(response.data.items);
      /*const productionTransferInfo = response.data.production_transfer_info;

      const planningItems = response.data.items.map((item) => {
        const productId = item.product_id;

        return {
          id: item.id,
          product_id: productId,
          product_name: item.product.name,
          shape: item.shape?.name,
          production_work_order: item.production_work_order?.receipt_no,
          company: item.production_work_order?.company?.name,
          product_code: item.product.code,
          planning_qty: item.qty,
          production_qty: 0,
          return_qty: 0,
          transfer_qty: "",
          total_production_qty:
            productionTransferInfo[productId]?.production_qty || 0, // Map production_qty
          total_transfer_qty:
            productionTransferInfo[productId]?.transfer_qty || 0, // Map transfer_qty
          weight: item.weight, // Example if qty is used as weight
          closing_balance: item.qty, // Example calculation; adjust if necessary
          remarks_delivery: "", // Set to empty or adjust if needed
        };
      });

      setList(planningItems);*/
    } catch (error) {
      console.error("Error fetching products for planning:", error);
      Swal.fire(
        "Error!",
        "Failed to fetch products for the selected planning.",
        "error"
      );
    }
  };

  const formSubmit = () => {
    checkRequiredFields();
    inputs.items = list;
    Http.put(`/requisition-art/${id}`, inputs)
      .then((res) => {
        navigate("/department-requisition-jcg", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch((e) => {
        setError(e.response?.data?.errors || {});
      });
  };

  const displayError = (fieldName) => {
    if (error && error[fieldName]) {
      return <div className="text-danger small">{error[fieldName]}</div>;
    }
    return null;
  };
  const cols = [
    {
      field: (i) => (
        <>
          {i.production_work_order.company.name}{" "}
          <b>#{i.production_work_order.receipt_no}</b> || {i.product.name}
        </>
      ),
      header: "Item",
    },
    { field: "shape.name", header: "Shape" },
  ];

  const actionTemplate = (item, deleteData) => {
    return (
      <td className="text-right">
        <button
          className="btn btn-success btn-sm"
          onClick={() => addMoreList(item.id)}
        >
          <i className="fa fa-plus"></i>
        </button>
      </td>
    );
  };

  return (
    <div>
      <IsLogin></IsLogin>
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Edit Department Requisition</h5>
        </div>
        <div className="am-pagebody row d-flex justify-content-evenly">
          <div className={"card pd-20 pd-sm-40 col-lg-7"}>
            <h6 className="card-body-title mg-sm-b-30 text-center">
              Edit Department Requisition To{" "}
              <strong>
                {role === "jigger" || role === "casting"
                  ? "Mould"
                  : role === "decoration_local" || role === "decoration_export"
                  ? "Decal Store"
                  : role === "packing_local" || role === "packing_export"
                  ? "Packaging Store"
                  : "Sagger"}
              </strong>
            </h6>
            <hr></hr>

            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg">
                    <label>
                      Date <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="date"
                      value={inputs.date || ""}
                      onChange={handleChange}
                      placeholder="Input Date"
                      data-required
                    />

                    {displayError("date")}
                  </div>
                  <div className="col-lg">
                    <label>
                      Expected Time <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      name="expected_time"
                      value={inputs.expected_time || ""}
                      onChange={handleChange}
                      placeholder="Input Time"
                      data-required
                    />
                    {displayError("expected_time")}
                  </div>
                  <div className="col-lg">
                    <label>
                      Req. No. <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="receipt_no"
                      className="form-control"
                      name="receipt_no"
                      value={inputs.receipt_no || ""}
                      onChange={handleChange}
                      placeholder="Input Batch No"
                      data-required
                    />
                    {displayError("receipt_no")}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg">
                    <label>Planning</label>
                    <select
                      className="form-control"
                      name="planning_id"
                      value={inputs.planning_id || ""} // Bind to inputs
                      onChange={(e) => handlePlanningChange(e.target.value)}
                    >
                      <option value="">Select Planning</option>
                      {planningOptions.map((planning) => (
                        <option key={planning.id} value={planning.id}>
                          {planning.receipt_no}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="card-solid">
                    <div className="card-header with-border text-center">
                      <h5 className="card-title mb-0">Items in the list</h5>
                    </div>
                    <div className="card-body mt-3 p-0 pt-3">
                      <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0">
                        <thead>
                          <tr>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              #
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Product
                            </th>

                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Request Qty{" "}
                              <i className="fa fa-star required"></i>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {list.length < 1 ? (
                            <tr>
                              <td colSpan={3}>
                                <p
                                  style={{ color: "red" }}
                                  className="text-center mt-3"
                                >
                                  Please Add Few Products For Requisition to{" "}
                                  <strong>
                                    {role === "jigger" || role === "casting"
                                      ? "Mould"
                                      : role === "decoration_local" ||
                                        role === "decoration_export"
                                      ? "Decal Store"
                                      : role === "packing_local" ||
                                        role === "packing_export"
                                      ? "Packaging Store"
                                      : "Sagger"}
                                  </strong>
                                </p>
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}

                          {Array.from(list).map((x, i) => (
                            <tr key={i}>
                              <td>
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={() => removeList(i)}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </td>

                              <td> {list[i].product.name}</td>

                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="required_qty"
                                  value={x.required_qty || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                  placeholder="Req. Qty"
                                  data-required
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-3">
                    <button
                      className="btn btn-primary btn-block mg-b-10"
                      onClick={formSubmit}
                    >
                      <i className="fa fa-send mg-r-10"></i> Update
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>

          <div
            className="card pd-20 pd-sm-40 col-4"
            style={{ backgroundColor: "#ffffffc7" }}
          >
            <h6 className="card-body-title mg-sm-b-30 text-center">
              Planning Products
            </h6>
            <hr></hr>

            <ReactDataTable
              cols={cols}
              receiveData={rmpm}
              isLoading={loading}
              actionTemplate={actionTemplate}
              // deleteData={deleteData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create;
