import $ from "jquery"; // Import jQuery
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import useRequiredFields from "../../../../../hooks/useRequiredFields";
import Http from "../../../../Common/Http";
import IsLogin from "../../../../Common/IsLogin";
import { LoaderComponent } from "../../../../Common/LoaderComponent";

const Create = () => {
  const { checkRequiredFields } = useRequiredFields();
  const navigate = useNavigate();
  const { id } = useParams();

  const [inputs, setInputs] = useState({
    receipt_no: "",
    production_work_order_id: "",
    date: "",
    receive_date: "",
    copy_type: "",
    purchase_type: "",
    from_department: "",
    to_department: "",
    status: "requested",
    remarks: "",
  });

  const [list, setList] = useState([]);
  const [error, setError] = useState({});
  // const [product, setProduct] = useState([]);
  const [planningOptions, setPlanningOptions] = useState([]);
  const [role, setRole] = useState("");
  const [itemLoading, setItemLoading] = useState(false);
  const [receiveData, setReceiveData] = useState([]);

  // const [departments, setDepartments] = useState([
  //   { id: 1, name: "BODY", user_type: "body" },
  //   { id: 2, name: "JIGGER", user_type: "jigger" },
  //   { id: 3, name: "CASTING", user_type: "casting" },
  //   { id: 4, name: "FINISHING", user_type: "finishing" },
  //   { id: 5, name: "BK", user_type: "bk" },
  //   { id: 6, name: "GLAZING", user_type: "glazing" },
  //   { id: 7, name: "GK", user_type: "gk" },
  //   { id: 8, name: "SELECTION", user_type: "selection" },
  //   { id: 9, name: "DECORATION LOCAL", user_type: "decoration_local" },
  //   { id: 10, name: "DECORATION EXPORT", user_type: "decoration_export" },
  //   { id: 11, name: "DFW", user_type: "dfw" },
  //   { id: 12, name: "PACKING LOCAL", user_type: "packing_local" },
  //   { id: 13, name: "PACKING EXPORT", user_type: "packing_export" },
  //   { id: 14, name: "DSTORE", user_type: "dstore" },
  //   { id: 15, name: "GSTORE", user_type: "gstore" },
  //   { id: 16, name: "MOULD", user_type: "mould" },
  //   { id: 17, name: "PSTORE", user_type: "pstore" },
  //   { id: 18, name: "PLANNING", user_type: "planning" },
  //   { id: 19, name: "SAGGER", user_type: "sagger" },
  // ]);

  // const defectTypes = ["BN", "IS", "PH", "GD", "UG"];
  // const repairTypes = ["PH", "SS", "AIR", "PC"];

  // Fetch the requisition data by ID and populate the form inputs
  // const fetchData = () => {
  //   Http.get("/product-list/all").then((res) => {
  //     setProduct(res.data);
  //   });

  //   Http.get("/staff-type-check").then((res) => {
  //     setRole(res.data);
  //   });

  //   Http.get("/planning")
  //     .then((res) => {
  //       setPlanningOptions(res.data); // Set planning options
  //     })
  //     .catch((e) => {
  //       console.error("Error fetching planning options:", e);
  //     });
  // };

  const fetchData = async () => {
    try {
      // const resProduct = await Http.get("/product-list/all");
      // setProduct(resProduct.data);

      const resRole = await Http.get("/staff-type-check");
      setRole(resRole.data);

      const redData = await Http.get("/production-transfer/" + id + "/edit");
      setReceiveData(redData.data);
      setInputs((prevInputs) => ({
        ...prevInputs,
        planning_id: redData.data.data.planning_id, // Assuming the ID field for planning
        shift: redData.data.data.shift,
      }));
      handlePlanningChange(redData.data.data.planning_id);

      const resPlanning = await Http.get("/planning");
      const planningData = resPlanning.data;
      setPlanningOptions(planningData);

      // if (planningData.length > 0) {
      //   // Automatically select the first planning and set in inputs
      //   const firstPlanning = planningData[0];
      //   setInputs((prevInputs) => ({
      //     ...prevInputs,
      //     planning_id: firstPlanning.id, // Assuming the ID field for planning
      //   }));

      //   // Load items for the first planning
      //   handlePlanningChange(firstPlanning.id);
      // }

      // Fetch the receipt number
      const resReceiptNo = await Http.get(
        "/last-code-increment-by-one/production_finished_goods/receipt_no/P"
      );
      const receiptNo = resReceiptNo.data; // Assuming the API returns the receipt number directly

      // Set the fetched receipt number to inputs
      setInputs((prevInputs) => ({
        ...prevInputs,
        receipt_no: receiptNo,
      }));
    } catch (e) {
      console.error("Error fetching data:", e);
    }
  };

  useEffect(() => {
    $("body").addClass("collapse-menu");
    // $("body").toggleClass("show-left");
    fetchData();

    // // Set `to_department` based on `role`
    // if (role === "jigger") {
    //   setInputs((prevInputs) => ({
    //     ...prevInputs,
    //     to_department:
    //       departments.find((dept) => dept.name === "FINISHING")?.id || "",
    //   }));
    // } else if (role === "casting") {
    //   setInputs((prevInputs) => ({
    //     ...prevInputs,
    //     to_department: departments.find((dept) => dept.name === "BK")?.id || "",
    //   }));
    // }
  }, []);

  const handleAddType = (index, type) => {
    const updatedList = [...list];
    const newEntry = { type: "", qty: 0 };

    if (type === "defect") {
      updatedList[index].defects = [
        ...(updatedList[index].defects || []),
        newEntry,
      ];
    } else if (type === "repair") {
      updatedList[index].repairs = [
        ...(updatedList[index].repairs || []),
        newEntry,
      ];
    }

    setList(updatedList);
  };

  const handleTypeChange = (index, subIndex, value, type) => {
    const updatedList = [...list];
    if (type === "defect") {
      updatedList[index].defects[subIndex].type = value;
    } else if (type === "repair") {
      updatedList[index].repairs[subIndex].type = value;
    }
    setList(updatedList);
  };

  const handleQtyChange = (index, subIndex, value, type) => {
    const updatedList = [...list];
    const quantity = parseInt(value) || 0;
    if (type === "defect") {
      updatedList[index].defects[subIndex].qty = quantity;
    } else if (type === "repair") {
      updatedList[index].repairs[subIndex].qty = quantity;
    }
    setList(updatedList);
  };

  const handleRemoveType = (index, subIndex, type) => {
    const updatedList = [...list];
    if (type === "defect") {
      updatedList[index].defects.splice(subIndex, 1);
    } else if (type === "repair") {
      updatedList[index].repairs.splice(subIndex, 1);
    }
    setList(updatedList);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedList = [...list];
    let inputValue = parseFloat(value) || 0;

    // Define limits for each field
    const limits = {
      delivery_qty_export: updatedList[index].ob_exp || 0,
      delivery_qty_a_class: updatedList[index].ob_a || 0,
      delivery_qty_b_class: updatedList[index].ob_b || 0,
      delivery_qty_c_class: updatedList[index].ob_c || 0,
    };

    // Check if the value exceeds the limit
    if (inputValue > limits[name]) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: `${name
          .replace("_class_qty", "")
          .toUpperCase()} quantity cannot exceed the opening balance.`,
      });
      updatedList[index][name] = limits[name]; // Reset to maximum allowable value
    } else {
      updatedList[index][name] = inputValue; // Update the value
      // // Update closing balances dynamically
      // updatedList[index].cb_exp =
      //   updatedList[index].ob_exp - (updatedList[index].delivery_qty_export || 0);
      // updatedList[index].cb_a =
      //   updatedList[index].ob_a - (updatedList[index].delivery_qty_a_class || 0);
      // updatedList[index].cb_b =
      //   updatedList[index].ob_b - (updatedList[index].delivery_qty_b_class || 0);
      // updatedList[index].cb_c =
      //   updatedList[index].ob_c - (updatedList[index].delivery_qty_c_class || 0);
    }

    setList(updatedList);
  };

  const formSubmit = () => {
    checkRequiredFields();
    const formData = {
      ...inputs,
      items: list,
    };

    Http.put(`/production-transfer/${id}`, formData)
      .then((res) => {
        navigate("/transfer-art", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  const handlePlanningChange = async (planningId) => {
    if (!planningId) return;

    setInputs((prevInputs) => ({
      ...prevInputs,
      planning_id: planningId, // Update planning_id in inputs
    }));

    try {
      // Fetch data based on selected planning ID
      const response = await Http.get(`/planning/${planningId}`);

      // Update list with items from the response
      const planningItems = response.data.items.map((item) => ({
        id: item.id,
        product_id: item.product_id,
        product_name: item.product.name,
        shape: item.shape?.name,
        planning_qty: item.qty,
        production_work_order: item.production_work_order?.receipt_no,
        company: item.production_work_order?.company?.name,
        product_code: item.product.code,
        ob_exp: item.openning_qty_class.export_class, // Adjust based on requirement or add additional fields as needed
        ob_a: item.openning_qty_class.a_class, // Adjust based on requirement or add additional fields as needed
        ob_b: item.openning_qty_class.b_class, // Adjust based on requirement or add additional fields as needed
        ob_c: item.openning_qty_class.c_class, // Adjust based on requirement or add additional fields as needed
        weight: item.weight, // Example if qty is used as weight
        // production_qty: item.qty,
        transfer_qty: 0,
        closing_balance: item.qty, // Example calculation; adjust if necessary
        remarks_delivery: "", // Set to empty or adjust if needed
      }));

      setList(planningItems);

      // Fetch slice_weight for each item from /production-transfer/{id}
      const productionTransferResponse = await Http.get(
        `/production-transfer/${id}/edit`
      );
      const productionTransferItems =
        productionTransferResponse.data.data.items;

      setList((prevList) =>
        prevList.map((item) => {
          const transferItem = productionTransferItems.find(
            (tItem) => tItem.planning_item_id === item.id
          );
          return {
            ...item,
            slice_weight: transferItem
              ? transferItem.slice_weight
              : item.slice_weight,
            green_weight: transferItem
              ? transferItem.green_weight
              : item.green_weight,
            fg_weight: transferItem ? transferItem.fg_weight : item.fg_weight,
            production_qty: transferItem
              ? transferItem.qty
              : item.production_qty,
            delivery_qty_export: transferItem
              ? transferItem.delivery_qty_export
              : item.delivery_qty_export,
            delivery_qty_a_class: transferItem
              ? transferItem.delivery_qty_a_class
              : item.delivery_qty_a_class,
            delivery_qty_b_class: transferItem
              ? transferItem.delivery_qty_b_class
              : item.delivery_qty_b_class,
            delivery_qty_c_class: transferItem
              ? transferItem.delivery_qty_c_class
              : item.delivery_qty_c_class,
            // transfer_qty: transferItem ? transferItem.delivery_qty: item.delivery_qty,
            // transfer_qty: transferItem ? transferItem.delivery_qty: item.delivery_qty,
            // transfer_qty: transferItem ? transferItem.delivery_qty: item.delivery_qty,
            // transfer_qty: transferItem ? transferItem.delivery_qty: item.delivery_qty,
            // transfer_qty: transferItem ? transferItem.delivery_qty: item.delivery_qty,
          };
        })
      );
    } catch (error) {
      console.error("Error fetching products for planning:", error);
      Swal.fire(
        "Error!",
        "Failed to fetch products for the selected planning.",
        "error"
      );
    }
  };

  const cols = [{ field: "name", header: "Item" }];

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Deliver</h5>
        </div>
        <div className="am-pagebody  row d-flex justify-content-evenly">
          <div className="card pd-20 pd-sm-40 col-12">
            <h6 className="card-body-title mg-sm-b-30">Delivery</h6>
            {/* <p>Transfer To Department {inputs.to_department}</p> */}
            <div className="row">
              <div className="col-lg">
                <label>Receipt No</label>
                <input
                  type="text"
                  className="form-control"
                  name="receipt_no"
                  value={inputs.receipt_no}
                  disabled
                />
              </div>

              {/* <div className="col-lg">
                <label>Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  value={inputs.date}
                  disabled
                />
              </div> */}
              <div className="col-lg">
                <label>Planning</label>
                <select
                  className="form-control"
                  value={inputs.planning_id || ""} // Bind to inputs
                  onChange={(e) => handlePlanningChange(e.target.value)}
                >
                  <option value="">Select Planning</option>
                  {planningOptions.map((planning) => (
                    <option key={planning.id} value={planning.id}>
                      {planning.receipt_no}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg">
                <label>
                  Shift <i className="fa fa-star required"></i>
                </label>
                <select
                  className="form-control"
                  name="shift"
                  value={inputs.shift}
                  onChange={handleChange}
                  data-required
                >
                  <option value="">Select Shift</option>
                  <option value="a">A</option>
                  <option value="b">B</option>
                  <option value="c">C</option>
                  <option value="general">General</option>
                </select>
              </div>

              {/* <div className="row">
                <label>Transfer To Department {inputs.to_department}</label>
                
              </div> */}

              <div className="col-lg">
                <label>Remarks</label>
                <textarea
                  className="form-control"
                  name="remarks"
                  value={inputs.remarks}
                />
              </div>
            </div>

            {/* Item List */}
            <div className="row mt-3">
              <div className="card-solid">
                <div className="card-header with-border text-center">
                  <h5 className="card-title mb-0">Items in the Production</h5>
                </div>
              </div>
              <div className="col-lg">
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      {/* <th>Planning Qty</th> */}
                      {role === "dfw" && <th>FG Weight</th>}
                      {/* {role === "dfw" && <th>Decoration</th>} */}
                      {/* <th>Op. Balance Exp.</th>
                      <th>Op. Balance A</th>
                      <th>Op. Balance B</th>
                      <th>Op. Balance C</th> */}
                      {/* <th>Selected Exp.</th>
                      <th>Selected A</th>
                      <th>Selected B</th>
                      <th>Selected C</th> */}

                      <th>Class Export</th>
                      <th>Class A</th>
                      <th>Class B</th>
                      <th>Class C</th>
                      {/* <th>Cl. Balance Exp.</th>
                      <th>Cl. Balance A</th>
                      <th>Cl. Balance B</th>
                      <th>Cl. Balance C</th> */}
                    </tr>
                  </thead>
                  {/* <thead>
                    <tr>
                      <th rowSpan="2">Product Name</th>
                      <th rowSpan="2">Planning Qty</th>
                      <th colSpan="4" className="text-center">
                        Opening Balance
                      </th>
                      <th colSpan="4" className="text-center">
                        Delivery Qty
                      </th>
                      <th colSpan="4" className="text-center">
                        Closing Balance
                      </th>
                    </tr>
                    <tr>
                      <th className="text-center">Export</th>
                      <th className="text-center">A</th>
                      <th className="text-center">B</th>
                      <th className="text-center">C</th>

                      <th className="text-center">Export</th>
                      <th className="text-center">A</th>
                      <th className="text-center">B</th>
                      <th className="text-center">C</th>

                      <th className="text-center">Export</th>
                      <th className="text-center">A</th>
                      <th className="text-center">B</th>
                      <th className="text-center">C</th>
                    </tr>
                  </thead> */}

                  <tbody>
                    {list.length < 1 ? (
                      <tr>
                        <td colSpan={13} className="text-center">
                          Please add products
                        </td>
                      </tr>
                    ) : (
                      list.map((item, index) => (
                        <tr key={index}>
                          <td style={{ width: "15%" }}>
                            {item.product_name} ({item.shape}) || {item.company}{" "}
                            #{item.production_work_order}
                            <br />
                            <b>Planning Qty:</b> {item.planning_qty || 0}
                          </td>
                          {/* <td></td> */}
                          {role === "dfw" && (
                            <td style={{ width: "7%" }}>
                              <input
                                type="number"
                                className="form-control"
                                name="fg_weight"
                                value={item.fg_weight || ""}
                                onChange={(e) => handleInputChange(e, index)}
                              />
                            </td>
                          )}
                          {/* {role === "dfw" && <td>{item.decoration || "DUMMY"}</td>} */}
                          {/* <td>{item.ob_exp || 0}</td>
                          <td>{item.ob_a || 0}</td>
                          <td>{item.ob_b || 0}</td>
                          <td>{item.ob_c || 0}</td> */}
                          <td>
                            <div className="d-flex justify-content-between align-items-center mb-2">
                              <strong>Opening: {item.ob_exp || 0}</strong>
                              <input
                                type="number"
                                className="form-control ms-2"
                                name="delivery_qty_export"
                                style={{ width: "50%" }}
                                value={item.delivery_qty_export}
                                onChange={(e) => handleInputChange(e, index)}
                                placeholder="Transfer Exp"
                              />
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <strong>
                                Closing:{" "}
                                {item.ob_exp - item.delivery_qty_export ||
                                  item.ob_exp}
                              </strong>
                              <input
                                type="number"
                                className="form-control ms-2"
                                name="loss_qty_export"
                                style={{ width: "50%" }}
                                value={item.loss_qty_export}
                                onChange={(e) => handleInputChange(e, index)}
                                placeholder="Loss Exp"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="d-flex justify-content-between align-items-center mb-2">
                              <strong>Opening: {item.ob_a || 0}</strong>
                              <input
                                type="number"
                                className="form-control ms-2"
                                name="delivery_qty_a_class"
                                style={{ width: "50%" }}
                                value={item.delivery_qty_a_class}
                                onChange={(e) => handleInputChange(e, index)}
                                placeholder="Transfer A"
                              />
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <strong>
                                Closing:{" "}
                                {item.ob_a - item.delivery_qty_a_class ||
                                  item.ob_a}
                              </strong>
                              <input
                                type="number"
                                className="form-control ms-2"
                                name="loss_qty_a_class"
                                style={{ width: "50%" }}
                                value={item.loss_qty_a_class}
                                onChange={(e) => handleInputChange(e, index)}
                                placeholder="Loss A"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="d-flex justify-content-between align-items-center mb-2">
                              <strong>Opening: {item.ob_b || 0}</strong>
                              <input
                                type="number"
                                className="form-control ms-2"
                                name="delivery_qty_b_class"
                                style={{ width: "50%" }}
                                value={item.delivery_qty_b_class}
                                onChange={(e) => handleInputChange(e, index)}
                                placeholder="Transfer B"
                              />
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <strong>
                                Closing:{" "}
                                {item.ob_b - item.delivery_qty_b_class ||
                                  item.ob_b}
                              </strong>
                              <input
                                type="number"
                                className="form-control ms-2"
                                name="loss_qty_b_class"
                                style={{ width: "50%" }}
                                value={item.loss_qty_b_class}
                                onChange={(e) => handleInputChange(e, index)}
                                placeholder="Loss B"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="d-flex justify-content-between align-items-center mb-2">
                              <strong>Opening: {item.ob_c || 0}</strong>
                              <input
                                type="number"
                                className="form-control ms-2"
                                name="delivery_qty_c_class"
                                style={{ width: "50%" }}
                                value={item.delivery_qty_c_class}
                                onChange={(e) => handleInputChange(e, index)}
                                placeholder="Transfer C"
                              />
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <strong>
                                Closing:{" "}
                                {item.ob_c - item.delivery_qty_c_class ||
                                  item.ob_c}
                              </strong>
                              <input
                                type="number"
                                className="form-control ms-2"
                                name="loss_qty_c_class"
                                style={{ width: "50%" }}
                                value={item.loss_qty_c_class}
                                onChange={(e) => handleInputChange(e, index)}
                                placeholder="Loss C"
                              />
                            </div>
                          </td>
                          {/* <td>
                            <input
                              type="number"
                              className="form-control"
                              name="delivery_qty_export"
                              value={item.delivery_qty_export}
                              onChange={(e) => handleInputChange(e, index)}
                              placeholder="Transfer Export"
                            />
                            <input
                              type="number"
                              className="form-control"
                              name="loss_qty_export"
                              value={item.loss_qty_export}
                              onChange={(e) => handleInputChange(e, index)}
                              placeholder="Loss Export"
                            />
                          </td> */}
                          {/* <td>
                            <input
                              type="number"
                              className="form-control"
                              name="delivery_qty_a_class"
                              value={item.delivery_qty_a_class}
                              onChange={(e) => handleInputChange(e, index)}
                              placeholder="Transfer A"
                            />
                            <input
                              type="number"
                              className="form-control"
                              name="loss_qty_a_class"
                              value={item.loss_qty_a_class}
                              onChange={(e) => handleInputChange(e, index)}
                              placeholder="Loss A"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              name="delivery_qty_b_class"
                              value={item.delivery_qty_b_class}
                              onChange={(e) => handleInputChange(e, index)}
                              placeholder="Transfer B"
                            />
                            <input
                              type="number"
                              className="form-control"
                              name="loss_qty_b_class"
                              value={item.loss_qty_b_class}
                              onChange={(e) => handleInputChange(e, index)}
                              placeholder="Loss B"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              name="delivery_qty_c_class"
                              value={item.delivery_qty_c_class}
                              onChange={(e) => handleInputChange(e, index)}
                              placeholder="Transfer C"
                            />
                            <input
                              type="number"
                              className="form-control"
                              name="loss_qty_c_class"
                              value={item.loss_qty_c_class}
                              onChange={(e) => handleInputChange(e, index)}
                              placeholder="Loss C"
                            />
                          </td>
                          <td>
                            {item.ob_exp - item.delivery_qty_export ||
                              item.ob_exp}
                          </td>
                          <td>
                            {item.ob_a - item.delivery_qty_a_class || item.ob_a}
                          </td>
                          <td>
                            {item.ob_b - item.delivery_qty_b_class || item.ob_b}
                          </td>
                          <td>
                            {item.ob_c - item.delivery_qty_c_class || item.ob_c}
                          </td> */}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            {list.length > 0 && (
              <div className="row mt-3">
                <div className="col-3">
                  <button className="btn btn-primary" onClick={formSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create;
