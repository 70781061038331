import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Alert from "../../../Common/Alert";
import DatatableFunc from "../../../Common/DatatableFunc";
import Http from "../../../Common/Http";
// import { LoaderComponent } from "../../../Common/LoaderComponent";
import IsLogin from "../../../Common/IsLogin";
import ReactDataTable from "../../../Common/ReactDataTable";

const List = () => {
  const location = useLocation();
  const [receiveData, setReceiveData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState("");

  // const fetchData = () => {
  //   Http.get("/production").then((res) => {
  //     setReceiveData(res.data);
  //   });
  // };
  const fetchData = async () => {
    setLoading(true); // Start loading

    try {
      const res = await Http.get(`/production`);
      setReceiveData(res.data);
      const res1 = await Http.get(`/staff-type-check`);
      setRole(res1.data);
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false); // Stop loading regardless of the outcome
    }
  };

  useEffect(() => {
    DatatableFunc();
    fetchData();
  }, []);

  const showAlert = (message, type) => {
    setAlert({
      message: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 4000);
  };

  useEffect(() => {
    if (!location.state) {
      return;
    }
    showAlert(location.state.message, location.state.type);
  }, [location]);

  const cols = [
    { field: "product.name", header: "Product" },
    { field: "date", header: "Production Date" },
    { field: "time", header: "Time" },
    // { field: "receipt_no", header: "Reference No" },
    { field: "qty", header: "Produced Qty" },
    /*{
      field: (item) =>
        <Link
          to={{ pathname: "/issue_view/" + item.issue_id }}
          className="btn btn-info btn-sm"
        >
          <i className="fa fa-list" aria-hidden="true"></i> &nbsp;
          {item.issue.reference_no}
        </Link>,
        header: "Issue No",
    },*/

    // { field: "weight", header: "Weight" },
    // { field: "qty", header: "Quantity" },
    // { field: "transfer_qty", header: "Transfer Quantity" },
    // {role != "gstore" && (
    // <Link
    //   to={`/create-department-transfer/${item.id}`}
    //   className="btn btn-secondary btn-sm ms-1"
    // >
    //   <i className="fa fa-plus" aria-hidden="true"></i>
    // </Link>
    // )}
    // { field: "product.unit.name", header: "Unit" },
  ];

  const cols2 = [
    { field: "receipt_no", header: "Receipt No" },
    { field: "production_date", header: "Production Date" },
    { field: (rowData) => rowData.shift.toUpperCase(), header: "Shift" },
    // { field: "receipt_no", header: "Reference No" },
    // { field: "qty", header: "Produced Qty" },
    /*{
      field: (item) =>
        <Link
          to={{ pathname: "/issue_view/" + item.issue_id }}
          className="btn btn-info btn-sm"
        >
          <i className="fa fa-list" aria-hidden="true"></i> &nbsp;
          {item.issue.reference_no}
        </Link>,
        header: "Issue No",
    },*/

    // { field: "weight", header: "Weight" },
    // { field: "qty", header: "Quantity" },
    // { field: "transfer_qty", header: "Transfer Quantity" },
    // {role != "gstore" && (
    // <Link
    //   to={`/create-department-transfer/${item.id}`}
    //   className="btn btn-secondary btn-sm ms-1"
    // >
    //   <i className="fa fa-plus" aria-hidden="true"></i>
    // </Link>
    // )}
    // { field: "product.unit.name", header: "Unit" },
  ];

  const selectedCols =
    role === "body" || role === "mould" || role === "sagger" ? cols : cols2;

  const actionTemplate = (item, deleteData) => {
    // const role = "admin"; // Ensure 'role' is defined or passed in correctly

    return (
      <>
        {role === "body" && (
          <td className="text-xs">
            {/* {role != "gstore" && item.qty > item.transfer_qty && (
            <Link
              to={`/create-department-transfer/${item.id}`}
              className="btn btn-secondary btn-sm"
            >
              <i className="fa fa-plus" aria-hidden="true"></i>
            </Link>
          )} */}
            {/* <Link
              to={`/edit-ball-charge-generate/${item.id}`}
              className="btn btn-primary btn-sm  ms-1"
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </Link> */}
            {/* {role != "gstore" && item.production_qty > 0 && ( */}
            {role != "gstore" && (
              <Link
                to={`/create-department-transfer/${item.id}`}
                className="btn btn-secondary btn-sm ms-1"
              >
                <i className="fa fa-plus" aria-hidden="true"></i>
              </Link>
            )}
            {/* <Link
              to={`/issue-view/${item.issue_id}`}
              className="btn btn-secondary btn-sm  ms-1"
            >
              <i className="fa fa-list" aria-hidden="true"></i>
            </Link> */}
          </td>
        )}
        {(role === "jigger" || role === "casting" || role === "finishing") && (
          <td className="text-xs">
            {/* <Link
              to={`/production-view/${item.id}`}
              className="btn btn-primary btn-sm"
            >
              <i className="fa fa-list" aria-hidden="true"></i>
            </Link> */}
            {/* <Link
              to={`/edit-production-jigcast/req-art/${item.id}`}
              className="btn btn-secondary btn-sm ms-2"
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </Link> */}
          </td>
        )}
        {role === "selection" && (
          <td className="text-xs">
            {/* <Link
              to={`/production-view/${item.id}`}
              className="btn btn-primary btn-sm"
            >
              <i className="fa fa-list" aria-hidden="true"></i>
            </Link> */}
            <Link
              to={`/edit-production-jigcast-selection/req-art/${item.id}`}
              className="btn btn-secondary btn-sm ms-2"
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </Link>
          </td>
        )}
        {(role === "decoration_local" ||
          role === "decoration_export" ||
          role === "packaging_local" ||
          role === "packaging_export") && (
          <td className="text-xs">
            {/* <Link
              to={`/production-view/${item.id}`}
              className="btn btn-primary btn-sm"
            >
              <i className="fa fa-list" aria-hidden="true"></i>
            </Link> */}
            <Link
              to={`/edit-production-jigcast-decoration/req-art/${item.id}`}
              className="btn btn-secondary btn-sm ms-2"
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </Link>
          </td>
        )}
      </>
    );
  };

  return (
    <div>
      <IsLogin></IsLogin>
      {/* <LoaderComponent /> */}
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">All Production</h5>
            {(role === "body" ||
              role === "mould" ||
              role === "sagger" ||
              role === "dstore") && (
              <Link
                to={
                  role === "dstore"
                    ? `/production-generate-requisition-department-wise/ekhane__item.id_silo`
                    : `/production-generate-body`
                }
                className="btn btn-primary"
              >
                <i className="fa fa-plus-square mg-r-6"></i>{" "}
                {role === "dstore" ? `Produce & Transfer` : `Production`}
              </Link>
            )}
          </div>

          <div className="am-pagebody">
            <Alert alert={alert} />

            <div className="card pd-20 pd-sm-40">
              {/* <div className="row">
                <div className="table-responsive p-0 pb-2">
                  <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0 datatable">
                    <thead>
                      <tr>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          S/N
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Production Date
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Receive Date
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Reference No
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Batch No
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Product
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Batch Size
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Quantity
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Unit
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Receive
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {receiveData.map((item, index) => (
                        <tr key={item.id}>
                          <td className="text-xs">{index + 1}</td>
                          <td className="text-xs">{item.date}</td>
                          <td className="text-xs">{item.receive_date}</td>
                          <td className="text-xs">{item.receipt_no}</td>
                          <td className="text-xs">{item.batch_no}</td>
                          <td className="text-xs">
                            {item.product ? item.product.name : ""}
                          </td>
                          <td className="text-xs">{item.batch_size}</td>
                          <td className="text-xs">{item.qty}</td>
                          <td className="text-xs">
                            {item.product
                              ? item.product.unit
                                ? item.product.unit.name
                                : ""
                              : ""}
                          </td>
                          <td className="text-xs">
                            {item.receive_status != 1 ? (
                              <Link
                                to={{
                                  pathname: "/production-receive/" + item.id,
                                }}
                                className="btn btn-info btn-sm"
                              >
                                Receive
                              </Link>
                            ) : (
                              <p className="btn btn-success btn-sm">
                                <i className="fa fa-check"></i>
                              </p>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div> */}
              <ReactDataTable
                cols={selectedCols}
                receiveData={receiveData}
                isLoading={loading}
                actionTemplate={actionTemplate}
                // deleteData={deleteData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
