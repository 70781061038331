import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Http from "../Common/Http";

import SupplyChain from "./Sidebar/SupplyChain";

// import React, { useEffect, useState } from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import Http from "../Common/Http";

// const Header = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const [user, setUser] = useState({});
//   const [notification, setNotification] = useState({});

//   const fetchData = () => {
//     Http.get("/me").then((res) => {
//       setUser(res.data.data);
//     });
//   };

const Sidebar = () => {
  const [active, setActive] = useState(window.location.pathname);
  const [receiveData, setReceiveData] = useState([]);
  const location = useLocation();
  const [user, setUser] = useState({});
  const [badge, setBadge] = useState([]);

  // console.log("active", active);

  // const fetchData = () => {
  //   Http.get("/me").then((res) => {
  //     setUser(res.data.data);
  //   });
  // };

  const fetchData = () => {
    Http.get("/staff-type-check").then((res) => {
      setReceiveData(res.data);
    });
    Http.get("/me").then((res) => {
      setUser(res.data.data);
    });
    // Http.get("/module-counts").then((res) => {
    //       setBadge(res.data);
    //     });
  };

  // Fetch /module-counts periodically
  useEffect(() => {
    const fetchModuleCounts = () => {
      Http.get("/module-counts")
        .then((res) => {
          setBadge(res.data);
        })
        .catch((error) => {
          console.error("Error fetching module counts:", error);
        });
    };

    fetchModuleCounts(); // Initial fetch

    const intervalId = setInterval(fetchModuleCounts, 20000); // Fetch every 10 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const handleSidebarClick = (path) => {
    setActive(path);
  };

  useEffect(() => {
    $(".show-sub + .nav-sub").slideDown();

    $(".with-sub").on("click", function (e) {
      e.preventDefault();
      // Collapse all other submenus before toggling the current one
      $(".nav-sub").not($(this).next()).slideUp();
      $(".with-sub").not($(this)).removeClass("show-sub");

      // Now toggle the clicked submenu
      $(this).next().slideToggle();
      $(this).toggleClass("show-sub");
    });
  }, [receiveData]);

  if (location.pathname === "/" || location.pathname === "/login") return null;

  return (
    <div>
      <div className="am-sideleft">
        <ul className="nav am-sideleft-tab">
          <li className="nav-item w-100">
            <a href="#mainMenu" className="nav-link tx-18">
              {" "}
              <i className="fa fa-user"></i> &nbsp; {user.name}{" "}
            </a>
            {/* <a href="#mainMenu" className="nav-link">
              <i className="icon ion-ios-menu-outline tx-24" />
              <i className="fa fa-bars text-12"></i>
              <p className="nav-link" > {user.name} </p>
            </a> */}
          </li>
          {/* <li className="nav-item">
            <a href="#emailMenu" className="nav-link">
              <i className="icon ion-ios-email-outline tx-24" />
            </a>
          </li>
          <li className="nav-item">
            <a href="#chatMenu" className="nav-link">
              <i className="icon ion-ios-chatboxes-outline tx-24" />
            </a>
          </li>
          <li className="nav-item">
            <a href="#settingMenu" className="nav-link">
              <i className="icon ion-ios-gear-outline tx-24" />
            </a>
          </li> */}
        </ul>
        <div className="tab-content">
          <div id="mainMenu" className="tab-pane active">
            {/* {sidebarComponent} */}
            <ul className="nav am-sideleft-menu">
              <li className="nav-item">
                <Link
                  to="/dashboard"
                  className={`nav-link ${
                    active === "/dashboard" ? "active" : ""
                  }`}
                  onClick={() => handleSidebarClick("/dashboard")}
                >
                  <i className="fa fa-tachometer" aria-hidden="true"></i>
                  <span>Dashboard</span>
                </Link>
              </li>

              {/* Basic Setup */}
              {/* {(receiveData === "admin" || receiveData === "hr") && (
                <BasicSetup onMenuClick={handleSidebarClick} active={active} />
              )} */}
              {/* WorkPlace */}
              {/* {(receiveData === "admin" || receiveData === "hr") && (
                <Workplace onMenuClick={handleSidebarClick} active={active} />
              )} */}
              {/* Staff Management */}
              {/* {(receiveData === "admin" ||
                receiveData === "hr" ||
                receiveData === "staff") && (
                <StaffManagement
                  onMenuClick={handleSidebarClick}
                  active={active}
                />
              )} */}
              {/* Artisan Setup */}
              {/* {(receiveData === "admin" ||
                receiveData === "hr" ||
                receiveData === "supply_chain" ||
                receiveData === "gstore" ||
                // receiveData === "body" ||
                receiveData === "jigger" ||
                receiveData === "casting" ||
                receiveData === "finishing" ||
                receiveData === "bk" ||
                receiveData === "glazing" ||
                receiveData === "gk" ||
                receiveData === "selection" ||
                receiveData === "decoration_local" ||
                receiveData === "decoration_export" ||
                receiveData === "dfw" ||
                receiveData === "packing_local" ||
                receiveData === "packing_export" ||
                receiveData === "dstore" ||
                receiveData === "mould" ||
                receiveData === "pstore" ||
                receiveData === "planning" ||
                receiveData === "depot" ||
                receiveData === "sagger") && (
                <ArtisanSetup
                  onMenuClick={handleSidebarClick}
                  active={active}
                  badge={badge}
                />
              )} */}

              {/* Payroll */}
              {/* {(receiveData === "admin" || receiveData === "hr") && (
                <Payroll onMenuClick={handleSidebarClick} active={active} />
              )} */}
              {/* Accounts */}
              {/* {(receiveData === "admin" || receiveData === "accounts") && (
                <Accounts onMenuClick={handleSidebarClick} active={active} />
              )} */}
              {/* Supply Chain */}
              {(receiveData === "admin" || receiveData === "supply_chain") && (
                <SupplyChain
                  role={receiveData}
                  onMenuClick={handleSidebarClick}
                  active={active}
                />
              )}
              {/* Production */}
              {/* {(receiveData === "admin" ||
                receiveData === "supply_chain" ||
                receiveData === "body" ||
                receiveData === "production" ||
                receiveData === "gstore" ||
                receiveData === "dstore" ||
                receiveData === "pstore" ||
                receiveData === "jigger" ||
                receiveData === "casting" ||
                receiveData === "finishing" ||
                receiveData === "bk" ||
                receiveData === "glazing" ||
                receiveData === "gk" ||
                receiveData === "selection" ||
                receiveData === "decoration_local" ||
                receiveData === "decoration_export" ||
                receiveData === "dfw" ||
                receiveData === "packing_local" ||
                receiveData === "packing_export" ||
                receiveData === "mould" ||
                receiveData === "sagger" ||
                // receiveData === "lab" ||
                receiveData === "depot") && (
                <Production
                  role={receiveData}
                  onMenuClick={handleSidebarClick}
                  active={active}
                  badge={badge}
                />
              )} */}
              {/* {(receiveData === "admin" ||
                receiveData === "glazing" ||
                receiveData === "gk" ||
                receiveData === "selection" ||
                receiveData === "decoration_local" ||
                receiveData === "decoration_export" ||
                receiveData === "dfw" ||
                receiveData === "packing_local" ||
                receiveData === "packing_export") && (
                <Repair
                  role={receiveData}
                  onMenuClick={handleSidebarClick}
                  active={active}
                />
              )} */}
              {/* {(receiveData === "admin" ||
                receiveData === "glazing" ||
                receiveData === "gk" ||
                receiveData === "selection" ||
                receiveData === "decoration_local" ||
                receiveData === "decoration_export" ||
                receiveData === "dfw" ||
                receiveData === "packing_local" ||
                receiveData === "packing_export") && (
                <Defect
                  role={receiveData}
                  onMenuClick={handleSidebarClick}
                  active={active}
                />
              )} */}
              {/* Assets */}
              {/* {(receiveData === "admin" || receiveData === "supply_chain") && (
                <Assets onMenuClick={handleSidebarClick} active={active} />
              )} */}
              {/* Sales */}
              {/* {(receiveData === "admin" ||
                receiveData === "depot" ||
                receiveData === "supply_chain") && (
                <Sales
                  role={receiveData}
                  onMenuClick={handleSidebarClick}
                  active={active}
                />
              )} */}
              {/* Secondary Sales */}
              {/* {receiveData === "admin" && (
                <SecondarySales
                  onMenuClick={handleSidebarClick}
                  active={active}
                />
              )} */}
              {/* Reports */}
              {(receiveData === "admin" ||
                receiveData === "staff" ||
                receiveData === "planning" ||
                receiveData === "supply_chain") && (
                <>
                  <li className="nav-item">
                    <Link
                      to="/production-work-order"
                      className={`nav-link ${
                        active === "/production-work-order" ? "active" : ""
                      }`}
                      onClick={() => setActive("/production-work-order")}
                    >
                      <i className="fa fa-briefcase"></i>
                      <span>Work Order</span>
                      {/* <span className="badge bg-danger ms-2">{badge.workOrders}</span> */}
                    </Link>
                  </li>
                </>
              )}

              {(receiveData === "admin" ||
                receiveData === "supply_chain" ||
                receiveData === "planning" ||
                receiveData === "casting" ||
                receiveData === "jigger" ||
                receiveData === "packing_local" ||
                receiveData === "packing_export" ||
                receiveData === "decoration_local" ||
                receiveData === "decoration_export") && (
                <>
                  <li className="nav-item">
                    <Link
                      to="/planning"
                      className={`nav-link ${
                        active === "/planning" ? "active" : ""
                      }`}
                      onClick={() => setActive("/planning")}
                    >
                      <i className="fa fa-briefcase"></i>
                      <span>Work Plan</span>
                    </Link>
                  </li>
                </>
              )}

              {(receiveData === "admin" ||
                receiveData === "depot" ||
                receiveData === "supply_chain" ||
                receiveData === "gstore" ||
                receiveData === "dstore" ||
                receiveData === "pstore") && (
                <>
                  <li className="nav-item">
                    <Link
                      to="/store-purchase-requisition-art"
                      className={`nav-link ${
                        active === "/store-purchase-requisition-art"
                          ? "active"
                          : ""
                      }`}
                      onClick={() =>
                        setActive("/store-purchase-requisition-art")
                      }
                    >
                      {/* <i className="fa fa-recipe" aria-hidden="true"></i> */}
                      <i className="fa fa-briefcase"></i>

                      <span>SPR</span>
                      <span className="badge bg-danger ms-2">{badge.sprs}</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/direct-purchase"
                      className={`nav-link ${
                        active === "/direct-purchase" ? "active" : ""
                      }`}
                      onClick={() => setActive("/direct-purchase")}
                    >
                      {/* <i className="fa fa-recipe" aria-hidden="true"></i> */}
                      <i className="fa fa-briefcase"></i>
                      <span>Purchase Order</span>
                      <span className="badge bg-danger ms-2">
                        {badge.direct_purchases}
                      </span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/grn"
                      className={`nav-link ${
                        active === "/grn" ? "active" : ""
                      }`}
                      onClick={() => setActive("/grn")}
                    >
                      {/* <i className="fa fa-recipe" aria-hidden="true"></i> */}
                      <i className="fa fa-briefcase"></i>
                      <span>MRR with QC</span>
                    </Link>
                  </li>
                </>
              )}

              {(receiveData === "body" ||
                receiveData === "glazing" ||
                receiveData === "mould" ||
                receiveData === "sagger" ||
                receiveData === "dstore" ||
                receiveData === "pstore" ||
                receiveData === "depot" ||
                receiveData === "gstore") && (
                <>
                  <li className="nav-item">
                    <Link
                      to="/requisition-production"
                      className={`nav-link ${
                        active === "/requisition-production" ? "active" : ""
                      }`}
                      onClick={() => setActive("/requisition-production")}
                    >
                      <i className="fa fa-briefcase"></i>
                      <span>Store Requisition</span>
                      <span className="badge bg-danger ms-2">
                        {badge.store_requisitions}
                      </span>
                      <span className="badge bg-danger ms-2">
                        {badge.unreceived_issues}
                      </span>
                    </Link>
                  </li>
                </>
              )}
              {receiveData !== "body" && receiveData !== "gstore" && (
                <>
                  <li className="nav-item">
                    <Link
                      to="/department-requisition-jcg"
                      className={`nav-link ${
                        active === "/department-requisition-jcg" ? "active" : ""
                      }`}
                      onClick={() => setActive("/department-requisition-jcg")}
                    >
                      <i className="fa fa-briefcase"></i>
                      <span>Requisition Department</span>
                    </Link>
                  </li>
                </>
              )}

              {(receiveData === "admin" ||
                receiveData === "body" ||
                receiveData === "gstore" ||
                receiveData === "dstore" ||
                // receiveData === "pstore" ||
                receiveData === "mould" ||
                receiveData === "sagger" ||
                receiveData === "packing_local" ||
                receiveData === "depot" ||
                receiveData === "packing_export") && (
                <>
                  <li className="nav-item">
                    <Link
                      to="/issue"
                      className={`nav-link ${
                        active === "/issue" ? "active" : ""
                      }`}
                      onClick={() => setActive("/issue")}
                    >
                      <i className="fa fa-briefcase"></i>
                      <span>
                        {receiveData === "gstore" ? "Issue" : "Received RM"}
                      </span>
                    </Link>
                  </li>
                </>
              )}

              {(receiveData === "admin" ||
                receiveData === "body" ||
                // receiveData === "gstore" ||
                receiveData === "dstore" ||
                receiveData === "pstore" ||
                receiveData === "mould" ||
                // receiveData === "finishing" ||
                // receiveData === "bk" ||
                // receiveData === "glazing" ||
                // receiveData === "gk" ||

                receiveData === "sagger") && (
                // receiveData === "decoration_local" ||
                // receiveData === "decoration_export" ||
                // receiveData === "packing_local" ||
                // receiveData === "packing_export"
                <>
                  <li className="nav-item">
                    <Link
                      to="/ball-mill-charge"
                      className={`nav-link ${
                        active === "/ball-mill-charge" ? "active" : ""
                      }`}
                      onClick={() => setActive("/ball-mill-charge")}
                    >
                      <i className="fa fa-briefcase"></i>
                      <span>Ball Charge</span>
                    </Link>
                  </li>
                </>
              )}

              {(receiveData === "admin" ||
                receiveData === "body" ||
                receiveData === "jigger" ||
                receiveData === "casting" ||
                receiveData === "selection" ||
                receiveData === "decoration_local" ||
                receiveData === "decoration_export" ||
                receiveData === "dfw" ||
                receiveData === "packaging_local" ||
                receiveData === "packaging_export") && (
                // receiveData === "gstore" ||
                <>
                  <li className="nav-item">
                    <Link
                      to="/production"
                      className={`nav-link ${
                        active === "/production" ? "active" : ""
                      }`}
                      onClick={() => setActive("/production")}
                    >
                      <i className="fa fa-briefcase"></i>
                      <span>Production</span>
                    </Link>
                  </li>
                </>
              )}

              {(receiveData === "admin" ||
                receiveData === "mould" ||
                receiveData === "sagger" ||
                receiveData === "pstore" ||
                receiveData === "dstore" ||
                receiveData === "body" ||
                receiveData === "jigger" ||
                receiveData === "casting" ||
                receiveData === "finishing" ||
                receiveData === "dstore" ||
                receiveData === "bk" ||
                receiveData === "glazing" ||
                receiveData === "gk" ||
                receiveData === "selection" ||
                receiveData === "decoration_local" ||
                receiveData === "decoration_export" ||
                receiveData === "dfw" ||
                receiveData === "packing_local" ||
                receiveData === "packing_export") && (
                <>
                  <li className="nav-item">
                    <Link
                      to="/transfer-art"
                      className={`nav-link ${
                        active === "/transfer-art" ? "active" : ""
                      }`}
                      onClick={() => setActive("/transfer-art")}
                    >
                      <i className="fa fa-briefcase"></i>
                      <span>Department Transfer</span>
                      <span className="badge bg-danger ms-2">
                        {badge.transfer}
                      </span>
                    </Link>
                  </li>
                </>
              )}

              {(receiveData === "admin" ||
                receiveData === "body" ||
                receiveData === "jigger" ||
                receiveData === "casting" ||
                receiveData === "decoration_local" ||
                receiveData === "decoration_export" ||
                receiveData === "finishing") && (
                <>
                  <li className="nav-item">
                    <Link
                      to="/return-art"
                      className={`nav-link ${
                        active === "/return-art" ? "active" : ""
                      }`}
                      onClick={() => setActive("/return-art")}
                    >
                      <i className="fa fa-briefcase"></i>
                      <span>Department Return</span>
                      <span className="badge bg-danger ms-2">
                        {badge.returns}
                      </span>
                    </Link>
                  </li>
                </>
              )}

              {(receiveData === "bk" ||
                receiveData === "glazing" ||
                receiveData === "gk") && (
                <>
                  <li className="nav-item">
                    <Link
                      to="/loss-art"
                      className={`nav-link ${
                        active === "/loss-art" ? "active" : ""
                      }`}
                      onClick={() => setActive("/loss-art")}
                    >
                      <i className="fa fa-briefcase"></i>
                      <span>Loss</span>
                      <span className="badge bg-danger ms-2">
                        {badge.returns}
                      </span>
                    </Link>
                  </li>
                </>
              )}
              {(receiveData === "admin" ||
                receiveData === "glazing" ||
                receiveData === "gk" ||
                receiveData === "selection" ||
                receiveData === "decoration_local" ||
                receiveData === "decoration_export" ||
                receiveData === "dfw" ||
                receiveData === "packing_local" ||
                receiveData === "packing_export") && (
                <>
                  <li className="nav-item">
                    <Link
                      to="/repair-art"
                      className={`nav-link ${
                        active === "/repair-art" ? "active" : ""
                      }`}
                      onClick={() => setActive("/repair-art")}
                    >
                      <i className="fa fa-briefcase"></i>
                      <span>Repair</span>
                    </Link>
                  </li>
                </>
              )}

              {(receiveData === "admin" ||
                receiveData === "glazing" ||
                receiveData === "gk" ||
                receiveData === "selection" ||
                receiveData === "decoration_local" ||
                receiveData === "decoration_export" ||
                receiveData === "dfw" ||
                receiveData === "packing_local" ||
                receiveData === "packing_export") && (
                <>
                  <li className="nav-item">
                    <Link
                      to="/defect-art"
                      className={`nav-link ${
                        active === "/defect-art" ? "active" : ""
                      }`}
                      onClick={() => setActive("/defect-art")}
                    >
                      <i className="fa fa-briefcase"></i>
                      <span>Defect</span>
                    </Link>
                  </li>
                </>
              )}

              {receiveData == "lab" && (
                <li className="nav-item">
                  <Link
                    to="/receipe"
                    className={`nav-link ${
                      active === "/receipe" ? "active" : ""
                    }`}
                    onClick={() => setActive("/receipe")}
                  >
                    {/* <i className="fa fa-recipe" aria-hidden="true"></i> */}
                    <i className="fa fa-flask"></i>
                    <span>Recipe</span>
                  </Link>
                </li>
              )}
              {receiveData != "hr" && (
                <li className="nav-item">
                  <Link
                    to="/reports"
                    className={`nav-link ${
                      active === "/reports" ? "active" : ""
                    }`}
                    onClick={() => setActive("/reports")}
                  >
                    <i className="fa fa-tachometer" aria-hidden="true"></i>
                    <span>Reports</span>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
