import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Alert from "../../../Common/Alert";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";

import useAlertFromLocation from "../../../../hooks/useAlertFromLocation";
import useDeleteData from "../../../../hooks/useDeleteData";
import ReactDataTable from "../../../Common/ReactDataTable";

const PlanningList = () => {
  const [planningData, setPlanningData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState(""); // State to hold the role

  // Fetch data from /planning API
  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await Http.get("/planning");
      setPlanningData(res.data);
      Http.get("/staff-type-check").then((res) => {
        setRole(res.data);
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Use custom hook to delete data
  const deleteData = useDeleteData(fetchData);
  const alert = useAlertFromLocation();

  const cols = [
    { field: "receipt_no", header: "Planning No" },
    { field: "start_date", header: "Date" },
    { field: "end_date", header: "Delivery Date" },
    // { field: "production_work_order.receipt_no", header: "Work Order No." },
    { field: "remarks", header: "Remarks" },
    { field: "created_by.name", header: "Created By" },
    {
      field: (item) => (
        <div className="text-xs text-nowrap">
          <Link
            to={`/planning-detail/${item.id}`}
            className="btn btn-info btn-sm"
          >
            <i className="fa fa-list" aria-hidden="true"></i>
          </Link>
          {role === "planning" && (
            <Link
              to={`/edit-planning/${item.id}`}
              className="btn btn-primary btn-sm ms-1"
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </Link>
          )} 
          {/* <button className="btn btn-danger btn-sm ms-1" onClick={() => deleteData(item.id, "planning", "planning")} title="Delete">
            <i className="fa fa-trash"></i> Delete
          </button> */}
        </div>
      ),
      header: "Actions",
    },
  ];

  return (
    <div>
      <IsLogin />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">All Work plan</h5>
          {role === "planning" && (
            <Link to="/create-planning" className="btn btn-primary">
              <i className="fa fa-plus-square mg-r-6"></i> Add Work Plan
            </Link>
          )}
        </div>
        <div className="am-pagebody">
          <Alert alert={alert} />
          <div className="card pd-20 pd-sm-40">
            <ReactDataTable
              cols={cols}
              receiveData={planningData}
              isLoading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanningList;
