import { PrimeReactProvider } from "primereact/api";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

//New Data table Code Start
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./Components/Layouts/Header";
import Sidebar from "./Components/Layouts/Sidebar";
import reportWebVitals from "./reportWebVitals";

import Dashboard from "./Components/Pages/Dashboard";
import Forms from "./Components/Pages/Forms"; // For Test Purpose. Should be deleted later on
import Login from "./Components/Pages/Login";
import Notification from "./Components/Pages/Notification/List";
import Testtable from "./Components/Pages/Testtable"; // For Test Purpose. Should be deleted later on

import CreateDesignation from "./Components/Pages/basic-setup/Designation/Create";
import EditDesignation from "./Components/Pages/basic-setup/Designation/Edit";
import Designation from "./Components/Pages/basic-setup/Designation/List";

import CreateGrade from "./Components/Pages/basic-setup/Grade/Create";
import EditGrade from "./Components/Pages/basic-setup/Grade/Edit";
import Grade from "./Components/Pages/basic-setup/Grade/List";

import CreateDepartment from "./Components/Pages/basic-setup/Department/Create";
import EditDepartment from "./Components/Pages/basic-setup/Department/Edit";
import Department from "./Components/Pages/basic-setup/Department/List";

// Artisan Basics
import CreateMachine from "./Components/Pages/basic-setup/Machine/Create";
import EditMachine from "./Components/Pages/basic-setup/Machine/Edit";
import Machine from "./Components/Pages/basic-setup/Machine/List";

import CreateShapeType from "./Components/Pages/basic-setup/ShapeType/Create";
import EditShapeType from "./Components/Pages/basic-setup/ShapeType/Edit";
import ShapeType from "./Components/Pages/basic-setup/ShapeType/List";

import CreateDeliveryReq from "./Components/Pages/Artisan/DeliveryReq/Create";
import CreateDeliveryReqJigCast from "./Components/Pages/Artisan/DeliveryReq/CreateJigCast";

import CreateJigCastDecoration from "./Components/Pages/Artisan/DeliveryReq/JigCast/CreateJigCastDecoration";
import CreateJigCastSelection from "./Components/Pages/Artisan/DeliveryReq/JigCast/CreateJigCastSelection";
import CreateJigCastSelectionTR from "./Components/Pages/Artisan/DeliveryReq/JigCast/CreateJigCastSelectionTR";
import CreateJigCastTillSelection from "./Components/Pages/Artisan/DeliveryReq/JigCast/CreateJigCastTillSelection";
// import DeliveryReqEdit from "./Components/Pages/Artisan/DeliveryReq/Edit";
// import DeliveryReq from "./Components/Pages/Artisan/DeliveryReq/List";
// import DeliveryReqView from "./Components/Pages/Artisan/DeliveryReq/Show";
import CreateDeliveryReqDec from "./Components/Pages/Artisan/DeliveryReq/CreateDecoration";
import CreateDeliveryReqSelection from "./Components/Pages/Artisan/DeliveryReq/CreateSelection";
import CreateReceiveReqSelection from "./Components/Pages/Artisan/ReceiveReq/CreateAS";

import CreateReceiveReq from "./Components/Pages/Artisan/ReceiveReq/Create";

import EditProductionJigCast from "./Components/Pages/Artisan/ProductionEdit/EditProductionJigCast";

import EditJigCastDecoration from "./Components/Pages/Artisan/ProductionEdit/JigCast/EditJigCastDecoration";
import EditJigCastDecorationTR from "./Components/Pages/Artisan/ProductionEdit/JigCast/EditJigCastDecorationTR";
import EditJigCastSelection from "./Components/Pages/Artisan/ProductionEdit/JigCast/EditJigCastSelection";
import EditJigCastSelectionTR from "./Components/Pages/Artisan/ProductionEdit/JigCast/EditJigCastSelectionTR";
import EditJigCastTillSelection from "./Components/Pages/Artisan/ProductionEdit/JigCast/EditJigCastTillSelection";
import EditSelectionDecorationRepair from "./Components/Pages/Artisan/ProductionEdit/JigCast/EditSelectionDecorationRepair";
import EditSelectionDecorationDefect from "./Components/Pages/Artisan/ProductionEdit/JigCast/EditSelectionDecorationDefect";
// Artisan Basics

import CreateCompanyLocation from "./Components/Pages/basic-setup/Location/Create";
import EditCompanyLocation from "./Components/Pages/basic-setup/Location/Edit";
import CompanyLocation from "./Components/Pages/basic-setup/Location/List";

import CreateFactory from "./Components/Pages/basic-setup/Factory/Create";
import EditFactory from "./Components/Pages/basic-setup/Factory/Edit";
import Factory from "./Components/Pages/basic-setup/Factory/List";

import CreateLeaveRules from "./Components/Pages/basic-setup/leave-rules/CreateLeaveRules";
import EditLeaveRules from "./Components/Pages/basic-setup/leave-rules/EditLeaveRules";
import LeaveRules from "./Components/Pages/basic-setup/leave-rules/LeaveRules";

import CreateRegion from "./Components/Pages/basic-setup/Workplace/Region/Create";
import EditRegion from "./Components/Pages/basic-setup/Workplace/Region/Edit";
import Region from "./Components/Pages/basic-setup/Workplace/Region/List";

import CreateArea from "./Components/Pages/basic-setup/Workplace/Area/Create";
import EditArea from "./Components/Pages/basic-setup/Workplace/Area/Edit";
import Area from "./Components/Pages/basic-setup/Workplace/Area/List";

import CreateBase from "./Components/Pages/basic-setup/Workplace/Base/Create";
import EditBase from "./Components/Pages/basic-setup/Workplace/Base/Edit";
import Base from "./Components/Pages/basic-setup/Workplace/Base/List";

import CreateRoute from "./Components/Pages/basic-setup/Workplace/Route/Create";
import EditRoute from "./Components/Pages/basic-setup/Workplace/Route/Edit";
import WorkplaceRoute from "./Components/Pages/basic-setup/Workplace/Route/List";

import Appointment from "./Components/Pages/HrManagement/Staff/Appointment";
import CreateStaff from "./Components/Pages/HrManagement/Staff/CreateStaff";
import EditStaff from "./Components/Pages/HrManagement/Staff/EditStaff";
import IdCard from "./Components/Pages/HrManagement/Staff/IdCard";
import ProfileEdit from "./Components/Pages/HrManagement/Staff/Profile/Edit";
import ProfileStaff from "./Components/Pages/HrManagement/Staff/Profile/Profile";
import Staff from "./Components/Pages/HrManagement/Staff/Staff";

// Payroll
import CreateBillSubmission from "./Components/Pages/HrManagement/Bill-Submission/Create";
import EditBillSubmission from "./Components/Pages/HrManagement/Bill-Submission/Edit";
import BillSubmission from "./Components/Pages/HrManagement/Bill-Submission/List";
import BillSubmissionView from "./Components/Pages/HrManagement/Bill-Submission/View";

import CreateMovementSlip from "./Components/Pages/HrManagement/Movement-Slip/Create";
import EditMovementSlip from "./Components/Pages/HrManagement/Movement-Slip/Edit";
import MovementSlip from "./Components/Pages/HrManagement/Movement-Slip/List";
import MovementSlipView from "./Components/Pages/HrManagement/Movement-Slip/View";

import CreateAttendance from "./Components/Pages/HrManagement/Attendance/Create";
import EditAttendance from "./Components/Pages/HrManagement/Attendance/Edit";
import ImportAttendance from "./Components/Pages/HrManagement/Attendance/Import";
import Attendance from "./Components/Pages/HrManagement/Attendance/List";

import CreateNotice from "./Components/Pages/HrManagement/Notice/Create";
import EditNotice from "./Components/Pages/HrManagement/Notice/Edit";
import Notice from "./Components/Pages/HrManagement/Notice/List";

import CreateArrear from "./Components/Pages/HrManagement/Arrear/Create";
import EditArrear from "./Components/Pages/HrManagement/Arrear/Edit";
import Arrear from "./Components/Pages/HrManagement/Arrear/List";

import CreateIncentive from "./Components/Pages/HrManagement/Incentive/Create";
import EditIncentive from "./Components/Pages/HrManagement/Incentive/Edit";
import Incentive from "./Components/Pages/HrManagement/Incentive/List";

import CreateOvertime from "./Components/Pages/HrManagement/Overtime/Create";
import EditOvertime from "./Components/Pages/HrManagement/Overtime/Edit";
import Overtime from "./Components/Pages/HrManagement/Overtime/List";

import CreateHoliday from "./Components/Pages/HrManagement/Holiday/Create";
import EditHoliday from "./Components/Pages/HrManagement/Holiday/Edit";
import Holiday from "./Components/Pages/HrManagement/Holiday/List";

import CreateHolidayList from "./Components/Pages/HrManagement/HolidayList/Create";
import EditHolidayList from "./Components/Pages/HrManagement/HolidayList/Edit";
import HolidayList from "./Components/Pages/HrManagement/HolidayList/List";

import CreateRoasterHolidayList from "./Components/Pages/HrManagement/RoasterHolidayList/Create";
import EditRoasterHolidayList from "./Components/Pages/HrManagement/RoasterHolidayList/Edit";
import RoasterHolidayList from "./Components/Pages/HrManagement/RoasterHolidayList/List";

import Settings from "./Components/Pages/basic-setup/settings/Edit";

import CreateWeeklyOffDay from "./Components/Pages/basic-setup/WeeklyOffDay/Create";
import EditWeeklyOffDay from "./Components/Pages/basic-setup/WeeklyOffDay/Edit";
import WeeklyOffDay from "./Components/Pages/basic-setup/WeeklyOffDay/List";

import CreateNightHold from "./Components/Pages/HrManagement/NightHold/Create";
import EditNightHold from "./Components/Pages/HrManagement/NightHold/Edit";
import NightHold from "./Components/Pages/HrManagement/NightHold/List";

import CreateTada from "./Components/Pages/HrManagement/Tada/Create";
import EditTada from "./Components/Pages/HrManagement/Tada/Edit";
import Tada from "./Components/Pages/HrManagement/Tada/List";

import CreateSpecialLateFacility from "./Components/Pages/basic-setup/SpecialLateFacility/Create";
import EditSpecialLateFacility from "./Components/Pages/basic-setup/SpecialLateFacility/Edit";
import SpecialLateFacility from "./Components/Pages/basic-setup/SpecialLateFacility/List";

import CreateSalaryAdvance from "./Components/Pages/HrManagement/SalaryAdvance/Create";
import EditSalaryAdvance from "./Components/Pages/HrManagement/SalaryAdvance/Edit";
import SalaryAdvance from "./Components/Pages/HrManagement/SalaryAdvance/List";

import CreateCanteen from "./Components/Pages/HrManagement/Canteen/Create";
import EditCanteen from "./Components/Pages/HrManagement/Canteen/Edit";
import Canteen from "./Components/Pages/HrManagement/Canteen/List";

import CreatePhoneBill from "./Components/Pages/HrManagement/PhoneBill/Create";
import EditPhoneBill from "./Components/Pages/HrManagement/PhoneBill/Edit";
import PhoneBill from "./Components/Pages/HrManagement/PhoneBill/List";

import CreateIncomeTax from "./Components/Pages/HrManagement/IncomeTax/Create";
import EditIncomeTax from "./Components/Pages/HrManagement/IncomeTax/Edit";
import IncomeTax from "./Components/Pages/HrManagement/IncomeTax/List";

import CreateDeduction from "./Components/Pages/HrManagement/Deduction/Create";
import EditDeduction from "./Components/Pages/HrManagement/Deduction/Edit";
import Deduction from "./Components/Pages/HrManagement/Deduction/List";

import CreateBonous from "./Components/Pages/HrManagement/Bonous/Create";
import EditBonous from "./Components/Pages/HrManagement/Bonous/Edit";
import Bonous from "./Components/Pages/HrManagement/Bonous/List";

import CreateLeaveDeduction from "./Components/Pages/HrManagement/LeaveDeduction/Create";
import EditLeaveDeduction from "./Components/Pages/HrManagement/LeaveDeduction/Edit";
import LeaveDeduction from "./Components/Pages/HrManagement/LeaveDeduction/List";

import CreateLeaveApplication from "./Components/Pages/HrManagement/LeaveApplication/Create";
import EditLeaveApplication from "./Components/Pages/HrManagement/LeaveApplication/Edit";
import LeaveApplication from "./Components/Pages/HrManagement/LeaveApplication/List";
import LeaveApplicationView from "./Components/Pages/HrManagement/LeaveApplication/View";

import CreateRequisition from "./Components/Pages/HrManagement/Requisition/Create";
import EditRequisition from "./Components/Pages/HrManagement/Requisition/Edit";
import Requisition from "./Components/Pages/HrManagement/Requisition/List";

import CreateCandidate from "./Components/Pages/HrManagement/Candidate/Create";
import EditCandidate from "./Components/Pages/HrManagement/Candidate/Edit";
import Candidate from "./Components/Pages/HrManagement/Candidate/List";

import CreateSalary from "./Components/Pages/HrManagement/Salary/Create";
import Salary from "./Components/Pages/HrManagement/Salary/List";
import PaySlip from "./Components/Pages/HrManagement/Salary/PaySlip";

// Accounts
import CreateBudgetPlan from "./Components/Pages/Budget/Plan/Create";
import EditBudgetPlan from "./Components/Pages/Budget/Plan/Edit";
import BudgetPlan from "./Components/Pages/Budget/Plan/List";
import ShowBudgetPlan from "./Components/Pages/Budget/Plan/Show";

import CreateChartOfAccount from "./Components/Pages/Accounts/Chart-Of-Account/Create";
import EditChartOfAccount from "./Components/Pages/Accounts/Chart-Of-Account/Edit";
import ChartOfAccount from "./Components/Pages/Accounts/Chart-Of-Account/List";
import TreeChartOfAccount from "./Components/Pages/Accounts/Chart-Of-Account/Tree";

import CreateJournalCode from "./Components/Pages/Accounts/Journal-Code/Create";
import EditJournalCode from "./Components/Pages/Accounts/Journal-Code/Edit";
import JournalCode from "./Components/Pages/Accounts/Journal-Code/List";

import CreateSubCategory from "./Components/Pages/Accounts/Sub-Category/Create";
import EditSubCategory from "./Components/Pages/Accounts/Sub-Category/Edit";
import SubCategory from "./Components/Pages/Accounts/Sub-Category/List";

import CreateJournalVoucher from "./Components/Pages/Accounts/Journal-Voucher/Create";
import EditJournalVoucher from "./Components/Pages/Accounts/Journal-Voucher/Edit";
import JournalVoucher from "./Components/Pages/Accounts/Journal-Voucher/List";

import CreateCashCount from "./Components/Pages/Accounts/Cash-Count/Create";
import EditCashCount from "./Components/Pages/Accounts/Cash-Count/Edit";
import CashCount from "./Components/Pages/Accounts/Cash-Count/List";

import CreateSetup from "./Components/Pages/Accounts/Setup/Create";
import AutoJournalSetup from "./Components/Pages/Accounts/Setup/List";

import EditTransactionsListConnect from "./Components/Pages/Accounts/Transactions-List-Connect/Edit";
import TransactionsListConnect from "./Components/Pages/Accounts/Transactions-List-Connect/List";

import CreateApa from "./Components/Pages/HrManagement/Apa/Create";
import EditApa from "./Components/Pages/HrManagement/Apa/Edit";
import Apa from "./Components/Pages/HrManagement/Apa/List";

// Supply Chain
import CreateUnit from "./Components/Pages/SupplyChain/Unit/Create";
import EditUnit from "./Components/Pages/SupplyChain/Unit/Edit";
import Unit from "./Components/Pages/SupplyChain/Unit/List";

import CreateVendor from "./Components/Pages/SupplyChain/Vendor/Create";
import EditVendor from "./Components/Pages/SupplyChain/Vendor/Edit";
import Vendors from "./Components/Pages/SupplyChain/Vendor/List";
import ViewVendor from "./Components/Pages/SupplyChain/Vendor/View";

import CreateProductCategory from "./Components/Pages/SupplyChain/product-category/Create";
import EditProductCategory from "./Components/Pages/SupplyChain/product-category/Edit";
import ProductCategory from "./Components/Pages/SupplyChain/product-category/List";

import CreateProduct from "./Components/Pages/SupplyChain/Product/Create";
import EditProduct from "./Components/Pages/SupplyChain/Product/Edit";
import Product from "./Components/Pages/SupplyChain/Product/List";

import AddCostDirectPurchase from "./Components/Pages/SupplyChain/Direct-Purchase/AddCostDirectPurchase";
import CreateDirectPurchase from "./Components/Pages/SupplyChain/Direct-Purchase/Create";
import CreatePurchaseByRequisition from "./Components/Pages/SupplyChain/Direct-Purchase/CreatePurchaseByRequisition";
import EditDirectPurchase from "./Components/Pages/SupplyChain/Direct-Purchase/Edit";
import DirectPurchase from "./Components/Pages/SupplyChain/Direct-Purchase/List";
import ShowDirectPurchase from "./Components/Pages/SupplyChain/Direct-Purchase/Show";

import CreateCircularRfq from "./Components/Pages/SupplyChain/CircularRfq/Create";
import EditCircularRfq from "./Components/Pages/SupplyChain/CircularRfq/Edit";
import CircularRfq from "./Components/Pages/SupplyChain/CircularRfq/List";
import ShowCircularRfq from "./Components/Pages/SupplyChain/CircularRfq/Show";
import VendorQuotationSent from "./Components/Pages/SupplyChain/CircularRfq/Vendor/Quotation";

import CircularCompare from "./Components/Pages/SupplyChain/CircularRfq/Vendor/Compare";
import VendorQuotationList from "./Components/Pages/SupplyChain/CircularRfq/Vendor/QuotationList";
import ShowVendorQuotation from "./Components/Pages/SupplyChain/CircularRfq/Vendor/Show";

import FinalQuotation from "./Components/Pages/SupplyChain/Final-Quotation/List";
import FinalQuotationView from "./Components/Pages/SupplyChain/Final-Quotation/Show";

import CreateGrn from "./Components/Pages/SupplyChain/Grn/Create";
import PurchaseOrder from "./Components/Pages/SupplyChain/Purchase-Order/List";
import PurchaseOrderView from "./Components/Pages/SupplyChain/Purchase-Order/Show";

import GrnEdit from "./Components/Pages/SupplyChain/Grn/Edit";
import Grn from "./Components/Pages/SupplyChain/Grn/List";
import GrnView from "./Components/Pages/SupplyChain/Grn/Show";

import PurchaseOrderReturnCreate from "./Components/Pages/SupplyChain/Grn-Return/Create";
import PurchaseOrderReturnEdit from "./Components/Pages/SupplyChain/Grn-Return/Edit";
import PurchaseOrderReturn from "./Components/Pages/SupplyChain/Grn-Return/List";
import PurchaseOrderReturnView from "./Components/Pages/SupplyChain/Grn-Return/Show";

import CreateothersReceive from "./Components/Pages/SupplyChain/Others-Receive/Create";
import EditothersReceive from "./Components/Pages/SupplyChain/Others-Receive/Edit";
import OthersReceive from "./Components/Pages/SupplyChain/Others-Receive/List";
import OthersReceiveView from "./Components/Pages/SupplyChain/Others-Receive/Show";

// Assets
import CreateAssetCategory from "./Components/Pages/Assets/Asset-Category/Create";
import EditAssetCategory from "./Components/Pages/Assets/Asset-Category/Edit";
import AssetCategory from "./Components/Pages/Assets/Asset-Category/List";

import CreateAsset from "./Components/Pages/Assets/Asset/Create";
import EditAsset from "./Components/Pages/Assets/Asset/Edit";
import Asset from "./Components/Pages/Assets/Asset/List";

import CreateAssetDirectPurchase from "./Components/Pages/Assets/Direct-Purchase/Create";
import EditAssetDirectPurchase from "./Components/Pages/Assets/Direct-Purchase/Edit";
import AssetDirectPurchase from "./Components/Pages/Assets/Direct-Purchase/List";

import CreateAssetMaintenenceClaim from "./Components/Pages/Assets/Maintenance-Claim/Create";
import EditAssetMaintenenceClaim from "./Components/Pages/Assets/Maintenance-Claim/Edit";
import AssetMaintenenceClaim from "./Components/Pages/Assets/Maintenance-Claim/List";

import CreateAssetDamageClaim from "./Components/Pages/Assets/Damage-Claim/Create";
import EditAssetDamageClaim from "./Components/Pages/Assets/Damage-Claim/Edit";
import AssetDamageClaim from "./Components/Pages/Assets/Damage-Claim/List";

import CreateAssetAssign from "./Components/Pages/Assets/Assign/Create";
import DamageAssetClaim from "./Components/Pages/Assets/Assign/Damage";
import EditAssetAssign from "./Components/Pages/Assets/Assign/Edit";
import AssetAssign from "./Components/Pages/Assets/Assign/List";
import ReturnAssetAssign from "./Components/Pages/Assets/Assign/Return";

import CreateAssetCircularRfq from "./Components/Pages/Assets/Asset-CircularRfq/Create";
import EditAssetCircularRfq from "./Components/Pages/Assets/Asset-CircularRfq/Edit";
import AssetCircularRfq from "./Components/Pages/Assets/Asset-CircularRfq/List";

import AssetCircularCompare from "./Components/Pages/Assets/Asset-CircularRfq/Vendor/Compare";
import VendorAssetQuotationSent from "./Components/Pages/Assets/Asset-CircularRfq/Vendor/Quotation";
import VendorAssetQuotationList from "./Components/Pages/Assets/Asset-CircularRfq/Vendor/QuotationList";

import AssetFinalQuotation from "./Components/Pages/Assets/Final-Quotation/List";
import AssetFinalQuotationView from "./Components/Pages/Assets/Final-Quotation/Show";

import AssetCreateGrn from "./Components/Pages/Assets/Grn/Create";
import AssetPurchaseOrder from "./Components/Pages/Assets/Purchase-Order/List";
import AssetPurchaseOrderView from "./Components/Pages/Assets/Purchase-Order/Show";

import AssetGrnEdit from "./Components/Pages/Assets/Grn/Edit";
import AssetGrn from "./Components/Pages/Assets/Grn/List";
import AssetGrnView from "./Components/Pages/Assets/Grn/Show";

import AssetPurchaseOrderReturnCreate from "./Components/Pages/Assets/Grn-Return/Create";
import AssetPurchaseOrderReturnEdit from "./Components/Pages/Assets/Grn-Return/Edit";
import AssetPurchaseOrderReturn from "./Components/Pages/Assets/Grn-Return/List";
import AssetPurchaseOrderReturnView from "./Components/Pages/Assets/Grn-Return/Show";

// Production
import CreateMachineries from "./Components/Pages/Production/Machineries/Create";
import EditMachineries from "./Components/Pages/Production/Machineries/Edit";
import Machineries from "./Components/Pages/Production/Machineries/List";

import CreateFactoryMachineries from "./Components/Pages/Production/Factory-Machineries/Create";
import EditFactoryMachineries from "./Components/Pages/Production/Factory-Machineries/Edit";
import FactoryMachineries from "./Components/Pages/Production/Factory-Machineries/List";

import CreateReceipe from "./Components/Pages/Production/Receipe/Create";
import EditReceipe from "./Components/Pages/Production/Receipe/Edit";
import Receipe from "./Components/Pages/Production/Receipe/List";
import ViewReceipe from "./Components/Pages/Production/Receipe/View";

import CreateReceipeFactory from "./Components/Pages/Production/ReceipeFactory/Create";
import EditReceipeFactory from "./Components/Pages/Production/ReceipeFactory/Edit";
import ReceipeFactory from "./Components/Pages/Production/ReceipeFactory/List";

import CreateRequisitionJigCastGK from "./Components/Pages/Production/RequisitionDepartment/CreateJigCastGK";
import EditRequisitionJigCastGK from "./Components/Pages/Production/RequisitionDepartment/EditJigCastGK";
import RequisitionJigCastGK from "./Components/Pages/Production/RequisitionDepartment/ListJigCastGK";
import ShowRequisitionJigCastGK from "./Components/Pages/Production/RequisitionDepartment/ShowJigCastGK";
import CreateRequisitionProduction from "./Components/Pages/Production/RequisitionProduction/Create";
import CreateRequisitionProductionByDepartment from "./Components/Pages/Production/RequisitionProduction/CreatebyReqDept";
import EditRequisitionProduction from "./Components/Pages/Production/RequisitionProduction/Edit";
import RequisitionProduction from "./Components/Pages/Production/RequisitionProduction/List";
import ViewRequisitionProduction from "./Components/Pages/Production/RequisitionProduction/Show";

import CreateIssue from "./Components/Pages/Production/Issue/Create";
import EditIssue from "./Components/Pages/Production/Issue/Edit";
import Issue from "./Components/Pages/Production/Issue/List";
import EditProductionGenerate from "./Components/Pages/Production/Issue/Receive/EditProductionGenerate";
import ProductionReceive from "./Components/Pages/Production/Issue/Receive/Production";
import ProductionGenerate from "./Components/Pages/Production/Issue/Receive/ProductionGenerate";
import ProductionGenerateOfBody from "./Components/Pages/Production/Issue/Receive/ProductionGenerateOfBody";
import ProductionGenerateRequisitionDepartmentWise from "./Components/Pages/Production/Issue/Receive/ProductionGenerateRequisitionDepartmentWise";
import ProductionGenerateRequisition from "./Components/Pages/Production/Issue/Receive/ProductionGenerateRequisitionDepartmentWise";
import RmPmReceive from "./Components/Pages/Production/Issue/Receive/Rmpm";
import ViewIssue from "./Components/Pages/Production/Issue/Show";

import Production from "./Components/Pages/Production/Production/List";
import BallMillCHargeList from "./Components/Pages/Production/BallMillChargeList/List";

import DepoTransfer from "./Components/Pages/Production/Depo-Transfer/List";

import CreateFactoryTransfer from "./Components/Pages/Production/Factory-Transfer/Create";
import EditFactoryTransfer from "./Components/Pages/Production/Factory-Transfer/Edit";
import FactoryTransfer from "./Components/Pages/Production/Factory-Transfer/List";
import FactoryTransferShow from "./Components/Pages/Production/Factory-Transfer/Show";
import CreateDepartmentTransfer from "./Components/Pages/Production/Factory-Transfer/TransferGenerate";
import EditDepartmentTransfer from "./Components/Pages/Production/Factory-Transfer/TransferGenerateEdit";

import CreateFactoryReturn from "./Components/Pages/Production/Factory-Return/Create";
import EditFactoryReturn from "./Components/Pages/Production/Factory-Return/Edit";
import FactoryReturn from "./Components/Pages/Production/Factory-Return/List";
import FactoryReturnShow from "./Components/Pages/Production/Factory-Return/Show";

import CreateDamageReturn from "./Components/Pages/Production/Damage-Return/Create";
import EditDamageReturn from "./Components/Pages/Production/Damage-Return/Edit";
import DamageReturn from "./Components/Pages/Production/Damage-Return/List";

// Sales
import CreatePrimarySales from "./Components/Pages/Sales/Primary-Sales/Create";
import DeliveryOrder from "./Components/Pages/Sales/Primary-Sales/DeliveryOrder";
import EditPrimarySales from "./Components/Pages/Sales/Primary-Sales/Edit";
import PrimarySales from "./Components/Pages/Sales/Primary-Sales/List";
import OrderDetail from "./Components/Pages/Sales/Primary-Sales/OrderDetail";
import PaymentReceive from "./Components/Pages/Sales/Primary-Sales/PaymentReceive";
import PaymentReceiveCoa from "./Components/Pages/Sales/Primary-Sales/PaymentReceiveCoa";

import Chalan from "./Components/Pages/Sales/Primary-Sales/Chalan";
import Mushok63 from "./Components/Pages/Sales/Primary-Sales/Mushok63";

import CreateDistributors from "./Components/Pages/Sales/Distributors/Create";
import EditDistributors from "./Components/Pages/Sales/Distributors/Edit";
import Distributors from "./Components/Pages/Sales/Distributors/List";
import DistributorsView from "./Components/Pages/Sales/Distributors/View";

import CreateCompany from "./Components/Pages/Artisan/Company/Create";
import EditCompany from "./Components/Pages/Artisan/Company/Edit";
import Company from "./Components/Pages/Artisan/Company/List";
import CompanyView from "./Components/Pages/Artisan/Company/View";

import CreateTradeOffer from "./Components/Pages/SupplyChain/Trade-Offer/Create";
import EditTradeOffer from "./Components/Pages/SupplyChain/Trade-Offer/Edit";
import TradeOffer from "./Components/Pages/SupplyChain/Trade-Offer/List";

// Secondary Sales
import CreateMonthlyTarget from "./Components/Pages/Sales/Secondary-Sales/Monthly-Target/CreateTarget";
import MonthlyTargetDailyReport from "./Components/Pages/Sales/Secondary-Sales/Monthly-Target/DailyReport";
import EditMonthlyTarget from "./Components/Pages/Sales/Secondary-Sales/Monthly-Target/EditTarget";
import MonthlyTarget from "./Components/Pages/Sales/Secondary-Sales/Monthly-Target/List";
import SummaryReport from "./Components/Pages/Sales/Secondary-Sales/Monthly-Target/SummaryReport";
import MonthlyTargetView from "./Components/Pages/Sales/Secondary-Sales/Monthly-Target/View";
import MonthlyTargetViewBaseWiseDaily from "./Components/Pages/Sales/Secondary-Sales/Monthly-Target/ViewBaseWiseDaily";
import MonthlyTargetViewRegionWise from "./Components/Pages/Sales/Secondary-Sales/Monthly-Target/ViewRegionWise";

import CreateOpeningStock from "./Components/Pages/Sales/Secondary-Sales/Opening-Stock/Create";
import EditOpeningStock from "./Components/Pages/Sales/Secondary-Sales/Opening-Stock/Edit";
import OpeningStock from "./Components/Pages/Sales/Secondary-Sales/Opening-Stock/List";
import OpeningStockView from "./Components/Pages/Sales/Secondary-Sales/Opening-Stock/View";

// Reports
import CreateShape from "./Components/Pages/basic-setup/Shape/Create";
import EditShape from "./Components/Pages/basic-setup/Shape/Edit";
import ListShape from "./Components/Pages/basic-setup/Shape/List";
import ChartOfAccountLedgerReport from "./Components/Pages/Reports/Accounts/ChartOfAccountLedgerReport";
import DistributorPayableReport from "./Components/Pages/Reports/Accounts/DistributorPayableReport";
import DistributorReceivableReport from "./Components/Pages/Reports/Accounts/DistributorReceivableReport";
import ReceiptPaymentSummaryReport from "./Components/Pages/Reports/Accounts/ReceiptPaymentSummaryReport";
import SubcategoryLedgerReport from "./Components/Pages/Reports/Accounts/SubcategoryLedgerReport";
import SubcategoryListReport from "./Components/Pages/Reports/Accounts/SubcategoryListReport";
import TrialBalance from "./Components/Pages/Reports/Accounts/TrialBalance";
import VendorSupplierPayableReport from "./Components/Pages/Reports/Accounts/VendorSupplierPayableReport";
import VendorSupplierReceivableReport from "./Components/Pages/Reports/Accounts/VendorSupplierReceivableReport";
import ArtisanStock from "./Components/Pages/Reports/ArtisanStock/Stock";
import DeptStockART from "./Components/Pages/Reports/ArtisanStock/DeptStock";
import BklDailyProductionReport from "./Components/Pages/Reports/BKL/DailyProductionReport";
import BodyDailyProductionReport from "./Components/Pages/Reports/Body/DailyProductionReport";
import BodyRawMaterialsConsumptionReport from "./Components/Pages/Reports/Body/RawMaterialsReport";
import CastingDailyProductionReport from "./Components/Pages/Reports/Casting/DailyProductionReport";
import CastingExportScheduleReport from "./Components/Pages/Reports/Casting/ExportScheduleReport";
import DepoWiseProductStock from "./Components/Pages/Reports/Depot/DepoWiseProductStock";
import Distributor from "./Components/Pages/Reports/Depot/Distributor";
import PackingMaterialStock from "./Components/Pages/Reports/Depot/PackingMaterialStock";
import ProductStock from "./Components/Pages/Reports/Depot/ProductStock";
import RawMaterialStock from "./Components/Pages/Reports/Depot/RawMaterialStock";
import FinishingDailyProductionReport from "./Components/Pages/Reports/Finishing/DailyProductionReport";
import GKDailyProductionReport from "./Components/Pages/Reports/GK/DailyProductionReport";
import GlazingDailyProductionReport from "./Components/Pages/Reports/Glazing/DailyProductionReport";
import GRNReport from "./Components/Pages/Reports/GRN/Grn";
import ApaReport from "./Components/Pages/Reports/HrManagement/ApaReport";
import AttendanceReport from "./Components/Pages/Reports/HrManagement/AttendanceReport";
import HrLeaveReport from "./Components/Pages/Reports/HrManagement/LeaveReport";
import MonthlySalaryReport from "./Components/Pages/Reports/HrManagement/MonthlySalaryReport";
import SalaryPaymentReport from "./Components/Pages/Reports/HrManagement/SalaryPaymentReport";
import HrSalaryStructure from "./Components/Pages/Reports/HrManagement/SalaryStructure";
import JiggerDailyProductionReport from "./Components/Pages/Reports/Jigger/DailyProductionReport";
import JiggerShiftWiseDailyProductionReport from "./Components/Pages/Reports/Jigger/ShiftWiseDailyProductionReport";
import Reports from "./Components/Pages/Reports/List";
import PlanningReport from "./Components/Pages/Reports/Planning/PlanningReport";
// import ListProductionWorkOrder from "./Components/Pages/basic-setup/ProductionWorkOrder/List";
// import CreateProductionWorkOrder from "./Components/Pages/basic-setup/ProductionWorkOrder/Create";
// import EditProductionWorkOrder from "./Components/Pages/basic-setup/ProductionWorkOrder/Edit";
// import ListProductionWorkOrderItem from "./Components/Pages/basic-setup/ProductionWorkOrderItem/List";
// import EditProductionWorkOrderItem from "./Components/Pages/basic-setup/ProductionWorkOrderItem/Edit";
// import CreateProductionWorkOrderItem from "./Components/Pages/basic-setup/ProductionWorkOrderItem/Create";
import CreatePlanning from "./Components/Pages/Artisan/Planning/Create";
import CreatePlanningWorkOrderWise from "./Components/Pages/Artisan/Planning/CreatePlanningWorkOrderWise";
import PlanningEdit from "./Components/Pages/Artisan/Planning/Edit";
import PlanningList from "./Components/Pages/Artisan/Planning/List";
import PlanningView from "./Components/Pages/Artisan/Planning/View";
import CreateProductionArt from "./Components/Pages/Artisan/Production/Create";
import EditProductionArt from "./Components/Pages/Artisan/Production/Edit";
import ProductionList from "./Components/Pages/Artisan/Production/List";
import ViewProductionArt from "./Components/Pages/Artisan/Production/View";
import CreateProductionWorkOrder from "./Components/Pages/Artisan/ProductionWorkOrder/Create";
import EditProductionWorkOrder from "./Components/Pages/Artisan/ProductionWorkOrder/Edit";
import ProductionWorkOrderList from "./Components/Pages/Artisan/ProductionWorkOrder/List";
import ViewProductionWorkOrder from "./Components/Pages/Artisan/ProductionWorkOrder/View";

import ApproveStorePurchaseRequisitionArt from "./Components/Pages/Artisan/StorePurchaseRequisition/Approve";
import CreateStorePurchaseRequisitionArt from "./Components/Pages/Artisan/StorePurchaseRequisition/Create";
import EditStorePurchaseRequisitionArt from "./Components/Pages/Artisan/StorePurchaseRequisition/Edit";
import StorePurchaseRequisitionList from "./Components/Pages/Artisan/StorePurchaseRequisition/List";
import ViewStorePurchaseRequisitionArt from "./Components/Pages/Artisan/StorePurchaseRequisition/View";

import CreateProductionTransfer from "./Components/Pages/Artisan/ProductionTransfer/Create";
import EditProductionTransfer from "./Components/Pages/Artisan/ProductionTransfer/Edit";
import ProductionTransferList from "./Components/Pages/Artisan/ProductionTransfer/List-alvi";
import ViewProductionTransfer from "./Components/Pages/Artisan/ProductionTransfer/View";

import TransferCreate from "./Components/Pages/Artisan/Transfer/Create";
import CreateWithoutOrderTransfer from "./Components/Pages/Artisan/Transfer/CreateWithoutOrder";
import TransferList from "./Components/Pages/Artisan/Transfer/List";
import TransferRec from "./Components/Pages/Artisan/Transfer/RecTransfer";

import RepairCreate from "./Components/Pages/Artisan/Repair/Create";
import CreateWithoutOrderRepair from "./Components/Pages/Artisan/Repair/CreateWithoutOrder";
import RepairList from "./Components/Pages/Artisan/Repair/List";
import RepairRec from "./Components/Pages/Artisan/Repair/RecRepair";

import DefectList from "./Components/Pages/Artisan/Defect/List";


import CreateReturnArt from "./Components/Pages/Artisan/Return/Create";
import EditReturnArt from "./Components/Pages/Artisan/Return/Edit";
import ReturnList from "./Components/Pages/Artisan/Return/List";
import ViewReturnArt from "./Components/Pages/Artisan/Return/View";

import EditLoss from "./Components/Pages/Artisan/Loss/EditLoss";
import LossList from "./Components/Pages/Artisan/Loss/List";

import { GlobalProvider } from "./GlobalContext";

console.error = () => {};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <GlobalProvider>
    <PrimeReactProvider>
      <BrowserRouter>
        <Header />
        <Sidebar />

        <div id="erp-content">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/form" element={<Forms />} />
            <Route path="/testtable" element={<Testtable />} />
            <Route path="/notification" element={<Notification />} />
            {/* Designation */}
            <Route path="/designation" element={<Designation />} />
            <Route path="/createdesignation" element={<CreateDesignation />} />
            <Route path="/editdesignation/:id" element={<EditDesignation />} />
            {/* Grade */}
            <Route path="/grade" element={<Grade />} />
            <Route path="/creategrade" element={<CreateGrade />} />
            <Route path="/editgrade/:id" element={<EditGrade />} />
            {/* Department */}
            <Route path="/department" element={<Department />} />
            <Route path="/createdepartment" element={<CreateDepartment />} />
            <Route path="/editdepartment/:id" element={<EditDepartment />} />

            {/* Artisan */}

            {/* Machines */}
            <Route path="/machine" element={<Machine />} />
            <Route path="/create-machine" element={<CreateMachine />} />
            <Route path="/edit-machine/:id" element={<EditMachine />} />
            {/* Shapes Type */}
            <Route path="/shape-type" element={<ShapeType />} />
            <Route path="/create-shape-type" element={<CreateShapeType />} />
            <Route path="/edit-shape-type/:id" element={<EditShapeType />} />
            {/* Shapes */}
            <Route path="/shape" element={<ListShape />} />
            <Route path="/create-shape" element={<CreateShape />} />
            <Route path="/edit-shape/:id" element={<EditShape />} />
            {/* ProductionWorkOrder */}
            {/* <Route path="/production-work-order" element={<ListProductionWorkOrder />} />
            <Route path="/create-production-work-order" element={<CreateProductionWorkOrder />} />
            <Route path="/edit-production-work-order/:id" element={<EditProductionWorkOrder />} /> */}
            {/* ProductionWorkOrderItem */}
            {/* <Route path="/production-work-order-item" element={<ListProductionWorkOrderItem />} />
            <Route path="/create-production-work-order-item" element={<CreateProductionWorkOrderItem />} />
            <Route path="/edit-production-work-order-item/:id" element={<EditProductionWorkOrderItem />} /> */}

            <Route
              path="/production-work-order"
              element={<ProductionWorkOrderList />}
            />
            <Route
              path="/create-production-work-order"
              element={<CreateProductionWorkOrder />}
            />
            <Route
              path="/show-production-work-order/:id"
              element={<ViewProductionWorkOrder />}
            />
            <Route
              path="/edit-production-work-order/:id"
              element={<EditProductionWorkOrder />}
            />

            <Route path="/planning" element={<PlanningList />} />
            <Route path="/create-planning" element={<CreatePlanning />} />
            <Route
              path="/create-planning-work-order-wise/:id"
              element={<CreatePlanningWorkOrderWise />}
            />
            <Route path="/planning-detail/:id" element={<PlanningView />} />
            <Route path="/edit-planning/:id" element={<PlanningEdit />} />

            <Route path="/production-art" element={<ProductionList />} />
            <Route
              path="/create-production-art"
              element={<CreateProductionArt />}
            />
            <Route
              path="/edit-production-art/:id"
              element={<EditProductionArt />}
            />
            <Route
              path="/view-production-art/:id"
              element={<ViewProductionArt />}
            />

            <Route
              path="/store-purchase-requisition-art"
              element={<StorePurchaseRequisitionList />}
            />
            <Route
              path="/create-store-purchase-requisition-art"
              element={<CreateStorePurchaseRequisitionArt />}
            />
            <Route
              path="/edit-store-purchase-requisition-art/:id"
              element={<EditStorePurchaseRequisitionArt />}
            />
            <Route
              path="/approve-store-purchase-requisition-art/:id"
              element={<ApproveStorePurchaseRequisitionArt />}
            />
            <Route
              path="/view-store-purchase-requisition-art/:id"
              element={<ViewStorePurchaseRequisitionArt />}
            />

            <Route
              path="/production-transfer-art"
              element={<ProductionTransferList />}
            />
            <Route
              path="/create-production-transfer-art"
              element={<CreateProductionTransfer />}
            />
            <Route
              path="/edit-production-transfer-art/:id"
              element={<EditProductionTransfer />}
            />
            <Route
              path="/view-production-transfer-art/:id"
              element={<ViewProductionTransfer />}
            />

            <Route path="/transfer-art" element={<TransferList />} />
            <Route path="/edit-transfer-art/:id" element={<TransferList />} />
            <Route
              path="/add-new-transfer-art/:id"
              element={<TransferCreate />}
            />
            <Route
              path="/add-new-transfer-art"
              element={<CreateWithoutOrderTransfer />}
            />
            <Route
              path="/receive-production-transfer/:id"
              element={<TransferRec />}
            />

            <Route path="/repair-art" element={<RepairList />} />
            <Route path="/defect-art" element={<DefectList />} />
            <Route path="/add-new-repair-art/:id" element={<RepairCreate />} />
            <Route
              path="/add-new-repair-art"
              element={<CreateWithoutOrderRepair />}
            />
            <Route
              path="/receive-production-repair/:id"
              element={<RepairRec />}
            />

            <Route path="/company" element={<Company />} />
            <Route path="/create-company" element={<CreateCompany />} />
            <Route path="/edit-company/:id" element={<EditCompany />} />
            <Route path="/company-details/:id" element={<CompanyView />} />

            {/* Grn */}
            <Route
              path="/create-delivery/:id/req-art"
              element={<CreateDeliveryReq />}
            />
            <Route
              path="/create-delivery-jigcast/req-art"
              element={<CreateDeliveryReqJigCast />}
            />

            <Route
              path="/create-delivery-jigcast-till-selection/req-art"
              element={<CreateJigCastTillSelection />}
            />
            <Route
              path="/create-delivery-jigcast-selection/req-art"
              element={<CreateJigCastSelection />}
            />
            <Route
              path="/create-delivery-jigcast-selection-tr/req-art"
              element={<CreateJigCastSelectionTR />}
            />
            <Route
              path="/create-delivery-jigcast-decoration/req-art"
              element={<CreateJigCastDecoration />}
            />

            <Route
              path="/create-receive-a-s/:id/req-art"
              element={<CreateReceiveReqSelection />}
            />

            <Route
              path="/create-delivery-decoration/:id/req-art"
              element={<CreateDeliveryReqDec />}
            />
            <Route
              path="/create-delivery-selection/:id/req-art"
              element={<CreateDeliveryReqSelection />}
            />
            {/* <Route path="/edit/delivery-req-art/:id" element={<DeliveryReqEdit />} />
            <Route path="/delivery-req-art" element={<DeliveryReq />} />
            <Route path="/delivery-req-art/:id" element={<DeliveryReqView />} /> */}

            <Route
              path="/create-receive/:id/req-art"
              element={<CreateReceiveReq />}
            />

            <Route
              path="/create-return-delivery/:id/req-art"
              element={<CreateDeliveryReq />}
            />
            <Route
              path="/edit-production-jigcast/req-art/:id"
              element={<EditProductionJigCast />}
            />

            <Route
              path="/edit-production-jigcast-till-selection/req-art/:id"
              element={<EditJigCastTillSelection />}
            />
            <Route
              path="/edit-production-jigcast-selection/req-art/:id"
              element={<EditJigCastSelection />}
            />
            <Route
              path="/edit-production-jigcast-selection-tr/req-art/:id"
              element={<EditJigCastSelectionTR />}
            />
            <Route
              path="/edit-production-jigcast-decoration/req-art/:id"
              element={<EditJigCastDecoration />}
            />
            <Route
              path="/edit-production-jigcast-decoration-tr/req-art/:id"
              element={<EditJigCastDecorationTR />}
            />
            <Route
              path="/edit-selection-decoration-repair/req-art/:id"
              element={<EditSelectionDecorationRepair />}
            />
            <Route
              path="/edit-selection-decoration-defect/req-art/:id"
              element={<EditSelectionDecorationDefect />}
            />

            <Route path="/loss-art" element={<LossList />} />
            <Route path="/edit-loss/req-art/:id" element={<EditLoss />} />

            <Route path="/return-art" element={<ReturnList />} />
            <Route path="/create-return-art" element={<CreateReturnArt />} />
            <Route path="/return-art" element={<ReturnList />} />
            <Route path="/edit-return-art/:id" element={<EditReturnArt />} />
            <Route path="/view-return-art/:id" element={<ViewReturnArt />} />

            {/* Artisan */}

            {/* Company Location */}
            <Route path="/companylocation" element={<CompanyLocation />} />
            <Route
              path="/createCompanyLocation"
              element={<CreateCompanyLocation />}
            />
            <Route
              path="/editCompanyLocation/:id"
              element={<EditCompanyLocation />}
            />

            <Route path="/factory" element={<Factory />} />
            <Route path="/factory/create" element={<CreateFactory />} />
            <Route path="/factory/edit/:id" element={<EditFactory />} />
            {/* Company Location */}
            {/* Region */}
            <Route path="/region" element={<Region />} />
            <Route path="/createregion" element={<CreateRegion />} />
            <Route path="/editregion/:id" element={<EditRegion />} />
            {/* Area */}
            <Route path="/area" element={<Area />} />
            <Route path="/createarea" element={<CreateArea />} />
            <Route path="/editarea/:id" element={<EditArea />} />
            {/* Base */}
            <Route path="/base" element={<Base />} />
            <Route path="/createbase" element={<CreateBase />} />
            <Route path="/editbase/:id" element={<EditBase />} />
            {/* Route */}
            <Route path="/route" element={<WorkplaceRoute />} />
            <Route path="/createroute" element={<CreateRoute />} />
            <Route path="/editroute/:id" element={<EditRoute />} />
            {/* Leave Rules */}
            <Route path="/leave-rules" element={<LeaveRules />} />
            <Route path="/create-leave-rules" element={<CreateLeaveRules />} />
            <Route path="/edit-leave-rules/:id" element={<EditLeaveRules />} />
            {/* Staff */}
            <Route path="/staff" element={<Staff />} />
            <Route path="/create-staff" element={<CreateStaff />} />
            <Route path="/create-staff/:id" element={<CreateStaff />} />
            <Route path="/edit-staff/:id" element={<EditStaff />} />
            <Route path="/appointment-letter/:id" element={<Appointment />} />
            <Route path="/idcard/:id" element={<IdCard />} />
            <Route path="/profile/:id" element={<ProfileStaff />} />
            <Route path="/profile-edit" element={<ProfileEdit />} />
            {/* Payroll */}
            {/* Bill Submission */}
            <Route path="/billsubmission" element={<BillSubmission />} />
            <Route
              path="/create-bill-submission"
              element={<CreateBillSubmission />}
            />
            <Route
              path="/edit-bill-submission/:id"
              element={<EditBillSubmission />}
            />
            <Route
              path="/bill-submission-view/:id"
              element={<BillSubmissionView />}
            />
            {/* Movement Slip */}
            <Route path="/movement-slip" element={<MovementSlip />} />
            <Route
              path="/create-movement-slip"
              element={<CreateMovementSlip />}
            />
            <Route
              path="/edit-movement-slip/:id"
              element={<EditMovementSlip />}
            />
            <Route
              path="/movement-slip-view/:id"
              element={<MovementSlipView />}
            />
            {/* Attendance */}
            <Route path="/attendance" element={<Attendance />} />
            <Route path="/create-attendance" element={<CreateAttendance />} />
            <Route path="/edit-attendance/:id" element={<EditAttendance />} />
            <Route path="/import-attendance" element={<ImportAttendance />} />

            <Route path="/notice" element={<Notice />} />
            <Route path="/createnotice" element={<CreateNotice />} />
            <Route path="/editnotice/:id" element={<EditNotice />} />
            {/* Arrear */}
            <Route path="/arrear" element={<Arrear />} />
            <Route path="/create-arrear" element={<CreateArrear />} />
            <Route path="/edit-arrear/:id" element={<EditArrear />} />
            {/* Incentive */}
            <Route path="/incentive" element={<Incentive />} />
            <Route path="/create-incentive" element={<CreateIncentive />} />
            <Route path="/edit-incentive/:id" element={<EditIncentive />} />
            {/* Overtime */}
            <Route path="/overtime" element={<Overtime />} />
            <Route path="/create-overtime" element={<CreateOvertime />} />
            <Route path="/edit-overtime/:id" element={<EditOvertime />} />
            {/* Holiday */}
            <Route path="/holiday" element={<Holiday />} />
            <Route path="/create-holiday" element={<CreateHoliday />} />
            <Route path="/edit-holiday/:id" element={<EditHoliday />} />
            {/* HolidayList */}
            <Route path="/holiday-list" element={<HolidayList />} />
            <Route
              path="/create-holiday-list"
              element={<CreateHolidayList />}
            />
            <Route
              path="/edit-holiday-list/:id"
              element={<EditHolidayList />}
            />
            {/* Roaster HolidayList */}
            <Route
              path="/roaster-holiday-list"
              element={<RoasterHolidayList />}
            />
            <Route
              path="/create-roaster-holiday-list"
              element={<CreateRoasterHolidayList />}
            />
            <Route
              path="/edit-roaster-holiday-list/:id"
              element={<EditRoasterHolidayList />}
            />
            {/* WeeklyOffDay */}
            <Route path="/weekly-off-day" element={<WeeklyOffDay />} />
            <Route
              path="/create-weekly-off-day"
              element={<CreateWeeklyOffDay />}
            />
            <Route
              path="/edit-weekly-off-day/:id"
              element={<EditWeeklyOffDay />}
            />
            <Route path="/setting" element={<Settings />} />
            <Route
              path="/special-late-facility"
              element={<SpecialLateFacility />}
            />
            <Route
              path="/create-special-late-facility"
              element={<CreateSpecialLateFacility />}
            />
            <Route
              path="/edit-special-late-facility/:id"
              element={<EditSpecialLateFacility />}
            />
            {/* NightHold */}
            <Route path="/night-hold" element={<NightHold />} />
            <Route path="/create-night-hold" element={<CreateNightHold />} />
            <Route path="/edit-night-hold/:id" element={<EditNightHold />} />
            {/* tada */}
            <Route path="/tada" element={<Tada />} />
            <Route path="/create-tada" element={<CreateTada />} />
            <Route path="/edit-tada/:id" element={<EditTada />} />
            {/* SalaryAdvance */}
            <Route path="/salary-advance" element={<SalaryAdvance />} />
            <Route
              path="/create-salary-advance"
              element={<CreateSalaryAdvance />}
            />
            <Route
              path="/edit-salary-advance/:id"
              element={<EditSalaryAdvance />}
            />
            {/* Canteen */}
            <Route path="/canteen" element={<Canteen />} />
            <Route path="/create-canteen" element={<CreateCanteen />} />
            <Route path="/edit-canteen/:id" element={<EditCanteen />} />
            {/* PhoneBill */}
            <Route path="/phonebill" element={<PhoneBill />} />
            <Route path="/create-phonebill" element={<CreatePhoneBill />} />
            <Route path="/edit-phonebill/:id" element={<EditPhoneBill />} />
            {/* IncomeTax */}
            <Route path="/incometax" element={<IncomeTax />} />
            <Route path="/create-incometax" element={<CreateIncomeTax />} />
            <Route path="/edit-incometax/:id" element={<EditIncomeTax />} />
            {/* Deduction */}
            <Route path="/deduction" element={<Deduction />} />
            <Route path="/create-deduction" element={<CreateDeduction />} />
            <Route path="/edit-deduction/:id" element={<EditDeduction />} />
            {/* Bonous */}
            <Route path="/bonous" element={<Bonous />} />
            <Route path="/create-bonous" element={<CreateBonous />} />
            <Route path="/edit-bonous/:id" element={<EditBonous />} />
            {/* Apa */}
            <Route path="/Apa" element={<Apa />} />
            <Route path="/create-Apa" element={<CreateApa />} />
            <Route path="/edit-Apa/:id" element={<EditApa />} />
            {/* LeaveApplication */}
            <Route path="/leave-application" element={<LeaveApplication />} />
            <Route
              path="/create-leave-application"
              element={<CreateLeaveApplication />}
            />
            <Route
              path="/edit-leave-application/:id"
              element={<EditLeaveApplication />}
            />

            <Route
              path="/leave-application-view/:id"
              element={<LeaveApplicationView />}
            />
            {/* LeaveDeduction */}
            <Route path="/leave-deduction" element={<LeaveDeduction />} />
            <Route
              path="/create-leave-deduction"
              element={<CreateLeaveDeduction />}
            />
            <Route
              path="/edit-leave-deduction/:id"
              element={<EditLeaveDeduction />}
            />
            {/* Requisition */}
            <Route path="/requisition" element={<Requisition />} />
            <Route path="/create-requisition" element={<CreateRequisition />} />
            <Route path="/edit-requisition/:id" element={<EditRequisition />} />
            {/* Candidate */}
            <Route path="/candidate" element={<Candidate />} />
            <Route path="/create-candidate" element={<CreateCandidate />} />
            <Route path="/edit-candidate/:id" element={<EditCandidate />} />
            {/* Salary */}
            <Route path="/salary" element={<Salary />} />
            <Route path="/create-salary" element={<CreateSalary />} />
            <Route path="/pay-slip/:id" element={<PaySlip />} />
            {/* Accounts */}
            <Route path="/budget-plan" element={<BudgetPlan />} />
            <Route path="/create-budget-plan" element={<CreateBudgetPlan />} />
            <Route path="/edit-budget-plan/:id" element={<EditBudgetPlan />} />
            <Route path="/show-budget-plan/:id" element={<ShowBudgetPlan />} />

            <Route
              path="/tree-chart-of-account"
              element={<TreeChartOfAccount />}
            />
            <Route path="/chart-of-account" element={<ChartOfAccount />} />
            <Route
              path="/create-chart-of-account"
              element={<CreateChartOfAccount />}
            />
            <Route
              path="/edit-chart-of-account/:id"
              element={<EditChartOfAccount />}
            />

            <Route path="/journal-code" element={<JournalCode />} />
            <Route
              path="/create-journal-code"
              element={<CreateJournalCode />}
            />
            <Route
              path="/edit-journal-code/:id"
              element={<EditJournalCode />}
            />
            <Route path="/sub-category" element={<SubCategory />} />
            <Route
              path="/create-sub-category"
              element={<CreateSubCategory />}
            />
            <Route
              path="/edit-sub-category/:id"
              element={<EditSubCategory />}
            />
            <Route path="/journal-voucher" element={<JournalVoucher />} />
            <Route
              path="/create-journal-voucher"
              element={<CreateJournalVoucher />}
            />
            <Route
              path="/edit-journal-voucher/:id"
              element={<EditJournalVoucher />}
            />
            <Route path="/cash-count" element={<CashCount />} />
            <Route path="/create-cash-count" element={<CreateCashCount />} />
            <Route path="/edit-cash-count/:id" element={<EditCashCount />} />
            <Route path="/auto-journal-setup" element={<AutoJournalSetup />} />
            <Route path="/create-setup" element={<CreateSetup />} />

            <Route
              path="/transactions-list-connect"
              element={<TransactionsListConnect />}
            />
            <Route
              path="/edit-transactions-list-connect/:id"
              element={<EditTransactionsListConnect />}
            />

            {/* Supply Chain */}
            <Route path="/vendors" element={<Vendors />} />
            <Route path="/create-vendor" element={<CreateVendor />} />
            <Route path="/edit-vendor/:id" element={<EditVendor />} />
            <Route path="/vendor-details/:id" element={<ViewVendor />} />

            <Route path="/unit" element={<Unit />} />
            <Route path="/create-unit" element={<CreateUnit />} />
            <Route path="/edit-unit/:id" element={<EditUnit />} />

            <Route path="/product-category" element={<ProductCategory />} />
            <Route
              path="/create-product-category"
              element={<CreateProductCategory />}
            />
            <Route
              path="/edit-product-category/:id"
              element={<EditProductCategory />}
            />

            <Route path="/product" element={<Product />} />
            <Route path="/create-product" element={<CreateProduct />} />
            <Route path="/edit-product/:id" element={<EditProduct />} />

            <Route path="/direct-purchase" element={<DirectPurchase />} />
            <Route
              path="/create-direct-purchase"
              element={<CreateDirectPurchase />}
            />
            <Route
              path="/edit-direct-purchase/:id"
              element={<EditDirectPurchase />}
            />
            <Route
              path="/add-cost-direct-purchase/:id"
              element={<AddCostDirectPurchase />}
            />
            <Route
              path="/show-direct-purchase/:id"
              element={<ShowDirectPurchase />}
            />
            <Route
              path="/create-direct-purchase-by-requisition/:id"
              element={<CreatePurchaseByRequisition />}
            />

            <Route path="/circular-rfq" element={<CircularRfq />} />
            <Route
              path="/create-circular-rfq"
              element={<CreateCircularRfq />}
            />
            <Route
              path="/edit-circular-rfq/:id"
              element={<EditCircularRfq />}
            />
            <Route
              path="/show-circular-rfq/:id"
              element={<ShowCircularRfq />}
            />

            <Route
              path="/vendor-quotation-list/:id"
              element={<VendorQuotationList />}
            />

            <Route
              path="/show-vendor-quotation/:id"
              element={<ShowVendorQuotation />}
            />

            <Route path="/circular-compare/:id" element={<CircularCompare />} />

            <Route
              path="/vendor-quotation-sent/:id"
              element={<VendorQuotationSent />}
            />
            <Route path="/final-quotation" element={<FinalQuotation />} />
            <Route
              path="/final-quotation-view/:id"
              element={<FinalQuotationView />}
            />

            <Route path="/purchase-order" element={<PurchaseOrder />} />
            <Route
              path="/purchase-order-view/:id"
              element={<PurchaseOrderView />}
            />

            {/* Others Receive */}
            <Route path="/others-receive" element={<OthersReceive />} />
            <Route
              path="/create-others-receive"
              element={<CreateothersReceive />}
            />
            <Route
              path="/edit-others-receive/:id"
              element={<EditothersReceive />}
            />
            <Route path="/others-receive/:id" element={<OthersReceiveView />} />

            {/* Grn */}
            <Route path="/create-grn/:id/:type" element={<CreateGrn />} />
            <Route path="/grn" element={<Grn />} />
            <Route path="/grn/:id" element={<GrnView />} />
            <Route path="/edit/grn/:id" element={<GrnEdit />} />

            {/* Purchase order Return */}
            <Route
              path="/purchase-order-return"
              element={<PurchaseOrderReturn />}
            />
            <Route
              path="/create-purchase-order-return"
              element={<PurchaseOrderReturnCreate />}
            />
            <Route
              path="/edit/purchase-order-return/:id"
              element={<PurchaseOrderReturnEdit />}
            />
            <Route
              path="/purchase-order-return-view/:id"
              element={<PurchaseOrderReturnView />}
            />

            {/* Assets */}
            <Route path="/asset-category" element={<AssetCategory />} />
            <Route
              path="/create-asset-category"
              element={<CreateAssetCategory />}
            />
            <Route
              path="/edit-asset-category/:id"
              element={<EditAssetCategory />}
            />

            <Route path="/asset" element={<Asset />} />
            <Route path="/create-asset" element={<CreateAsset />} />
            <Route path="/edit-asset/:id" element={<EditAsset />} />

            <Route
              path="/asset-direct-purchase"
              element={<AssetDirectPurchase />}
            />
            <Route
              path="/create-asset-direct-purchase"
              element={<CreateAssetDirectPurchase />}
            />
            <Route
              path="/edit-asset-direct-purchase/:id"
              element={<EditAssetDirectPurchase />}
            />

            <Route path="/asset-assign" element={<AssetAssign />} />
            <Route
              path="/create-asset-assign"
              element={<CreateAssetAssign />}
            />
            <Route
              path="/edit-asset-assign/:id"
              element={<EditAssetAssign />}
            />
            <Route
              path="/return-asset-assign/:id"
              element={<ReturnAssetAssign />}
            />
            <Route
              path="/damage-asset-claim/:id"
              element={<DamageAssetClaim />}
            />

            <Route
              path="/asset-maintenence-claim"
              element={<AssetMaintenenceClaim />}
            />
            <Route
              path="/create-asset-maintenence-claim"
              element={<CreateAssetMaintenenceClaim />}
            />
            <Route
              path="/edit-asset-maintenence-claim/:id"
              element={<EditAssetMaintenenceClaim />}
            />

            <Route path="/asset-damage-claim" element={<AssetDamageClaim />} />
            <Route
              path="/create-asset-damage-claim"
              element={<CreateAssetDamageClaim />}
            />
            <Route
              path="/edit-asset-damage-claim/:id"
              element={<EditAssetDamageClaim />}
            />

            <Route path="/asset-circular-rfq" element={<AssetCircularRfq />} />
            <Route
              path="/asset-vendor-quotation-list/:id"
              element={<VendorAssetQuotationList />}
            />
            <Route
              path="/create-asset-circular-rfq"
              element={<CreateAssetCircularRfq />}
            />
            <Route
              path="/edit-asset-circular-rfq/:id"
              element={<EditAssetCircularRfq />}
            />
            <Route
              path="/vendor-asset-quotation-sent/:id"
              element={<VendorAssetQuotationSent />}
            />

            <Route
              path="/asset-circular-compare/:id"
              element={<AssetCircularCompare />}
            />

            <Route
              path="/asset-final-quotation"
              element={<AssetFinalQuotation />}
            />
            <Route
              path="/asset-final-quotation-view/:id"
              element={<AssetFinalQuotationView />}
            />

            <Route
              path="/asset-purchase-order"
              element={<AssetPurchaseOrder />}
            />
            <Route
              path="/asset-purchase-order-view/:id"
              element={<AssetPurchaseOrderView />}
            />

            <Route path="/asset-create-grn/:id" element={<AssetCreateGrn />} />
            <Route path="/asset-grn" element={<AssetGrn />} />
            <Route path="/asset-grn/:id" element={<AssetGrnView />} />
            <Route path="/asset-edit/grn/:id" element={<AssetGrnEdit />} />

            <Route
              path="/asset-purchase-order-return"
              element={<AssetPurchaseOrderReturn />}
            />
            <Route
              path="/create-asset-purchase-order-return"
              element={<AssetPurchaseOrderReturnCreate />}
            />
            <Route
              path="/edit/asset-purchase-order-return/:id"
              element={<AssetPurchaseOrderReturnEdit />}
            />
            <Route
              path="/asset-purchase-order-return-view/:id"
              element={<AssetPurchaseOrderReturnView />}
            />

            {/* Production */}
            <Route path="/receipe" element={<Receipe />} />
            <Route path="/create-receipe" element={<CreateReceipe />} />
            <Route path="/edit-receipe/:id" element={<EditReceipe />} />
            <Route path="/view-receipe/:id" element={<ViewReceipe />} />

            <Route path="/receipe-factory" element={<ReceipeFactory />} />
            <Route
              path="/create-receipe-factory"
              element={<CreateReceipeFactory />}
            />
            <Route
              path="/edit-receipe-factory/:id"
              element={<EditReceipeFactory />}
            />

            <Route path="/machineries" element={<Machineries />} />
            <Route path="/create-machineries" element={<CreateMachineries />} />
            <Route path="/edit-machineries/:id" element={<EditMachineries />} />

            <Route
              path="/factory-machineries"
              element={<FactoryMachineries />}
            />
            <Route
              path="/create-factory-machineries"
              element={<CreateFactoryMachineries />}
            />
            <Route
              path="/edit-factory-machineries/:id"
              element={<EditFactoryMachineries />}
            />

            <Route
              path="/requisition-production"
              element={<RequisitionProduction />}
            />
            <Route
              path="/create-requisition-production"
              element={<CreateRequisitionProduction />}
            />
            <Route
              path="/create-requisition-production/:id"
              element={<CreateRequisitionProductionByDepartment />}
            />
            <Route
              path="/create-department-requisition-jcg"
              element={<CreateRequisitionJigCastGK />}
            />
            <Route
              path="/show-department-requisition-jcg/:id"
              element={<ShowRequisitionJigCastGK />}
            />
            <Route
              path="/edit-department-requisition-jcg/:id"
              element={<EditRequisitionJigCastGK />}
            />
            <Route
              path="/department-requisition-jcg"
              element={<RequisitionJigCastGK />}
            />
            <Route
              path="/edit-requisition-production/:id"
              element={<EditRequisitionProduction />}
            />
            <Route
              path="/requisition-production-view/:id"
              element={<ViewRequisitionProduction />}
            />

            <Route path="/issue" element={<Issue />} />
            <Route path="/issue-view/:id" element={<ViewIssue />} />

            <Route path="/create-issue/:id" element={<CreateIssue />} />
            {/* <Route
              path="/edit-issue/:requisition_production_id/:id"
              element={<EditIssue />}
            /> */}
            <Route path="/edit-issue/:id" element={<EditIssue />} />
            <Route path="/rmpm-receive/:id" element={<RmPmReceive />} />
            <Route
              path="/production-generate"
              element={<ProductionGenerate />}
            />
            <Route
              path="/production-generate-body"
              element={<ProductionGenerateOfBody />}
            />
            <Route
              path="/edit-ball-charge-generate/:id"
              element={<EditProductionGenerate />}
            />
            <Route
              path="/production-generate-requisition-department-wise"
              element={<ProductionGenerateRequisitionDepartmentWise />}
            />
            <Route
              path="/production-generate/:id"
              element={<ProductionGenerateRequisition />}
            />
            <Route
              path="/production-receive/:id"
              element={<ProductionReceive />}
            />

            <Route path="/production" element={<Production />} />
            <Route path="/ball-mill-charge" element={<BallMillCHargeList />} />

            <Route path="/depo-transfer" element={<DepoTransfer />} />

            <Route
              path="/show-transfer/:id"
              element={<FactoryTransferShow />}
            />
            <Route path="/factory-transfer" element={<FactoryTransfer />} />
            <Route
              path="/create-factory-transfer"
              element={<CreateFactoryTransfer />}
            />
            <Route
              path="/create-department-transfer/:id"
              element={<CreateDepartmentTransfer />}
            />
            <Route
              path="/edit-department-transfer/:id"
              element={<EditDepartmentTransfer />}
            />
            <Route
              path="/edit-factory-transfer/:id"
              element={<EditFactoryTransfer />}
            />

            <Route path="/show-return/:id" element={<FactoryReturnShow />} />
            <Route path="/factory-return" element={<FactoryReturn />} />
            <Route
              path="/create-factory-return"
              element={<CreateFactoryReturn />}
            />
            <Route
              path="/edit-factory-return/:id"
              element={<EditFactoryReturn />}
            />

            <Route path="/damage-return" element={<DamageReturn />} />
            <Route
              path="/create-damage-return"
              element={<CreateDamageReturn />}
            />
            <Route
              path="/edit-damage-return/:id"
              element={<EditDamageReturn />}
            />

            {/* Assets */}
            <Route path="/asset-category" element={<AssetCategory />} />
            <Route
              path="/create-asset-category"
              element={<CreateAssetCategory />}
            />
            <Route
              path="/edit-asset-category/:id"
              element={<EditAssetCategory />}
            />

            <Route path="/asset" element={<Asset />} />
            <Route path="/create-asset" element={<CreateAsset />} />
            <Route path="/edit-asset/:id" element={<EditAsset />} />

            <Route path="/asset-circular-rfq" element={<AssetCircularRfq />} />
            <Route
              path="/create-asset-circular-rfq"
              element={<CreateAssetCircularRfq />}
            />
            <Route
              path="/edit-asset-circular-rfq/:id"
              element={<EditAssetCircularRfq />}
            />

            {/* Sales */}
            <Route path="/primary-sales" element={<PrimarySales />} />
            <Route
              path="/create-primary-sales"
              element={<CreatePrimarySales />}
            />
            <Route
              path="/edit-primary-sales/:id"
              element={<EditPrimarySales />}
            />
            <Route path="/payment-receive/:id" element={<PaymentReceive />} />
            <Route
              path="/payment-receive-coa/:id"
              element={<PaymentReceiveCoa />}
            />
            <Route path="/primary-sales-detail/:id" element={<OrderDetail />} />
            <Route path="/delivery-order/:id" element={<DeliveryOrder />} />

            <Route path="/mushok-6-3/:id" element={<Mushok63 />} />
            <Route path="/chalan/:id" element={<Chalan />} />

            <Route path="/distributors" element={<Distributors />} />
            <Route
              path="/create-distributors"
              element={<CreateDistributors />}
            />
            <Route
              path="/edit-distributors/:id"
              element={<EditDistributors />}
            />
            <Route
              path="/distributor-details/:id"
              element={<DistributorsView />}
            />

            <Route path="/trade-offer" element={<TradeOffer />} />
            <Route path="/create-trade-offer" element={<CreateTradeOffer />} />
            <Route path="/edit-trade-offer/:id" element={<EditTradeOffer />} />

            {/* Secondary Sales */}
            <Route path="/monthly-target" element={<MonthlyTarget />} />
            <Route
              path="/monthly-target-view"
              element={<MonthlyTargetView />}
            />
            <Route
              path="/daily-report"
              element={<MonthlyTargetDailyReport />}
            />
            <Route path="/summary-report" element={<SummaryReport />} />
            <Route
              path="/monthly-target-view-region-wise/:targetId/:id"
              element={<MonthlyTargetViewRegionWise />}
            />
            <Route
              path="/monthly-target-view-base-wise-daily/:targetId/:regionId/:baseId"
              element={<MonthlyTargetViewBaseWiseDaily />}
            />
            <Route path="/create-target" element={<CreateMonthlyTarget />} />
            <Route path="/edit-target" element={<EditMonthlyTarget />} />
            <Route
              path="/edit-monthly-target/:id"
              element={<EditMonthlyTarget />}
            />

            <Route path="/opening-stock" element={<OpeningStock />} />
            <Route path="/opening-stock-view" element={<OpeningStockView />} />
            <Route
              path="/create-opening-stock"
              element={<CreateOpeningStock />}
            />
            <Route path="/edit-opening-stock" element={<EditOpeningStock />} />
            <Route
              path="/edit-opening-stock/:id"
              element={<EditOpeningStock />}
            />

            {/* Reports */}
            <Route path="/reports" element={<Reports />} />
            <Route
              path="/hr-salary-structure"
              element={<HrSalaryStructure />}
            />
            <Route path="/hr-leave-report" element={<HrLeaveReport />} />
            <Route
              path="/monthly-salary-report"
              element={<MonthlySalaryReport />}
            />
            <Route path="/attendance-report" element={<AttendanceReport />} />
            <Route
              path="/salary-payment-report"
              element={<SalaryPaymentReport />}
            />
            <Route path="/apa-report" element={<ApaReport />} />
            <Route
              path="/chart-of-account-ledger-report"
              element={<ChartOfAccountLedgerReport />}
            />
            <Route
              path="/sub-category-list"
              element={<SubcategoryListReport />}
            />
            <Route
              path="/subcategory-ledger-report/:id"
              element={<SubcategoryLedgerReport />}
            />
            <Route
              path="/vendor-supplier-payable-report"
              element={<VendorSupplierPayableReport />}
            />
            <Route
              path="/distributor-payable-report"
              element={<DistributorPayableReport />}
            />
            <Route
              path="/receipt-payment-summary-report"
              element={<ReceiptPaymentSummaryReport />}
            />
            <Route
              path="/vendor-supplier-receivable-report"
              element={<VendorSupplierReceivableReport />}
            />
            <Route
              path="/distributor-receivable-report"
              element={<DistributorReceivableReport />}
            />
            <Route path="/trial-balance" element={<TrialBalance />} />

            {/* Depot Report */}
            <Route
              path="/report/depo-wise-product-stock"
              element={<DepoWiseProductStock />}
            />
            <Route
              path="/report/depo-wise-raw-material-stock"
              element={<RawMaterialStock />}
            />
            <Route
              path="/report/depo-wise-packing-material-stock"
              element={<PackingMaterialStock />}
            />
            <Route path="/report/product-stock" element={<ProductStock />} />
            <Route path="/report/distributor" element={<Distributor />} />

            {/* Plinning Report */}
            <Route path="/planning-reports" element={<PlanningReport />} />
            <Route path="/grn-reports" element={<GRNReport />} />

            {/* Body Report */}
            <Route
              path="/body-daily-production-report"
              element={<BodyDailyProductionReport />}
            />
            <Route
              path="/body-raw-materials-consumption-report"
              element={<BodyRawMaterialsConsumptionReport />}
            />

            {/* Jigger Report */}
            <Route
              path="/jigger-daily-production-report"
              element={<JiggerDailyProductionReport />}
            />
            <Route
              path="/jigger-shift-wise-daily-production-report"
              element={<JiggerShiftWiseDailyProductionReport />}
            />

            {/* Casting Report */}
            <Route
              path="/casting-daily-production-report"
              element={<CastingDailyProductionReport />}
            />
            <Route
              path="/casting-export-schedule-report"
              element={<CastingExportScheduleReport />}
            />

            {/* Finishing Report */}
            <Route
              path="/finishing-daily-production-report"
              element={<FinishingDailyProductionReport />}
            />

            {/* BKL Report */}
            <Route
              path="/bkl-daily-production-report"
              element={<BklDailyProductionReport />}
            />

            {/* Glazing Report */}
            <Route
              path="/glazing-daily-production-report"
              element={<GlazingDailyProductionReport />}
            />
            <Route path="/artisan-stock" element={<ArtisanStock />} />
            <Route path="/artisan-stock-department-wise" element={<DeptStockART />} />

            {/* GK Report */}
            <Route
              path="/gk-daily-production-report"
              element={<GKDailyProductionReport />}
            />
          </Routes>
        </div>
      </BrowserRouter>
    </PrimeReactProvider>
  </GlobalProvider>
  // </React.StrictMode>
);
reportWebVitals();
