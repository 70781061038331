import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import IsLogin from "../../../Common/IsLogin";
import { LoaderComponent } from "../../../Common/LoaderComponent";

const Create = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [inputs, setInputs] = useState({
    receipt_no: "R-1001",
    production_work_order_id: "WO-2001",
    date: "2024-11-20",
    receive_date: "2024-11-22",
    copy_type: "",
    purchase_type: "",
    from_department: "GLAZING",
    to_department: "PACKING LOCAL",
    status: "delivered",
    remarks: "Sample remarks for testing",
  });

  const [list, setList] = useState([]);
  const [error, setError] = useState({});
  const [role, setRole] = useState("admin");

  const defectTypes = ["LS", "SS", "Decels", "Others"];
  const lossTypes = ["C", "D", "Rejected"];

  // Dummy data for the items list
  useEffect(() => {
    const dummyData = [
      {
        id: 1,
        product_name: "31cm Cake Plate",
        shape: "art-2",
        code: "CP-31",
        decoration: "Matte Finish",
        delivered_a_class_qty: 100,
        delivered_b_class_qty: 50,
        delivered_c_class_qty: 30,
        delivered_export_qty: 200,
        defects: [],
        repairs: [],
        receive_qty: 0, // Tracks the quantity received
        production_work_order: "WO-1001",
        company: "Limerick Resources LTD.",
      },
      {
        id: 2,
        product_name: "27cm Dinner Plate",
        shape: "art-25",
        code: "DP-27",
        decoration: "Glossy Finish",
        delivered_a_class_qty: 150,
        delivered_b_class_qty: 80,
        delivered_c_class_qty: 40,
        delivered_export_qty: 250,
        defects: [],
        repairs: [],
        receive_qty: 0,
        production_work_order: "WO-1001",
        company: "Limerick Resources LTD.",
      },
      {
        id: 3,
        product_name: "Saucer",
        shape: "art-55",
        code: "SC-10",
        decoration: "Embossed Design",
        delivered_a_class_qty: 120,
        delivered_b_class_qty: 60,
        delivered_c_class_qty: 20,
        delivered_export_qty: 300,
        defects: [],
        repairs: [],
        receive_qty: 0,
        production_work_order: "WO-1002",
        company: "Limerick Resources LTD.",
      },
      {
        id: 4,
        product_name: "Tea Cup",
        shape: "art-5",
        code: "T-10",
        decoration: "Embossed Design",
        delivered_a_class_qty: 22,
        delivered_b_class_qty: 60,
        delivered_c_class_qty: 2,
        delivered_export_qty: 360,
        defects: [],
        repairs: [],
        receive_qty: 0,
        production_work_order: "WO-1002",
        company: "Thun",
      },
      {
        id: 5,
        product_name: "Cake Plate",
        shape: "art-7",
        code: "SC-10",
        decoration: "Embossed Design",
        delivered_a_class_qty: 33,
        delivered_b_class_qty: 20,
        delivered_c_class_qty: 20,
        delivered_export_qty: 336,
        defects: [],
        repairs: [],
        receive_qty: 0,
        production_work_order: "WO-1002",
        company: "Thun",
      },
    ];
    setList(dummyData);
  }, []);

  const handleReceive = (index) => {
    const updatedList = [...list];
    updatedList[index].receive_qty = updatedList[index].delivered_export_qty;
    setList(updatedList);

    alert(
      `Received ${updatedList[index].receive_qty} items for ${updatedList[index].product_name}`
    );
  };

  const handleAddType = (index, type) => {
    const updatedList = [...list];
    const newEntry = { type: "", qty: 0 };

    if (type === "defect") {
      updatedList[index].defects = [
        ...(updatedList[index].defects || []),
        newEntry,
      ];
    } else if (type === "repair") {
      updatedList[index].repairs = [
        ...(updatedList[index].repairs || []),
        newEntry,
      ];
    }

    setList(updatedList);
  };

  const handleTypeChange = (index, subIndex, value, type) => {
    const updatedList = [...list];
    if (type === "defect") {
      updatedList[index].defects[subIndex].type = value;
    } else if (type === "repair") {
      updatedList[index].repairs[subIndex].type = value;
    }
    setList(updatedList);
  };

  const handleQtyChange = (index, subIndex, value, type) => {
    const updatedList = [...list];
    const quantity = parseInt(value) || 0;
    if (type === "defect") {
      updatedList[index].defects[subIndex].qty = quantity;
    } else if (type === "repair") {
      updatedList[index].repairs[subIndex].qty = quantity;
    }
    setList(updatedList);
  };

  const handleRemoveType = (index, subIndex, type) => {
    const updatedList = [...list];
    if (type === "defect") {
      updatedList[index].defects.splice(subIndex, 1);
    } else if (type === "repair") {
      updatedList[index].repairs.splice(subIndex, 1);
    }
    setList(updatedList);
  };

  const formSubmit = () => {
    const formData = {
      ...inputs,
      items: list,
    };

    // Simulate form submission
    console.log("Form Data Submitted:", formData);
    alert("Form Submitted Successfully!");
    navigate("/requisition-art", {
      state: { message: "Data submitted successfully.", type: "success" },
    });
  };

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Deliver</h5>
        </div>
        <div className="am-pagebody   row d-flex justify-content-evenly">
          <div className="card pd-20 pd-sm-40 col-11">
            <h6 className="card-body-title mg-sm-b-30">Deliver</h6>
            <div className="row">
              <div className="col-lg">
                <label>Receipt No</label>
                <input
                  type="text"
                  className="form-control"
                  name="receipt_no"
                  value={inputs.receipt_no}
                  disabled
                />
              </div>
              <div className="col-lg">
                <label>Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  value={inputs.date}
                  disabled
                />
              </div>
              <div className="col-lg">
                <label>Receive Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="receive_date"
                  value={inputs.receive_date}
                  disabled
                />
              </div>
              <div className="col-lg">
                <label>From Department</label>
                <input
                  type="text"
                  className="form-control"
                  name="from_department"
                  value={inputs.from_department}
                  disabled
                />
              </div>
              <div className="col-lg">
                <label>Remarks</label>
                <textarea
                  className="form-control"
                  name="remarks"
                  value={inputs.remarks}
                  disabled
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg">
                <table className="table table-sm">
                  <thead className="text-center">
                    <tr>
                      <th>Product Name</th>
                      {/* <th>Shape</th>
                      <th>Code</th> */}
                      {/* {(role !== "selection" || role === "admin") && (
                        <th>Decoration</th>
                      )} */}
                      <th>Selected Exp</th>
                      <th>Deliver Exp</th>
                      <th>Selected A</th>
                      <th>Deliver A</th>
                      <th>Selected B</th>
                      <th>Deliver B</th>
                      <th>Selected C</th>
                      <th>Deliver C</th>
                      {/* <th>Deliver</th> */}
                      {/* <th>Loss Qty</th>
                      <th>Defects Qty</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {item.product_name} ({item.shape}) || {item.company} #
                          {item.production_work_order}
                        </td>
                        {/* {(role !== "selection" || role === "admin") && (
                          <td>{item.decoration}</td>
                        )} */}

                        <td>{item.delivered_export_qty}</td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="shape"
                            value={0}
                            // disabled
                          />
                        </td>
                        <td>{item.delivered_a_class_qty}</td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="shape"
                            value={0}
                            // disabled
                          />
                        </td>
                        <td>{item.delivered_b_class_qty}</td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="shape"
                            value={0}
                            // disabled
                          />
                        </td>
                        <td>{item.delivered_c_class_qty}</td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="shape"
                            value={0}
                            // disabled
                          />
                        </td>
                        {/* <td>
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => handleReceive(index)}
                          >
                            Deliver
                          </button>
                        </td> */}
                        {/* Defects column */}
                        {/* <td>
                          {(item.defects || []).map((defect, subIndex) => (
                            <div
                              key={subIndex}
                              className="d-flex align-items-center mb-2"
                            >
                              <select
                                className="form-control mr-2"
                                value={defect.type}
                                onChange={(e) =>
                                  handleTypeChange(
                                    index,
                                    subIndex,
                                    e.target.value,
                                    "defect"
                                  )
                                }
                              >
                                <option value="">Select Defect Type</option>
                                {defectTypes.map((type) => (
                                  <option key={type} value={type}>
                                    {type}
                                  </option>
                                ))}
                              </select>
                              <input
                                type="number"
                                className="form-control mr-2"
                                value={defect.qty}
                                onChange={(e) =>
                                  handleQtyChange(
                                    index,
                                    subIndex,
                                    e.target.value,
                                    "defect"
                                  )
                                }
                                placeholder="Qty"
                              />
                              <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={() =>
                                  handleRemoveType(index, subIndex, "defect")
                                }
                              >
                                Remove
                              </button>
                            </div>
                          ))}
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={() => handleAddType(index, "defect")}
                          >
                            Add Defect
                          </button>
                        </td> */}

                        {/* Repairs column */}
                        {/* <td>
                          {(item.repairs || []).map((repair, subIndex) => (
                            <div
                              key={subIndex}
                              className="d-flex align-items-center mb-2"
                            >
                              <select
                                className="form-control mr-2"
                                value={repair.type}
                                onChange={(e) =>
                                  handleTypeChange(
                                    index,
                                    subIndex,
                                    e.target.value,
                                    "repair"
                                  )
                                }
                              >
                                <option value="">Select Repair Type</option>
                                {lossTypes.map((type) => (
                                  <option key={type} value={type}>
                                    {type}
                                  </option>
                                ))}
                              </select>
                              <input
                                type="number"
                                className="form-control mr-2"
                                value={repair.qty}
                                onChange={(e) =>
                                  handleQtyChange(
                                    index,
                                    subIndex,
                                    e.target.value,
                                    "repair"
                                  )
                                }
                                placeholder="Qty"
                              />
                              <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={() =>
                                  handleRemoveType(index, subIndex, "repair")
                                }
                              >
                                Remove
                              </button>
                            </div>
                          ))}
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={() => handleAddType(index, "repair")}
                          >
                            Add Repair
                          </button>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-3">
                <button className="btn btn-primary" onClick={formSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create;
