import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import { LoaderComponent } from "../../../Common/LoaderComponent";

const Create = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  // const [inputs, setInputs] = useState({});

  const [list, setList] = useState([]);
  const [receiveData, setReceiveData] = useState({
    items: [],
    receipt_no: "",
    transfer_date: "",
    receive_date: "",
    remarks: "",
    from_department_art: { name: "" },
  });
  const [error, setError] = useState({});
  const [role, setRole] = useState("");

  // const [shapes, setShapes] = useState([]); // Store shapes from API
  // const [shapeTypes, setShapeTypes] = useState([]); // Store shape types from API
  // const [units, setUnits] = useState([]); // Store units from API
  // const [departments, setDepartments] = useState([
  //   { id: 1, name: "BODY", user_type: "body" },
  //   { id: 2, name: "JIGGER", user_type: "jigger" },
  //   { id: 3, name: "CASTING", user_type: "casting" },
  //   { id: 4, name: "FINISHING", user_type: "finishing" },
  //   { id: 5, name: "BK", user_type: "bk" },
  //   { id: 6, name: "GLAZING", user_type: "glazing" },
  //   { id: 7, name: "GK", user_type: "gk" },
  //   { id: 8, name: "SELECTION", user_type: "selection" },
  //   { id: 9, name: "DECORATION LOCAL", user_type: "decoration_local" },
  //   { id: 10, name: "DECORATION EXPORT", user_type: "decoration_export" },
  //   { id: 11, name: "DFW", user_type: "dfw" },
  //   { id: 12, name: "PACKING LOCAL", user_type: "packing_local" },
  //   { id: 13, name: "PACKING EXPORT", user_type: "packing_export" },
  //   { id: 14, name: "DSTORE", user_type: "dstore" },
  //   { id: 15, name: "GSTORE", user_type: "gstore" },
  //   { id: 16, name: "MOULD", user_type: "mould" },
  //   { id: 17, name: "PSTORE", user_type: "pstore" },
  //   { id: 18, name: "PLANNING", user_type: "planning" },
  //   { id: 19, name: "SAGGER", user_type: "sagger" },
  // ]);

  const fetchData = () => {
    Http.get("/staff-type-check").then((res) => {
      setRole(res.data);
    });
    // Http.get("/production-transfer/" + id).then((res) => {
    //   setReceiveData(res.data);
    // });

    // Dummy data for production transfer
    const dummyData = {
      receipt_no: "RCPT-12345",
      transfer_date: "2024-11-20",
      receive_date: "2024-11-21",
      remarks: "Test remarks for production transfer.",
      from_department_art: { name: "Body Department" },
      items: [
        {
          product: { name: "31cm cake plate" },
          shape: { name: "art-2" },
          delivery_qty: 100,
        },
        {
          product: { name: "27cm Dinner plate" },
          shape: { name: "art-25" },
          delivery_qty: 200,
        },
        {
          product: { name: "Saucer" },
          shape: { name: "art-55" },
          delivery_qty: 300,
        },
      ],
    };

    // Set the dummy data to state
    setReceiveData(dummyData);
    // Http.get("/production-transfer/" + id)
    //   .then((res) => {
    //     const requisitionData = res.data;

    //     setInputs({
    //       receipt_no: requisitionData.receipt_no || "",
    //       production_work_order_id:
    //         requisitionData.production_work_order_id || "",
    //       date: requisitionData.date || "",
    //       receive_date: requisitionData.receive_date || "",
    //       copy_type: requisitionData.copy_type || "",
    //       purchase_type: requisitionData.purchase_type || "",
    //       from_department: requisitionData.from_department || "",
    //       to_department: requisitionData.to_department || "",
    //       status: requisitionData.status || "delivered",
    //       remarks: requisitionData.remarks || "",
    //     });

    //     const itemsList = requisitionData.items.map((item) => {
    //       return {
    //         id: item.id,
    //         product_id: item.product_id,
    //         product_name: item.product.name || "",
    //         unit_id: item.unit_id || "",
    //         shape_id: item.shape_id || "",
    //         shape_type_id: item.shape_type_id || "",
    //         in_hand_qty: item.in_hand_qty || 0,
    //         in_pipeline_qty: item.in_pipeline_qty || 0,
    //         on_order_qty: item.on_order_qty || 0,
    //         required_qty: item.required_qty || 0,
    //         delivery_qty: item.delivery_qty || 0,
    //         receive_qty: item.receive_qty !== 0 ? item.receive_qty : item.delivery_qty,
    //         mould_qty_per_set: item.mould_qty_per_set || 0,
    //         required_time: item.required_time || "",
    //         estimated_unit_price: item.estimated_unit_price || "0.00",
    //         estimated_total_price: item.estimated_total_price || "0.00",
    //         avg_monthly_consumption: item.avg_monthly_consumption || "",
    //         decoration: item.decoration || "",
    //         remarks_receive: item.remarks_receive || "",
    //       };
    //     });

    //     setList(itemsList);
    //   })
    // .catch((e) => {
    //   console.error("Error fetching data:", e);
    // });
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const formSubmit = () => {
    Http.post(`/transfer-received/${id}`, receiveData)
      .then((res) => {
        navigate("/transfer-art", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Receive</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Receiving Form</h6>
            <div className="row">
              <div className="col-lg">
                <label>Receipt No</label>
                <input
                  type="text"
                  className="form-control"
                  name="receipt_no"
                  value={receiveData.receipt_no}
                  // disabled
                />
              </div>
              {/* <div className="col-lg">
                <label>Production Work Order</label>
                <input
                  type="text"
                  className="form-control"
                  name="production_work_order_id"
                  value={inputs.production_work_order_id}
                  disabled
                />
              </div> */}
              <div className="col-lg">
                <label>Transfer Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  value={receiveData.transfer_date}
                  disabled
                />
              </div>
              <div className="col-lg">
                <label>Receive Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="receive_date"
                  value={receiveData.receive_date}
                  disabled
                />
              </div>

              <div className="col-lg">
                <label>From Department</label>
                <input
                  type="text"
                  className="form-control"
                  name="from_department"
                  value={receiveData.from_department_art?.name} // Get department name
                  disabled
                />
              </div>

              <div className="col-lg">
                <label>Remarks</label>
                <textarea
                  className="form-control"
                  name="remarks"
                  value={receiveData.remarks}
                  disabled
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-12">
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      {/* <th>Unit</th>
                    <th>Shape</th>
                    <th>Shape Type</th>
                    <th>In Hand</th>
                    <th>In Pipeline</th>
                    <th>On Order</th> */}
                      {/* <th>Required Qty</th> */}
                      <th>Delivered Qty</th>
                      {/* {
                    role == "mould" && (
                    <th>Mould Qty Per Set</th>
                    )} */}
                      {/* <th>Required Time</th> */}
                      {/* <th>Estimated Unit Price</th>
                    <th>Estimated Total Price</th>
                    <th>Average Monthly Consumption</th> */}
                      {/* {
                    (role == "decoration_local" || role == 'decoration_export') && (
                    <th>Decoration</th>
                    )} */}
                      {/* <th>Receive Qty</th> */}
                      {/* <th>Remarks Receive</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {receiveData.items.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="product_name"
                            value={item.product?.name}
                            disabled
                          />
                        </td>
                        {/* <td>
                        <input
                          type="text"
                          className="form-control"
                          name="unit_id"
                          value={getUnitName(item.unit_id)} // Get unit name
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="shape_id"
                          value={getShapeName(item.shape_id)} // Get shape name
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="shape_type_id"
                          value={getShapeTypeName(item.shape_type_id)} // Get shape type name
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="in_hand_qty"
                          value={item.in_hand_qty}
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="in_pipeline_qty"
                          value={item.in_pipeline_qty}
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="on_order_qty"
                          value={item.on_order_qty}
                          disabled
                        />
                      </td> */}
                        {/* <td>
                        <input
                          type="number"
                          className="form-control"
                          name="required_qty"
                          value={item.required_qty}
                          disabled
                        />
                      </td> */}
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="delivery_qty"
                            value={item.delivery_qty}
                            disabled
                          />
                        </td>
                        {/* {
                    role == "mould" && (
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="mould_qty_per_set"
                          value={item.mould_qty_per_set}
                          disabled
                        />
                      </td>
                      )} */}
                        {/* <td>
                        <input
                          type="text"
                          className="form-control"
                          name="required_time"
                          value={item.required_time}
                          disabled
                        />
                      </td> */}
                        {/* <td>
                        <input
                          type="number"
                          className="form-control"
                          name="estimated_unit_price"
                          value={item.estimated_unit_price}
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="estimated_total_price"
                          value={item.estimated_total_price}
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="avg_monthly_consumption"
                          value={item.avg_monthly_consumption}
                          disabled
                        />
                      </td> */}
                        {/* {
                    (role == "decoration_local" || role == 'decoration_export') && (
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="decoration"
                          value={item.decoration}
                          disabled
                        />
                      </td>
                      )} */}
                        {/* <td>
                        <input
                          type="number"
                          className="form-control"
                          name="receive_qty"
                          value={item.receive_qty}
                          disabled
                          // onChange={(e) => handleInputChange(e, index)}
                        />
                      </td> */}
                        {/* <td>
                        <textarea
                          type="text"
                          className="form-control"
                          name="remarks_receive"
                          value={item.remarks_receive || ""}
                          // onChange={(e) => handleInputChange(e, index)}
                        />
                      </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-3">
                <button className="btn btn-primary" onClick={formSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create;
