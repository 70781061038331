import $ from "jquery";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import useRequiredFields from "../../../../hooks/useRequiredFields";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import ReactDataTable from "../../../Common/ReactDataTable";

const EditPlanning = () => {
  const { checkRequiredFields } = useRequiredFields();
  const navigate = useNavigate();
  const { id } = useParams(); // Get the planning ID from the route
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [itemLoading, setItemLoading] = useState(false);
  const [workOrders, setWorkOrders] = useState([]);

  /*const dummy = {
    start_date: "2025-02-01",
    end_date: "2025-02-28",
    receipt_no: "PL003",
    remarks: "Main Remarks",
    input_qty_0_0: 24,
    input_qty_0_1: 37,
    input_qty_0_2: 30,
    input_qty_0_3: 98,
    input_qty_0_4: 61,
    input_qty_1_0: 107,
    input_qty_1_1: 85,
    input_qty_1_2: 0,
    items: [
      {
        id: 2,
        requisition_art_id: null,
        company_id: 1,
        receipt_no: "WO002",
        work_order_date: "2024-12-12",
        delivery_date: "2025-04-02",
        created_by: {
          id: 480,
          name: "Head Office",
          roaster_group_id: null,
          user_type: "admin",
          department_art_id: null,
          depot_id: null,
          card_no: "SA000001",
          email: "admin@gmail.com",
          password:
            "$2y$10$JwtMdS5xH.XTBVg0pUsiWOjBVnzCb3.5dWDLJVblKNrnzX13gXW.y",
          mobile: "1958577523",
          type: "permanent",
          joining_date: "2021-12-18",
          inactive_date: "2022-12-11",
          bank_account: "1212121212",
          blood_group: "O+",
          date_of_birth: "1996-12-21",
          present_address: "Super Admin",
          permanent_address: null,
          emergency_contact: "01722222222",
          reference: null,
          grade_id: 10,
          designation_id: 5,
          department_id: 23,
          requisition_id: null,
          candidate_id: null,
          company_location_id: 1,
          workplace_id: null,
          workplace_type: null,
          notes: null,
          department_head: 0,
          approved_by: 0,
          status: "active",
          created_at: null,
          updated_at: "2023-10-07T12:26:12.000000Z",
          deleted_at: null,
        },
        status: "active",
        created_at: "2024-12-12T11:08:08.000000Z",
        updated_at: "2025-01-09T11:07:07.000000Z",
        deleted_at: null,
        items: [
          {
            id: 44,
            production_work_order_id: 2,
            product_id: 40,
            shape_id: 4,
            qty: 9750,
            created_at: "2025-01-09T12:18:20.000000Z",
            updated_at: "2025-01-09T12:18:20.000000Z",
            deleted_at: null,
            previous_production_qty: "5",
            product: {
              id: 40,
              code: "101017",
              name: "Anglae(ss) 1''x1''x4 mm",
              unit_id: 1,
              department_art_id: null,
              price: null,
              secondary_price: null,
              sku: null,
              slice_weight: null,
              green_weight: null,
              glost_weight: null,
              biscuit_weight: null,
              fg_weight: null,
              pack_size: null,
              stock: null,
              type: "spare_parts",
              notes: null,
              vat: null,
              status: "active",
              std_price_accounts: null,
              vat_value_accounts: null,
              sdv_inv: null,
              sd_inv: null,
              vat_inv: null,
              unit_supply: null,
              unit_supply_qty: null,
              mushok_6_4_show: null,
              department_finished_good: null,
              created_at: null,
              updated_at: null,
              deleted_at: null,
            },
            shape: {
              id: 4,
              code: "SH004",
              name: "Art-4",
              status: "active",
              created_at: "2024-11-13T04:22:10.000000Z",
              updated_at: "2024-11-13T04:22:10.000000Z",
              deleted_at: null,
            },
            input_qty: 24,
          },
          {
            id: 45,
            production_work_order_id: 2,
            product_id: 41,
            shape_id: 4,
            qty: 12560,
            created_at: "2025-01-09T12:18:20.000000Z",
            updated_at: "2025-01-09T12:18:20.000000Z",
            deleted_at: null,
            previous_production_qty: "13",
            product: {
              id: 41,
              code: "101018",
              name: "Angle Menti Plastic",
              unit_id: 1,
              department_art_id: null,
              price: null,
              secondary_price: null,
              sku: null,
              slice_weight: null,
              green_weight: null,
              glost_weight: null,
              biscuit_weight: null,
              fg_weight: null,
              pack_size: null,
              stock: null,
              type: "spare_parts",
              notes: null,
              vat: null,
              status: "active",
              std_price_accounts: null,
              vat_value_accounts: null,
              sdv_inv: null,
              sd_inv: null,
              vat_inv: null,
              unit_supply: null,
              unit_supply_qty: null,
              mushok_6_4_show: null,
              department_finished_good: null,
              created_at: null,
              updated_at: null,
              deleted_at: null,
            },
            shape: {
              id: 4,
              code: "SH004",
              name: "Art-4",
              status: "active",
              created_at: "2024-11-13T04:22:10.000000Z",
              updated_at: "2024-11-13T04:22:10.000000Z",
              deleted_at: null,
            },
            input_qty: 37,
          },
          {
            id: 46,
            production_work_order_id: 2,
            product_id: 42,
            shape_id: 4,
            qty: 55,
            created_at: "2025-01-09T12:18:20.000000Z",
            updated_at: "2025-01-09T12:18:20.000000Z",
            deleted_at: null,
            previous_production_qty: "21",
            product: {
              id: 42,
              code: "101019",
              name: "Angle  Plastic",
              unit_id: 1,
              department_art_id: null,
              price: null,
              secondary_price: null,
              sku: null,
              slice_weight: null,
              green_weight: null,
              glost_weight: null,
              biscuit_weight: null,
              fg_weight: null,
              pack_size: null,
              stock: null,
              type: "spare_parts",
              notes: null,
              vat: null,
              status: "active",
              std_price_accounts: null,
              vat_value_accounts: null,
              sdv_inv: null,
              sd_inv: null,
              vat_inv: null,
              unit_supply: null,
              unit_supply_qty: null,
              mushok_6_4_show: null,
              department_finished_good: null,
              created_at: null,
              updated_at: null,
              deleted_at: null,
            },
            shape: {
              id: 4,
              code: "SH004",
              name: "Art-4",
              status: "active",
              created_at: "2024-11-13T04:22:10.000000Z",
              updated_at: "2024-11-13T04:22:10.000000Z",
              deleted_at: null,
            },
            input_qty: 30,
          },
          {
            id: 47,
            production_work_order_id: 2,
            product_id: 43,
            shape_id: 5,
            qty: 323,
            created_at: "2025-01-09T12:18:20.000000Z",
            updated_at: "2025-01-09T12:18:20.000000Z",
            deleted_at: null,
            previous_production_qty: "18",
            product: {
              id: 43,
              code: "101020",
              name: "Aluminium Foam Window-4''",
              unit_id: 1,
              department_art_id: null,
              price: null,
              secondary_price: null,
              sku: null,
              slice_weight: null,
              green_weight: null,
              glost_weight: null,
              biscuit_weight: null,
              fg_weight: null,
              pack_size: null,
              stock: null,
              type: "spare_parts",
              notes: null,
              vat: null,
              status: "active",
              std_price_accounts: null,
              vat_value_accounts: null,
              sdv_inv: null,
              sd_inv: null,
              vat_inv: null,
              unit_supply: null,
              unit_supply_qty: null,
              mushok_6_4_show: null,
              department_finished_good: null,
              created_at: null,
              updated_at: null,
              deleted_at: null,
            },
            shape: {
              id: 5,
              code: "SH005",
              name: "Art-5",
              status: "active",
              created_at: "2024-11-13T04:22:26.000000Z",
              updated_at: "2024-11-13T04:22:26.000000Z",
              deleted_at: null,
            },
            input_qty: 98,
          },
          {
            id: 48,
            production_work_order_id: 2,
            product_id: 30,
            shape_id: 4,
            qty: 77,
            created_at: "2025-01-09T12:18:20.000000Z",
            updated_at: "2025-01-09T12:18:20.000000Z",
            deleted_at: null,
            previous_production_qty: "16",
            product: {
              id: 30,
              code: "101006",
              name: 'Angle (M.S) - 1"x1"x4 mm',
              unit_id: 1,
              department_art_id: null,
              price: null,
              secondary_price: null,
              sku: null,
              slice_weight: null,
              green_weight: null,
              glost_weight: null,
              biscuit_weight: null,
              fg_weight: null,
              pack_size: null,
              stock: null,
              type: "spare_parts",
              notes: null,
              vat: null,
              status: "active",
              std_price_accounts: null,
              vat_value_accounts: null,
              sdv_inv: null,
              sd_inv: null,
              vat_inv: null,
              unit_supply: null,
              unit_supply_qty: null,
              mushok_6_4_show: null,
              department_finished_good: null,
              created_at: null,
              updated_at: null,
              deleted_at: null,
            },
            shape: {
              id: 4,
              code: "SH004",
              name: "Art-4",
              status: "active",
              created_at: "2024-11-13T04:22:10.000000Z",
              updated_at: "2024-11-13T04:22:10.000000Z",
              deleted_at: null,
            },
            input_qty: 61,
          },
        ],
        company: {
          id: 1,
          code: "6038",
          name: "Thun",
          contact_person: "Md. Tareq Khan",
          contact_phone: "1916206746",
          secondary_phone: null,
          email: "tareqkhansr@gmail.com",
          opening_date: "2021-12-28",
          inactive_date: null,
          resign_date: "2024-07-18",
          previous_due: "1.42",
          present_address: "Vill+Post:Kanakshar, Louhajong, Munshigonj",
          permanent_address: "Same as parmanent",
          company_type: "foreign",
          created_by: null,
          updated_by: 480,
          status: "active",
          nid_number: "19900000000000000",
          bin: null,
          created_at: null,
          updated_at: "2024-07-18T06:52:32.000000Z",
          deleted_at: null,
        },
        requisition_art: null,
      },
      {
        id: 1,
        requisition_art_id: null,
        company_id: 389,
        receipt_no: "WO001",
        work_order_date: "2024-12-12",
        delivery_date: "2025-03-12",
        created_by: {
          id: 480,
          name: "Head Office",
          roaster_group_id: null,
          user_type: "admin",
          department_art_id: null,
          depot_id: null,
          card_no: "SA000001",
          email: "admin@gmail.com",
          password:
            "$2y$10$JwtMdS5xH.XTBVg0pUsiWOjBVnzCb3.5dWDLJVblKNrnzX13gXW.y",
          mobile: "1958577523",
          type: "permanent",
          joining_date: "2021-12-18",
          inactive_date: "2022-12-11",
          bank_account: "1212121212",
          blood_group: "O+",
          date_of_birth: "1996-12-21",
          present_address: "Super Admin",
          permanent_address: null,
          emergency_contact: "01722222222",
          reference: null,
          grade_id: 10,
          designation_id: 5,
          department_id: 23,
          requisition_id: null,
          candidate_id: null,
          company_location_id: 1,
          workplace_id: null,
          workplace_type: null,
          notes: null,
          department_head: 0,
          approved_by: 0,
          status: "active",
          created_at: null,
          updated_at: "2023-10-07T12:26:12.000000Z",
          deleted_at: null,
        },
        status: "active",
        created_at: "2024-12-12T11:06:46.000000Z",
        updated_at: "2024-12-12T11:06:46.000000Z",
        deleted_at: null,
        items: [
          {
            id: 37,
            production_work_order_id: 1,
            product_id: 1,
            shape_id: 5,
            qty: 12000,
            created_at: "2025-01-09T11:07:48.000000Z",
            updated_at: "2025-01-09T11:07:48.000000Z",
            deleted_at: null,
            previous_production_qty: 0,
            product: {
              id: 1,
              code: "P001",
              name: "21cm Dinner Plate",
              unit_id: 3,
              department_art_id: null,
              price: "55.00",
              secondary_price: null,
              sku: "55",
              slice_weight: null,
              green_weight: null,
              glost_weight: null,
              biscuit_weight: null,
              fg_weight: null,
              pack_size: "55",
              stock: "55.00",
              type: "finished_goods",
              notes: null,
              vat: null,
              status: "active",
              std_price_accounts: null,
              vat_value_accounts: null,
              sdv_inv: null,
              sd_inv: null,
              vat_inv: null,
              unit_supply: null,
              unit_supply_qty: null,
              mushok_6_4_show: 1,
              department_finished_good: null,
              created_at: "2024-11-12T11:54:59.000000Z",
              updated_at: "2024-11-12T11:54:59.000000Z",
              deleted_at: null,
            },
            shape: {
              id: 5,
              code: "SH005",
              name: "Art-5",
              status: "active",
              created_at: "2024-11-13T04:22:26.000000Z",
              updated_at: "2024-11-13T04:22:26.000000Z",
              deleted_at: null,
            },
            input_qty: 107,
          },
          {
            id: 38,
            production_work_order_id: 1,
            product_id: 2,
            shape_id: 5,
            qty: 3700,
            created_at: "2025-01-09T11:07:48.000000Z",
            updated_at: "2025-01-09T11:07:48.000000Z",
            deleted_at: null,
            previous_production_qty: 0,
            product: {
              id: 2,
              code: "P002",
              name: "6cm Tea Cup",
              unit_id: 3,
              department_art_id: null,
              price: "55.00",
              secondary_price: null,
              sku: "55",
              slice_weight: null,
              green_weight: null,
              glost_weight: null,
              biscuit_weight: null,
              fg_weight: null,
              pack_size: "55",
              stock: "55.00",
              type: "finished_goods",
              notes: null,
              vat: null,
              status: "active",
              std_price_accounts: null,
              vat_value_accounts: null,
              sdv_inv: null,
              sd_inv: null,
              vat_inv: null,
              unit_supply: null,
              unit_supply_qty: null,
              mushok_6_4_show: 1,
              department_finished_good: null,
              created_at: "2024-11-12T11:54:59.000000Z",
              updated_at: "2024-11-12T11:54:59.000000Z",
              deleted_at: null,
            },
            shape: {
              id: 5,
              code: "SH005",
              name: "Art-5",
              status: "active",
              created_at: "2024-11-13T04:22:26.000000Z",
              updated_at: "2024-11-13T04:22:26.000000Z",
              deleted_at: null,
            },
            input_qty: 85,
          },
          {
            id: 39,
            production_work_order_id: 1,
            product_id: 24,
            shape_id: 4,
            qty: 88,
            created_at: "2025-01-09T11:07:48.000000Z",
            updated_at: "2025-01-09T11:07:48.000000Z",
            deleted_at: null,
            previous_production_qty: 0,
            product: {
              id: 24,
              code: "101000",
              name: 'Angle (M.S) - 1"x1"x3 mm',
              unit_id: 1,
              department_art_id: null,
              price: null,
              secondary_price: null,
              sku: null,
              slice_weight: null,
              green_weight: null,
              glost_weight: null,
              biscuit_weight: null,
              fg_weight: null,
              pack_size: null,
              stock: null,
              type: "spare_parts",
              notes: null,
              vat: null,
              status: "active",
              std_price_accounts: null,
              vat_value_accounts: null,
              sdv_inv: null,
              sd_inv: null,
              vat_inv: null,
              unit_supply: null,
              unit_supply_qty: null,
              mushok_6_4_show: null,
              department_finished_good: null,
              created_at: null,
              updated_at: null,
              deleted_at: null,
            },
            shape: {
              id: 4,
              code: "SH004",
              name: "Art-4",
              status: "active",
              created_at: "2024-11-13T04:22:10.000000Z",
              updated_at: "2024-11-13T04:22:10.000000Z",
              deleted_at: null,
            },
            input_qty: 0,
          },
        ],
        company: {
          id: 389,
          code: "COM010",
          name: "Limerick Resources LTD",
          contact_person: "Nazmul Hasan",
          contact_phone: "01212121",
          secondary_phone: "121212122112",
          email: "nazmul@lrl.com",
          opening_date: "2024-11-13",
          inactive_date: null,
          resign_date: null,
          previous_due: "12212.00",
          present_address: "gdfdfdfsd",
          permanent_address: "fgfgdsfgdg",
          company_type: "local",
          created_by: 480,
          updated_by: null,
          status: "active",
          nid_number: null,
          bin: null,
          created_at: "2024-11-13T04:47:30.000000Z",
          updated_at: "2024-11-13T04:47:30.000000Z",
          deleted_at: null,
        },
        requisition_art: null,
      },
    ],
  };*/

  useEffect(() => {
    initializePage();
    $("body").addClass("collapse-menu");
    $("body").toggleClass("show-left");
  }, []);

  const initializePage = async () => {
    setLoading(true);
    try {
      // Fetch existing planning details
      const resPlanning = await Http.get(`/planning/${id}/edit`);
      // const resPlanning = await Http.get(`/planning/${id}`);
      const planningData = resPlanning.data.data;
      setInputs({
        receipt_no: planningData.receipt_no,
        start_date: planningData.start_date,
        end_date: planningData.end_date,
        remarks: planningData.remarks,
      });
      setList(planningData.items);

      // Fetch available work orders
      const resWorkOrders = await Http.get(
        "/production-work-order?expire_check=1"
      );
      setWorkOrders(resWorkOrders.data);
    } catch (error) {
      console.error("Error initializing page:", error);
      Swal.fire("Error!", "Failed to load planning details.", "error");
    } finally {
      setLoading(false);
    }
  };

  const addMoreList = async (id) => {
    setItemLoading(true);
    try {
      const response = await Http.get(`/production-work-order/${id}`);
      const hasDuplicates = list.some((group) => group.id === response.data.id);

      if (hasDuplicates) {
        Swal.fire(
          "Warning!",
          "Same work order is already in the list!",
          "warning"
        );
      } else {
        setList((prev) => [...prev, response.data]);
      }
    } catch (error) {
      console.error("Error fetching work order:", error);
      Swal.fire("Error!", "Failed to add work order.", "error");
    } finally {
      setItemLoading(false);
    }
  };

  const removeGroup = (groupIndex) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This will remove the entire work order.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedList = list.filter((_, i) => i !== groupIndex);
        setList(updatedList);
        Swal.fire("Deleted!", "The group has been removed.", "success");
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleInputChange = (e, groupIndex, itemIndex, availableQty) => {
    const { name, value } = e.target;
    let input_qty = parseInt(value) || 0;

    setList((prevList) => {
      const updatedList = [...prevList];
      const item = updatedList[groupIndex].items[itemIndex];
      item[name] = Math.max(0, Math.min(input_qty, availableQty));
      return updatedList;
    });

    if (availableQty >= input_qty) {
      setInputs((prevInputs) => ({
        ...prevInputs,
        [`input_qty_${groupIndex}_${itemIndex}`]: input_qty,
      }));
    } else {
      Swal.fire(
        "Warning!",
        "You can't add more than available quantity! Available Quantity Is = " +
          availableQty,
        "warning"
      );
      setInputs((prevInputs) => ({
        ...prevInputs,
        [`input_qty_${groupIndex}_${itemIndex}`]: availableQty,
      }));
    }
  };

  const formSubmit = () => {
    checkRequiredFields();
    inputs.items = list;
    console.log("Submitted data:", inputs);

    Http.put(`/planning/${id}`, inputs) // Use PUT for updating
      .then((res) => {
        navigate("/planning", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch((e) => {
        setError(e.response.data.errors);
      });
  };

  const cols = [
    {
      field: (WO) => {
        return WO.receipt_no + " / " + WO.work_order_date;
      },
      header: "Work Order",
    },
    { field: "company.name", header: "Company" },
  ];

  const actionTemplate = (item) => {
    return (
      <td className="text-right">
        <button
          className="btn btn-success btn-sm"
          onClick={() => addMoreList(item.id)}
        >
          <i className="fa fa-plus"></i>
        </button>
      </td>
    );
  };

  return (
    <div>
      <IsLogin />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Edit Work Plan</h5>
        </div>
        <div className="am-pagebody row d-flex justify-content-evenly">
          <div className="card pd-20 pd-sm-40 col-8">
            <h6 className="card-body-title mg-sm-b-30 text-center">
              Edit Work Plan
            </h6>
            <hr />
            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg">
                    <label>
                      Work Planning No <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="receipt_no"
                      value={inputs.receipt_no || ""}
                      onChange={handleChange}
                      readOnly
                    />
                  </div>
                  <div className="col-lg">
                    <label>
                      Start Date <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="start_date"
                      value={inputs.start_date || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg">
                    <label>
                      End Date <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="end_date"
                      value={inputs.end_date || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg">
                    <label>Remarks</label>
                    <textarea
                      className="form-control"
                      name="remarks"
                      value={inputs.remarks || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {/* Render Work Orders and Items */}
                {/* Same as CreatePlanning */}
                {/* ... */}
                <div className="row mt-3">
                  <div className="card-solid">
                    <div className="card-header with-border text-center">
                      <h5 className="card-title mb-0">
                        Work Orders Items in the Order{" "}
                        <i className="fa fa-star required"></i>
                      </h5>
                    </div>
                    <div className="card-body p-0">
                      <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0">
                        <thead>
                          <tr>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Product
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Shape
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Order Qty
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Previous Production Qty
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Qty
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {list.length < 1 ? (
                            <tr>
                              <td colSpan={5}>
                                <p
                                  style={{ color: "red" }}
                                  className="text-center mt-3"
                                >
                                  Please Add Few Work Orders for Work Plan
                                </p>
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                          {list.map((group, groupIndex) => (
                            <React.Fragment key={`group-${groupIndex}`}>
                              <tr>
                                <td
                                  colSpan={5}
                                  className="text-start font-weight-bold"
                                >
                                  {group.company?.name} #{group.receipt_no} (
                                  {group.work_order_date} to{" "}
                                  {group.delivery_date})
                                  <button
                                    className="btn btn-danger btn-sm float-right"
                                    onClick={() => removeGroup(groupIndex)}
                                  >
                                    <i className="fa fa-trash"></i> Remove Work
                                    Order
                                  </button>
                                </td>
                              </tr>
                              {group.items.map((item, itemIndex) => (
                                <tr key={`item-${groupIndex}-${itemIndex}`}>
                                  <td>{item.product?.name || ""}</td>
                                  <td>{item.shape?.name || ""}</td>
                                  <td>{item.qty || 0}</td>
                                  <td>{item.previous_production_qty || 0}</td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="input_qty"
                                      // value={
                                      //   inputs[
                                      //     `input_qty_${groupIndex}_${itemIndex}`
                                      //   ] ?? 0
                                      // }
                                      value={
                                        inputs[
                                          `input_qty_${groupIndex}_${itemIndex}`
                                        ] ??
                                        list[groupIndex].items[itemIndex]
                                          .input_qty ??
                                        0
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          groupIndex,
                                          itemIndex,
                                          item.qty -
                                            (item.previous_production_qty || 0)
                                        )
                                      }
                                      placeholder="Qty"
                                    />
                                  </td>
                                </tr>
                              ))}
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-3">
                    <button
                      className="btn btn-primary btn-block mg-b-10"
                      onClick={formSubmit}
                    >
                      <i className="fa fa-send mg-r-10"></i> Save Changes
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className="card pd-20 pd-sm-40 col-3"
            style={{ backgroundColor: "#ffffffc7" }}
          >
            <h6 className="card-body-title mg-sm-b-30 text-center">
              Available Work Orders
            </h6>
            <hr />
            <ReactDataTable
              cols={cols}
              receiveData={workOrders}
              isLoading={loading}
              actionTemplate={actionTemplate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPlanning;
