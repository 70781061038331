import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import { LoaderComponent } from "../../../Common/LoaderComponent";

const Create = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [inputs, setInputs] = useState({
    receipt_no: "",
    production_work_order_id: "",
    date: "",
    receive_date: "",
    copy_type: "",
    purchase_type: "",
    from_department: "",
    to_department: "",
    status: "",
    remarks: "",
  });

  const [list, setList] = useState([]);
  const [error, setError] = useState({});

  // const [shapes, setShapes] = useState([]); // Store shapes from API
  // const [shapeTypes, setShapeTypes] = useState([]); // Store shape types from API
  // const [units, setUnits] = useState([]); // Store units from API
  const [role, setRole] = useState("");
  const [departments, setDepartments] = useState([
    { id: 1, name: "BODY", user_type: "body" },
    { id: 2, name: "JIGGER", user_type: "jigger" },
    { id: 3, name: "CASTING", user_type: "casting" },
    { id: 4, name: "FINISHING", user_type: "finishing" },
    { id: 5, name: "BK", user_type: "bk" },
    { id: 6, name: "GLAZING", user_type: "glazing" },
    { id: 7, name: "GK", user_type: "gk" },
    { id: 8, name: "SELECTION", user_type: "selection" },
    { id: 9, name: "DECORATION LOCAL", user_type: "decoration_local" },
    { id: 10, name: "DECORATION EXPORT", user_type: "decoration_export" },
    { id: 11, name: "DFW", user_type: "dfw" },
    { id: 12, name: "PACKING LOCAL", user_type: "packing_local" },
    { id: 13, name: "PACKING EXPORT", user_type: "packing_export" },
    { id: 14, name: "DSTORE", user_type: "dstore" },
    { id: 15, name: "GSTORE", user_type: "gstore" },
    { id: 16, name: "MOULD", user_type: "mould" },
    { id: 17, name: "PSTORE", user_type: "pstore" },
    { id: 18, name: "PLANNING", user_type: "planning" },
    { id: 19, name: "SAGGER", user_type: "sagger" },
  ]);

  // Fetch the requisition data by ID and populate the form inputs
  const fetchData = () => {
    Http.get("/staff-type-check").then((res) => {
      setRole(res.data);
    });
    Http.get("/store-purchase-requisition-art/" + id)
      .then((res) => {
        const requisitionData = res.data;

        // Populate inputs with the response data
        setInputs({
          receipt_no: requisitionData.receipt_no || "",
          date: new Date().toISOString().split("T")[0], // Returns date in localized string format (e.g., "10/17/2023" in MM/DD/YYYY format)
          status: requisitionData.status || "requested",
          remarks: requisitionData.remarks || "",
        });

        // Populate list (items) with the response data, setting delivery_qty for validation
        const itemsList = requisitionData.items.map((item) => {
          return {
            id: item.id,
            product_id: item.product_id,
            product_name: item.product.name || "",
            required_qty: item.required_qty || 0,
            approved_qty: inputs.approved_qty || 0,
            approved_remarks: inputs.approved_remarks || "",
          };
        });

        setList(itemsList);
      })
      .catch((e) => {
        console.error("Error fetching data:", e);
      });

    // Fetch shapes from API
    // Http.get("/shape")
    //   .then((res) => {
    //     setShapes(res.data); // Store shapes response
    //   })
    //   .catch((e) => {
    //     console.error("Error fetching shapes:", e);
    //   });

    // // Fetch shape types from API
    // Http.get("/shapetype")
    //   .then((res) => {
    //     setShapeTypes(res.data); // Store shape types response
    //   })
    //   .catch((e) => {
    //     console.error("Error fetching shape types:", e);
    //   });
    // // Fetch units from API
    // Http.get("/unit")
    //   .then((res) => {
    //     setUnits(res.data); // Store units response
    //   })
    //   .catch((e) => {
    //     console.error("Error fetching units:", e);
    //   });
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // const handleInputChange = (e, index) => {
  //   const { name, value } = e.target;
  //   const updatedList = [...list];

  //   if (name === "delivery_qty") {
  //     const deliverQty = parseFloat(value);
  //     const remainingQty = updatedList[index].required_qty;

  //     if (deliverQty < 1) {
  //       alert("Deliver quantity cannot be less than 1.");
  //       return;
  //     } else if (deliverQty > remainingQty) {
  //       alert(`Deliver quantity cannot exceed ${remainingQty}.`);
  //       return;
  //     }

  //     updatedList[index][name] = deliverQty;

  //     const allItemsDelivered = updatedList.every(
  //       (item) => item.delivery_qty === item.required_qty
  //     );

  //     if (allItemsDelivered) {
  //       setInputs((values) => ({ ...values, status: "delivered" }));
  //     } else {
  //       setInputs((values) => ({ ...values, status: "requested" }));
  //     }
  //   } else {
  //     updatedList[index][name] = value;
  //   }

  //   // Calculate total_p, total_t, and closing_balance based on updated values
  //   const opening_balance = parseFloat(updatedList[index].opening_balance) || 0;
  //   const received_qty = parseFloat(updatedList[index].received_qty) || 0;
  //   const biscuit_loss = parseFloat(updatedList[index].biscuit_loss) || 0;
  //   const delivery_qty = parseFloat(updatedList[index].delivery_qty) || 0;

  //   updatedList[index].total_p = opening_balance + received_qty;
  //   updatedList[index].total_t = biscuit_loss + delivery_qty;
  //   updatedList[index].closing_balance =
  //     updatedList[index].total_p - updatedList[index].total_t;

  //   setList(updatedList);
  // };

  // const handleInputChange = (e, index) => {
  //   const { name, value } = e.target;
  //   const updatedList = [...list];
  //   const inputValue = parseFloat(value) || 0;

  //   setList(updatedList);
  // };

  // const handleInputChange = (e, index) => {
  //   const { name, value } = e.target;
  //   const updatedList = [...list];
  //   let inputValue = parseFloat(value) || 0;

  //   // Enforce non-negative values
  //   // if (inputValue < 0) {
  //   //   Swal.fire({
  //   //     icon: "error",
  //   //     title: "Invalid Input",
  //   //     text: `${name} cannot be less than 0.`,
  //   //   });
  //   //   inputValue = 0;
  //   // }

  //   // // Update the specific field
  //   // updatedList[index][name] = inputValue;

  //   // // Calculate total_p, total_t, and closing_balance based on updated values
  //   // const opening_balance = parseFloat(updatedList[index].opening_balance);
  //   // const received_qty = parseFloat(updatedList[index].received_qty);
  //   // let biscuit_loss = parseFloat(updatedList[index].biscuit_loss);
  //   // let delivery_qty = parseFloat(updatedList[index].delivery_qty);

  //   // // Calculate total_p
  //   // updatedList[index].total_p = opening_balance + received_qty;

  //   // // Check if biscuit_loss and delivery_qty together would exceed total_p
  //   // if (
  //   //   name === "biscuit_loss" &&
  //   //   biscuit_loss + delivery_qty > updatedList[index].total_p
  //   // ) {
  //   //   // Adjust biscuit_loss so that total_t does not exceed total_p
  //   //   Swal.fire({
  //   //     icon: "error",
  //   //     title: "Validation Error",
  //   //     text: `Biscuit Loss cannot be set to ${inputValue} as it would cause Total Transfer (total_t) to exceed Total Production (total_p). Adjusting to maximum allowed value.`,
  //   //   });
  //   //   biscuit_loss = updatedList[index].total_p - delivery_qty;
  //   //   updatedList[index].biscuit_loss = biscuit_loss;
  //   // }

  //   // // Calculate total_t based on adjusted biscuit_loss and current delivery_qty
  //   // updatedList[index].total_t = biscuit_loss + delivery_qty;

  //   // // Ensure total_t does not exceed total_p
  //   // if (updatedList[index].total_t > updatedList[index].total_p) {
  //   //   Swal.fire({
  //   //     icon: "error",
  //   //     title: "Validation Error",
  //   //     text: "Total Transfer (total_t) cannot be greater than Total Production (total_p).",
  //   //   });
  //   //   updatedList[index].total_t = updatedList[index].total_p;
  //   //   updatedList[index].delivery_qty =
  //   //     updatedList[index].total_p - biscuit_loss; // Adjust delivery_qty accordingly
  //   // }

  //   // // Calculate processed_qty as the difference between total_p and total_t
  //   // updatedList[index].processed_qty =
  //   //   updatedList[index].total_p - updatedList[index].total_t;

  //   // // Calculate closing_balance
  //   // updatedList[index].closing_balance =
  //   //   updatedList[index].total_p - updatedList[index].total_t;

  //   setList(updatedList);
  // };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedList = [...list];

    // Update the specific field in the list
    updatedList[index][name] = value;

    setList(updatedList);
  };

  const formSubmit = () => {
    const formData = {
      ...inputs,
      items: list,
    };

    Http.post(`/approve-store-purchase-requisition-art/${id}`, formData)
      .then((res) => {
        navigate("/store-purchase-requisition-art", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  // Function to get department name by ID
  const getDepartmentName = (id) => {
    const department = departments.find((dept) => dept.id === id);
    return department ? department.name : "N/A";
  };

  // // Function to get shape name by ID
  // const getShapeName = (id) => {
  //   const shape = shapes.find((shape) => shape.id === id);
  //   return shape ? shape.name : "N/A";
  // };

  // const getShapeTypeName = (id) => {
  //   const shapeType = shapeTypes.find((type) => type.id === id);
  //   return shapeType ? shapeType.name : "N/A";
  // };

  // const getUnitName = (id) => {
  //   const unit = units.find((unit) => unit.id === id);
  //   return unit ? unit.name : "N/A";
  // };

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Processing and Deliver</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">
              Processing and Delivery Form
            </h6>
            <div className="row">
              <div className="col-lg">
                <label>Receipt No</label>
                <input
                  type="text"
                  className="form-control"
                  name="receipt_no"
                  value={inputs.receipt_no}
                  disabled
                />
              </div>
              {/* <div className="col-lg">
                <label>Production Work Order</label>
                <input
                  type="text"
                  className="form-control"
                  name="production_work_order_id"
                  value={inputs.production_work_order_id}
                  disabled
                />
              </div> */}
              {/* <div className="col-lg">
                <label>Received Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="receive_date"
                  value={inputs.receive_date}
                  disabled
                />
              </div> */}
              <div className="col-lg">
                <label>Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  value={inputs.date}
                  disabled
                />
              </div>
              {/* {(role == 'mould' || role == 'sagger' || role == 'gstore' || role == 'dstore' || role == 'pstore') && ( <> */}
              {/* <div className="col-lg">
                <label>Copy Type</label>
                <select
                  className="form-control"
                  name="copy_type"
                  value={inputs.copy_type}
                  disabled
                >
                  <option value="original">Original</option>
                  <option value="duplicate">Duplicate</option>
                  <option value="triplicate">Triplicate</option>
                </select>
              </div>

              <div className="col-lg">
                <label>Purchase Type</label>
                <select
                  className="form-control"
                  name="purchase_type"
                  value={inputs.purchase_type}
                  disabled
                >
                  <option value="local">Local</option>
                  <option value="foreign">Foreign</option>
                </select>
              </div> */}

              {/* </>)} */}

              {/* <div className="col-lg">
                <label>Transfer To Department</label>
                <input
                  type="text"
                  className="form-control"
                  name="to_department"
                  value={getDepartmentName(inputs.to_department)} // Get department name
                  disabled
                />
              </div> */}

              {/* <div className="col-lg">
                <label>To Department</label>
                <input
                  type="text"
                  className="form-control"
                  name="to_department"
                  value={getDepartmentName(inputs.to_department)} // Get department name
                  disabled
                />
              </div> */}

              {/* <div className="col-lg">
                <label>Remarks</label>
                <textarea
                  className="form-control"
                  name="remarks"
                  value={inputs.remarks}
                  disabled
                />
              </div> */}
            </div>

            {/* Item List */}
            <div className="row mt-3">
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th>Product Name</th>
                    {/* <th>Work Order</th>
                    <th>Shape</th>
                    <th>Opening Balance</th>
                    <th>Received Qty</th>
                    <th>Total P</th>
                    {(role === "bk" || role === "admin") && (
                      <>
                        <th>Green Loss</th>
                        <th>Biscuit Loss</th>
                      </>
                    )}
                    <th>Transfer Qty</th>
                    <th>Total T</th>
                    <th>Processed Qty</th>
                    <th>Closing Balance</th> */}
                    {/* <th>Unit</th>
                    <th>Shape</th>
                    <th>Shape Type</th>
                    <th>In Hand</th>
                    <th>In Pipeline</th>
                    <th>On Order</th> */}
                    <th>Required Qty</th>
                    <th>Approved Qty</th>
                    {/* <th>Received Qty</th> */}
                    {/* <th>Mould Qty Per Set</th>
                    <th>Required Time</th> */}
                    {/* <th>Estimated Unit Price</th>
                    <th>Estimated Total Price</th>
                    <th>Avg Monthly Consumption</th> */}
                    {/* <th>Decoration</th> */}
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {list.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="product_name"
                          value={item.product_name}
                          disabled
                        />
                      </td>
                      {/* <td>
                        <input
                          type="text"
                          className="form-control"
                          name="work_order"
                          value={item.work_order}
                          onChange={(e) => handleInputChange(e, index)}
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="shape"
                          value={item.shape}
                          onChange={(e) => handleInputChange(e, index)}
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="opening_balance"
                          value={item.opening_balance}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="received_qty"
                          value={item.received_qty}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="total_p"
                          value={item.total_p}
                          onChange={(e) => handleInputChange(e, index)}
                          disabled
                        />
                      </td> */}
                      {/* {(role === "bk" || role === "admin") && (
                        <>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              name="green_loss"
                              value={item.green_loss}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              name="biscuit_loss"
                              value={item.biscuit_loss}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </td>
                        </>
                      )} */}

                      {/* <td>
                        <input
                          type="number"
                          className="form-control"
                          name="delivery_qty"
                          value={item.delivery_qty}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="total_t"
                          value={item.total_t}
                          onChange={(e) => handleInputChange(e, index)}
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="processed_qty"
                          value={item.processed_qty}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="closing_balance"
                          value={item.closing_balance}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      </td> */}
                      {/* <td>
                        <input
                          type="text"
                          className="form-control"
                          name="unit_id"
                          value={getUnitName(item.unit_id)} // Get unit name
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="shape_id"
                          value={getShapeName(item.shape_id)} // Get shape name
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="shape_type_id"
                          value={getShapeTypeName(item.shape_type_id)} // Get shape type name
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="in_hand_qty"
                          value={item.in_hand_qty}
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="in_pipeline_qty"
                          value={item.in_pipeline_qty}
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="on_order_qty"
                          value={item.on_order_qty}
                          disabled
                        />
                      </td> */}
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="required_qty"
                          value={item.required_qty}
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="approved_qty"
                          value={list[index]?.approved_qty || ""}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      </td>
                      {/* <td>
                        <input
                          type="number"
                          className="form-control"
                          name="receive_qty"
                          value={item.receive_qty}
                          disabled
                        />
                      </td> */}
                      {/* <td>
                        <input
                          type="number"
                          className="form-control"
                          name="mould_qty_per_set"
                          value={item.mould_qty_per_set}
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="required_time"
                          value={item.required_time}
                          disabled
                        />
                      </td> */}
                      {/* <td>
                        <input
                          type="number"
                          className="form-control"
                          name="estimated_unit_price"
                          value={item.estimated_unit_price}
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="estimated_total_price"
                          value={item.estimated_total_price}
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="avg_monthly_consumption"
                          value={item.avg_monthly_consumption}
                          disabled
                        />
                      </td> */}
                      {/* <td>
                        <input
                          type="text"
                          className="form-control"
                          name="decoration"
                          value={item.decoration}
                          disabled
                        />
                      </td> */}
                      <td>
                        <textarea
                          type="text"
                          className="form-control"
                          name="approved_remarks"
                          value={list[index]?.approved_remarks || ""}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Submit Button */}
            <div className="row mt-3">
              <div className="col-3">
                <button className="btn btn-primary" onClick={formSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create;
