import $ from "jquery"; // Import jQuery
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Http from "../../../Common/Http";
import ReactDataTable from "../../../Common/ReactDataTable";

const Edit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  // const [product, setProduct] = useState([]);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [itemLoading, setItemLoading] = useState(false);
  const [shapes, setShapes] = useState([]); // Store shapes from API
  const [shapeTypes, setShapeTypes] = useState([]); // Store shape types from API
  const [workOrders, setWorkOrders] = useState([]); // Store work orders from API
  const [unit, setUnit] = useState([]); // Store shape types from API
  const [receiveData, setReceiveData] = useState([]);
  const [departments, setDepartments] = useState([
    { id: 1, name: "BODY", user_type: "body" },
    // { id: 2, name: "JIGGER", user_type: "jigger" },
    // { id: 3, name: "CASTING", user_type: "casting" },
    // { id: 4, name: "FINISHING", user_type: "finishing" },
    // { id: 5, name: "BK", user_type: "bk" },
    // { id: 6, name: "GLAZING", user_type: "glazing" },
    // { id: 7, name: "GK", user_type: "gk" },
    // { id: 8, name: "SELECTION", user_type: "selection" },
    // { id: 9, name: "DECORATION LOCAL", user_type: "decoration_local" },
    // { id: 10, name: "DECORATION EXPORT", user_type: "decoration_export" },
    // { id: 11, name: "DFW", user_type: "dfw" },
    // { id: 12, name: "PACKING LOCAL", user_type: "packing_local" },
    // { id: 13, name: "PACKING EXPORT", user_type: "packing_export" },
    { id: 14, name: "DSTORE", user_type: "dstore" },
    { id: 15, name: "GSTORE", user_type: "gstore" },
    { id: 16, name: "MOULD", user_type: "mould" },
    { id: 17, name: "PSTORE", user_type: "pstore" },
    { id: 18, name: "PLANNING", user_type: "planning" },
    { id: 19, name: "SAGGER", user_type: "sagger" },
  ]);
  const [product, setProduct] = useState([]);
  const [rmpm, setRmpm] = useState([]);
  const [combinedData, setCombinedData] = useState([]);

  // Utility function to capitalize the first letter
  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    fetchInitialData();
    $("body").toggleClass("collapse-menu");
    $("body").toggleClass("show-left");
  }, [id]);

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      // Fetch initial data such as products, shapes, units, etc.
      const resProduct = await Http.get("/fg-with-stock");
      setProduct(resProduct.data);

      const resRmpm = await Http.get("/rmpm-with-stock");
      setRmpm(resRmpm.data);

      const combined = [
        ...resRmpm.data.map((item) => ({ ...item, type: "RMPM" })),
        ...resProduct.data.map((item) => ({ ...item, type: "FG" })),
      ];
      setCombinedData(combined);

      const response = await Http.get("/shape");
      const data = response.data;
      setShapes(data);

      // const resUnit = await Http.get("/unit");
      // setUnit(resUnit.data);

      const resWorkOrders = await Http.get("/production-work-order");
      setWorkOrders(resWorkOrders.data);

      const uniqueShapeTypes = [
        ...new Map(
          data.map((item) => [item.shape_type.id, item.shape_type])
        ).values(),
      ];
      setShapeTypes(uniqueShapeTypes);

      // Fetch the requisition data for editing
      const resReq = await Http.get(`/requisition-art/${id}`);
      const requisitionData = resReq.data;
      setReceiveData(resReq);

      // Populate form inputs with the fetched data
      setInputs({
        receipt_no: requisitionData.receipt_no || "",
        production_work_order_id:
          requisitionData.production_work_order_id || "",
        copy_type: capitalizeFirstLetter(requisitionData.copy_type) || "",
        purchase_type:
          capitalizeFirstLetter(requisitionData.purchase_type) || "",

        date: requisitionData.date || "",
        receive_date: requisitionData.receive_date || "",
        from_department: requisitionData.from_department || "",
        to_department: requisitionData.to_department || "",
        remarks: requisitionData.remarks || "",
      });

      // Populate the list for items
      setList(
        requisitionData.items.map((item) => ({
          id: item.id,
          product_id: item.product_id,
          product_name: item.product.name,
          unit_id: item.unit_id,
          shape_id: item.shape_id,
          shape_type_id: item.shape_type_id,
          in_hand_qty: item.in_hand_qty,
          in_pipeline_qty: item.in_pipeline_qty,
          on_order_qty: item.on_order_qty,
          required_qty: item.required_qty,
          mould_qty_per_set: item.mould_qty_per_set,
          required_time: item.required_time,
          estimated_unit_price: item.estimated_unit_price,
          estimated_total_price: item.estimated_total_price,
          avg_monthly_consumption: item.avg_monthly_consumption,
          decoration: item.decoration,
          remarks: item.remarks,
        }))
      );
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  const addMoreList = async (id) => {
    setItemLoading(true); // Use itemLoading to control this specific action
    try {
      const response = await Http.get(`/product/${id}`);
      if (!list.find((item) => item.id === response.data.id)) {
        setList((prev) => [...prev, response.data]);
      } else {
        Swal.fire(
          "Warning!",
          "This Product is already in the list!",
          "warning"
        );
      }
    } catch (error) {
      console.error("Error fetching product:", error);
      Swal.fire("Error!", "Failed to add product.", "error");
    } finally {
      setItemLoading(false); // Stop loading after action is complete
    }
  };

  const removeList = (index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedList = list.filter((_, i) => i !== index);
        setList(updatedList);
        Swal.fire("Deleted!", "Your item has been deleted.", "success");
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the value is numeric (e.g., department ID), convert it to an integer
    const parsedValue = !isNaN(value) && value !== "" ? parseInt(value) : value;

    setInputs((values) => ({ ...values, [name]: parsedValue }));
  };

  const selectHandleChange = async (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    const { value } = selectedOption;

    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target; // Chanages Experience Input Name and Value
    const inputInfo = [...list]; // Defined Experience State All Information into List Variable

    inputInfo[index][name] = value;
    setList(inputInfo);
  };

  const formSubmit = () => {
    inputs.items = list;

    // Prepare the updated list of items
    const updatedList = list.map((item) => ({
      ...item,
      product_id: item.product_id, // Keep the 'product_id' as it is
      shape_id: item.shape_id || null, // Ensure shape_id is included
      shape_type_id: item.shape_type_id || null, // Ensure shape_type_id is included
    }));

    // Assign the updated list to inputs.items
    inputs.items = updatedList;

    // Make PUT request to update the requisition using the ID from useParams
    Http.put(`/requisition-art/${id}`, inputs)
      .then((res) => {
        // Navigate back to the requisition list or show a success message
        navigate("/requisition-art", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        // Set validation errors if any
        setError(e.response.data.errors);
      });
  };

  const displayError = (fieldName) => {
    if (error && error[fieldName]) {
      return <div className="text-danger small">{error[fieldName]}</div>;
    }
    return null;
  };
  const cols = [{ field: "name", header: "Item" }];

  // Dummy arrays for the select fields with id and name
  const copyTypes = [
    { id: 1, name: "Original" },
    { id: 2, name: "Duplicate" },
    { id: 3, name: "Triplicate" },
  ];

  // const departments = [
  //   { id: 1, name: "HR" },
  //   { id: 2, name: "Sales" },
  //   { id: 3, name: "Production" },
  //   { id: 4, name: "Finance" },
  // ];

  const purchaseTypes = [
    { id: 1, name: "Local" },
    { id: 2, name: "Foreign" },
  ];

  const actionTemplate = (item, deleteData) => {
    return (
      <td className="text-right">
        <button
          className="btn btn-success btn-sm"
          onClick={() => addMoreList(item.id)}
        >
          <i className="fa fa-plus"></i>
        </button>
      </td>
    );
  };
  return (
    <div>
      {/* <IsLogin></IsLogin> */}
      {/* <LoaderComponent /> */}
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Edit Requisition To Store</h5>
        </div>
        <div className="am-pagebody row d-flex justify-content-evenly">
          <div className="card pd-20 pd-sm-40 col-8">
            <h6 className="card-body-title mg-sm-b-30 text-center">
              Edit Requisition Form
            </h6>
            <hr></hr>
            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg">
                    <label>
                      Receipt No <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="receipt_no"
                      value={inputs.receipt_no || ""}
                      onChange={handleChange}
                      placeholder="Input Invoice No"
                    />

                    {displayError("receipt_no")}
                  </div>
                  <div className="col-lg">
                    <label>
                      Work Order <i className="fa fa-star required"></i>
                    </label>
                    {/* Dropdown for work order */}
                    <select
                      className="form-control"
                      name="production_work_order_id"
                      value={inputs.production_work_order_id || ""}
                      onChange={handleChange}
                    >
                      <option value="">Select Work Order</option>
                      {workOrders.map((order) => (
                        <option key={order.id} value={order.id}>
                          {order.receipt_no}
                        </option>
                      ))}
                    </select>
                    {displayError("production_work_order_id")}
                  </div>
                  <div className="col-lg">
                    <label>
                      Copy Type <i className="fa fa-star required"></i>
                    </label>
                    <select
                      className="form-control"
                      name="copy_type"
                      value={inputs.copy_type || ""}
                      onChange={handleChange}
                    >
                      <option value="">Select Copy Type</option>
                      {copyTypes.map((type) => (
                        <option key={type.id} value={type.name}>
                          {type.name}
                        </option>
                      ))}
                    </select>
                    {displayError("copy_type")}
                  </div>

                  <div className="col-lg">
                    <label>
                      Date <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="date"
                      value={inputs.date || ""}
                      onChange={handleChange}
                      placeholder="Input Date"
                    />

                    {displayError("work_order_date")}
                  </div>
                  <div className="col-lg">
                    <label>
                      Receive Date <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="receive_date"
                      value={inputs.receive_date || ""}
                      onChange={handleChange}
                      placeholder="Input Date"
                    />

                    {displayError("date")}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg">
                    <label>
                      From <i className="fa fa-star required"></i>
                    </label>
                    <select
                      className="form-control"
                      name="from_department"
                      value={inputs.from_department || ""}
                      onChange={handleChange}
                      disabled
                    >
                      <option value="">Select Department</option>
                      {departments.map((dept) => (
                        <option key={dept.id} value={dept.id}>
                          {dept.name}
                        </option>
                      ))}
                    </select>
                    {displayError("from_department")}
                  </div>

                  <div className="col-lg">
                    <label>
                      To <i className="fa fa-star required"></i>
                    </label>
                    <select
                      className="form-control"
                      name="to_department"
                      value={inputs.to_department || ""}
                      onChange={handleChange}
                    >
                      <option value="">Select Department</option>
                      {departments.map((dept) => (
                        <option key={dept.id} value={dept.id}>
                          {dept.name}
                        </option>
                      ))}
                    </select>
                    {displayError("to_department")}
                  </div>
                  <div className="col-lg">
                    <label>
                      Purchase Type <i className="fa fa-star required"></i>
                    </label>
                    <select
                      className="form-control"
                      name="purchase_type"
                      value={inputs.purchase_type || ""}
                      onChange={handleChange}
                    >
                      <option value="">Select Purchase Type</option>
                      {purchaseTypes.map((type) => (
                        <option key={type.id} value={type.name}>
                          {type.name}
                        </option>
                      ))}
                    </select>
                    {displayError("purchase_type")}
                  </div>
                  <div className="col-lg">
                    <label>
                      Remarks <i className="fa fa-star required"></i>
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      name="remarks"
                      value={inputs.remarks || ""}
                      onChange={handleChange}
                      placeholder="Remarks"
                    />

                    {displayError("remarks")}
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="card-solid">
                    <div className="card-header with-border text-center">
                      <h5 className="card-title mb-0">
                        Items in the Order{" "}
                        <i className="fa fa-star required"></i>
                      </h5>
                    </div>
                    <div className="card-body p-0">
                      <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0">
                        <thead>
                          <tr>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              #
                            </th>
                            {/* <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Shift
                            </th> */}
                            <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Product
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Unit
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Shape
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Shape Type
                            </th>
                            {/* <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Order Number
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Company
                            </th> */}
                            <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              In Hand
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              In Pipeline
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              On Order
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Req. Qty
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Mould Qty Per Set
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Req. Time
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Est. Unit Price
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Est. Total Price
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Avg. Monthly Cons.
                            </th>
                            {/* <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Receive Qty
                            </th> */}
                            <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Decor.
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Remarks
                            </th>
                            {/* <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Required
                            </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {list.length < 1 ? (
                            <tr>
                              <td colSpan={17}>
                                <p
                                  style={{ color: "red" }}
                                  className="text-center mt-3"
                                >
                                  Please Add Few Products For Planning
                                </p>
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}

                          {Array.from(list).map((x, i) => (
                            <tr key={i}>
                              <td>
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={() => removeList(i)}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </td>

                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="product"
                                  value={list[i].name || x.product_name}
                                  // onChange={(e) => handleInputChange(e, i)}
                                  placeholder="Product"
                                />
                              </td>

                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="unit_id"
                                  value={list[i].unit_id || x.unit_id}
                                  // onChange={(e) => handleInputChange(e, i)}
                                  placeholder="Unit"
                                />
                              </td>
                              <td>
                                <select
                                  className="form-control"
                                  name="shape_id"
                                  value={x.shape_id || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                >
                                  <option value="">Select Shape</option>
                                  {shapes.map((shape) => (
                                    <option key={shape.id} value={shape.id}>
                                      {shape.name}
                                    </option>
                                  ))}
                                </select>
                              </td>

                              <td>
                                <select
                                  className="form-control"
                                  name="shape_type_id"
                                  value={x.shape_type_id || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                >
                                  <option value="">Select Shape Type</option>
                                  {shapeTypes.map((type) => (
                                    <option key={type.id} value={type.id}>
                                      {type.name}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="in_hand_qty"
                                  value={x.in_hand_qty || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                  placeholder="In Hand"
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="in_pipeline_qty"
                                  value={x.in_pipeline_qty || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                  placeholder="In Hand"
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="on_order_qty"
                                  value={x.on_order_qty || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                  placeholder="On Order"
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="required_qty"
                                  value={x.required_qty || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                  placeholder="Req. Qty"
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="mould_qty_per_set"
                                  value={x.mould_qty_per_set || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                  placeholder="Mould Qty"
                                />
                              </td>
                              <td>
                                <input
                                  type="time"
                                  className="form-control"
                                  name="required_time"
                                  value={x.required_time || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                  placeholder="In hours"
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="estimated_unit_price"
                                  value={x.estimated_unit_price || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                  placeholder="Est. Unit Price"
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="estimated_total_price"
                                  value={x.estimated_total_price || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                  placeholder="Est. Total Price"
                                />
                              </td>
                              {/* <td>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="starting"
                                  value={x.starting || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </td> */}
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="avg_monthly_consumption"
                                  value={x.avg_monthly_consumption || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                  placeholder="Avg. Monthly Cons."
                                />
                              </td>
                              {/* <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="receive_qty"
                                  value={x.receive_qty || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                  placeholder="Receive Qty"
                                />
                              </td> */}
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="decoration"
                                  value={x.decoration || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                  placeholder="Decoration"
                                />
                              </td>
                              <td>
                                <textarea
                                  type="text"
                                  className="form-control"
                                  name="remarks"
                                  value={x.remarks || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                  placeholder="Remarks"
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>

                        {/* <tfoot>
                          <tr>
                            <td
                              colSpan={6}
                              className="text-right"
                              style={{ lineHeight: "40px" }}
                            >
                              Total DayYYY
                            </td>
                            <td colSpan={2}>
                              <input
                                type="number"
                                className="form-control"
                                name="total_day"
                                value={inputs.total_day || ""}
                                onChange={handleChange}
                                placeholder="Total Day"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td
                              colSpan={6}
                              className="text-right"
                              style={{ lineHeight: "40px" }}
                            >
                              Target Day wiseYYY
                            </td>
                            <td colSpan={2}>
                              <input
                                type="number"
                                className="form-control"
                                name="target_day_wise"
                                value={inputs.target_day_wise || ""}
                                onChange={handleChange}
                                placeholder="Target Day wise"
                              />
                            </td>
                          </tr>
                          
                        </tfoot> */}
                      </table>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-3">
                    <button
                      className="btn btn-primary btn-block mg-b-10"
                      onClick={formSubmit}
                    >
                      <i className="fa fa-send mg-r-10"></i> Update
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className="card pd-20 pd-sm-40 col-3"
            style={{ backgroundColor: "#ffffffc7" }}
          >
            <h6 className="card-body-title mg-sm-b-30 text-center">
              Available Items
            </h6>
            <hr></hr>
            <ReactDataTable
              cols={cols}
              receiveData={combinedData}
              isLoading={loading}
              actionTemplate={actionTemplate}
              // deleteData={deleteData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
