import React, { createContext, useState } from "react";
import Http from "./Components/Common/Http";

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
    const [globalData, setGlobalData] = useState(null);
    const fetchGlobalData = async () => {
        try {
            Http.get("/staff-type-check").then((res) => {
                setGlobalData(res.data);
              });
        } catch (error) {
            console.error("Error fetching global data:", error);
        }
    };

    return (
        <GlobalContext.Provider value={{ globalData, setGlobalData, fetchGlobalData }}>
            {children}
        </GlobalContext.Provider>
    );
};
