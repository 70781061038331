import React, { useEffect, useState } from "react";
import Alert from "../../../Common/Alert";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import { LoaderComponent } from "../../../Common/LoaderComponent";

const Stock = () => {
  const [departments, setDepartments] = useState([]); // For department list
  const [departmentArtId, setDepartmentArtId] = useState(null);
  const [stockData, setStockData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const [me, setMe] = useState({});
  const [selectedUserType, setSelectedUserType] = useState(null); // For dropdown
  const [selectedDepartment, setSelectedDepartment] = useState(""); // Selected department
  const [fromDate, setFromDate] = useState(""); // From date
  const [toDate, setToDate] = useState(""); // To date

  // Function to fetch department_art_id from the /me API
  const fetchDepartmentArtId = async () => {
    try {
      setLoading(true);
      const response = await Http.get("/me");
      setMe(response.data);
      // Fetch department list if admin
      if (response.data?.data?.user_type === "admin") {
        await fetchDepartments();
        // setSelectedUserType(2); // Default deptId for admin
      } else {
        const id = response.data?.data?.department_art_id;
        if (!id) {
          throw new Error("Department ID not found in response.");
        }
        setDepartmentArtId(id);
        fetchStockData(id, response.data?.data?.department_art_id);
      }
    } catch (err) {
      const errorMessage =
        err.response?.data?.error ||
        err.message ||
        "Failed to fetch department ID.";
      showAlert(errorMessage, "danger");
      setLoading(false);
    }
  };

  // Function to fetch stock data
  const fetchStockData = async (id, deptId) => {
    try {
      const response = await Http.post("/stock", {
        department_art_id: id,
        from_date: fromDate,
        to_date: toDate,
      });
      setStockData({ data: response.data.stock, deptId }); // Store stock and deptId
      setLoading(false);
    } catch (err) {
      const errorMessage =
        err.response?.data?.error || "Failed to fetch stock data.";
      showAlert(errorMessage, "danger");
      setLoading(false);
    }
  };

  const fetchDepartments = async () => {
    try {
      const response = await Http.get("/departmentArtSelect2"); // Example API endpoint
      setDepartments(response.data);
    } catch (err) {
      showAlert("Failed to fetch departments.", "danger");
    }
  };

  // Function to display alerts
  const showAlert = (message, type) => {
    setAlert({
      message: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 4000);
  };

  const handleUserTypeChange = (e) => {
    const deptId = e.target.value;
    setSelectedUserType(deptId);
    if (departmentArtId) {
      fetchStockData(departmentArtId, deptId);
    }
  };

  // Handle department selection
  const handleDepartmentChange = (e) => {
    const selectedDeptId = e.target.value;
    setSelectedDepartment(selectedDeptId);
    // if (selectedDeptId) {
    //   fetchStockData(selectedDeptId, parseInt(selectedDeptId));
    // }
  };

  // const handleSubmit = (e) => {
  //   if (selectedDepartment) {
  //     fetchStockData(selectedDepartment, parseInt(selectedDepartment));
  //   } else {
  //     showAlert("Please select a department before submitting.", "warning");
  //   }
  // };

  const handleSubmit = (e) => {
    let departmentIdToSend = selectedDepartment; // Default for admin
    if (me?.data?.user_type !== "admin" && me?.data?.department_art_id !== 15 ) {
      departmentIdToSend = departmentArtId; // Use the value from `me` for non-admin users
    }

    if (departmentIdToSend) {
      fetchStockData(departmentIdToSend, parseInt(departmentIdToSend));
    } else {
      showAlert("Please select a department before submitting.", "warning");
    }
  };

  // Get today's date in YYYY-MM-DD format
  const getTodayDate = () => new Date().toISOString().split("T")[0];
  // Use effect to fetch department ID on component mount
  useEffect(() => {
    const today = getTodayDate();
    setFromDate(today);
    setToDate(today);
    fetchDepartmentArtId();
  }, []);

  // Define a variable for non-specific deptId conditions
  const isOtherUserType =
    stockData.deptId !== 8 &&
    stockData.deptId !== 9 &&
    stockData.deptId !== 12 &&
    stockData.deptId !== 11;

  // Define filtered departments for user_type 15
  const predefinedDepartments = [
    { value: 1, label: "Body" },
    { value: 14, label: "Decel Store" },
    { value: 15, label: "General Store" },
    { value: 17, label: "Packaging Store" },
  ];

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent loading={loading} />
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">Stock Report</h5>
          </div>
          <div className="am-pagebody">
            <Alert alert={alert} />
            <div className="card pd-20 pd-sm-40">
              <div className="row">
                <div className="col-12">
                  <div className="text-center">
                    <h5 className="text-uppercase text-sm font-weight-bolder">
                      Artisan Ceramics Limited
                    </h5>
                    <p>
                      House-16, Road-07, Sector-01, Uttara, Dhaka
                      <br />
                      {me?.data?.user_type === "admin" || me?.data?.department_art_id === 15 ? (
                        <p>
                          <b>Stock Reports</b>
                        </p>
                      ) : (
                        <p>
                          <b>{me.data?.name?.toUpperCase()}</b> Stock Report
                        </p>
                      )}
                      <br />
                    </p>
                  </div>
                  {/* Admin Dropdown for Departments */}
                  {/* {me.data?.user_type === "admin" && (
                    <div className="mb-3">
                      <label
                        htmlFor="departmentSelect"
                        className="form-label text-dark"
                      >
                        Select Department
                      </label>
                      <div className="d-flex align-items-center">
                        <select
                          id="departmentSelect"
                          className="form-control me-2"
                          value={selectedDepartment}
                          onChange={handleDepartmentChange}
                        >
                          <option value="">Select Department</option>
                          {departments.map((dept) => (
                            <option key={dept.value} value={dept.value}>
                              {dept.label}
                            </option>
                          ))}
                        </select>
                        <button
                          className="btn btn-primary"
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )} */}

                  {/* Admin Dropdown for Departments */}
                  {/* {me.data?.user_type === "admin" && (
                    <div className="mb-3">
                      <label
                        htmlFor="departmentSelect"
                        className="form-label text-dark"
                      >
                        Select Department
                      </label>
                      <div className="d-flex align-items-center mb-2">
                        <select
                          id="departmentSelect"
                          className="form-control me-2"
                          value={selectedDepartment}
                          onChange={handleDepartmentChange}
                        >
                          <option value="">Select Department</option>
                          {departments.map((dept) => (
                            <option key={dept.value} value={dept.value}>
                              {dept.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )} */}

                  {/* // Modify the dropdown logic in the render section */}
                  {(me.data?.user_type === "admin" ||
                    (me.data?.department_art_id === 15 &&
                      predefinedDepartments.length > 0)) && (
                    <div className="mb-3">
                      <label
                        htmlFor="departmentSelect"
                        className="form-label text-dark"
                      >
                        Select Department
                      </label>
                      <div className="d-flex align-items-center mb-2">
                        <select
                          id="departmentSelect"
                          className="form-control me-2"
                          value={selectedDepartment}
                          onChange={handleDepartmentChange}
                        >
                          <option value="">Select Department</option>
                          {(me.data?.user_type === "admin"
                            ? departments
                            : predefinedDepartments
                          ).map((dept) => (
                            <option key={dept.value} value={dept.value}>
                              {dept.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}

                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label htmlFor="fromDate" className="form-label">
                        From Date
                      </label>
                      <input
                        type="date"
                        id="fromDate"
                        className="form-control"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="toDate" className="form-label">
                        To Date
                      </label>
                      <input
                        type="date"
                        id="toDate"
                        className="form-control"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                      />
                    </div>
                  </div>

                  <button
                    className="btn btn-primary mb-3"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>

                  <div className="table-responsive p-0 pb-2">
                    <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0 _datatable">
                      <thead>
                        <tr>
                          <th
                            rowSpan="2"
                            className="text-uppercase text-dark text-sm font-weight-bolder ps-2"
                          >
                            Product Name
                          </th>
                          {/* {stockData.deptId === "selection" ? (
                            <>
                              <th
                                colSpan="3"
                                className="text-uppercase text-dark text-sm text-center font-weight-bolder ps-2"
                              >
                                Before Selection Stock
                              </th>
                              <th
                                colSpan="4"
                                className="text-uppercase text-dark text-center text-sm font-weight-bolder ps-2"
                              >
                                Remainings
                              </th>
                            </>
                          ) : (
                            <>
                              <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                Lifetime Qty
                              </th>
                              <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                Lifetime Transfer Qty
                              </th>
                              <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                Stock
                              </th>
                            </>
                          )} */}
                          {stockData.deptId === 8 && (
                            <>
                              <th
                                colSpan="6"
                                className="text-uppercase text-dark text-sm text-center font-weight-bolder ps-2"
                              >
                                Selection Stock
                              </th>
                              <th
                                colSpan="8"
                                className="text-uppercase text-dark text-sm text-center font-weight-bolder ps-2"
                              >
                                Class Stock
                              </th>
                            </>
                          )}
                          {(stockData.deptId === 9 ||
                            stockData.deptId === 12) && (
                            <>
                              <th
                                colSpan="3"
                                className="text-uppercase text-dark text-sm text-center font-weight-bolder ps-2"
                              >
                                Opening Receive Qty
                              </th>
                              <th
                                colSpan="3"
                                className="text-uppercase text-dark text-sm text-center font-weight-bolder ps-2"
                              >
                                Lifetime Receive Qty
                              </th>
                              <th
                                colSpan="3"
                                className="text-uppercase text-dark text-sm text-center font-weight-bolder ps-2"
                              >
                                Opening Delivery Qty
                              </th>
                              <th
                                colSpan="3"
                                className="text-uppercase text-dark text-sm text-center font-weight-bolder ps-2"
                              >
                                Lifetime Delivery Qty
                              </th>
                              <th
                                colSpan="3"
                                className="text-uppercase text-dark text-sm text-center font-weight-bolder ps-2"
                              >
                                Opening Stock
                              </th>
                              <th
                                colSpan="3"
                                className="text-uppercase text-dark text-sm text-center font-weight-bolder ps-2"
                              >
                                Stock
                              </th>
                            </>
                          )}
                          {stockData.deptId === 11 && (
                            <>
                              <th
                                colSpan="4"
                                className="text-uppercase text-dark text-sm text-center font-weight-bolder ps-2"
                              >
                                Opening Receive Qty
                              </th>
                              <th
                                colSpan="4"
                                className="text-uppercase text-dark text-sm text-center font-weight-bolder ps-2"
                              >
                                Lifetime Receive Qty
                              </th>
                              <th
                                colSpan="4"
                                className="text-uppercase text-dark text-sm text-center font-weight-bolder ps-2"
                              >
                                Opening Delivery Qty
                              </th>
                              <th
                                colSpan="4"
                                className="text-uppercase text-dark text-sm text-center font-weight-bolder ps-2"
                              >
                                Lifetime Delivery Qty
                              </th>
                              <th
                                colSpan="4"
                                className="text-uppercase text-dark text-sm text-center font-weight-bolder ps-2"
                              >
                                Opening Stock
                              </th>
                              <th
                                colSpan="4"
                                className="text-uppercase text-dark text-sm text-center font-weight-bolder ps-2"
                              >
                                Stock
                              </th>
                            </>
                          )}
                          {isOtherUserType && (
                            <>
                              {stockData.deptId === 2 ||
                              stockData.deptId === 3 ? (
                                <>
                                  <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    Opening Production Qty
                                  </th>
                                  <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    Closing Production Qty
                                  </th>
                                </>
                              ) : (
                                <>
                                  <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    Opening Receive Qty
                                  </th>
                                  <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    Closing Receive Qty
                                  </th>
                                </>
                              )}
                              <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                Opening Transfer Qty
                              </th>
                              <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                Closing Transfer Qty
                              </th>
                              <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                Opening Stock
                              </th>
                              <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                Closing Stock
                              </th>
                            </>
                          )}
                        </tr>
                        {stockData.deptId === 8 && (
                          <tr>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Opening Total Receive
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Closing Total Receive
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Opening Total Selected
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Closing Total Selected
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Opening Unselected
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Closing Unselected
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Opening Export
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Closing Export
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Opening A
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Closing A
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Opening B
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Closing B
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Opening C
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Closing C
                            </th>
                          </tr>
                        )}
                        {(stockData.deptId === 9 ||
                          stockData.deptId === 12) && (
                          <tr>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              A
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              B
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              C
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              A
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              B
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              C
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              A
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              B
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              C
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              A
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              B
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              C
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              A
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              B
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              C
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              A
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              B
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              C
                            </th>
                          </tr>
                        )}
                        {stockData.deptId === 11 && (
                          <tr>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Export
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              A
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              B
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              C
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Export
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              A
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              B
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              C
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Export
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              A
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              B
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              C
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Export
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              A
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              B
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              C
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Export
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              A
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              B
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              C
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Export
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              A
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              B
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              C
                            </th>
                          </tr>
                        )}
                      </thead>

                      <tbody>
                        {stockData.data?.length > 0 ? (
                          stockData.data.map((item, index) => (
                            <tr key={index}>
                              <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                {item.product_name}
                              </td>
                              {/* {stockData.deptId === "selection" ? (
                                <>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.total_receive}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.total_selected}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.receive_MINUS_selected}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.remaining.export}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.remaining.A}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.remaining.B}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.remaining.C}
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.lifetime_qty || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.lifetime_delivery_qty || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.stock || 0}
                                  </td>
                                </>
                              )} */}
                              {stockData.deptId === 8 && (
                                <>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_total_receive}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.total_receive}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_total_selected}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.total_selected}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_receive_MINUS_selected}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.receive_MINUS_selected}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_selected.export}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.remaining.export}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_selected.A}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.remaining.A}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_selected.B}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.remaining.B}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_selected.C}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.remaining.C}
                                  </td>
                                </>
                              )}
                              {(stockData.deptId === 9 ||
                                stockData.deptId === 12) && (
                                <>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_qty?.A || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_qty?.B || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_qty?.C || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.lifetime_qty?.A || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.lifetime_qty?.B || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.lifetime_qty?.C || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_delivery_qty?.A || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_delivery_qty?.B || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_delivery_qty?.C || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.lifetime_delivery_qty?.A || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.lifetime_delivery_qty?.B || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.lifetime_delivery_qty?.C || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_stock?.A || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_stock?.B || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_stock?.C || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.stock?.A || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.stock?.B || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.stock?.C || 0}
                                  </td>
                                </>
                              )}
                              {stockData.deptId === 11 && (
                                <>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_qty?.Export || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_qty?.A || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_qty?.B || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_qty?.C || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.lifetime_qty?.Export || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.lifetime_qty?.A || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.lifetime_qty?.B || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.lifetime_qty?.C || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_delivery_qty?.Export || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_delivery_qty?.A || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_delivery_qty?.B || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_delivery_qty?.C || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.lifetime_delivery_qty?.Export || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.lifetime_delivery_qty?.A || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.lifetime_delivery_qty?.B || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.lifetime_delivery_qty?.C || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_stock?.Export || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_stock?.A || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_stock?.B || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_stock?.C || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.stock?.Export || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.stock?.A || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.stock?.B || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.stock?.C || 0}
                                  </td>
                                </>
                              )}

                              {isOtherUserType && (
                                <>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_qty || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.lifetime_qty || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_delivery_qty || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.lifetime_delivery_qty || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.opening_stock || 0}
                                  </td>
                                  <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                                    {item.stock || 0}
                                  </td>
                                </>
                              )}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={8}
                              className="text-center text-uppercase text-dark text-sm font-weight-bolder ps-2"
                            >
                              Stock Not Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stock;
