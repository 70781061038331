import $ from "jquery"; // Import jQuery
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Http from "../../../Common/Http";
import ReactDataTable from "../../../Common/ReactDataTable";
//requiredApply 1
import useRequiredFields from "../../../../hooks/useRequiredFields";

const Create = () => {
  const navigate = useNavigate();
  //requiredApply 2
  const { checkRequiredFields } = useRequiredFields();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  // const [product, setProduct] = useState([]);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [itemLoading, setItemLoading] = useState(false);
  const [shapes, setShapes] = useState([]); // Store shapes from API
  const [shapeTypes, setShapeTypes] = useState([]); // Store shape types from API
  // const [machines, setMachines] = useState([]); // Store shape types from API
  const [workOrders, setWorkOrders] = useState([]); // Store work orders from API
  // const [unit, setUnit] = useState([]); // Store shape types from API
  const [departments, setDepartments] = useState([
    // { id: 1, name: "BODY", user_type: "body" },
    // { id: 2, name: "JIGGER", user_type: "jigger" },
    // { id: 3, name: "CASTING", user_type: "casting" },
    // { id: 4, name: "FINISHING", user_type: "finishing" },
    // { id: 5, name: "BK", user_type: "bk" },
    // { id: 6, name: "GLAZING", user_type: "glazing" },
    // { id: 7, name: "GK", user_type: "gk" },
    // { id: 8, name: "SELECTION", user_type: "selection" },
    // { id: 9, name: "DECORATION LOCAL", user_type: "decoration_local" },
    // { id: 10, name: "DECORATION EXPORT", user_type: "decoration_export" },
    // { id: 11, name: "DFW", user_type: "dfw" },
    // { id: 12, name: "PACKING LOCAL", user_type: "packing_local" },
    // { id: 13, name: "PACKING EXPORT", user_type: "packing_export" },
    { id: 14, name: "Decel Store", user_type: "dstore" },
    { id: 1, name: "General Store", user_type: "gstore" },
    // { id: 16, name: "MOULD", user_type: "mould" },
    { id: 17, name: "Packaging Store", user_type: "pstore" },
    // { id: 18, name: "PLANNING", user_type: "planning" },
    // { id: 19, name: "SAGGER", user_type: "sagger" }
  ]);
  const [product, setProduct] = useState([]);
  const [rmpm, setRmpm] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [role, setRole] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]); // Store filtered products

  useEffect(() => {
    const setCurrentDate = () => {
      const today = new Date().toISOString().split("T")[0];
      setInputs((prevInputs) => ({
        ...prevInputs,
        date: today,
      }));
    };

    setCurrentDate();
    fetchInitialData();
    $("body").addClass("collapse-menu");
    // $("body").toggleClass("show-left");
  }, []);

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      Http.get("/staff-type-check").then((res) => {
        setRole(res.data);
      });

      // const resProduct = await Http.get("/fg-with-stock");
      //   setProduct(resProduct.data);

      const resRmpm = await Http.get("/product-list/all");
      setRmpm(resRmpm.data);

      //   const combined = [
      //     ...resRmpm.data.map(item => ({ ...item, type: 'RMPM' })),
      //     ...resProduct.data.map(item => ({ ...item, type: 'FG' }))
      //   ];
      // setCombinedData(combined);
      // const response = await Http.get("/shape"); // Fetch shape data from API
      // const data = response.data;
      // setShapes(data);
      // const resUnit = await Http.get("/unit");
      // setUnit(resUnit.data);
      // Fetch work orders from API
      // const resWorkOrders = await Http.get("/production-work-order");
      // setWorkOrders(resWorkOrders.data);
      // const resMachine = await Http.get("/machine"); // Fetch shape data from API
      // setMachines(resMachine.data);

      // Extract unique shape types from the shape data
      // const uniqueShapeTypes = [
      //   ...new Map(
      //     data.map((item) => [item.shape_type.id, item.shape_type])
      //   ).values(),
      // ];
      // setShapeTypes(uniqueShapeTypes);

      const responseStaff = await Http.get("/staff-type-check");
      const staffType = responseStaff.data; // Assuming the responseStaff is a string

      // Find the department matching the user_type
      // const matchingDepartment = departments.find(
      //   (dept) => dept.user_type === staffType
      // );

      // if (matchingDepartment) {
      //   // Update the inputs with the matching department ID
      //   setInputs((prevInputs) => ({
      //     ...prevInputs,
      //     from_department: matchingDepartment.id,
      //   }));
      //   // Fetch products based on the department's factory_id
      // const factoryId = matchingDepartment.id; // Assuming department ID represents factory_id

      // // Use the existing endpoint to get products for the factory
      // const productResponse = await Http.get(`/product-list/all`);
      // setFilteredProducts(productResponse.data); // Set the fetched products
      // }

      const resInvoiceNo = await Http.get(
        "/last-code-increment-by-one/purchase_requisition_arts/receipt_no/SPR"
      );
      setInputs((values) => ({
        ...values,
        receipt_no: resInvoiceNo.data,
      }));
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  const addMoreList = async (id) => {
    setItemLoading(true); // Use itemLoading to control this specific action
    try {
      const response = await Http.get(`/product/${id}`);
      if (!list.find((item) => item.id === response.data.id)) {
        setList((prev) => [...prev, response.data]);
      } else {
        Swal.fire(
          "Warning!",
          "This Product is already in the list!",
          "warning"
        );
      }
    } catch (error) {
      console.error("Error fetching product:", error);
      Swal.fire("Error!", "Failed to add product.", "error");
    } finally {
      setItemLoading(false); // Stop loading after action is complete
    }
  };

  const removeList = (index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedList = list.filter((_, i) => i !== index);
        setList(updatedList);
        Swal.fire("Deleted!", "Your item has been deleted.", "success");
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the value is numeric (e.g., department ID), convert it to an integer
    const parsedValue = !isNaN(value) && value !== "" ? parseInt(value) : value;

    setInputs((values) => ({ ...values, [name]: parsedValue }));
  };

  const selectHandleChange = async (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    const { value } = selectedOption;

    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target; // Chanages Experience Input Name and Value
    const inputInfo = [...list]; // Defined Experience State All Information into List Variable

    inputInfo[index][name] = value;
    setList(inputInfo);
  };

  const formSubmit = (e) => {
    //requiredApply 3 (also add 'e' in function like above line)(after this just write 'data-required' where neccessary)
    e.preventDefault();
    const isValid = checkRequiredFields();

    // if (!isValid) {
    //   Swal.fire("Error!", "Please fill out all required fields.", "error");
    //   return;
    // }
    inputs.items = list;
    // inputs.sr_id = parseInt(inputs.sr_id);
    const updatedList = list.map((item) => ({
      ...item,
      product_id: item.id, // Map the 'id' to 'product_id'
      // shape_id: item.shape_id || null, // Ensure shape_id is included
      // unit_id: item.unit_id || null, // Ensure shape_id is included
      // machine_id: item.machine_id || null,  // Ensure shape_id is included
      // shape_type_id: item.shape_type_id || null, // Ensure shape_type_id is included
      // starting_date: item.starting || null,  // Ensure starting date is included
      // closing_date: item.closing || null,    // Ensure closing date is included
      // total_day: item.total_day || null,   // Ensure total_day is included
    }));

    // Assign the updated list to inputs.items
    inputs.items = updatedList;
    Http.post("/store-purchase-requisition-art", inputs)
      .then((res) => {
        navigate("/store-purchase-requisition-art", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };
  const displayError = (fieldName) => {
    if (error && error[fieldName]) {
      return <div className="text-danger small">{error[fieldName]}</div>;
    }
    return null;
  };
  const cols = [
    { field: "code", header: "Code" },
    { field: "name", header: "Item" },
  ];

  // Dummy arrays for the select fields with id and name
  // const copyTypes = [
  //   { id: 1, name: "Original" },
  //   { id: 2, name: "Duplicate" },
  //   { id: 3, name: "Triplicate" },
  // ];

  //   const departments = [
  //     { id: 1, name: "BODY", user_type: "body" },
  //     { id: 2, name: "JIGGER", user_type: "jigger" },
  //     { id: 3, name: "CASTING", user_type: "casting" },
  //     { id: 4, name: "FINISHING", user_type: "finishing" },
  //     { id: 5, name: "BK", user_type: "bk" },
  //     { id: 6, name: "GLAZING", user_type: "glazing" },
  //     { id: 7, name: "GK", user_type: "gk" },
  //     { id: 8, name: "SELECTION", user_type: "selection" },
  //     { id: 9, name: "DECORATION LOCAL", user_type: "decoration local" },
  //     { id: 10, name: "DECORATION EXPORT", user_type: "decoration export" },
  //     { id: 11, name: "DFW", user_type: "dfw" },
  //     { id: 12, name: "PACKING LOCAL", user_type: "packing local" },
  //     { id: 13, name: "PACKING EXPORT", user_type: "packing export" },
  //     { id: 14, name: "DSTORE", user_type: "dstore" },
  //     { id: 15, name: "GSTORE", user_type: "gstore" },
  //     { id: 16, name: "MOULD", user_type: "mould" },
  //     { id: 17, name: "PSTORE", user_type: "pstore" },
  //     { id: 18, name: "PLANNING", user_type: "planning" },
  //     { id: 19, name: "SAGGER", user_type: "sagger" }
  // ];

  const purchaseTypes = [
    { id: 1, name: "Local" },
    { id: 2, name: "Foreign" },
  ];

  const actionTemplate = (item, deleteData) => {
    return (
      <td className="text-right">
        <button
          className="btn btn-success btn-sm"
          onClick={() => addMoreList(item.id)}
        >
          <i className="fa fa-plus"></i>
        </button>
      </td>
    );
  };
  return (
    <div>
      {/* <IsLogin></IsLogin> */}
      {/* <LoaderComponent /> */}
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Store Purchase Requisition</h5>
        </div>
        <div className="am-pagebody row d-flex justify-content-evenly">
          <div className="card pd-20 pd-sm-40 col-7">
            <h6 className="card-body-title mg-sm-b-30 text-center">
              Requisition Form
            </h6>
            <hr></hr>
            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg">
                    <label>
                      Receipt No <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="receipt_no"
                      value={inputs.receipt_no || ""}
                      onChange={handleChange}
                      placeholder="Input Invoice No"
                    />

                    {displayError("receipt_no")}
                  </div>
                  {/* {
                    role == "body" && (
                      <div className="col-lg">
                        <label>
                          Work Order <i className="fa fa-star required"></i>
                        </label>
                        Dropdown for work order
                        <select
                          className="form-control"
                          name="production_work_order_id"
                          value={inputs.production_work_order_id || ""}
                          onChange={handleChange}
                        >
                          <option value="">Select Work Order</option>
                          {workOrders.map((order) => (
                            <option key={order.id} value={order.id}>
                              {order.receipt_no}
                            </option>
                          ))}
                        </select>
                        {displayError("production_work_order_id")}
                      </div>
                    )
                  } */}
                  {(role == "mould" ||
                    role == "sagger" ||
                    role == "gstore" ||
                    role == "dstore" ||
                    role == "pstore") && (
                    <>
                      {/* <div className="col-lg">
                      <label>
                        Copy Type <i className="fa fa-star required"></i>
                      </label>
                      <select
                        className="form-control"
                        name="copy_type"
                        value={inputs.copy_type || ""}
                        onChange={handleChange}
                      >
                        <option value="">Select Copy Type</option>
                        {copyTypes.map((type) => (
                          <option key={type.id} value={type.name}>
                            {type.name}
                          </option>
                        ))}
                      </select>
                      {displayError("copy_type")}
                    </div> */}
                    </>
                  )}

                  <div className="col-lg">
                    <label>
                      Date <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="date"
                      value={inputs.date || ""}
                      onChange={handleChange}
                      placeholder="Input Date"
                    />

                    {displayError("work_order_date")}
                  </div>
                  {/* <div className="col-lg">
                    <label>
                      Receive Date <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="receive_date"
                      value={inputs.receive_date || ""}
                      onChange={handleChange}
                      placeholder="Input Date"
                    />

                    {displayError("date")}
                  </div> */}
                </div>
                <div className="row mt-3">
                  {/* <div className="col-lg">
                    <label>
                      From <i className="fa fa-star required"></i>
                    </label>
                    <select
                      className="form-control"
                      name="from_department"
                      value={inputs.from_department || ""}
                      onChange={handleChange}
                      disabled
                    >
                      <option value="">Select Department</option>
                      {departments.map((dept) => (
                        <option key={dept.id} value={dept.id}>
                          {dept.name}
                        </option>
                      ))}
                    </select>
                    {displayError("from_department")}
                  </div> */}

                  {/* <div className="col-lg">
                    <label>
                      To <i className="fa fa-star required"></i>
                    </label>
                    <select
                      className="form-control"
                      name="to_department"
                      value={inputs.to_department || ""}
                      onChange={handleChange}
                    >
                      <option value="">Select Department</option>
                      {departments.map((dept) => (
                        <option key={dept.id} value={dept.id}>
                          {dept.name}
                        </option>
                      ))}
                    </select>
                    {displayError("to_department")}
                  </div> */}
                  {(role == "admin" ||
                    role == "mould" ||
                    role == "sagger" ||
                    role == "gstore" ||
                    role == "depot") && (
                    <>
                      <div className="col-lg">
                        <label>
                          Purchase Type <i className="fa fa-star required"></i>
                        </label>
                        <select
                          className="form-control"
                          name="purchase_type"
                          value={inputs.purchase_type || ""}
                          onChange={handleChange}
                          data-required
                        >
                          <option value="">Select Purchase Type</option>
                          {purchaseTypes.map((type) => (
                            <option key={type.id} value={type.name}>
                              {type.name}
                            </option>
                          ))}
                        </select>
                        {/* {displayError("purchase_type")} */}
                      </div>
                    </>
                  )}
                  <div className="col-lg">
                    <label>Remarks</label>
                    <textarea
                      type="text"
                      className="form-control"
                      name="remarks"
                      value={inputs.remarks || ""}
                      onChange={handleChange}
                      placeholder="Remarks"
                    />

                    {displayError("remarks")}
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="card-solid">
                    <div className="card-header with-border text-center">
                      <h5 className="card-title mb-0">
                        Items in the Order{" "}
                        <i className="fa fa-star required"></i>
                      </h5>
                    </div>
                    <div className="card-body p-0">
                      <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0">
                        <thead>
                          <tr>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              #
                            </th>
                            {/* <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Shift
                            </th> */}
                            <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Product
                            </th>
                            {/* <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Unit
                            </th> 
                            <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Shape
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Shape Type
                            </th>
                             <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Order Number
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Company
                            </th> 
                            <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              In Hand
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              In Pipeline
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              On Order
                            </th>*/}
                            <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Req. Qty <i className="fa fa-star required"></i>
                            </th>
                            {/* {role == 'mould' && (
                              <>
                                <th className="text-uppercase text-dark text-sm font-weight-bolder">
                                Mould Qty Per Set 
                                </th>
                              </>
                            )} */}

                            <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Req. Time
                            </th>
                            {/* <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Est. Unit Price
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Est. Total Price
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Avg. Monthly Cons.
                            </th> */}
                            {/* <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Receive Qty
                            </th> */}

                            {/* {(role == 'decoration_local' || role == 'decoration_export') && (
                                <>
                              <th className="text-uppercase text-dark text-sm font-weight-bolder">
                                Decor.
                              </th>
                            </>)} */}
                            <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Remarks
                            </th>
                            {/* <th className="text-uppercase text-dark text-sm font-weight-bolder">
                              Required
                            </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {list.length < 1 ? (
                            <tr>
                              <td colSpan={8}>
                                <p
                                  style={{ color: "red" }}
                                  className="text-center mt-3"
                                >
                                  Please Add Few Products For Planning
                                </p>
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}

                          {Array.from(list).map((x, i) => (
                            <tr key={i}>
                              <td>
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={() => removeList(i)}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </td>

                              <td>
                                {/* <input
                                  type="text"
                                  className="form-control"
                                  name="product"
                                  value={list[i].name}
                                  // onChange={(e) => handleInputChange(e, i)}
                                  placeholder="Product"
                                /> */}
                                {list[i].code} # {list[i].name}
                              </td>

                              {/* <td>
                              <input
                                  type="text"
                                  className="form-control"
                                  name="product"
                                  value={list[i].unit_id}
                                  // onChange={(e) => handleInputChange(e, i)}
                                  placeholder="Product"
                                />
                              </td> 
                              <td>
                                <select
                                  className="form-control"
                                  name="shape_id"
                                  value={x.shape_id || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                >
                                  <option value="">Select Shape</option>
                                  {shapes.map((shape) => (
                                    <option key={shape.id} value={shape.id}>
                                      {shape.name}
                                    </option>
                                  ))}
                                </select>
                              </td>

                              <td>
                                <select
                                  className="form-control"
                                  name="shape_type_id"
                                  value={x.shape_type_id || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                >
                                  <option value="">Select Shape Type</option>
                                  {shapeTypes.map((type) => (
                                    <option key={type.id} value={type.id}>
                                      {type.name}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="in_hand_qty"
                                  value={x.in_hand_qty || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                  placeholder="In Hand"
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="in_pipeline_qty"
                                  value={x.in_pipeline_qty || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                  placeholder="In Hand"
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="on_order_qty"
                                  value={x.on_order_qty || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                  placeholder="On Order"
                                />
                              </td>*/}
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="required_qty"
                                  value={x.required_qty || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                  placeholder="Req. Qty"
                                  data-required
                                />
                              </td>
                              {role == "mould" && (
                                <>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="mould_qty_per_set"
                                      value={x.mould_qty_per_set || ""}
                                      onChange={(e) => handleInputChange(e, i)}
                                      placeholder="Mould Qty"
                                    />
                                  </td>
                                </>
                              )}
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="required_time"
                                  value={x.required_time || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                  placeholder="Required Time"
                                />
                              </td>
                              {/*<td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="estimated_unit_price"
                                  value={x.estimated_unit_price || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                  placeholder="Est. Unit Price"
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="estimated_total_price"
                                  value={x.estimated_total_price || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                  placeholder="Est. Total Price"
                                />
                              </td>
                               <td>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="starting"
                                  value={x.starting || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </td> 
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="avg_monthly_consumption"
                                  value={x.avg_monthly_consumption || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                  placeholder="Avg. Monthly Cons."
                                />
                              </td>
                               <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="receive_qty"
                                  value={x.receive_qty || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                  placeholder="Receive Qty"
                                />
                              </td> */}
                              {(role == "decoration_local" ||
                                role == "decoration_export") && (
                                <>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="decoration"
                                      value={x.decoration || ""}
                                      onChange={(e) => handleInputChange(e, i)}
                                      placeholder="Decoration"
                                    />
                                  </td>
                                </>
                              )}

                              <td>
                                <textarea
                                  type="text"
                                  className="form-control"
                                  name="remarks"
                                  value={x.remarks || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                  placeholder="Remarks"
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>

                        {/* <tfoot>
                          <tr>
                            <td
                              colSpan={6}
                              className="text-right"
                              style={{ lineHeight: "40px" }}
                            >
                              Total DayYYY
                            </td>
                            <td colSpan={2}>
                              <input
                                type="number"
                                className="form-control"
                                name="total_day"
                                value={inputs.total_day || ""}
                                onChange={handleChange}
                                placeholder="Total Day"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td
                              colSpan={6}
                              className="text-right"
                              style={{ lineHeight: "40px" }}
                            >
                              Target Day wiseYYY
                            </td>
                            <td colSpan={2}>
                              <input
                                type="number"
                                className="form-control"
                                name="target_day_wise"
                                value={inputs.target_day_wise || ""}
                                onChange={handleChange}
                                placeholder="Target Day wise"
                              />
                            </td>
                          </tr>
                          
                        </tfoot> */}
                      </table>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-3">
                    <button
                      className="btn btn-primary btn-block mg-b-10"
                      onClick={formSubmit}
                    >
                      <i className="fa fa-send mg-r-10"></i> Create
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className="card pd-20 pd-sm-40 col-4"
            style={{ backgroundColor: "#ffffffc7" }}
          >
            <h6 className="card-body-title mg-sm-b-30 text-center">
              Available Items
            </h6>
            <hr></hr>
            <ReactDataTable
              cols={cols}
              receiveData={rmpm}
              isLoading={loading}
              actionTemplate={actionTemplate}
              // deleteData={deleteData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create;
