import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FormValidation from "../../../Common/FormValidation";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import { LoaderComponent } from "../../../Common/LoaderComponent";
import useRequiredFields from "../../../../hooks/useRequiredFields";

const Production = () => {
  const { checkRequiredFields } = useRequiredFields();
  const navigate = useNavigate();
  // const { id } = useParams();
  const [error, setError] = useState({});
  const [receipt, setReceipt] = useState("");
  // const [receiveData, setReceiveData] = useState([]);
  const [inputs, setInputs] = useState({ status: "1" });
  // const [workOrders, setWorkOrders] = useState([]); // State for work orders
  const [role, setRole] = useState(""); // State for role

  const fetchData = async () => {
    // Http.get("/issue/" + id).then((res) => {
    //   setReceiveData(res.data.info);
    // });
    // const resWorkOrders = await Http.get("/production-work-order"); // Fetch work orders
    // setWorkOrders(resWorkOrders.data);
    const resRole = await Http.get("/staff-type-check"); // Fetch role
    setRole(resRole.data);
    const resRec = await Http.get("/last-code-increment-by-one/return_arts/receipt_no/RET"); // Fetch role
    setReceipt(resRec.data);
    setInputs((prevInputs) => ({
      ...prevInputs,
      receipt_no: resRec.data, // Set receipt_no directly during fetch
      date: getTodayDate(),
      status: "returned",
    }));
  };
  useEffect(() => {
    fetchData();
    
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const formSubmit = () => {
    checkRequiredFields();
    // inputs.items = receiveData;
    // inputs.issue_id = id;

    Http.post("/return-art", inputs)
      .then((res) => {
        navigate("/return-art", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Create Return</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Return Filter Cake</h6>
            <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div>
            <div className="row">
              <div className="col-lg">
                <label>Receipt</label>
                <input
                  type="text"
                  className="form-control"
                  name="receipt_no"
                  value={receipt}
                  onChange={handleChange}
                  placeholder="Input Date"
                />
              </div>
              <div className="col-lg">
                <label>Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  value={inputs.date || ""}
                  onChange={handleChange}
                  placeholder="Input Date"
                />
              </div>
              <div className="col-lg">
              <label>Shift <i className="fa fa-star required"></i></label>
                <select
                  className="form-control"
                  name="shift"
                  value={inputs.shift || ""}
                  onChange={handleChange}
                  data-required
                >
                  <option value="">Select Work Order</option>
                  <option value="a">A</option>
                  <option value="b">B</option>
                  <option value="c">C</option>
                  <option value="general">D</option>
                  
                </select>
              </div>

              {/* <div className="col-lg">
                <label>Standard Size</label>
                <input
                  type="text"
                  readOnly
                  className="form-control"
                  name="standard_size"
                  value={receiveData.batch_size || ""}
                  placeholder="Input Standerd Size"
                />
              </div> */}
              {/* <div className="col-lg">
                <label>Previous Produced</label>
                <input
                  type="number"
                  className="form-control"
                  name="prev_qty"
                  value={inputs.prev_qty || 0}
                  onChange={handleChange}
                  // placeholder="Input Produced Qty"
                  readOnly
                />
              </div> */}
              {/* <div className="col-lg">
                <label>Return Qty</label>
                <input
                  type="number"
                  className="form-control"
                  name="return_qty"
                  value={inputs.return_qty}
                  onChange={handleChange}
                  placeholder="Input Produced Qty"
                />
              </div> */}
              {/* {role === "mould" && (
                <div className="col-lg">
                  <label>Plaster of Paris</label>
                  <input
                    type="number"
                    className="form-control"
                    name="plaster_paris"
                    value={inputs.plaster_paris || 0}
                    onChange={handleChange}
                    placeholder="Input Plaster of Paris"
                  />
                </div>
              )} */}
            </div>
            <div className="row mt-3">
              {/* {role !== "body" && (
                <div className="col-lg">
                  <label>Loss Qty</label>
                  <input
                    type="number"
                    className="form-control"
                    name="loss_qty"
                    value={inputs.loss_qty || 0}
                    onChange={handleChange}
                    placeholder="Input Loss Qty"
                  />
                </div>
              )} */}
              <div className="col-lg">
                <label>Return Qty</label>
                <input
                  type="number"
                  className="form-control"
                  name="return_qty"
                  value={inputs.return_qty || 0}
                  onChange={handleChange}
                  placeholder="Input Deliver Qty"
                />
              </div>
              {/* {role === "body" && inputs.transfer_qty > 0 && (
                <div className="col-lg">
                  <label>Transfer To Department</label>
                  <select
                    className="form-control"
                    name="to_department_id"
                    onChange={handleChange}
                  >
                    <option value={""}>Select Department</option>
                    <option value={2}>Jigger</option>
                    <option value={3}>Casting</option>
                    <option value={6}>Glaze</option>
                  </select>
                </div>
              )} */}
              {/* <div className="col-lg">
                <label>Complete / Incomplete</label>
                <select
                  className="form-control"
                  name="status"
                  onChange={handleChange}
                >
                  <option value={""}>Select Status</option>
                  <option value={0}>Incomplete</option>
                  <option value={1}>Complete</option>
                </select>
              </div> */}
              <div className="col-lg">
                <label>Remarks</label>
                <textarea
                  className="form-control"
                  name="remarks"
                  onChange={handleChange}
                  placeholder="Remarks"
                >
                  {inputs.remarks || ""}
                </textarea>
              </div>
            </div>

            {/* <div className="row mt-3">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="center">#</th>
                    <th>Product</th>
                    <th className="text-right">Stndrd Qty</th>
                    <th className="text-right">Qty</th>
                  </tr>
                </thead>
                <tbody>
                  {receiveData.items
                    ? receiveData.items.map((item, index) => (
                        <tr key={item.id}>
                          <td className="center">{index + 1}</td>
                          <td className="left strong">
                            {item.product ? item.product.name : ""}
                          </td>
                          <td className="text-right">{item.stndrd_qty}</td>
                          <td className="text-right">{item.qty}</td>
                        </tr>
                      ))
                    : ""}
                </tbody>
              </table>
            </div> */}

            <div className="row mt-3">
              <div className="col-3">
                <button
                  className="btn btn-primary btn-block mg-b-10"
                  onClick={formSubmit}
                >
                  <i className="fa fa-send mg-r-10"></i> Submit
                </button>
              </div>
            </div>
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default Production;
