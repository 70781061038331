import $ from "jquery"; // Import jQuery
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Http from "../../../Common/Http";
import ReactDataTable from "../../../Common/ReactDataTable";

const Edit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [itemLoading, setItemLoading] = useState(false);
  // const [shapes, setShapes] = useState([]);
  // const [shapeTypes, setShapeTypes] = useState([]);
  // const [workOrders, setWorkOrders] = useState([]);
  const [departments, setDepartments] = useState([
    { id: 14, name: "Decel Store", user_type: "dstore" },
    { id: 15, name: "General Store", user_type: "gstore" },
    { id: 17, name: "Packaging Store", user_type: "pstore" },
  ]);
  const [product, setProduct] = useState([]);
  // const [rmpm, setRmpm] = useState([]);
  // const [combinedData, setCombinedData] = useState([]);

  useEffect(() => {
    fetchInitialData();
    $("body").addClass("collapse-menu");
    // $("body").toggleClass("show-left");
  }, [id]);

  // const fetchInitialData = async () => {
  //   setLoading(true);
  //   try {
  //     const resProduct = await Http.get("/fg-with-stock");
  //     setProduct(resProduct.data);

  //     const resRmpm = await Http.get("/rmpm-with-stock");
  //     setRmpm(resRmpm.data);

  //     const combined = [
  //       ...resRmpm.data.map(item => ({ ...item, type: 'RMPM' })),
  //       ...resProduct.data.map(item => ({ ...item, type: 'FG' }))
  //     ];
  //     setCombinedData(combined);

  //     const response = await Http.get("/shape");
  //     const data = response.data;
  //     setShapes(data);

  //     const resWorkOrders = await Http.get("/production-work-order");
  //     setWorkOrders(resWorkOrders.data);

  //     const uniqueShapeTypes = [
  //       ...new Map(data.map((item) => [item.shape_type.id, item.shape_type])).values()
  //     ];
  //     setShapeTypes(uniqueShapeTypes);

  //     const resReq = await Http.get(`/store-purchase-requisition-art/${id}`);
  //     const requisitionData = resReq.data;

  //     setInputs({
  //       receipt_no: requisitionData.receipt_no || "",
  //       date: requisitionData.date || "",
  //       purchase_type: requisitionData.purchase_type || "",
  //       remarks: requisitionData.remarks || ""
  //     });

  //     setList(requisitionData.items.map((item) => ({
  //       id: item.id,
  //       name: item.name,
  //       required_qty: item.required_qty,
  //       mould_qty_per_set: item.mould_qty_per_set,
  //       required_time: item.required_time,
  //       remarks: item.remarks
  //     })));

  //   } catch (error) {
  //     console.error("Fetch error:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      const resProduct = await Http.get("/product-list/all");
      setProduct(resProduct.data);

      // const resRmpm = await Http.get("/rmpm-with-stock");
      // setRmpm(resRmpm.data);

      // const combined = [
      //   ...resRmpm.data.map((item) => ({ ...item, type: "RMPM" })),
      //   ...resProduct.data.map((item) => ({ ...item, type: "FG" }))
      // ];
      // setCombinedData(combined);

      // const response = await Http.get("/shape");
      // const data = response.data;
      // setShapes(data);

      // const resWorkOrders = await Http.get("/production-work-order");
      // setWorkOrders(resWorkOrders.data);

      // const uniqueShapeTypes = [
      //   ...new Map(data.map((item) => [item.shape_type.id, item.shape_type])).values()
      // ];
      // setShapeTypes(uniqueShapeTypes);

      // Fetch requisition data for editing
      const resReq = await Http.get(`/store-purchase-requisition-art/${id}`);
      const requisitionData = resReq.data;

      // Populate form inputs with the fetched data
      setInputs({
        receipt_no: requisitionData.receipt_no || "",
        date: requisitionData.date || "",
        purchase_type:
          capitalizeFirstLetter(requisitionData.purchase_type) || "",
        from_department: requisitionData.from_department || "",
        remarks: requisitionData.remarks || "",
      });

      // Populate the list for items, including product names
      setList(
        requisitionData.items.map((item) => ({
          id: item.id,
          product_id: item.product_id,
          product_name: item.product.name, // Set the product name properly
          unit_id: item.unit_id, // Include unit_id here
          required_qty: item.required_qty,
          required_time: item.required_time,
          remarks: item.remarks,
        }))
      );
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const addMoreList = async (id) => {
    setItemLoading(true);
    try {
      const response = await Http.get(`/product/${id}`);
      if (!list.find((item) => item.id === response.data.id)) {
        setList((prev) => [
          ...prev,
          { product_id: response.data.id, ...response.data },
        ]);
      } else {
        Swal.fire(
          "Warning!",
          "This Product is already in the list!",
          "warning"
        );
      }
    } catch (error) {
      console.error("Error fetching product:", error);
      Swal.fire("Error!", "Failed to add product.", "error");
    } finally {
      setItemLoading(false);
    }
  };

  const removeList = (index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedList = list.filter((_, i) => i !== index);
        setList(updatedList);
        Swal.fire("Deleted!", "Your item has been deleted.", "success");
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const parsedValue = !isNaN(value) && value !== "" ? parseInt(value) : value;
    setInputs((values) => ({ ...values, [name]: parsedValue }));
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const inputInfo = [...list];
    inputInfo[index][name] = value;
    setList(inputInfo);
  };

  const formSubmit = () => {
    inputs.items = list.map((item) => ({
      ...item,
      id: item.id || null,
      unit_id: item.unit_id || null,
    }));

    Http.put(`/store-purchase-requisition-art/${id}`, inputs)
      .then((res) => {
        navigate("/store-purchase-requisition-art", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  const displayError = (fieldName) => {
    if (error && error[fieldName]) {
      return <div className="text-danger small">{error[fieldName]}</div>;
    }
    return null;
  };

  const cols = [
    { field: "code", header: "Code" },
    { field: "name", header: "Item" },
  ];

  const purchaseTypes = [
    { id: 1, name: "Local" },
    { id: 2, name: "Foreign" },
  ];

  return (
    <div>
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Edit Store Purchase Requisition</h5>
        </div>
        <div className="am-pagebody row d-flex justify-content-evenly">
          <div className="card pd-20 pd-sm-40 col-7">
            <h6 className="card-body-title mg-sm-b-30 text-center">
              Edit Requisition Form
            </h6>
            <hr></hr>
            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg">
                    <label>
                      Receipt No <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="receipt_no"
                      value={inputs.receipt_no || ""}
                      onChange={handleChange}
                      placeholder="Input Receipt No"
                    />
                    {displayError("receipt_no")}
                  </div>
                  <div className="col-lg">
                    <label>
                      Date <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="date"
                      value={inputs.date || ""}
                      onChange={handleChange}
                      placeholder="Input Date"
                    />
                    {displayError("date")}
                  </div>
                  <div className="col-lg">
                    <label>
                      Purchase Type <i className="fa fa-star required"></i>
                    </label>
                    <select
                      className="form-control"
                      name="purchase_type"
                      value={inputs.purchase_type || ""}
                      onChange={handleChange}
                    >
                      <option value="">Select Purchase Type</option>
                      {purchaseTypes.map((type) => (
                        <option key={type.id} value={type.name}>
                          {type.name}
                        </option>
                      ))}
                    </select>
                    {displayError("purchase_type")}
                  </div>
                  <div className="col-lg">
                    <label>
                      Remarks <i className="fa fa-star required"></i>
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      name="remarks"
                      value={inputs.remarks || ""}
                      onChange={handleChange}
                      placeholder="Remarks"
                    />
                    {displayError("remarks")}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="card-solid">
                    <div className="card-header with-border text-center">
                      <h5 className="card-title mb-0">Items in the Order</h5>
                    </div>
                    <div className="card-body p-0">
                      <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Product</th>
                            <th>Req. Qty</th>
                            <th>Req. Time</th>
                            <th>Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          {list.length < 1 ? (
                            <tr>
                              <td colSpan={5}>
                                <p
                                  style={{ color: "red" }}
                                  className="text-center mt-3"
                                >
                                  Please Add Few Products For Planning
                                </p>
                              </td>
                            </tr>
                          ) : (
                            list.map((x, i) => (
                              <tr key={i}>
                                <td>
                                  <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => removeList(i)}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </td>
                                <td>{x.product_name || list[i].name}</td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="required_qty"
                                    value={x.required_qty || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                    placeholder="Req. Qty"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="required_time"
                                    value={x.required_time || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                    placeholder="Req. Time"
                                  />
                                </td>
                                <td>
                                  <textarea
                                    type="text"
                                    className="form-control"
                                    name="remarks"
                                    value={x.remarks || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                    placeholder="Remarks"
                                  />
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-3">
                    <button
                      className="btn btn-primary btn-block mg-b-10"
                      onClick={formSubmit}
                    >
                      <i className="fa fa-send mg-r-10"></i> Update
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className="card pd-20 pd-sm-40 col-4"
            style={{ backgroundColor: "#ffffffc7" }}
          >
            <h6 className="card-body-title mg-sm-b-30 text-center">
              Available Items
            </h6>
            <hr></hr>
            <ReactDataTable
              cols={cols}
              receiveData={product}
              isLoading={loading}
              actionTemplate={(item) => (
                <td className="text-right">
                  <button
                    className="btn btn-success btn-sm"
                    onClick={() => addMoreList(item.id)}
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                </td>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
