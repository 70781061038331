import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Http from "../../../Common/Http";
import { LoaderComponent } from "../../../Common/LoaderComponent";

const View = () => {
  const [requisitionData, setRequisitionData] = useState({});
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [departments, setDepartments] = useState([
    { id: 14, name: "Decel Store", user_type: "dstore" },
    { id: 15, name: "General Store", user_type: "gstore" },
    { id: 17, name: "Packaging Store", user_type: "pstore" },
  ]);

  useEffect(() => {
    fetchRequisitionData();
  }, [id]);

  const fetchRequisitionData = () => {
    Http.get(`/store-purchase-requisition-art/${id}`)
      .then((res) => {
        setRequisitionData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Fetch error:", err);
        Swal.fire("Error!", "Failed to fetch requisition details.", "error");
        setLoading(false);
      });
  };

  const getDepartmentNameById = (departmentId) => {
    const department = departments.find((dept) => dept.id === departmentId);
    return department ? department.name : "N/A";
  };

  const dataList = requisitionData.items || [];

  const print = () => {
    window.print();
  };

  return (
    <div>
      <LoaderComponent loading={loading} />
      <style>
        {`
          @media print {
              body * { visibility: hidden; font-size:10px; }
              .no-print { visibility: hidden !important; }
              #printable-content, #printable-content * { visibility: visible; }
              #printable-content { position: absolute; left: 0; top: 0; width: 100%; }
          }
        `}
      </style>

      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Requisition Details</h5>
        </div>

        <div className="am-pagebody">
          <div className="card card-primary card-outline">
            <div className="card-header noPrint">
              <div className="row">
                <div className="col-md-7">
                  <h4 className="card-title">
                    Receipt No - {requisitionData.receipt_no}
                  </h4>
                </div>
                <div className="col-md-5 text-right">
                  <button className="btn btn-default" onClick={print}>
                    <i className="fa fa-print"></i>
                    &nbsp;Print
                  </button>
                </div>
              </div>
            </div>

            <div className="card-body" id="printable-content">
              <div className="row">
                <div className="col-md-12">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td style={{ border: "none" }} align="center">
                          <img
                            src="https://demo.limerickbd.com/backend/public/image/artisan-logo.svg"
                            width="30%"
                            alt="Logo"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <table className="table table-sm">
                    <tbody>
                      <tr>
                        <td>Receipt No: {requisitionData.receipt_no}</td>
                        <td>Purchase Type: {requisitionData.purchase_type}</td>
                      </tr>
                      <tr>
                        <td>Date: {requisitionData.date}</td>
                        <td>Status: {requisitionData.status}</td>
                      </tr>
                      <tr>
                        <td>
                          From Department:{" "}
                          {getDepartmentNameById(
                            requisitionData.from_department
                          )}
                        </td>
                        <td>Remarks: {requisitionData.remarks}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <table className="table table-bordered table-sm">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Product</th>
                        <th>Req. Qty</th>
                        <th>Req. Time</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataList.map((item, index) => (
                        <tr key={item.id}>
                          <td>{index + 1}</td>
                          <td>{item.product.name}</td>
                          <td>{item.required_qty}</td>
                          <td>{item.required_time}</td>
                          <td>{item.remarks}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <table className="table" style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td width="30%" align="center">
                          <br />
                          <br />
                          <br />
                          ......................................................
                          <br />
                          Prepared By
                        </td>
                        <td width="30%" align="center">
                          <br />
                          <br />
                          <br />
                          ......................................................
                          <br />
                          Checked By
                        </td>
                        <td width="30%" align="center">
                          <br />
                          <br />
                          <br />
                          ......................................................
                          <br />
                          Approved By
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
