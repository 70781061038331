import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import useAlertFromLocation from "../../../../hooks/useAlertFromLocation";
import useDeleteData from "../../../../hooks/useDeleteData";
import Alert from "../../../Common/Alert";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import ReactDataTable from "../../../Common/ReactDataTable";

const List = () => {
  const [requisitionData, setRequisitionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [staffType, setStaffType] = useState(""); // For storing the staff check result
  const [role, setRole] = useState(""); // For storing the staff check result
  // const [departments, setDepartments] = useState([
  //   { id: 1, name: "BODY", user_type: "body" },
  //   { id: 2, name: "JIGGER", user_type: "jigger" },
  //   { id: 3, name: "CASTING", user_type: "casting" },
  //   { id: 4, name: "FINISHING", user_type: "finishing" },
  //   { id: 5, name: "BK", user_type: "bk" },
  //   { id: 6, name: "GLAZING", user_type: "glazing" },
  //   { id: 7, name: "GK", user_type: "gk" },
  //   { id: 8, name: "SELECTION", user_type: "selection" },
  //   { id: 9, name: "DECORATION LOCAL", user_type: "decoration_local" },
  //   { id: 10, name: "DECORATION EXPORT", user_type: "decoration_export" },
  //   { id: 11, name: "DFW", user_type: "dfw" },
  //   { id: 12, name: "PACKING LOCAL", user_type: "packing_local" },
  //   { id: 13, name: "PACKING EXPORT", user_type: "packing_export" },
  //   { id: 14, name: "DSTORE", user_type: "dstore" },
  //   { id: 15, name: "GSTORE", user_type: "gstore" },
  //   { id: 16, name: "MOULD", user_type: "mould" },
  //   { id: 17, name: "PSTORE", user_type: "pstore" },
  //   { id: 18, name: "PLANNING", user_type: "planning" },
  //   { id: 19, name: "SAGGER", user_type: "sagger" },
  // ]);

  // Helper function to get user type from department ID
  // const getUserType = (departmentId) => {
  //   const department = departments.find((dept) => dept.id === departmentId);
  //   return department ? department.user_type : null;
  // };

  //  // Helper function to get department name from department ID
  //  const getDepartmentNameById = (departmentId) => {
  //   const department = departments.find((dept) => dept.id === departmentId);
  //   return department ? department.name : "N/A";
  // };

  // Fetch planning data and staff check data
  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await Http.get("/loss-art");
      const staffTypeResponse = await Http.get("/me");
      const resRole = await Http.get("/staff-type-check");
      // const currentStaffType = staffTypeResponse.data; // Assuming staffTypeResponse.data is the string returned by the API

      // // Filter the requisition data based on the conditions
      // const filteredData = res.data.filter((item) => {
      //   const fromDeptUserType = getUserType(item.from_department);
      //   const toDeptUserType = getUserType(item.to_department);

      //   return (
      //     currentStaffType === "admin" ||
      //     currentStaffType === item.created_by.user_type ||
      //     currentStaffType === fromDeptUserType ||
      //     currentStaffType === toDeptUserType
      //   );
      // });

      setRequisitionData(res.data);
      setStaffType(staffTypeResponse.data.data.department_art_id);
      setRole(resRole.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const receiveSubmitData = (id, showMsg) => {
    Swal.fire({
      title: "Are you sure You Want to Receive this Return?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, receive it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Http.post("/" + deleteApiUrl + "/" + id + "/cash-count").then((res) => {
        //   setPlanningData(res.data);
        //   Swal.fire("Received!", showMsg + " has been deleted.", "success");
        // });
        Http.post(`/return-item-received/${id}`)
          .then((res) => {
            Swal.fire("Received!", "Item has been received.", "success");
            fetchData();
          })
          .catch(function (e) {
            Swal.fire("Could not receive!", "Could not receive", "danger");
          });
      }
    });
  };

  // Use custom hook to delete data
  const deleteData = useDeleteData(fetchData);
  const alert = useAlertFromLocation();

  const cols = [
    {
      field: (rowData) => rowData.shift?.toUpperCase(),
      header: "Shift",
    },
    { field: "date", header: "Return Date" },
    { field: "loss_qty", header: "Loss Qty" },
    ...(role === "decoration_local" || role === "packing_local"
      ? [
          { field: "loss_qty_a_class", header: "Loss Qty A Class" },
          { field: "loss_qty_b_class", header: "Loss Qty B Class" },
          { field: "loss_qty_c_class", header: "Loss Qty C Class" },
        ]
      : []),
    ...(role === "packing_export" || role === "decoration_export"
      ? [{ field: "loss_qty_export", header: "Loss Qty Export" }]
      : []),
    ...(role === "selection" || role === "dfw"
      ? [
          { field: "loss_qty_export", header: "Loss Qty Export" },
          { field: "loss_qty_a_class", header: "Loss Qty A Class" },
          { field: "loss_qty_b_class", header: "Loss Qty B Class" },
          { field: "loss_qty_c_class", header: "Loss Qty C Class" },
        ]
      : []),
    {
      field: (item) => (
        <div className="text-xs text-nowrap">
          <Link
            to={`/edit-loss/req-art/${item.id}`}
            className="btn btn-secondary btn-sm ms-4"
          >
            <i className="fa fa-edit"></i> Edit
          </Link>
        </div>
      ),
      header: "Actions",
    },
  ];

  return (
    <div>
      <IsLogin />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">All Returns</h5>
          {(role === "jigger" ||
            role === "casting" ||
            role === "finishing" ||
            role === "decoration_local" ||
            role === "decoration_export") && (
            <Link to="/create-return-art" className="btn btn-primary">
              <i className="fa fa-plus-square mg-r-6"></i> Add Return
            </Link>
          )}
        </div>
        <div className="am-pagebody">
          <Alert alert={alert} />
          <div className="card pd-20 pd-sm-40">
            <ReactDataTable
              cols={cols}
              receiveData={requisitionData}
              isLoading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
