import React from "react";

const StaticProductTable = () => {
    const data = [
        {
          code: "601000",
          productName: "Aluminium HTM-30 Grade",
          grnNo: "GRN001",
          grnDate: "2024-11-01",
          acceptableMoisture: "8%",
          moistureFound: "12%",
          claimed: false,
        },
        {
          code: "601001",
          productName: "Alumina Ball Stone 60 mm",
          grnNo: "GRN001",
          grnDate: "2024-11-01",
          acceptableMoisture: "7%",
          moistureFound: "10%",
          claimed: false,
        },
        {
          code: "601000",
          productName: "Aluminium HTM-30 Grade",
          grnNo: "GRN002",
          grnDate: "2024-11-02",
          acceptableMoisture: "9%",
          moistureFound: "13%",
          claimed: false,
        },
        {
          code: "601003",
          productName: "Alumina Lining 150x50x60 mm",
          grnNo: "GRN002",
          grnDate: "2024-11-02",
          acceptableMoisture: "6%",
          moistureFound: "9%",
          claimed: true,
        },
        {
          code: "601000",
          productName: "Aluminium HTM-30 Grade",
          grnNo: "GRN003",
          grnDate: "2024-11-03",
          acceptableMoisture: "7%",
          moistureFound: "10%",
          claimed: true,
        },
        {
          code: "602000",
          productName: "Ball Clay G-F 1005",
          grnNo: "GRN003",
          grnDate: "2024-11-03",
          acceptableMoisture: "7%",
          moistureFound: "11%",
          claimed: false,
        },
        {
          code: "603002",
          productName: "China Clay Premium",
          grnNo: "GRN004",
          grnDate: "2024-11-04",
          acceptableMoisture: "8%",
          moistureFound: "10%",
          claimed: false,
        },
        {
          code: "603002",
          productName: "China Clay Premium",
          grnNo: "GRN005",
          grnDate: "2024-11-05",
          acceptableMoisture: "9%",
          moistureFound: "13%",
          claimed: false,
        },
        {
          code: "603002",
          productName: "China Clay Premium",
          grnNo: "GRN006",
          grnDate: "2024-11-06",
          acceptableMoisture: "7%",
          moistureFound: "12%",
          claimed: false,
        },
        {
          code: "606000",
          productName: "Feldspar (Lump)",
          grnNo: "GRN007",
          grnDate: "2024-11-07",
          acceptableMoisture: "6%",
          moistureFound: "9%",
          claimed: false,
        },
        {
          code: "606000",
          productName: "Feldspar (Lump)",
          grnNo: "GRN008",
          grnDate: "2024-11-08",
          acceptableMoisture: "7%",
          moistureFound: "11%",
          claimed: false,
        },
        {
          code: "606000",
          productName: "Feldspar (Lump)",
          grnNo: "GRN009",
          grnDate: "2024-11-09",
          acceptableMoisture: "6%",
          moistureFound: "10%",
          claimed: false,
        },
        {
          code: "617000",
          productName: "Quartz (Lump)",
          grnNo: "GRN010",
          grnDate: "2024-11-10",
          acceptableMoisture: "5%",
          moistureFound: "7%",
          claimed: false,
        },
        {
          code: "617001",
          productName: "Quartz (Powder)",
          grnNo: "GRN010",
          grnDate: "2024-11-10",
          acceptableMoisture: "6%",
          moistureFound: "10%",
          claimed: false,
        },
        {
          code: "619001",
          productName: "Sodium Carbonate",
          grnNo: "GRN011",
          grnDate: "2024-11-11",
          acceptableMoisture: "6%",
          moistureFound: "9%",
          claimed: false,
        },
        {
          code: "603020",
          productName: "Cobalt Oxide Powder",
          grnNo: "GRN011",
          grnDate: "2024-11-11",
          acceptableMoisture: "6%",
          moistureFound: "9%",
          claimed: false,
        },
        {
          code: "716029",
          productName: "Pigment Dry Colour RV-869 Red / (80609)",
          grnNo: "GRN012",
          grnDate: "2024-11-12",
          acceptableMoisture: "8%",
          moistureFound: "12%",
          claimed: false,
        },
        {
          code: "716032",
          productName: "Pigment Dry Colour RV-731 / 7000",
          grnNo: "GRN012",
          grnDate: "2024-11-12",
          acceptableMoisture: "9%",
          moistureFound: "11%",
          claimed: false,
        },
        {
          code: "716037",
          productName: "Pigment Dry Colour Black - 29312",
          grnNo: "GRN013",
          grnDate: "2024-11-13",
          acceptableMoisture: "6%",
          moistureFound: "8%",
          claimed: false,
        },
        {
          code: "716046",
          productName: "Pigment Dry Colour Green RV-70001",
          grnNo: "GRN013",
          grnDate: "2024-11-13",
          acceptableMoisture: "5%",
          moistureFound: "9%",
          claimed: false,
        },
        {
          code: "716061",
          productName: "Pigment Dry Colour - 2017A Rose",
          grnNo: "GRN014",
          grnDate: "2024-11-14",
          acceptableMoisture: "6%",
          moistureFound: "10%",
          claimed: false,
        },
      ];
      
      

  return (
    <>
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Product Moisture Claim Report</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <div className="container mt-4">
              {/* <h5 className="text-center mb-4">
                Product Moisture Claim Report
              </h5> */}
              <table className="table table-bordered table-striped">
                <thead className="thead-dark">
                  <tr>
                    <th>Code</th>
                    <th>Product Name</th>
                    <th>GRN No.</th>
                    <th>GRN Date</th>
                    <th>Acceptable Moisture</th>
                    <th>Moisture Found</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td>{item.code}</td>
                      <td>{item.productName}</td>
                      <td>{item.grnNo}</td>
                      <td>{item.grnDate}</td>
                      <td>{item.acceptableMoisture}</td>
                      <td>{item.moistureFound}</td>
                      <td>
                        {item.claimed ? (
                          <i className="text-muted">claimed</i>
                        ) : (
                          <button className="btn btn-primary btn-sm">
                            Claim
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StaticProductTable;
