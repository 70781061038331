import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormValidation from "../../../Common/FormValidation";
import Http from "../../../Common/Http";
import { LoaderComponent } from "../../../Common/LoaderComponent";
import useRequiredFields from "../../../../hooks/useRequiredFields";

const EditProduction = () => {
  const { checkRequiredFields } = useRequiredFields();
  const navigate = useNavigate();
  const { id } = useParams(); // Get ID from URL params
  const [error, setError] = useState({});
  const [inputs, setInputs] = useState({});
  const [loading, setLoading] = useState(true); // Loading state for API calls

  // Fetch data for the specific production return
  const fetchData = async () => {
    try {
      const res = await Http.get(`/return-art/${id}`); // Fetch using show method
      setInputs(res.data); // Set inputs with the fetched data
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const formSubmit = () => {
    checkRequiredFields();
    Http.put(`/return-art/${id}`, inputs) // Call the update method
      .then((res) => {
        navigate("/return-art", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch((e) => {
        setError(e.response.data.errors);
      });
  };

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <div>
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Edit Return</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Edit Return Filter Cake</h6>
            <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div>
            <div className="row">
              <div className="col-lg">
                <label>Receipt</label>
                <input
                  type="text"
                  className="form-control"
                  name="receipt_no"
                  value={inputs?.receipt_no || ""}
                  onChange={handleChange}
                  placeholder="Receipt No"
                />
              </div>
              <div className="col-lg">
                <label>Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  value={inputs?.date || ""}
                  onChange={handleChange}
                  placeholder="Date"
                />
              </div>
              <div className="col-lg">
                <label>Shift <i className="fa fa-star required"></i></label>
                <select
                  className="form-control"
                  name="shift"
                  value={inputs?.shift || ""}
                  onChange={handleChange}
                >
                  <option value="">Select Shift</option>
                  <option value="a">A</option>
                  <option value="b">B</option>
                  <option value="c">C</option>
                  <option value="general">D</option>
                </select>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg">
                <label>Return Qty</label>
                <input
                  type="number"
                  className="form-control"
                  name="return_qty"
                  value={inputs?.return_qty || 0}
                  onChange={handleChange}
                  placeholder="Return Qty"
                />
              </div>
              <div className="col-lg">
                <label>Remarks</label>
                <textarea
                  className="form-control"
                  name="remarks"
                  onChange={handleChange}
                  placeholder="Remarks"
                >
                  {inputs?.remarks || ""}
                </textarea>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-3">
                <button
                  className="btn btn-primary btn-block mg-b-10"
                  onClick={formSubmit}
                >
                  <i className="fa fa-send mg-r-10"></i> Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProduction;
