import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormValidation from "../../../Common/FormValidation";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import { LoaderComponent } from "../../../Common/LoaderComponent";
import useRequiredFields from "../../../../hooks/useRequiredFields";

const Transfer = () => {
  const { checkRequiredFields } = useRequiredFields();
  const navigate = useNavigate();
  const { id, product_id } = useParams();
  const [error, setError] = useState({});
  const [receiveData, setReceiveData] = useState([]);
  const [inputs, setInputs] = useState({ status: "1" });
  const [remainingQty, setRemainingQty] = useState(0);
  // const [workOrders, setWorkOrders] = useState([]); // State for work orders
  const [role, setRole] = useState(""); // State for role

  const fetchData = async () => {
    // Http.get("/issue/" + id).then((res) => {
    //   setReceiveData(res.data.info);
    //   console.log(parseFloat(res.data.info.production_qty),parseFloat(res.data.info.transfer_qty));
    //   // setRemainingQty(parseFloat(res.data.info.production_qty) - parseFloat(res.data.info.transfer_qty));
    //   setRemainingQty(
    //     res.data.info.transfer_qty 
    //       ? parseFloat(res.data.info.production_qty) - parseFloat(res.data.info.transfer_qty)
    //       : parseFloat(res.data.info.production_qty)
    //   );
      
    // });
    const resRole = await Http.get("/staff-type-check"); // Fetch role
    setRole(resRole.data);
    const resData = await Http.get("/production/" + id); // Fetch Data
    setReceiveData(resData.data);
    const redBallCharge = await Http.get("/ball-charge-stock/" + resData?.data?.product_id); // Fetch Data
    setRemainingQty(redBallCharge.data.chargeQty);
  };
  useEffect(() => {
    fetchData();
    setInputs((prevInputs) => ({
      ...prevInputs,
      date: getTodayDate(), // Set today's date as default
    }));
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));

    // if(name == 'transfer_qty'){
    //   if(parseInt(value) > remainingQty){
    //     alert('Transfer quantity cannot be greater than Produced quantity Remaining Qty is '+ remainingQty);
    //     setInputs((values) => ({ ...values, [name]: 0 }));
    //   }
    // }
  };

  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };
  

  const formSubmit = () => {
    checkRequiredFields();
    inputs.issue_id = id;
    inputs.product_id = receiveData.product.id;

    Http.post("/transfer", inputs)
      .then((res) => {
        navigate("/transfer-art", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Create Transfer</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">
              Create Transfer From 
              {/* Issue Reference No - <i>{receiveData.reference_no	}</i> */}
            </h6>
            {/* <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg">
                <label>Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  value={inputs.date || ""}
                  onChange={handleChange}
                  placeholder="Input Date"
                />
              </div>
              <div className="col-lg">
                <label>Total Produced</label>
                <input
                  type="number"
                  className="form-control"
                  name="prev_qty"
                  value={receiveData.production_qty}
                  onChange={handleChange}
                  // placeholder="Input Produced Qty"
                  readOnly
                />
              </div>
              {/* <div className="col-lg">
                <label>Remaining Qty</label>
                <input
                  type="number"
                  className="form-control"
                  name="prev_qty"
                  value={remainingQty || 0}
                  onChange={handleChange}
                  // placeholder="Input Produced Qty"
                  readOnly
                />
              </div> */}
              {role === "mould" && (
                <div className="col-lg">
                <label>Plaster of Paris</label>
                <input
                  type="number"
                  className="form-control"
                  name="plaster_paris"
                  value={inputs.plaster_paris || 0}
                  onChange={handleChange}
                  placeholder="Input Plaster of Paris"
                />
              </div>
              )}
            </div>
            <div className="row mt-3">
              <div className="col-lg">
                <label>Transfer Qty <i className="fa fa-star required"></i>

                </label>
                <input
                  type="number"
                  className="form-control"
                  name="transfer_qty"
                  value={inputs.transfer_qty}
                  onChange={handleChange}
                  placeholder="Input Deliver Qty"
                  data-required
                />
              </div>
              {/* {(role === "body" && inputs.transfer_qty > 0 )  && ( */}
                <div className="col-lg">
                <label>To Department <i className="fa fa-star required"></i>

                </label>
                <select
                  className="form-control"
                  name="to_department_id"
                  onChange={handleChange}
                  data-required
                >
                  <option value={""}>Select Department</option>
                  <option value={2}>Jigger</option>
                  <option value={3}>Casting</option>
                  <option value={6}>Glaze</option>
                </select>
              </div>
              <div className="col-lg">
                <label>Remarks</label>
                <textarea
                  className="form-control"
                  name="remarks"
                  onChange={handleChange}
                  placeholder="Remarks"
                >
                  {inputs.remarks || ""}
                </textarea>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-3">
                <button
                  className="btn btn-primary btn-block mg-b-10"
                  onClick={formSubmit}
                >
                  <i className="fa fa-send mg-r-10"></i> Submit
                </button>
              </div>
            </div>
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default Transfer;
