import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Http from "../../../Common/Http";
import { LoaderComponent } from "../../../Common/LoaderComponent";

const ViewProductionTransfer = () => {
  const { id } = useParams();
  const [receiveData, setReceiveData] = useState({});
  const [finishedGood, setFinishedGood] = useState({});
  const [loading, setLoading] = useState(true);
  const [departments, setDepartments] = useState([
    { id: 1, name: "BODY" },
    { id: 2, name: "JIGGER" },
    { id: 3, name: "CASTING" },
    { id: 4, name: "FINISHING" },
    { id: 5, name: "BK" },
    { id: 6, name: "GLAZING" },
    { id: 7, name: "GK" },
    { id: 8, name: "SELECTION" },
    { id: 9, name: "DECORATION LOCAL" },
    { id: 10, name: "DECORATION EXPORT" },
    { id: 11, name: "DFW" },
    { id: 12, name: "PACKING LOCAL" },
    { id: 13, name: "PACKING EXPORT" },
    { id: 14, name: "DSTORE" },
    { id: 15, name: "GSTORE" },
    { id: 16, name: "MOULD" },
    { id: 17, name: "PSTORE" },
    { id: 18, name: "PLANNING" },
    { id: 19, name: "SAGGER" },
  ]);

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = () => {
    Http.get(`/production-transfer/${id}`)
      .then((res) => {
        setReceiveData(res.data.transfer);
        setFinishedGood(res.data.finished_good);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Fetch error:", err);
        Swal.fire(
          "Error!",
          "Failed to fetch production transfer details.",
          "error"
        );
      });
  };

  const getDepartmentNameById = (departmentId) => {
    const department = departments.find((dept) => dept.id === departmentId);
    return department ? department.name : "N/A";
  };

  const print = () => {
    window.print();
  };

  return (
    <div>
      <LoaderComponent loading={loading} />
      <style>
        {`
          @media print {
              body * { visibility: hidden; font-size:10px;}
              .no-print { visibility: hidden !important; }
              #printable-content, #printable-content * { visibility: visible; }
              #printable-content { position: absolute; left: 0; top: 0; width: 100%; }
              .main-card { padding: 0px; }
              .am-pagebody { padding: 0px; }
              .am-mainpanel { margin-top: 0px; }
              .signature_section .table td{ border-bottom: 0;}
          }
          .main-card { padding: 40px; }
        `}
      </style>

      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Production Transfer Details</h5>
        </div>

        <div className="am-pagebody">
          <div className="card card-primary card-outline">
            <div className="card-header noPrint">
              <div className="row">
                <div className="col-md-7">
                  <h4 className="card-title">
                    Receipt No - {finishedGood.receipt_no}
                  </h4>
                </div>
                <div className="col-md-5 text-right">
                  <button className="btn btn-default" onClick={print}>
                    <i className="fa fa-print"></i>
                    &nbsp;Print
                  </button>
                </div>
              </div>
            </div>

            <div className="card-body" id="printable-content">
              <div className="row">
                <div className="col-md-12">
                  <table className="table table-sm">
                    <tbody>
                      <tr>
                        <td>
                          Work Order: {finishedGood.production_work_order_id}
                        </td>
                        <td>Production Date: {finishedGood.production_date}</td>
                      </tr>
                      <tr>
                        <td>
                          From Department:{" "}
                          {getDepartmentNameById(finishedGood.department)}
                        </td>
                        <td>Transfer Date: {receiveData.transfer_date}</td>
                        {/* <td>Receive Date: {receiveData.receive_date}</td> */}
                      </tr>

                      {/* <tr>
                        <td colSpan="2">Remarks: {receiveData.remarks}</td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <table className="table table-bordered table-sm">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Product</th>
                        <th>Qty</th>
                        <th>Loss Qty</th>
                        <th>Repair Qty</th>
                        <th>To Department</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {finishedGood.items && finishedGood.items.length > 0 ? (
                        finishedGood.items.map((item, index) => (
                          <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td>{item.product.name}</td>
                            <td>{item.qty}</td>
                            <td>{item.loss_qty}</td>
                            <td>{item.repair_qty}</td>
                            <td>
                              {getDepartmentNameById(
                                receiveData.items[index]?.to_department
                              )}
                            </td>
                            <td>{item.remarks}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="7" className="text-center">
                            No items available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <table className="table" style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td width="30%" align="center">
                          <br />
                          <br />
                          <br />
                          ......................................................
                          <br />
                          Prepared By
                        </td>
                        <td width="30%" align="center">
                          <br />
                          <br />
                          <br />
                          ......................................................
                          <br />
                          Checked By
                        </td>
                        <td width="30%" align="center">
                          <br />
                          <br />
                          <br />
                          ......................................................
                          <br />
                          Approved By
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProductionTransfer;
