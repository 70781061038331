import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Alert from "../../../Common/Alert";
import DatatableFunc from "../../../Common/DatatableFunc";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import { LoaderComponent } from "../../../Common/LoaderComponent";

const Show = () => {
  const [receiveData, setReceiveData] = useState([]);
  const { id } = useParams();
  const [alert, setAlert] = useState(null);
  const location = useLocation();

  const fetchData = () => {
    Http.get("/requisition-art/" + id).then((res) => {
      setReceiveData(res.data);
    });
  };

  useEffect(() => {
    DatatableFunc();
    fetchData();
  }, []);

  const showAlert = (message, type) => {
    setAlert({
      message: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 4000);
  };

  useEffect(() => {
    if (!location.state) {
      return;
    }
    showAlert(location.state.message, location.state.type);
  }, [location]);

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">Departmental Requisition</h5>
          </div>

          <div className="am-pagebody">
            <Alert alert={alert} />
            <div className="card pd-20 pd-sm-40">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 padding">
                <div className="card-header noPrint">
                  <div className="row">
                    <div className="col-md-7">
                      <h4 className="card-title">
                        Receipt No - {receiveData.receipt_no}
                      </h4>
                    </div>
                    <div className="col-md-5 text-right">
                      <button className="btn btn-default" onClick={print}>
                        <i className="fa fa-print"></i>
                        &nbsp;Print
                      </button>
                    </div>
                  </div>
                </div>
                {/* <div className="asd"> */}
                <div className="card-body" id="printable-content">
                  <div className="row">
                    <div className="col-md-12">
                      <table className="table">
                        <tbody>
                          <tr>
                            <td style={{ border: "none" }} align="center">
                              <img
                                src="https://demo.limerickbd.com/backend/public/image/artisan-logo.svg"
                                width="30%"
                                alt="Logo"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <h5 className="mb-3">Departmental Requisition:</h5>
                    <div className="col-sm-12 d-flex">
                      <div className="col-lg-6">
                        <div>Date :{receiveData.date}</div>
                        <div>Time :{receiveData.expected_time}</div>
                        {/* <div>
                          Finished Good :
                          {receiveData.product
                            ? receiveData.product.name
                            : "Other Requisition"}
                        </div> */}
                        {/* <div>Recipe :{receiveData.receipe.receipt_no}</div> */}
                      </div>
                      <div className="col-lg-6 text-right">
                        <div>Req. No. :{receiveData.receipt_no}</div>
                        {/* <div>Batch Size :{receiveData.batch_size}</div> */}
                        <div>From :{receiveData.from_department?.name}</div>
                        <div>To :{receiveData.to_department?.name}</div>

                        {/* <div>
                          Created By :
                          {receiveData.created_by
                            ? receiveData.created_by.name
                            : ""}
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive-sm">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th className="center">#</th>
                          <th>Product</th>
                          {/* {receiveData.type !== "other" && ( */}
                          <th className="text-right">Required Qty</th>
                          {/* )} */}
                          {/* <th className="text-right">Qty</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {receiveData.items
                          ? receiveData.items.map((item, index) => (
                              <tr key={item.id}>
                                <td className="center">{index + 1}</td>
                                <td className="left strong">
                                  {item.product ? item.product.name : ""}
                                </td>
                                {/* {receiveData.type !== "other" && ( */}
                                <td className="text-right">
                                  {item.required_qty}
                                </td>
                                {/* )} */}
                                {/* <td className="text-right">{item.qty}</td> */}
                              </tr>
                            ))
                          : ""}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Show;
