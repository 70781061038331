import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import useAlertFromLocation from "../../../../hooks/useAlertFromLocation";
import useDeleteData from "../../../../hooks/useDeleteData";
import Alert from "../../../Common/Alert";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import ReactDataTable from "../../../Common/ReactDataTable";

const List = () => {
  const [requisitionData, setRequisitionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [staffType, setStaffType] = useState(""); // For storing the staff check result
  const [role, setRole] = useState(""); // For storing the staff check result
  // const [departments, setDepartments] = useState([
  //   { id: 1, name: "BODY", user_type: "body" },
  //   { id: 2, name: "JIGGER", user_type: "jigger" },
  //   { id: 3, name: "CASTING", user_type: "casting" },
  //   { id: 4, name: "FINISHING", user_type: "finishing" },
  //   { id: 5, name: "BK", user_type: "bk" },
  //   { id: 6, name: "GLAZING", user_type: "glazing" },
  //   { id: 7, name: "GK", user_type: "gk" },
  //   { id: 8, name: "SELECTION", user_type: "selection" },
  //   { id: 9, name: "DECORATION LOCAL", user_type: "decoration_local" },
  //   { id: 10, name: "DECORATION EXPORT", user_type: "decoration_export" },
  //   { id: 11, name: "DFW", user_type: "dfw" },
  //   { id: 12, name: "PACKING LOCAL", user_type: "packing_local" },
  //   { id: 13, name: "PACKING EXPORT", user_type: "packing_export" },
  //   { id: 14, name: "DSTORE", user_type: "dstore" },
  //   { id: 15, name: "GSTORE", user_type: "gstore" },
  //   { id: 16, name: "MOULD", user_type: "mould" },
  //   { id: 17, name: "PSTORE", user_type: "pstore" },
  //   { id: 18, name: "PLANNING", user_type: "planning" },
  //   { id: 19, name: "SAGGER", user_type: "sagger" },
  // ]);

  // Helper function to get user type from department ID
  // const getUserType = (departmentId) => {
  //   const department = departments.find((dept) => dept.id === departmentId);
  //   return department ? department.user_type : null;
  // };

  //  // Helper function to get department name from department ID
  //  const getDepartmentNameById = (departmentId) => {
  //   const department = departments.find((dept) => dept.id === departmentId);
  //   return department ? department.name : "N/A";
  // };

  // Fetch planning data and staff check data
  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await Http.get("/return-art");
      const staffTypeResponse = await Http.get("/me");
      const resRole = await Http.get("/staff-type-check");
      // const currentStaffType = staffTypeResponse.data; // Assuming staffTypeResponse.data is the string returned by the API

      // // Filter the requisition data based on the conditions
      // const filteredData = res.data.filter((item) => {
      //   const fromDeptUserType = getUserType(item.from_department);
      //   const toDeptUserType = getUserType(item.to_department);

      //   return (
      //     currentStaffType === "admin" ||
      //     currentStaffType === item.created_by.user_type ||
      //     currentStaffType === fromDeptUserType ||
      //     currentStaffType === toDeptUserType
      //   );
      // });

      setRequisitionData(res.data);
      setStaffType(staffTypeResponse.data.data.department_art_id);
      setRole(resRole.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const receiveSubmitData = (id, showMsg) => {
    Swal.fire({
      title: "Are you sure You Want to Receive this Return?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, receive it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Http.post("/" + deleteApiUrl + "/" + id + "/cash-count").then((res) => {
        //   setPlanningData(res.data);
        //   Swal.fire("Received!", showMsg + " has been deleted.", "success");
        // });
        Http.post(`/return-item-received/${id}`)
          .then((res) => {
            Swal.fire("Received!", "Item has been received.", "success");
            fetchData();
          })
          .catch(function (e) {
            Swal.fire("Could not receive!", "Could not receive", "danger");
          });
      }
    });
  };

  // Use custom hook to delete data
  const deleteData = useDeleteData(fetchData);
  const alert = useAlertFromLocation();

  const cols = [
    { field: "receipt_no", header: "Receipt No" },
    { field: "date", header: "Return Date" },
    // { field: "purchase_type", header: "Purchase" },
    {
      field: "from_department_art.name", // Use department name instead of ID
      header: "From",
    },
    {
      field: "to_department_art.name", // Use department name instead of ID
      header: "To",
    },
    { field: "return_qty", header: "Return Qty" },
    { field: "receive_qty", header: "Receive Qty" },
    { field: "receive_date", header: "Return Receiving Date" },
    // { field: "status", header: " Status" },
    {
      field: (item) => {
        if (item.from_department === staffType) {
          // From department logic
          return item.receive_qty < 1 ? (
            <span className="badge bg-info">Returned</span>
          ) : (
            <span className="badge bg-success">Received</span>
          );
        } else if (item.to_department === staffType) {
          // To department logic
          return item.receive_qty < 1 ? (
            <span className="badge bg-warning">Pending</span>
          ) : (
            <span className="badge bg-success">Received</span>
          );
        } else {
          // Default fallback (if neither department matches)
          return <span className="badge bg-secondary">Unknown</span>;
        }
      },
      header: " Status ",
    },
    {
      field: (item) => (
        <div className="text-xs text-nowrap">
          {item.receive_qty < 1 && item.to_department == staffType ? (
            <button
              className="btn btn-primary btn-sm ms-1"
              onClick={() => receiveSubmitData(item.id)}
            >
              <i className="fa fa-send"></i> Receive
            </button>
          ) : item.receive_qty < 1 ? (
            <>
              <i>Returned</i>
              <Link
                to={`/edit-return-art/${item.id}`}
                className="btn btn-secondary btn-sm ms-4"
              >
                <i className="fa fa-edit"></i> Edit
              </Link>
            </>
          ) : (
            <i
              className="fa fa-check"
              style={{ color: "green", fontWeight: "bold" }}
            >
              {" "}
              Received
            </i>
          )}
        </div>
      ),
      header: "Actions",
    },
    // { field: "status", header: "Status " },
    // {
    //   header: "Deliver",
    //   field: (item) => {
    //     const toDeptUserType = getUserType(item.to_department);
    //     if (staffType === "admin" || staffType === toDeptUserType) {
    //       return item.status === "requested" ? (
    //         <Link
    //           className="btn btn-primary btn-sm ms-1"
    //           to={`/create-delivery/${item.id}/req-art`}
    //         >
    //           <i className="fa fa-send"></i> Deliver
    //         </Link>
    //       ) : (
    //         <b className="btn btn-success btn-sm ms-1">Delivered</b>
    //       );
    //     }
    //     return null; // Hide column if conditions don't match
    //   },
    // },
    // {
    //   header: "Receive",
    //   field: (item) => {
    //     const fromDeptUserType = getUserType(item.from_department);
    //     if (staffType === "admin" || staffType === fromDeptUserType) {
    //       if (item.status === "delivered") {
    //         return (
    //           <Link
    //             className="btn btn-primary btn-sm ms-1"
    //             to={`/create-receive/${item.id}/req-art`}
    //           >
    //             <i className="fa fa-send"></i> Receive
    //           </Link>
    //         );
    //       } else if (item.status === "requested") {
    //         return <b className="btn btn-secondary btn-sm ms-1" >Requested</b>;
    //       } else {
    //         return <b className="btn btn-success btn-sm ms-1">Received</b>;
    //       }
    //     }
    //     return null; // Hide column if conditions don't match
    //   },
    // },
    // {
    //   field: (item) => (
    //     <div className="text-xs text-nowrap">
    //       {/* Conditionally render buttons based on from_department user type and status */}
    //       {(staffType === "admin" && item.status === "requested" ||
    //         staffType === getUserType(item.from_department) && item.status === "requested") && (
    //         <>
    //           <Link
    //             to={`/edit-requisition-art/${item.id}`}
    //             className="btn btn-primary btn-sm ms-1"
    //           >
    //             <i className="fa fa-pencil" aria-hidden="true"></i>
    //           </Link>
    //         </>
    //       )}
    //       <Link
    //         to={`/view-requisition-art/${item.id}`}
    //         className="btn btn-info btn-sm ms-1"
    //       >
    //         <i className="fa fa-list" aria-hidden="true"></i>
    //       </Link>
    //     </div>
    //   ),
    //   header: "Actions",

    // },
  ];

  return (
    <div>
      <IsLogin />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">All Returns</h5>
          {(role === "jigger" ||
            role === "casting" ||
            role === "finishing" ||
            role === "decoration_local" ||
            role === "decoration_export") && (
            <Link to="/create-return-art" className="btn btn-primary">
              <i className="fa fa-plus-square mg-r-6"></i> Add Return
            </Link>
          )}
        </div>
        <div className="am-pagebody">
          <Alert alert={alert} />
          <div className="card pd-20 pd-sm-40">
            <ReactDataTable
              cols={cols}
              receiveData={requisitionData}
              isLoading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
