import $ from "jquery"; // Import jQuery
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Http from "../../../Common/Http";
import ReactDataTable from "../../../Common/ReactDataTable";
import useRequiredFields from "../../../../hooks/useRequiredFields";


const CreateProductionWorkOrder = () => {
  const navigate = useNavigate();
  const { checkRequiredFields } = useRequiredFields();
  const [inputs, setInputs] = useState({
    work_order_date: getCurrentDate(),
    delivery_date: getCurrentDate(),
  });
  const [error, setError] = useState({});
  // const [product, setProduct] = useState([]);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [itemLoading, setItemLoading] = useState(false);
  const [shapes, setShapes] = useState([]); // Store shapes from API
  // const [shapeTypes, setShapeTypes] = useState([]); // Store shape types from API
  const [company, setCompany] = useState([]); // Store shape types from API
  // const [machines, setMachines] = useState([]); // Store shape types from API
  const [product, setProduct] = useState([]);
  // const [rmpm, setRmpm] = useState([]);
  // const [combinedData, setCombinedData] = useState([]);

  function getCurrentDate() {
    const dateObj = new Date();
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const year = dateObj.getFullYear();
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    fetchInitialData();
    $("body").addClass("collapse-menu");
    $("body").toggleClass("show-left");
  }, []);

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      const resProduct = await Http.get(`/product-list/finished_goods`);
      setProduct(resProduct.data);

      // const resRmpm = await Http.get("/rmpm-with-stock");
      // setRmpm(resRmpm.data);

      //   const combined = [
      //     ...resRmpm.data.map(item => ({ ...item, type: 'RMPM' })),
      //     ...resProduct.data.map(item => ({ ...item, type: 'FG' }))
      //   ];
      // setCombinedData(combined);
      const response = await Http.get("/shape"); // Fetch shape data from API
      const data = response.data;
      setShapes(data);
      const redCompany = await Http.get("/companies"); // Fetch shape data from API
      setCompany(redCompany.data);

      // Extract unique shape types from the shape data
      // const uniqueShapeTypes = [
      //   ...new Map(
      //     data.map((item) => [item.shape_type.id, item.shape_type])
      //   ).values(),
      // ];
      // setShapeTypes(uniqueShapeTypes);
      const resInvoiceNo = await Http.get(
        "/last-code-increment-by-one/production_work_orders/receipt_no/WO"
      );
      setInputs((values) => ({
        ...values,
        receipt_no: resInvoiceNo.data,
      }));
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  const addMoreList = async (id) => {
    setItemLoading(true); // Use itemLoading to control this specific action
    try {
      const response = await Http.get(`/product/${id}`);
      if (!list.find((item) => item.id === response.data.id)) {
      setList((prev) => [...prev, response.data]);
      } else {
        Swal.fire(
          "Warning!",
          "This Product is already in the list!",
          "warning"
        );
      }
    } catch (error) {
      console.error("Error fetching product:", error);
      Swal.fire("Error!", "Failed to add product.", "error");
    } finally {
      setItemLoading(false); // Stop loading after action is complete
    }
  };

  const removeList = (index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedList = list.filter((_, i) => i !== index);
        setList(updatedList);
        Swal.fire("Deleted!", "Your item has been deleted.", "success");
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const selectHandleChange = async (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    const { value } = selectedOption;

    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target; // Chanages Experience Input Name and Value
    const inputInfo = [...list]; // Defined Experience State All Information into List Variable

    inputInfo[index][name] = value;
    setList(inputInfo);
  };

  const formSubmit = (e) => {
    e.preventDefault();
    const isValid = checkRequiredFields();
    inputs.items = list;
    // inputs.sr_id = parseInt(inputs.sr_id);
    const updatedList = list.map((item) => ({
      ...item,
      product_id: item.id, // Map the 'id' to 'product_id'
      shape_id: item.shape_id || null, // Ensure shape_id is included
      // machine_id: item.machine_id || null,  // Ensure shape_id is included
      // shape_type_id: item.shape_type_id || null, // Ensure shape_type_id is included
      // starting_date: item.starting || null,  // Ensure starting date is included
      // closing_date: item.closing || null,    // Ensure closing date is included
      // total_day: item.total_day || null,   // Ensure total_day is included
    }));

    // Assign the updated list to inputs.items
    inputs.items = updatedList;
    Http.post("/production-work-order", inputs)
      .then((res) => {
        navigate("/production-work-order", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };
  const displayError = (fieldName) => {
    if (error && error[fieldName]) {
      return <div className="text-danger small">{error[fieldName]}</div>;
    }
    return null;
  };
  const cols = [{ field: "name", header: "Item" }];

  // const company = [
  //   {name: "Company 1", id: 1},
  //   {name: "Company 2", id: 2}
  // ]
  const actionTemplate = (item, deleteData) => {
    return (
      <td className="text-right">
        <button
          className="btn btn-success btn-sm"
          onClick={() => addMoreList(item.id)}
        >
          <i className="fa fa-plus"></i>
        </button>
      </td>
    );
  };
  return (
    <div>
      {/* <IsLogin></IsLogin> */}
      {/* <LoaderComponent /> */}
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Create Work Order</h5>
        </div>
        <div className="am-pagebody row d-flex justify-content-evenly">
          <div className="card pd-20 pd-sm-40 col-8">
            <h6 className="card-body-title mg-sm-b-30 text-center">
              Create Work Order
            </h6>
            <hr></hr>
            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg">
                    <label>
                      Work Order No <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="receipt_no"
                      value={inputs.receipt_no || ""}
                      onChange={handleChange}
                      placeholder="Input Invoice No"
                      data-required
                    />

                    {/* {displayError("receipt_no")} */}
                  </div>
                  <div className="col-lg">
                    <label>Company</label> <i className="fa fa-star required"></i>
                    <select
                      className="form-control"
                      name="company_id"
                      value={inputs.company_id || ""}
                      onChange={handleChange}
                      data-required
                    >
                      <option value="">Select Company</option>
                      {company.map((shape) => (
                        <option key={shape.id} value={shape.id}>
                          {shape.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg">
                    <label>
                      Work Order Date <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="work_order_date"
                      value={inputs.work_order_date || ""}
                      onChange={handleChange}
                      placeholder="Input Date"
                      data-required
                    />

                    {/* {displayError("work_order_date")} */}
                  </div>

                  <div className="col-lg">
                    <label>
                      Delivery Date <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="delivery_date"
                      value={inputs.delivery_date || ""}
                      onChange={handleChange}
                      placeholder="Input Date"
                      data-required
                    />

                    {/* {displayError("date")} */}
                  </div>
                </div>
                {/* <div className="row mt-3">
                  <div className="col-lg">
                    <label>
                      Remarks <i className="fa fa-star required"></i>
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      name="remarks"
                      value={inputs.remarks || ""}
                      onChange={handleChange}
                      placeholder="Remarks"
                    />

                    {displayError("remarks")}
                  </div>
                </div> */}

                <div className="row mt-3">
                  <div className="card-solid">
                    <div className="card-header with-border text-center">
                      <h5 className="card-title mb-0">
                        Items in the Work Order{" "}
                        <i className="fa fa-star required"></i>
                      </h5>
                    </div>
                    <div className="card-body p-0">
                      <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0">
                        <thead>
                          <tr>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              #
                            </th>
                            {/* <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Shift
                            </th> */}
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Product
                            </th>
                            {/* <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Machine
                            </th> */}
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Shape <i className="fa fa-star required"></i>
                            </th>
                            {/* <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Shape Type
                            </th> */}
                            {/* <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Order Number
                            </th> */}
                            {/* <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Company
                            </th> */}
                            {/* <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Closing
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Total Day
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Target Day wise
                            </th> */}

                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Qty <i className="fa fa-star required"></i>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {list.length < 1 ? (
                            <tr>
                              <td colSpan={12}>
                                <p
                                  style={{ color: "red" }}
                                  className="text-center mt-3"
                                >
                                  Please Add Few Products For Work Order
                                </p>
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}

                          {Array.from(list).map((x, i) => (
                            <tr key={i}>
                              <td>
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={() => removeList(i)}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </td>
                              {/* <td>
                                <select
                                  className="form-control"
                                  name="shift"
                                  value={x.shift || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                >
                                  <option value="">Select Shift</option>
                                  <option value="a">A</option>
                                  <option value="b">B</option>
                                  <option value="c">C</option>
                                  <option value="d">D</option>
                                </select>
                              </td> */}
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="product"
                                  value={list[i].name}
                                  // onChange={(e) => handleInputChange(e, i)}
                                  placeholder="Product"
                                  disabled
                                />
                              </td>
                              {/* <td> */}
                              {/* <select
                                  className="form-control"
                                  name="machine_id"
                                  value={x.machine_id || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                >
                                  <option value="">Select Shape</option>
                                  {machines.map((shape) => (
                                    <option key={shape.id} value={shape.id}>
                                      {shape.name}
                                    </option>
                                  ))}
                                </select> */}
                              {/* </td> */}
                              <td>
                                <select
                                  className="form-control"
                                  name="shape_id"
                                  value={x.shape_id || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                  data-required
                                >
                                  <option value="">Select Shape</option>
                                  {shapes.map((shape) => (
                                    <option key={shape.id} value={shape.id}>
                                      {shape.name}
                                    </option>
                                  ))}
                                </select>
                              </td>

                              {/* <td>
                                <select
                                  className="form-control"
                                  name="shape_type_id"
                                  value={x.shape_type_id || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                >
                                  <option value="">Select Shape Type</option>
                                  {shapeTypes.map((type) => (
                                    <option key={type.id} value={type.id}>
                                      {type.name}
                                    </option>
                                  ))}
                                </select>
                              </td> */}
                              {/* <td>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="starting"
                                  value={x.starting || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </td> */}
                              {/* <td>
                              <input
                                type="text"
                                className="form-control"
                                name="order_number"
                                value={x.order_number || ""}
                                onChange={(e) => handleInputChange(e, i)}
                                placeholder="Order number"
                              />
                            </td> */}
                              {/* <td>
                                <select
                                  className="form-control"
                                  name="company_id"
                                  value={x.company_id || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                >
                                  <option value="">Select Shape</option>
                                  {company.map((shape) => (
                                    <option key={shape.id} value={shape.id}>
                                      {shape.name}
                                    </option>
                                  ))}
                                </select>
                              </td> */}
                              {/* <td>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="closing"
                                  value={x.closing || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </td>
                              <td>
                              <input
                                type="number"
                                className="form-control"
                                name="total_day"
                                value={x.total_day || ""}
                                onChange={(e) => handleInputChange(e, i)}
                                placeholder="Total Day"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                className="form-control"
                                name="target_day_wise"
                                value={x.target_day_wise || ""}
                                onChange={(e) => handleInputChange(e, i)}
                                placeholder="Target Day wise"
                              />
                            </td> */}

                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="qty"
                                  value={x.qty || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                  placeholder="Qty"
                                  data-required
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>

                        {/* <tfoot>
                          <tr>
                            <td
                              colSpan={6}
                              className="text-right"
                              style={{ lineHeight: "40px" }}
                            >
                              Total DayYYY
                            </td>
                            <td colSpan={2}>
                              <input
                                type="number"
                                className="form-control"
                                name="total_day"
                                value={inputs.total_day || ""}
                                onChange={handleChange}
                                placeholder="Total Day"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td
                              colSpan={6}
                              className="text-right"
                              style={{ lineHeight: "40px" }}
                            >
                              Target Day wiseYYY
                            </td>
                            <td colSpan={2}>
                              <input
                                type="number"
                                className="form-control"
                                name="target_day_wise"
                                value={inputs.target_day_wise || ""}
                                onChange={handleChange}
                                placeholder="Target Day wise"
                              />
                            </td>
                          </tr>
                          
                        </tfoot> */}
                      </table>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-3">
                    <button
                      className="btn btn-primary btn-block mg-b-10"
                      onClick={formSubmit}
                    >
                      <i className="fa fa-send mg-r-10"></i> Create
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className="card pd-20 pd-sm-40 col-3"
            style={{ backgroundColor: "#ffffffc7" }}
          >
            <h6 className="card-body-title mg-sm-b-30 text-center">
              Available Items
            </h6>
            <hr></hr>
            <ReactDataTable
              cols={cols}
              receiveData={product}
              isLoading={loading}
              actionTemplate={actionTemplate}
              // deleteData={deleteData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProductionWorkOrder;
