import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import useRequiredFields from "../../../../../hooks/useRequiredFields";
import Http from "../../../../Common/Http";
import IsLogin from "../../../../Common/IsLogin";
import { LoaderComponent } from "../../../../Common/LoaderComponent";



const Transfer = () => {
  const { checkRequiredFields } = useRequiredFields();
  const navigate = useNavigate();
  // const { id } = useParams();
  const [error, setError] = useState({});
  const [receiveData, setReceiveData] = useState([]);
  const [inputs, setInputs] = useState({ status: "1" });
  const [remainingQty, setRemainingQty] = useState(0);
  // const [workOrders, setWorkOrders] = useState([]); // State for work orders
  const [role, setRole] = useState(""); // State for role
  const [product, setProduct] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const fetchData = async () => {
    // Http.get("/issue/" + id).then((res) => {
    //   setReceiveData(res.data.info);
    //   console.log(parseFloat(res.data.info.production_qty),parseFloat(res.data.info.transfer_qty));
    //   // setRemainingQty(parseFloat(res.data.info.production_qty) - parseFloat(res.data.info.transfer_qty));
    //   setRemainingQty(
    //     res.data.info.transfer_qty
    //       ? parseFloat(res.data.info.production_qty) - parseFloat(res.data.info.transfer_qty)
    //       : parseFloat(res.data.info.production_qty)
    //   );

    // });
    const resRole = await Http.get("/staff-type-check"); // Fetch role
    setRole(resRole.data);
    const resProduct = await Http.get("/receipe-finished-good");
    setProduct(resProduct.data);
  };
  useEffect(() => {
    fetchData();
    setInputs((prevInputs) => ({
      ...prevInputs,
      date: getTodayDate(), // Set today's date as default
    }));
  }, []);

  // const fetchReceipeData = async (updatedInputs) => {
  //   try {
  //     const res = await Http.post("ball-charge-stock/", updatedInputs);
  //     setReceiveData(res.data);
  //   } catch (error) {
  //     console.error("Error loading receipe data:", error);
  //   }
  // };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));

    // Validation for Transfer Qty
    // Validation for Transfer Qty
    if (name === "transfer_qty") {
      if (parseInt(value) > remainingQty) {
        Swal.fire({
          icon: "error",
          title: "Invalid Quantity",
          text: `Transfer quantity cannot be greater than Remaining quantity (${remainingQty}).`,
        });
        setInputs((values) => ({ ...values, [name]: 0 }));
        return;
      }
    }

    // Validation for Production Qty
    if (name === "production_qty") {
      if (parseInt(value) > remainingQty) {
        Swal.fire({
          icon: "error",
          title: "Invalid Quantity",
          text: `Production quantity cannot be greater than Remaining quantity (${remainingQty}).`,
        });
        setInputs((values) => ({ ...values, [name]: 0 }));
        return;
      }
    }
  };

  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const handleSelectChange = async (option) => {
    const value = option.value;
    setSelectedOption(option);
    setInputs((prev) => ({ ...prev, product_id: value }));

    if (value === 0) {
      setReceiveData([]); // Clear previous data if the selected product is invalid
      return;
    }

    try {
      // Trigger the ball-charge-stock API with the selected product ID
      const res = await Http.get("ball-charge-stock/" + value);
      setRemainingQty(parseFloat(res.data.chargeQty) || 0); // Update the received data with the API response
    } catch (error) {
      console.error("Error fetching ball-charge-stock data:", error);
    }
  };

  const formSubmit = () => {
    checkRequiredFields();
    // inputs.issue_id = id;

    Http.post("/issue-ball-charge-production-generate", inputs)
      .then((res) => {
        navigate("/production", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Create Production</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">
              Create Production form
              {/* Issue Reference No - <i>{receiveData.reference_no	}</i> */}
            </h6>
            {/* <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg">
                <label>Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  value={inputs.date || ""}
                  onChange={handleChange}
                  placeholder="Input Date"
                />
              </div>
              <div className="col-lg">
                    <label>
                      Shift <i className="fa fa-star required"></i>
                    </label>
                    <select
                      className="form-control"
                      name="shift"
                      value={inputs.shift || ""}
                      onChange={handleChange}
                      data-required
                    >
                      <option value="">Select Shift</option>
                      <option value="a">A</option>
                      <option value="b">B</option>
                      <option value="c">C</option>
                      <option value="general">General</option>
                    </select>
                  </div>
              <div className="col-lg">
                <label>
                  Finished Good <i className="fa fa-star required"></i>
                </label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  value={selectedOption}
                  name="product_id"
                  options={product}
                  onChange={handleSelectChange}
                  isDisabled={role === "mould" || role === "sagger"}
                />
              </div>
              {/* <div className="col-lg">
                <label>Total Produced</label>
                <input
                  type="number"
                  className="form-control"
                  name="prev_qty"
                  value={receiveData.production_qty}
                  onChange={handleChange}
                  // placeholder="Input Produced Qty"
                  readOnly
                />
              </div> */}
              <div className="col-lg">
                <label>Remaining Qty</label>
                <input
                  type="text"
                  className="form-control"
                  name="prev_qty"
                  value={remainingQty || 0}
                  onChange={handleChange}
                  // placeholder="Input Produced Qty"
                  readOnly
                />
              </div>
              {/* {role === "mould" && (
                <div className="col-lg">
                <label>Plaster of Paris</label>
                <input
                  type="number"
                  className="form-control"
                  name="plaster_paris"
                  value={inputs.plaster_paris || 0}
                  onChange={handleChange}
                  placeholder="Input Plaster of Paris"
                />
              </div>
              )} */}
            </div>
            <div className="row mt-3">
              <div className="col-lg">
                <label>
                  Production Qty <i className="fa fa-star required"></i>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="production_qty"
                  value={inputs.production_qty}
                  onChange={handleChange}
                  placeholder="Input Deliver Qty"
                  data-required
                />
              </div>
              <div className="col-lg">
                <label>
                  Transfer Qty <i className="fa fa-star required"></i>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="transfer_qty"
                  value={inputs.transfer_qty}
                  onChange={handleChange}
                  placeholder="Input Deliver Qty"
                  data-required
                />
              </div>
              {role === "body" && inputs.transfer_qty > 0 && (
                <div className="col-lg">
                  <label>
                    To Department <i className="fa fa-star required"></i>
                  </label>
                  <select
                    className="form-control"
                    name="to_department_id"
                    onChange={handleChange}
                    data-required
                  >
                    <option value={""}>Select Department</option>
                    <option value={2}>Jigger</option>
                    <option value={3}>Casting</option>
                    <option value={6}>Glaze</option>
                  </select>
                </div>
              )}
              <div className="col-lg">
                <label>Remarks</label>
                <textarea
                  className="form-control"
                  name="remarks"
                  onChange={handleChange}
                  placeholder="Remarks"
                >
                  {inputs.remarks || ""}
                </textarea>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-3">
                <button
                  className="btn btn-primary btn-block mg-b-10"
                  onClick={formSubmit}
                >
                  <i className="fa fa-send mg-r-10"></i> Submit
                </button>
              </div>
            </div>
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default Transfer;
