import $ from "jquery"; // Import jQuery
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import useRequiredFields from "../../../../../hooks/useRequiredFields";
import Http from "../../../../Common/Http";
import IsLogin from "../../../../Common/IsLogin";
import { LoaderComponent } from "../../../../Common/LoaderComponent";

const Create = () => {
  const { checkRequiredFields } = useRequiredFields();
  const navigate = useNavigate();
  // const { id } = useParams();

  const [inputs, setInputs] = useState({
    receipt_no: "",
    production_work_order_id: "",
    date: "",
    receive_date: "",
    copy_type: "",
    purchase_type: "",
    from_department: "",
    to_department: "",
    status: "requested",
    remarks: "",
  });

  const [list, setList] = useState([]);
  const [error, setError] = useState({});
  const [product, setProduct] = useState([]);
  const [planningOptions, setPlanningOptions] = useState([]);
  const [role, setRole] = useState("");
  const [itemLoading, setItemLoading] = useState(false);

  const fetchData = async () => {
    try {
      const resProduct = await Http.get("/product-list/all");
      setProduct(resProduct.data);

      const resRole = await Http.get("/staff-type-check");
      setRole(resRole.data);

      const resPlanning = await Http.get("/planning");
      const planningData = resPlanning.data;
      setPlanningOptions(planningData);

      if (planningData.length > 0) {
        // Automatically select the first planning and set in inputs
        const firstPlanning = planningData[0];
        setInputs((prevInputs) => ({
          ...prevInputs,
          planning_id: firstPlanning.id, // Assuming the ID field for planning
        }));

        // Load items for the first planning
        handlePlanningChange(firstPlanning.id);
      }

      // Fetch the receipt number
      const resReceiptNo = await Http.get(
        "/last-code-increment-by-one/production_finished_goods/receipt_no/P"
      );
      const receiptNo = resReceiptNo.data; // Assuming the API returns the receipt number directly

      // Set the fetched receipt number to inputs
      setInputs((prevInputs) => ({
        ...prevInputs,
        receipt_no: receiptNo,
      }));
    } catch (e) {
      console.error("Error fetching data:", e);
    }
  };

  useEffect(() => {
    $("body").addClass("collapse-menu");
    // $("body").toggleClass("show-left");
    fetchData();

    // // Set `to_department` based on `role`
    // if (role === "jigger") {
    //   setInputs((prevInputs) => ({
    //     ...prevInputs,
    //     to_department:
    //       departments.find((dept) => dept.name === "FINISHING")?.id || "",
    //   }));
    // } else if (role === "casting") {
    //   setInputs((prevInputs) => ({
    //     ...prevInputs,
    //     to_department: departments.find((dept) => dept.name === "BK")?.id || "",
    //   }));
    // }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedList = [...list];
    let inputValue = parseFloat(value) || 0;

    // Update the specific field with the input value
    updatedList[index][name] = inputValue;

    // Validation: transfer_qty can't be more than total_received_qty
    // if (role === "jigger" || role === "casting" || role === "finishing" || role === "bk" || role === "glazing" || role === "gk" || role === "seelction" || role === 'sagger' || role === 'mould') {
    if (name === "transfer_qty") {
      const total_received_qty =
        parseFloat(updatedList[index].total_received_qty) || 0;
      const total_transfer_qty =
        parseFloat(updatedList[index].total_transfer_qty) || 0;
      const remaining_qty = total_received_qty - total_transfer_qty;

      if (value > remaining_qty) {
        Swal.fire({
          icon: "error",
          title: "Validation Error",
          text: "Transfer quantity cannot exceed production quantity.",
        });
        updatedList[index].transfer_qty = remaining_qty;
      }
    }
    // }

    setList(updatedList);
  };

  const formSubmit = () => {
    checkRequiredFields();
    const formData = {
      ...inputs,
      items: list,
    };

    Http.post(`/production-transfer`, formData)
      .then((res) => {
        navigate("/transfer-art", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  const handlePlanningChange = async (planningId) => {
    if (!planningId) return;

    setInputs((prevInputs) => ({
      ...prevInputs,
      planning_id: planningId, // Update planning_id in inputs
    }));

    try {
      // Fetch data based on selected planning ID
      const response = await Http.get(`/planning/${planningId}`);

      // Update list with items from the response
      const planningItems = response.data.items.map((item) => ({
        id: item.id,
        product_id: item.product_id,
        product_name: item.product.name,
        qty: item.qty,
        total_transfer: item.total_transfer,
        shape: item.shape?.name,
        production_work_order: item.production_work_order?.receipt_no,
        company: item.production_work_order?.company?.name,
        product_code: item.product.code,
        opening_balance: 0, // Adjust based on requirement or add additional fields as needed
        weight: item.weight, // Example if qty is used as weight
        // production_qty: item.qty,
        transfer_qty: 0,
        biscuit_weight: 0, // Adjust if role is bk
        glost_weight: 0, // Adjust if role is bk
        loss_qty: 0,
        closing_balance: item.qty, // Example calculation; adjust if necessary
        remarks_delivery: "", // Set to empty or adjust if needed
        total_received_qty: item.total_received_qty,
        total_transfer_qty: item.total_transfer_qty,
        total_loss_qty: item.total_loss_qty,
      }));

      setList(planningItems);
    } catch (error) {
      console.error("Error fetching products for planning:", error);
      Swal.fire(
        "Error!",
        "Failed to fetch products for the selected planning.",
        "error"
      );
    }
  };

  const cols = [{ field: "name", header: "Item" }];

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Production and Deliver</h5>
        </div>
        <div className="am-pagebody  row d-flex justify-content-evenly">
          <div className="card pd-20 pd-sm-40 col-11">
            <h6 className="card-body-title mg-sm-b-30">
              Production and Delivery
            </h6>
            {/* <p>Transfer To Department {inputs.to_department}</p> */}
            <div className="row">
              <div className="col-lg">
                <label>Receipt No</label>
                <input
                  type="text"
                  className="form-control"
                  name="receipt_no"
                  value={inputs.receipt_no}
                  disabled
                />
              </div>

              {/* <div className="col-lg">
                <label>Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  value={inputs.date}
                  disabled
                />
              </div> */}
              <div className="col-lg">
                <label>Planning</label>
                <select
                  className="form-control"
                  value={inputs.planning_id || ""} // Bind to inputs
                  onChange={(e) => handlePlanningChange(e.target.value)}
                >
                  <option value="">Select Planning</option>
                  {planningOptions.map((planning) => (
                    <option key={planning.id} value={planning.id}>
                      {planning.receipt_no}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg">
                <label>
                  Shift <i className="fa fa-star required"></i>
                </label>
                <select
                  className="form-control"
                  name="shift"
                  value={inputs.shift}
                  onChange={handleChange}
                  data-required
                >
                  <option value="">Select Shift</option>
                  <option value="a">A</option>
                  <option value="b">B</option>
                  <option value="c">C</option>
                  <option value="general">General</option>
                </select>
              </div>

              {/* <div className="row">
                <label>Transfer To Department {inputs.to_department}</label>
                
              </div> */}

              <div className="col-lg">
                <label>Remarks</label>
                <textarea
                  className="form-control"
                  name="remarks"
                  value={inputs.remarks}
                />
              </div>
            </div>

            {/* Item List */}
            <div className="row mt-3">
              <div className="card-solid">
                <div className="card-header with-border text-center">
                  <h5 className="card-title mb-0">Items in the Production</h5>
                </div>
              </div>
              <div className="col-lg">
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      <th>Planning Qty</th>
                      {/* <th>Opening Balance</th> */}
                      <th>Received</th>
                      {/* <th>Total Produced</th> */}
                      {/* <th>Weight</th> */}
                      {/* <th>Production Qty</th> opening + rcved   */}
                      <th>Transfered</th>
                      {role === "bk" && <th>Biscuit Weight</th>}
                      {role === "gk" && <th>Glost Weight</th>}
                      <th>Transfer</th>
                      {role !== "finishing" && <th>Total Loss Qty</th>}
                      {role !== "finishing" && <th>Loss Qty</th>}
                      {/* <th>Closing Balance</th> total p - total t */}
                    </tr>
                  </thead>
                  <tbody>
                    {list.length < 1 ? (
                      <tr>
                        <td colSpan={13} className="text-center">
                          Please add products
                        </td>
                      </tr>
                    ) : (
                      list.map((item, index) => (
                        <tr key={index}>
                          <td>
                            {item.product_name} ({item.shape}) || {item.company}{" "}
                            #{item.production_work_order}
                          </td>
                          <td>{item.qty}</td>

                          {/* <td>{item.opening_balance}</td> */}
                          <td>{item.total_received_qty}</td>
                          <td>{item.total_transfer_qty}</td>
                          {/* <td>
                            <input
                              type="number"
                              className="form-control"
                              name="weight"
                              value={item.weight}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </td> */}
                          {/* <td>
                            <input
                              type="number"
                              className="form-control"
                              name="production_qty"
                              value={inputs.production_qty || 0}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </td> */}
                          {/* <td>350</td> */}
                          {role === "bk" && (
                            <td>
                              <input
                                type="number"
                                className="form-control"
                                name="biscuit_weight"
                                value={item.biscuit_weight || ""}
                                onChange={(e) => handleInputChange(e, index)}
                              />
                            </td>
                          )}

                          {role === "gk" && (
                            <td>
                              <input
                                type="number"
                                className="form-control"
                                name="glost_weight"
                                value={item.glost_weight || ""}
                                onChange={(e) => handleInputChange(e, index)}
                              />
                            </td>
                          )}
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              name="transfer_qty"
                              value={item.transfer_qty}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </td>

                          {role !== "finishing" && (
                            <>
                              <td>{item.total_loss_qty || 0}</td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="loss_qty"
                                  value={item.loss_qty}
                                  onChange={(e) => handleInputChange(e, index)}
                                />
                              </td>
                            </>
                          )}
                          {/* <td>{item.closing_balance}</td> */}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            {list.length > 0 && (
              <div className="row mt-3">
                <div className="col-3">
                  <button className="btn btn-primary" onClick={formSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create;
