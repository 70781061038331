import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Alert from "../../../Common/Alert";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";

import useAlertFromLocation from "../../../../hooks/useAlertFromLocation";
import useDeleteData from "../../../../hooks/useDeleteData";
import ReactDataTable from "../../../Common/ReactDataTable";

const RepairList = () => {
  const [planningData, setPlanningData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState(""); // State to hold the role
  const [userDepartment, setUserDepartment] = useState(""); // State to hold the role

  // Fetch data from /planning API
  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await Http.get("/repair-list");
      const transferData = res.data; // Root-level array

      setPlanningData(transferData);
      // setPlanningData(dummy);

      Http.get("/staff-type-check").then((res) => {
        setRole(res.data);
      });
      Http.get("/me").then((res) => {
        setUserDepartment(res.data.data.department_art_id);
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Use custom hook to delete data
  const deleteData = useDeleteData(fetchData);
  const alert = useAlertFromLocation();

  const receiveSubmitData = (id, showMsg) => {
    Swal.fire({
      title: "Are you sure You Want to Receive this Item?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, receive it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Http.post("/" + deleteApiUrl + "/" + id + "/cash-count").then((res) => {
        //   setPlanningData(res.data);
        //   Swal.fire("Received!", showMsg + " has been deleted.", "success");
        // });
        Http.post(`/transfer-item-received/${id}`)
          .then((res) => {
            Swal.fire("Received!", "Item has been received.", "success");
            fetchData();
          })
          .catch(function (e) {
            Swal.fire("Could not receive!", "Could not receive", "danger");
          });
      }
    });
  };
  /*const produceSubmitData = (id, showMsg) => {
    Swal.fire({
      title: "Are you sure You Want to Produce this Repair Item?",
      text: "You won't be able to revert this! <>",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, produce it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Http.post(`/repair-produced/${id}`)
          .then((res) => {
            Swal.fire("Received!", "Item has been received.", "success");
            fetchData();
          })
          .catch(function (e) {
            Swal.fire("Could not receive!", "Could not receive", "danger");
          });
      }
    });
  };*/

  /*const produceSubmitData = (id, showMsg) => {
    Swal.fire({
      title: "Are you sure You Want to Produce this Repair Item?",
      text: "You won't be able to revert this!",
      icon: "warning",
      input: "number", // Add an input field for quantity
      inputPlaceholder: "Enter quantity",
      inputAttributes: {
        min: 1,
      },
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, produce it!",
      inputValidator: (value) => {
        if (!value || isNaN(value) || parseInt(value) <= 0) {
          return "Please enter a valid quantity!";
        }
      },
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        const qty = result.value; // Get the quantity value from the input
        Http.post(`/repair-produced/${id}`, { qty }) // Pass the quantity in the POST request
          .then((res) => {
            Swal.fire("Received!", "Item has been received.", "success");
            fetchData();
          })
          .catch((e) => {
            Swal.fire("Could not receive!", "Could not receive", "danger");
          });
      }
    });
  };*/

  // const produceSubmitData = (id, userType, qty, itemName) => {
  //   const allowedRolesForLossQty = [
  //     "selection",
  //     "packing_local",
  //     "packing_export",
  //     "decoration_export",
  //     "decoration_local",
  //     "dfw",
  //   ];
  //   const showLossQty = allowedRolesForLossQty.includes(userType);

  //   const showDropdown = userType === "selection";

  //   Swal.fire({
  //     title: `You Want to Repair ${itemName}?`,
  //     icon: "warning",
  //     html: `
  //       <input type="number" id="qty" value="${qty}" class="swal2-input" placeholder="Enter quantity" min="1">
  //       ${
  //         showLossQty
  //           ? '<input type="number" id="loss_qty" class="swal2-input" placeholder="Enter loss quantity">'
  //           : ""
  //       }
  //       ${
  //         showDropdown
  //           ? `
  //         <select id="selectionDropdown" class="swal2-input mt-3">
  //           <option value="" disabled selected>Select Transfer Department</option>
  //           <option value="9">Decorate Local</option>
  //           <option value="10">Decorate Export</option>
  //         </select>
  //       `
  //           : ""
  //       }
  //     `,
  //     focusConfirm: false,
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes",
  //     preConfirm: () => {
  //       const qty = document.getElementById("qty").value;
  //       const lossQty = showLossQty
  //         ? document.getElementById("loss_qty").value
  //         : null;
  //       const dropdownValue = showDropdown
  //         ? document.getElementById("selectionDropdown").value
  //         : null;

  //       if (!qty || isNaN(qty) || parseInt(qty) <= 0) {
  //         Swal.showValidationMessage("Please enter a valid quantity!");
  //         return false;
  //       }

  //       // if (
  //       //   showLossQty &&
  //       //   (lossQty === null ||
  //       //     lossQty === "" ||
  //       //     isNaN(lossQty) ||
  //       //     parseInt(lossQty) < 0)
  //       // ) {
  //       //   Swal.showValidationMessage("Please enter a valid loss quantity!");
  //       //   return false;
  //       // }

  //       if (showDropdown && (!dropdownValue || dropdownValue === "")) {
  //         Swal.showValidationMessage(
  //           "Please select an option from the dropdown!"
  //         );
  //         return false;
  //       }

  //       return { qty, loss_qty: lossQty, to_department_id: dropdownValue };
  //     },
  //   }).then((result) => {
  //     if (result.isConfirmed && result.value) {
  //       const { qty, loss_qty, to_department_id } = result.value;
  //       Http.post(`/repair-produced/${id}`, { qty, loss_qty, to_department_id }) // Pass all values in the POST request
  //         .then((res) => {
  //           Swal.fire("Repaired!", "Item has been repaired.", "success");
  //           fetchData();
  //         })
  //         .catch((e) => {
  //           Swal.fire("Could not repair!", "Could not repair", "danger");
  //         });
  //     }
  //   });
  // };
  
  
  
  
  
  
  
  
  
  
  
  /*
  without auto to dept
  const produceSubmitData = (id, userType, qty, itemName) => {
    const allowedRolesForLossQty = [
      "selection",
      "packing_local",
      "packing_export",
      "decoration_export",
      "decoration_local",
      "dfw",
    ];

    const showLossQty = allowedRolesForLossQty.includes(userType);
    const showDropdown = userType === "selection";

    const inputFields = []; // Dynamically render input fields

    // Add fields based on the role
    if (userType === "selection" || userType === "dfw") {
      inputFields.push(
        '<input type="number" id="delivery_qty_export" class="swal2-input" placeholder="Enter Delivery Qty Export" min="1">',
        '<input type="number" id="delivery_qty_a_class" class="swal2-input" placeholder="Enter Delivery Qty A Class" min="1">',
        '<input type="number" id="delivery_qty_b_class" class="swal2-input" placeholder="Enter Delivery Qty B Class" min="1">',
        '<input type="number" id="delivery_qty_c_class" class="swal2-input" placeholder="Enter Delivery Qty C Class" min="1">'
      );
    } else if (
      userType === "decoration_local" ||
      userType === "packing_local"
    ) {
      inputFields.push(
        '<input type="number" id="delivery_qty_a_class" class="swal2-input" placeholder="Enter Delivery Qty A Class" min="1">',
        '<input type="number" id="delivery_qty_b_class" class="swal2-input" placeholder="Enter Delivery Qty B Class" min="1">',
        '<input type="number" id="delivery_qty_c_class" class="swal2-input" placeholder="Enter Delivery Qty C Class" min="1">'
      );
    } else if (
      userType === "decoration_export" ||
      userType === "packing_export"
    ) {
      inputFields.push(
        '<input type="number" id="delivery_qty_export" class="swal2-input" placeholder="Enter Delivery Qty Export" min="1">'
      );
    }

    // // Add dropdown for decoration roles
    // if (userType === "decoration_local" || userType === "decoration_export") {
    //   inputFields.push(`
    //     <select id="decorationDropdown" class="swal2-input mt-3">
    //       <option value="" disabled selected>Select Decoration Type</option>
    //       <option value="local">Local Decoration</option>
    //       <option value="export">Export Decoration</option>
    //     </select>
    //   `);
    // }
    if (userType === "glazing" || userType === "gk") {
      inputFields.push(`
        <input type="number" id="qty" value="${qty}" class="swal2-input" placeholder="Enter quantity">
      `);
    }

    Swal.fire({
      title: `You Want to Repair ${itemName}?`,
      icon: "warning",
      html: `
        
        ${inputFields.join("")}
        ${
          showDropdown
            ? `
                  <select id="selectionDropdown" class="swal2-input mt-3">
                    <option value="" disabled selected>Select Transfer Department</option>
                    <option value="9">Decorate Local</option>
                    <option value="10">Decorate Export</option>
                  </select>
                `
            : ""
        }
      `,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      preConfirm: () => {
        // const qty = document.getElementById("qty").value;

        const deliveryQty = document.getElementById("qty")
          ? document.getElementById("qty").value
          : null;

        // Get field values
        const deliveryQtyExport = document.getElementById("delivery_qty_export")
          ? document.getElementById("delivery_qty_export").value
          : null;
        const deliveryQtyAClass = document.getElementById(
          "delivery_qty_a_class"
        )
          ? document.getElementById("delivery_qty_a_class").value
          : null;
        const deliveryQtyBClass = document.getElementById(
          "delivery_qty_b_class"
        )
          ? document.getElementById("delivery_qty_b_class").value
          : null;
        const deliveryQtyCClass = document.getElementById(
          "delivery_qty_c_class"
        )
          ? document.getElementById("delivery_qty_c_class").value
          : null;
        const decorationType = document.getElementById("decorationDropdown")
          ? document.getElementById("decorationDropdown").value
          : null;
        const dropdownValue = showDropdown
          ? document.getElementById("selectionDropdown").value
          : null;

        // Validation
        // if (!qty || isNaN(qty) || parseInt(qty) <= 0) {
        //   Swal.showValidationMessage("Please enter a valid quantity!");
        //   return false;
        // }

        if (userType === "glazing" || userType === "gk") {
          if (!qty) {
            Swal.showValidationMessage(
              "Please enter at least one value for Export, A, B, or C!"
            );
            return false;
          }
        }
        if (userType === "selection" || userType === "dfw") {
          if (
            !deliveryQtyExport &&
            !deliveryQtyAClass &&
            !deliveryQtyBClass &&
            !deliveryQtyCClass
          ) {
            Swal.showValidationMessage(
              "Please enter at least one value for Export, A, B, or C!"
            );
            return false;
          }
        }

        if (
          (userType === "decoration_local" || userType === "packing_local") &&
          !deliveryQtyAClass &&
          !deliveryQtyBClass &&
          !deliveryQtyCClass
        ) {
          Swal.showValidationMessage(
            "Please enter at least one value for A, B, or C!"
          );
          return false;
        }

        if (
          (userType === "decoration_export" || userType === "packing_export") &&
          (!deliveryQtyExport ||
            isNaN(deliveryQtyExport) ||
            parseInt(deliveryQtyExport) <= 0)
        ) {
          Swal.showValidationMessage(
            "Please enter a valid value for Delivery Qty Export!"
          );
          return false;
        }

        if (
          (userType === "decoration_local" ||
            userType === "decoration_export") &&
          !decorationType
        ) {
          Swal.showValidationMessage("Please select a decoration type!");
          return false;
        }

        if (showDropdown && (!dropdownValue || dropdownValue === "")) {
          Swal.showValidationMessage(
            "Please select an option from the dropdown!"
          );
          return false;
        }

        return {
          qty,
          delivery_qty_export: deliveryQtyExport,
          delivery_qty_a_class: deliveryQtyAClass,
          delivery_qty_b_class: deliveryQtyBClass,
          delivery_qty_c_class: deliveryQtyCClass,
          decoration_type: decorationType,
          to_department_id: dropdownValue,
        };
      },
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        const {
          qty,
          delivery_qty_export,
          delivery_qty_a_class,
          delivery_qty_b_class,
          delivery_qty_c_class,
          decoration_type,
          to_department_id
        } = result.value;

        // POST request with all values
        Http.post(`/repair-produced/${id}`, {
          qty,
          delivery_qty_export,
          delivery_qty_a_class,
          delivery_qty_b_class,
          delivery_qty_c_class,
          decoration_type,
          to_department_id
        })
          .then((res) => {
            Swal.fire("Repaired!", "Item has been repaired.", "success");
            fetchData();
          })
          .catch((e) => {
            Swal.fire("Could not repair!", "Could not repair", "danger");
          });
      }
    });
  };
  */

  const produceSubmitData = (id, userType, qty, itemName) => {
    const allowedRolesForLossQty = [
      "selection",
      "packing_local",
      "packing_export",
      "decoration_export",
      "decoration_local",
      "dfw",
    ];
  
    const isQtyMandatory = userType === "glazing" || userType === "gk";
  
    const inputFields = []; // Dynamically render input fields
  
    // Add fields based on the role
    if (userType === "selection" || userType === "dfw") {
      inputFields.push(
        '<input type="number" id="delivery_qty_export" class="swal2-input" placeholder="Enter Delivery Qty Export" min="1">',
        '<input type="number" id="delivery_qty_a_class" class="swal2-input" placeholder="Enter Delivery Qty A Class" min="1">',
        '<input type="number" id="delivery_qty_b_class" class="swal2-input" placeholder="Enter Delivery Qty B Class" min="1">',
        '<input type="number" id="delivery_qty_c_class" class="swal2-input" placeholder="Enter Delivery Qty C Class" min="1">'
      );
    } else if (
      userType === "decoration_local" ||
      userType === "packing_local"
    ) {
      inputFields.push(
        '<input type="number" id="delivery_qty_a_class" class="swal2-input" placeholder="Enter Delivery Qty A Class" min="1">',
        '<input type="number" id="delivery_qty_b_class" class="swal2-input" placeholder="Enter Delivery Qty B Class" min="1">',
        '<input type="number" id="delivery_qty_c_class" class="swal2-input" placeholder="Enter Delivery Qty C Class" min="1">'
      );
    } else if (
      userType === "decoration_export" ||
      userType === "packing_export"
    ) {
      inputFields.push(
        '<input type="number" id="delivery_qty_export" class="swal2-input" placeholder="Enter Delivery Qty Export" min="1">'
      );
    }
  
    if (isQtyMandatory) {
      inputFields.unshift(`
        <input type="number" id="qty" value="${qty}" class="swal2-input" placeholder="Enter quantity">
      `);
    }
  
    Swal.fire({
      title: `You Want to Repair ${itemName}?`,
      icon: "warning",
      html: `
        ${inputFields.join("")}
      `,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      preConfirm: () => {
        const qtyField = document.getElementById("qty");
        const deliveryQtyExportField = document.getElementById(
          "delivery_qty_export"
        );
        const deliveryQtyAClassField = document.getElementById(
          "delivery_qty_a_class"
        );
        const deliveryQtyBClassField = document.getElementById(
          "delivery_qty_b_class"
        );
        const deliveryQtyCClassField = document.getElementById(
          "delivery_qty_c_class"
        );
  
        const qty = qtyField ? qtyField.value : null;
        const deliveryQtyExport = deliveryQtyExportField
          ? deliveryQtyExportField.value
          : null;
        const deliveryQtyAClass = deliveryQtyAClassField
          ? deliveryQtyAClassField.value
          : null;
        const deliveryQtyBClass = deliveryQtyBClassField
          ? deliveryQtyBClassField.value
          : null;
        const deliveryQtyCClass = deliveryQtyCClassField
          ? deliveryQtyCClassField.value
          : null;
  
        // Determine to_department_id based on field values
        let to_department_id = null;
        if (deliveryQtyExport) {
          to_department_id = 10; // Export
        } else if (deliveryQtyAClass || deliveryQtyBClass || deliveryQtyCClass) {
          to_department_id = 9; // Local
        }
  
        // Validation
        if (
          isQtyMandatory &&
          (!qty || isNaN(qty) || parseInt(qty) <= 0)
        ) {
          Swal.showValidationMessage("Please enter a valid quantity!");
          return false;
        }
  
        if (
          (userType === "decoration_local" || userType === "packing_local") &&
          !deliveryQtyAClass &&
          !deliveryQtyBClass &&
          !deliveryQtyCClass
        ) {
          Swal.showValidationMessage(
            "Please enter at least one value for A, B, or C!"
          );
          return false;
        }
  
        if (
          (userType === "decoration_export" || userType === "packing_export") &&
          (!deliveryQtyExport ||
            isNaN(deliveryQtyExport) ||
            parseInt(deliveryQtyExport) <= 0)
        ) {
          Swal.showValidationMessage(
            "Please enter a valid value for Delivery Qty Export!"
          );
          return false;
        }
  
        if (!to_department_id) {
          Swal.showValidationMessage(
            "Please ensure at least one field has a valid value!"
          );
          return false;
        }
  
        return {
          qty,
          delivery_qty_export: deliveryQtyExport,
          delivery_qty_a_class: deliveryQtyAClass,
          delivery_qty_b_class: deliveryQtyBClass,
          delivery_qty_c_class: deliveryQtyCClass,
          to_department_id,
        };
      },
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        const {
          qty,
          delivery_qty_export,
          delivery_qty_a_class,
          delivery_qty_b_class,
          delivery_qty_c_class,
          to_department_id,
        } = result.value;
  
        // POST request with all values
        Http.post(`/repair-produced/${id}`, {
          qty,
          delivery_qty_export,
          delivery_qty_a_class,
          delivery_qty_b_class,
          delivery_qty_c_class,
          to_department_id,
        })
          .then((res) => {
            Swal.fire("Repaired!", "Item has been repaired.", "success");
            fetchData();
          })
          .catch((e) => {
            Swal.fire("Could not repair!", "Could not repair", "danger");
          });
      }
    });
  };
  
  

  /*const produceSubmitData = (id, role) => {
    Swal.fire({
      title: "Are you sure You Want to Produce this Repair Item?",
      html: `
        <div className="mt-3">
          <label for="qtyInput">Enter Quantity:</label>
          <input type="number" id="qtyInput" class="swal2-input" placeholder="Enter quantity" min="1" required>
        </div>
        ${role === "selection" || role === "packing_local" || role === "packing_export"
          ? `
        <div className="mt-3">
          <label for="rejectedQtyInput">Enter Rejected Quantity:</label>
          <input type="number" id="rejectedQtyInput" class="swal2-input" placeholder="Enter rejected quantity" min="0">
        </div>`
          : ""}
      `,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, produce it!",
      preConfirm: () => {
        const qty = document.getElementById("qtyInput").value;
        const rejectedQty = role === "selection" || role === "packing_local" || role === "packing_export"
          ? document.getElementById("rejectedQtyInput").value
          : null;

        if (!qty || isNaN(qty) || parseInt(qty) <= 0) {
          return Swal.showValidationMessage("Please enter a valid quantity.");
        }

        if (rejectedQty !== null && (isNaN(rejectedQty) || parseInt(rejectedQty) < 0)) {
          return Swal.showValidationMessage("Please enter a valid rejected quantity.");
        }

        return { qty, rejectedQty };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const { qty, rejectedQty } = result.value;
        Http.post(`/repair-produced/${id}`, { qty, loss_qty: rejectedQty || 0 })
          .then(() => {
            Swal.fire("Received!", "Item has been produced successfully.", "success");
            fetchData();
          })
          .catch(() => {
            Swal.fire("Error!", "An error occurred while producing the item.", "error");
          });
      }
    });
  };*/

  const cols = [
    {
      field: "production_finished_good_transfer.receipt_no",
      header: "Receipt No",
    },
    { field: "product.name", header: "Product Name" },

    ...(role !== "body" || role === "admin"
      ? [{ field: "shape.name", header: "Shape" }]
      : []),
    ...(role === "dfw" ||
    role === "packing_export" ||
    role === "packing_local" ||
    role === "admin"
      ? [{ field: "decoration", header: "Decor" }]
      : []),
    { field: "type_heading", header: "Repair Type" },
    ...(role === "admin" ||
    (role !== "decoration_local" &&
      role !== "decoration_export" &&
      role !== "dfw" &&
      role !== "packing_local" &&
      role !== "packing_export")
      ? [{ field: "delivery_qty", header: "Qty" }]
      : []),
    ...(role === "admin" ||
    role === "decoration_export" ||
    role === "dfw" ||
    role === "packing_export"
      ? [{ field: "qty_export", header: "Qty Exp" }]
      : []),
    ...(role === "admin" ||
    role === "decoration_local" ||
    role === "dfw" ||
    role === "packing_local"
      ? [
          { field: "qty_a_class", header: "Qty A" },
          { field: "qty_b_class", header: "Qty B" },
          { field: "qty_c_class", header: "Qty C" },
        ]
      : []),
    {
      field: "production_finished_good_transfer.transfer_date",
      header: "Tr Date",
    },
    {
      field: "receive_date",
      header: "Rec Date",
    },
    { field: "from_department_art.name", header: "From" },
    { field: "to_department_art.name", header: "To" },
    {
      field: (item) => {
        if (item.from_department_art_id === userDepartment) {
          // From department logic
          return item.receive_qty < 1 ? (
            <span className="badge bg-info">Delivered</span>
          ) : (
            <span className="badge bg-success">Received</span>
          );
        } else if (item.to_department_art_id === userDepartment) {
          // To department logic
          return item.receive_qty < 1 ? (
            <span className="badge bg-warning">Pending</span>
          ) : (
            <span className="badge bg-success">Received</span>
          );
        } else {
          // Default fallback (if neither department matches)
          return <span className="badge bg-secondary">Unknown</span>;
        }
      },
      header: " Status ",
    },
    // {
    //   field: (item) => (
    //     <div className="text-xs text-nowrap">
    //       {item.receive_qty < 1 &&
    //       item.to_department_art_id == userDepartment ? (
    //         <button
    //           className="btn btn-primary btn-sm ms-1"
    //           onClick={() => receiveSubmitData(item.id)}
    //         >
    //           <i className="fa fa-send"></i> Receive
    //         </button>
    //       ) : item.receive_qty < 1 ? (
    //         <i>Pending</i>
    //       ) : (
    //         <i
    //           className="fa fa-check"
    //           style={{ color: "green", fontWeight: "bold" }}
    //         >
    //           {" "}
    //           Received
    //         </i>
    //       )}
    //     </div>
    //   ),
    //   header: "Actions",
    // },
    {
      field: (item) => (
        <div className="text-xs text-nowrap">
          {item.production == null &&
          item.to_department_art_id === userDepartment &&
          item.is_repair == 1 &&
          item.receive_qty > 0 ? (
            <button
              className="btn btn-warning btn-sm ms-1"
              onClick={() =>
                produceSubmitData(
                  item.id,
                  role,
                  item.delivery_qty,
                  item.product.name
                )
              }
              // onClick={() => produceSubmitData(item.id, role)}
            >
              <i className="fa fa-cogs"></i> Repair
            </button>
          ) : item.receive_qty < 1 &&
            item.to_department_art_id === userDepartment ? (
            <button
              className="btn btn-primary btn-sm ms-1"
              onClick={() => receiveSubmitData(item.id)}
            >
              <i className="fa fa-send"></i> Receive
            </button>
          ) : item.receive_qty < 1 ? (
            <>
              <i>Pending</i>
              <Link
                to={`/edit-selection-decoration-repair/req-art/${item.production_finished_good_transfer_id}`}
                className="btn btn-secondary btn-sm ms-3"
              >
                <i className="fa fa-edit"></i> Edit
              </Link>
            </>
          ) : (
            <i
              className="fa fa-check"
              style={{ color: "green", fontWeight: "bold" }}
            >
              {" "}
              Received
            </i>
          )}
        </div>
      ),
      header: "Actions",
    },
  ];

  // const cols = [
  //   { field: "receipt_no", header: "Receipt No" },
  //   { field: "product_name", header: "Product Name" },
  //   { field: "shape", header: "Shape" },
  //   { field: "decoration", header: "Decor" },
  //   { field: "qty", header: "Qty Exp" },
  //   // { field: "qty_export", header: "Qty Exp" },
  //   // { field: "qty_a_class", header: "Qty A" },
  //   // { field: "qty_b_class", header: "Qty B" },
  //   // { field: "qty_c_class", header: "Qty C" },

  //   { field: "transfer_date", header: "Tr Date" },
  //   { field: "receive_date", header: "Rec Date" },
  //   { field: "from_department_art.name", header: "From" },
  //   { field: "to_department_art.name", header: "To" },
  //   { field: "status", header: " Status" },
  //   {
  //     field: (item) => (
  //       <div className="text-xs text-nowrap">
  //         {item.status === "Pending" ? (
  //           <Link
  //             className="btn btn-primary btn-sm ms-1"
  //             to={`/create-receive/${item.id}/req-art`}
  //           >
  //             <i className="fa fa-send"></i> Receive
  //           </Link>
  //         ) : (
  //           <i>Received</i>
  //         )}
  //       </div>
  //     ),
  //     header: "Actions",
  //   },
  // ];

  return (
    <div>
      <IsLogin />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">All Repairs</h5>
        </div>
        <div className="am-pagebody">
          <Alert alert={alert} />
          <div className="card pd-20 pd-sm-40">
            <ReactDataTable
              cols={cols}
              receiveData={planningData}
              isLoading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepairList;
