import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAlertFromLocation from "../../../../hooks/useAlertFromLocation";
import useDeleteData from "../../../../hooks/useDeleteData";
import Alert from "../../../Common/Alert";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import ReactDataTable from "../../../Common/ReactDataTable";

const List = () => {
  const [requisitionData, setRequisitionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [staffType, setStaffType] = useState(""); // For storing the staff check result
  const [me, setMe] = useState([]); // For storing the staff check result
  const [departments, setDepartments] = useState([
    { id: 1, name: "BODY", user_type: "body" },
    { id: 2, name: "JIGGER", user_type: "jigger" },
    { id: 3, name: "CASTING", user_type: "casting" },
    { id: 4, name: "FINISHING", user_type: "finishing" },
    { id: 5, name: "BK", user_type: "bk" },
    { id: 6, name: "GLAZING", user_type: "glazing" },
    { id: 7, name: "GK", user_type: "gk" },
    { id: 8, name: "SELECTION", user_type: "selection" },
    { id: 9, name: "DECORATION LOCAL", user_type: "decoration_local" },
    { id: 10, name: "DECORATION EXPORT", user_type: "decoration_export" },
    { id: 11, name: "DFW", user_type: "dfw" },
    { id: 12, name: "PACKING LOCAL", user_type: "packing_local" },
    { id: 13, name: "PACKING EXPORT", user_type: "packing_export" },
    { id: 14, name: "DSTORE", user_type: "dstore" },
    { id: 15, name: "GSTORE", user_type: "gstore" },
    { id: 16, name: "MOULD", user_type: "mould" },
    { id: 17, name: "PSTORE", user_type: "pstore" },
    { id: 18, name: "PLANNING", user_type: "planning" },
    { id: 19, name: "SAGGER", user_type: "sagger" },
  ]);

  // Helper function to get user type from department ID
  const getUserType = (departmentId) => {
    const department = departments.find((dept) => dept.id === departmentId);
    return department ? department.user_type : null;
  };

  // Helper function to get department name from department ID
  const getDepartmentNameById = (departmentId) => {
    const department = departments.find((dept) => dept.id === departmentId);
    return department ? department.name : "N/A";
  };

  // Fetch planning data and staff check data
  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await Http.get("/store-purchase-requisition-art");
      const staffTypeResponse = await Http.get("/staff-type-check");
      const currentStaffType = staffTypeResponse.data; // Assuming staffTypeResponse.data is the string returned by the API

      // Filter the requisition data based on the conditions
      const filteredData = res.data.filter((item) => {
        const fromDeptUserType = getUserType(item.from_department);
        const toDeptUserType = getUserType(item.to_department);

        return (
          currentStaffType === "admin" ||
          currentStaffType === "supply_chain" ||
          currentStaffType === item.created_by.user_type ||
          currentStaffType === fromDeptUserType ||
          currentStaffType === toDeptUserType
        );
      });

      setRequisitionData(filteredData);
      setStaffType(currentStaffType);

      const resMe = await Http.get("/me");
      setMe(resMe.data.data); // Store user's department_art_id
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Use custom hook to delete data
  const deleteData = useDeleteData(fetchData);
  const alert = useAlertFromLocation();

  // const approveSubmitData = (id, showMsg) => {
  //     Swal.fire({
  //       title: "Are you sure You Want to Approve this Purchase?",
  //       text: "You won't be able to revert this!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Yes, Approve it!",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         // Http.post("/" + deleteApiUrl + "/" + id + "/cash-count").then((res) => {
  //         //   setPlanningData(res.data);
  //         //   Swal.fire("Received!", showMsg + " has been deleted.", "success");
  //         // });
  //         Http.post(`/approve-store-purchase-requisition-art/${id}`)
  //           .then((res) => {
  //             Swal.fire("Approved!", "Purchase has been approved.", "success");
  //             fetchData();
  //           })
  //           .catch(function (e) {
  //             Swal.fire("Could not approve!", "Could not approve", "danger");
  //           });
  //       }
  //     });
  //   };

  const cols = [
    { field: "receipt_no", header: "Receipt" },
    { field: "date", header: "Requisition Date" },
    // { field: "receive_date", header: "Requisition Receiving Date" },
    // { field: "copy_type", header: "Copy" },
    { field: "purchase_type", header: "Purchase" },
    // {
    //   field: (item) => getDepartmentNameById(item.from_department), // Use department name instead of ID
    //   header: "From"
    // },
    // {
    //   field: (item) => getDepartmentNameById(item.to_department), // Use department name instead of ID
    //   header: "To"
    // },
    // { field: "status", header: "Status " },
    // {
    //   header: "Deliver",
    //   field: (item) => {
    //     const toDeptUserType = getUserType(item.to_department);
    //     if (staffType === "admin" || staffType === toDeptUserType) {
    //       return item.status === "requested" ? (
    //         <Link
    //           className="btn btn-primary btn-sm ms-1"
    //           to={`/create-delivery/${item.id}/req-art`}
    //         >
    //           <i className="fa fa-send"></i> Deliver
    //         </Link>
    //       ) : (
    //         <b className="btn btn-success btn-sm ms-1">Delivered</b>
    //       );
    //     }
    //     return null; // Hide column if conditions don't match
    //   },
    // },
    {
      header: " Status ",
      field: (item) => {
        const fromDeptUserType = getUserType(item.from_department);
        /*if (item.status === "delivered" && me === item.created_by) {
            return (
              <Link
                className="btn btn-primary btn-sm ms-1"
                to={`/create-receive/${item.id}/req-art`}
              >
                <i className="fa fa-send"></i> Receive
              </Link>
            );
          } */
        return (
          <i
            className={`ms-1 ${
              item.status === "requested" ? "text-secondary" : "text-success"
            }`}
          >
            {/* {item.status.toUpperCase()} */}
            {item.status
              .toLowerCase()
              .replace(/^./, (char) => char.toLocaleUpperCase())}
          </i>
        );
      },
    },
    {
      field: (item) => (
        <div className="text-xs text-nowrap">
          {/* Conditionally render buttons based on from_department user type and status */}
          {(((staffType === "admin" || staffType === "supply_chain") &&
            item.status === "requested") ||
            (staffType === getUserType(item.department_art_id) &&
              item.status === "requested")) && (
            <>
              {item.department_art_id == me.department_art_id && (
                <Link
                  to={`/edit-store-purchase-requisition-art/${item.id}`}
                  className="btn btn-primary btn-sm ms-1"
                >
                  <i className="fa fa-pencil" aria-hidden="true"></i>
                </Link>
              )}
            </>
          )}

          <Link
            to={`/view-store-purchase-requisition-art/${item.id}`}
            className="btn btn-info btn-sm ms-1"
          >
            <i className="fa fa-list" aria-hidden="true"></i>
          </Link>
          {/* {(staffType === "admin" || staffType === 'supply_chain') && item.status === "approved" && item.direct_purchase == null && (
            <Link
              to={`/create-direct-purchase-by-requisition/${item.id}`} // Navigate to the Create component with the item ID
              className="btn btn-primary btn-sm ms-1"
            >
              <i className="fa fa-plus" aria-hidden="true"></i> PO
            </Link>
          )} */}
          {/* Updated PO button condition */}
          {(staffType === "admin" || staffType === "supply_chain") &&
            (item.status === "approved" || item.status === "purchased") &&
            // item.items.some((itm) => {
            //   const totalPurchasedQty = itm.direct_purchase_item.reduce(
            //     (sum, dpi) => sum + parseFloat(dpi.qty || 0),
            //     0
            //   );
            //   return totalPurchasedQty < itm.required_qty; // Check if any item's purchased qty is less than required qty
            // })

            item.items.some(
              (itm) => itm.direct_purchase_qty_sum < itm.required_qty
            ) && (
              <Link
                to={`/create-direct-purchase-by-requisition/${item.id}`} // Navigate to the Create component with the item ID
                // className="btn btn-primary btn-sm ms-1"
                className={`btn ${
                  item.status === "purchased" ? "btn-success" : "btn-primary"
                } btn-sm ms-1`}
              >
                <i className="fa fa-plus" aria-hidden="true"></i> PO
              </Link>
            )}
          {staffType === "admin" &&
            item.status === "requested" &&
            item.direct_purchase == null && (
              <Link
                to={`/approve-store-purchase-requisition-art/${item.id}`} // Navigate to the Create component with the item ID
                className="btn btn-primary btn-sm ms-1"
              >
                <i className="fa fa-check-circle" aria-hidden="true"></i> &nbsp;
                Approve
              </Link>
              //   <button
              //   className="btn btn-primary btn-sm ms-1"
              //   onClick={() => approveSubmitData(item.id)}
              // >
              //   <i className="fa fa-check-circle" aria-hidden="true"></i> &nbsp; Approve
              // </button>
            )}
        </div>
      ),
      header: "Actions",
    },

    // {
    //   field: (item) => (
    //     <div className="text-xs text-nowrap">
    //       {/* Check if "PO" button should be visible */}
    //       {(staffType === "admin" || staffType === "supply_chain") &&
    //         (item.status === "approved" || item.status === "purchased") &&
    //         item.items.some((itm) => itm.direct_purchase_qty_sum < itm.required_qty) && (
    //           <Link
    //             to={`/create-direct-purchase-by-requisition/${item.id}`}
    //             className={`btn ${
    //               item.status === "purchased" ? "btn-success" : "btn-primary"
    //             } btn-sm ms-1`}
    //           >
    //             <i className="fa fa-plus" aria-hidden="true"></i> PO
    //           </Link>
    //         )}
    //     </div>
    //   ),
    //   header: "Actions",
    // }
  ];

  return (
    <div>
      <IsLogin />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">All Requisitions</h5>
          {(staffType === "gstore" ||
            staffType === "d store" ||
            staffType === "p store" ||
            staffType === "depot") && (
            <Link
              to="/create-store-purchase-requisition-art"
              className="btn btn-primary"
            >
              <i className="fa fa-plus-square mg-r-6"></i> Add Requisition
            </Link>
          )}
        </div>
        <div className="am-pagebody">
          <Alert alert={alert} />
          <div className="card pd-20 pd-sm-40">
            <ReactDataTable
              cols={cols}
              receiveData={requisitionData}
              isLoading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
