import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Alert from "../../../Common/Alert";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import { LoaderComponent } from "../../../Common/LoaderComponent";

const PlanningView = () => {
  const { id } = useParams();

  const [receiveData, setReceiveData] = useState(null);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fetch the planning data
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await Http.get(`/planning-detail/${id}`);
      setReceiveData(response.data);
    } catch (error) {
      Swal.fire("Error!", "Failed to fetch planning details.", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const showAlert = (message, type) => {
    setAlert({
      message: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 4000);
  };

  useEffect(() => {
    if (location.state) {
      showAlert(location.state.message, location.state.type);
    }
  }, [location]);

  if (loading || !receiveData) {
    return <LoaderComponent />;
  }

  return (
    <div>
      <IsLogin />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Planning Report</h5>
        </div>
        <div className="am-pagebody">
          <Alert alert={alert} />
          <div className="card pd-20 pd-sm-40">
            <div className="text-center">
              <img
                src="https://demo.limerickbd.com/backend/public/image/artisan-logo.svg"
                width="20%"
              />
              {/* <h5 className="text-uppercase text-sm font-weight-bolder">
                Artisan Ceramics Limited
              </h5>
              <p>House-16, Road-07, Sector-01, Uttara, Dhaka</p> */}
            </div>

            {/* General Work Order Details */}
            <div className="mb-0">
              <p>
                <strong>Receipt No:</strong> {receiveData.info.receipt_no}
              </p>
              <p>
                <strong>Start Date:</strong> {receiveData.info.start_date}
              </p>
              <p>
                <strong>End Date:</strong> {receiveData.info.end_date}
              </p>
              {/* <p>
                <strong>Created By:</strong> {receiveData.info.created_by}
              </p> */}
            </div>

            {/* Item Table */}
            <div className="table-responsive p-0 pb-2">
              <table className="table table-sm table-bordered table-striped align-items-center mb-0 _datatable">
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Item Name</th>
                    <th>Shape</th> {/* New Shape column */}
                    {/* Dynamically add work order columns */}
                    {receiveData["work-order"].map((workOrder) => (
                      <th key={workOrder.production_work_order_id}>
                        {workOrder.name} {workOrder.company_name} <b>#{workOrder.receipt_no}</b>
                      </th>
                    ))}
                    <th>Line Total</th> {/* New Line Total column */}
                  </tr>
                </thead>
                <tbody>
                  {receiveData.list.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.product}</td>
                      <td>{item.shape || "N/A"}</td>
                      {receiveData["work-order"].map((workOrder) => (
                        <td key={workOrder.production_work_order_id}>
                          {item.work_order[
                            workOrder.production_work_order_id
                          ] || ""}
                        </td>
                      ))}
                      <td>
                        {Object.values(item.work_order).reduce(
                          (sum, qty) => sum + qty,
                          0
                        )}
                      </td>{" "}
                      {/* Calculate and display Line Total */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanningView;
